import storeUtils from './storeUtils';

export const actionTypes = {
	locationFail: 'LOCATION_FAIL',
	
	fetchTimeZonesRequest: 'FETCH_TIMEZONES_LOCATION_REQUEST',
	fetchTimeZonesSuccess: 'FETCH_TIMEZONES_LOCATION_SUCCESS'
};

const initialState = { timeZones: null, countryCode: null, loading: null, errors: null };

export const locationActionCreators = {

	fetchTimeZones: countryCode => async (dispatch, getState) => {
		if(countryCode === getState().location.countryCode) return;	// caching
		dispatch({ type: actionTypes.fetchTimeZonesRequest });
		const data = await storeUtils.execute('location', 'getTimeZones', { countryCode: countryCode });
		if(data.errors) {
			dispatch({ type: actionTypes.locationFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchTimeZonesSuccess, timeZones: data.timezone, countryCode: countryCode });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('LOCATION_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.locationFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchTimeZonesSuccess: {
			// I think we just need the strings so simplifying here...
			const timeZones = action.timeZones.map(tz => tz.timezone).sort();
			return {
				...state,
				loading: null,
				timeZones: timeZones,
				countryCode: action.countryCode
			}
		}

		default: {
			return state;
		}
	}
}