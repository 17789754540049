import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import dates from 'services/dates';
import { errorsActionCreators } from 'store/errors';
import { ordersActionCreators } from 'store/orders';
import { menusActionCreators } from 'store/menus';
import { printersActionCreators } from 'store/printers';
import { settingsActionCreators } from 'store/settings';
import useOrderEvents from 'store/OrderEvents';
import Notification from 'components/Notification';
import ValidationMessage from 'components/ValidationMessage';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import OrderDetailsDialog from 'dialogs/OrderDetailsDialog';
import OrderCancelDialog from './dialogs/OrderCancelDialog';
import OrderModifyDialog from './dialogs/OrderModifyDialog';
import OrderItemAddDialog from './dialogs/OrderItemAddDialog';
import OrderItemModifiersDialog from './dialogs/OrderItemModifiersDialog';
import OrdersList from './OrdersList';
import './OrdersList.scss';

const OrdersListContainer = props => {
	const _t = useL10n().getText;
	const [showOrderDetailsDialog, setShowOrderDetailsDialog] = useState(false);
	const [showOrderCancelDialog, setShowOrderCancelDialog] = useState(false);
	const [showOrderModifyDialog, setShowOrderModifyDialog] = useState(0);	// 1 = open and clear form, 2 = open but leave form (used when "returning" from adding an item)
	const [showOrderItemAddDialog, setShowOrderItemAddDialog] = useState(false);
	const [showOrderItemModifiersDialog, setShowOrderItemModifiersDialog] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [currentOrder, setCurrentOrder] = useState();
	const [itemsToAdd, setItemsToAdd] = useState([]);
	const [today, setToday] = useState();

	const orderStore = useSelector(state => state.orders);
	const settingStore = useSelector(state => state.settings);
	const menuStore = useSelector(state => state.menus);
	const venue = useSelector(state => state.venues.venue);
	const currencySymbol = venue?.currencySymbol;
	const timeZone = venue?.openingHours?.timezone.timezone;
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();

	const getPayload = options => {
		console.log(options);
		// prep the payload based on user's settings...
		let from;
		let to;
		const pageSize = calcPageSize(settingStore.fullscreen);
		switch(options.fromOption) {
			case 0:	// today
				from = options.today;
				to = options.today + dates.day - 1;
				break;
			case 1:	// yesterday
				from = options.today - dates.day;
				to = options.today - 1;
				break;
			case 2:	// last 2 days
				from = options.today - dates.day;
				to = options.today + dates.day - 1;
				break;
			case 3:	// last 3 days
				from = options.today - dates.day * 2;
				to = options.today + dates.day - 1;
				break;
			case 4:
				from = dates.zonedDateToUtc(timeZone, options.fromDate).getTime();
				to = options.toDate ? dates.zonedDateToUtc(timeZone, options.toDate).getTime() + dates.day - 1 : null;
				break;
			default: return;
		}
		const payload = {
			orderStatus: options.orderStatus,
			from: from,
			pagingOffset: typeof options.page === 'number' ? (options.page - 1) * pageSize : 0,
			pagingSize: pageSize
		};
		if(to) payload.to = to;
		return payload;
	}

	const setOrders = data => {
		dispatch(ordersActionCreators.setOrders(data, settingStore.orderGroupingMinutes, 'all'));
	}

	const setErrors = errors => {
		dispatch(errorsActionCreators.setErrors(errors));
	}

	const orderEvents = useOrderEvents({
		station: 'all',
		settings: settingStore,
		orders: orderStore.orderData,
		venue: venue,
		setOrders,
		setErrors,
		lostConnectionHandler: lost => setShowConfirmDialog(lost),
		getPayload: getPayload
	});

	useEffect(() => {
		// the BUMP button retaining focus can be odd so if it caused the store action, then make it lose focus (PB-244)
		if(!orderStore.loading && currentOrder) {
			const btn = document.activeElement;
			if(btn.classList.contains('Pane-item_textButton')) {
				btn.blur();
			}
		}
	}, [orderStore.loading, currentOrder]);

	useEffect(() => {
		// start by clearing any existing orders in the store...
		dispatch(ordersActionCreators.setOrders({ orders: [] }, settingStore.orderGroupingMinutes));
	}, [dispatch, settingStore.orderGroupingMinutes]);

	useEffect(() => {
		if(orderStore.orderData) {
			// keep store orders and orderEvents orders bound together
			orderEvents.setOrderData(orderStore.orderData);
		}
	}, [orderStore.orderData, orderEvents]);

	useEffect(() => {
		// set today to be today in appropriate TZ
		if(timeZone) {
			const today = dates.getLocalToday(timeZone);
			setToday(today);
		}
	}, [timeZone]);

	const fetchOrders = options => {
		const payload = getPayload(options);
		// console.log('fetchOrders', payload);
		dispatch(ordersActionCreators.fetchOrders(payload, settingStore.orderGroupingMinutes));
	}

	const updateSetting = (setting, value) => {
		dispatch(settingsActionCreators.updateSetting(setting, value));
	}

	const hideOrderCancelDialog = payload => {
		// console.log('dialog returned:', payload);
		setShowOrderCancelDialog(false);
		if(payload) {
			payload.externalOrderId = currentOrder.externalOrderId;
			dispatch(ordersActionCreators.cancelOrder(payload));
		}
	}

	const hideOrderDetailsDialog = btn => {
		// console.log('hideOrderDetailsDialog returned:', btn);
		switch(btn) {
			case 'btnCancel': setShowOrderCancelDialog(true); break;
			case 'btnModify': setShowOrderModifyDialog(1); break;
			case 'btnReprint':
				// console.log(currentOrder);
				dispatch(printersActionCreators.createPrintJobs({
					externalOrderIds: [currentOrder.externalOrderId]
				}));
				break;
			default: break;
		}
		setShowOrderDetailsDialog(false);
	}

	const hideOrderModifyDialog = (e, formData) => {
		// console.log('hideOrderModifyDialog returned:', e?.currentTarget.id, formData);
		switch(e?.currentTarget.id) {
			case 'btnAdd': {
				dispatch(menusActionCreators.fetchOrderMenus());
				setShowOrderItemAddDialog(true);
				break;
			}
			case 'btnOk': {	// MODIFY ORDER selected
				dispatch(ordersActionCreators.updateOrder(currentOrder, itemsToAdd, formData));
				break;
			}
			default: break;
		}
		setShowOrderModifyDialog(0);
	}

	const removeAddedItem = ix => {
		console.log(itemsToAdd);
		const items = [...itemsToAdd];
		items.splice(ix, 1);
		setItemsToAdd(items);
	}

	const hideOrderItemAddDialog = newItem => {
		setShowOrderItemAddDialog(false);
		if(newItem.item) {
			setItemsToAdd([...itemsToAdd, newItem]);
			if(newItem.item.modifierGroups.length) {
				setShowOrderItemModifiersDialog(true);
			} else {
				setShowOrderModifyDialog(2);
			}
		} else {
			setShowOrderModifyDialog(2);
		}
	}

	const hideOrderItemModifiersDialog = obj => {
		setShowOrderItemModifiersDialog(false);
		if(Array.isArray(obj)) {
			const updatedItemsToAdd = [...itemsToAdd];
			const lastAddedItemIndex = itemsToAdd.length - 1;
			const item = itemsToAdd[lastAddedItemIndex].item;
			item.modifierItems = obj;
			console.log(updatedItemsToAdd);
			setItemsToAdd(updatedItemsToAdd);
			setShowOrderModifyDialog(2);
		}
		// console.log('hideOrderItemModifiersDialog:', obj);
	}

	const orderDetailsHandler = order => {
		const clonedOrder = { ...order };
		clonedOrder.orderItems = [...order.orderItems];
		// console.log(clonedOrder.orderItems[0]);
		setCurrentOrder(clonedOrder);
		setItemsToAdd([]);
		setShowOrderDetailsDialog(true);
	}

	const bumpHandler = index => {
		setCurrentOrder(orderStore.orderData.orders[index]);
		dispatch(ordersActionCreators.bumpOrders(index, orderStore.orderData.kitchenDisplayType));
	}

	const undoHandler = e => {
		dispatch(ordersActionCreators.undoBumpOrders(orderStore.orderData.kitchenDisplayType));
	}

	const hideConfirmDialogHandler = response => {
		if(response.button === 'btnOk') {
			// user confirms they want to attempt to reconnect to SSE service
			orderEvents.reset();
		}
		setShowConfirmDialog(false);
	}


	const resetOrder = () => {
		// unmark all items marked for removal
		if(currentOrder) {
			currentOrder.orderItems.forEach(item => {
				delete(item.delete);
			});
		}
	}

	return (
		<Fragment>

			{ timeZone
					?
						<OrdersList
							orderStore={ orderStore }
							settings={ settingStore }
							currentOrder={ currentOrder }
							venue={ venue }
							currencySymbol={ currencySymbol }
							timeZone={ timeZone }
							fetchOrders={ fetchOrders }
							fullscreen={ settingStore.fullscreen }
							today={ today }
							loading={ orderEvents.isBusy }
							orderDetailsHandler={ orderDetailsHandler }
							updateSetting={ updateSetting }
							bumpHandler={ bumpHandler }
							undoHandler={ undoHandler }
						/>
					:
						<ValidationMessage
							open={ venue && !venue.openingHours?.timezone }
							message={ _t('TXT_TIMEZONE_MISSING', false, <Link to="/venues/locations/opening-hours">{ _t('TXT_OPENINGHOURS_LINK') }</Link>) }
						/>
			}

			<OrderDetailsDialog
				show={ showOrderDetailsDialog }
				privileged={ user?.hasPrivilegedAccess }
				order={ currentOrder }
				currencySymbol={ currencySymbol }
				timeZone={ timeZone }
				from="OrdersList"
				onHide={ hideOrderDetailsDialog }
			/>

			<OrderCancelDialog
				orderId={ currentOrder?.externalOrderId }
				show={ showOrderCancelDialog }
				onHide={ hideOrderCancelDialog }
			/>

			<OrderModifyDialog
				show={ showOrderModifyDialog }
				order={ currentOrder }
				itemsToAdd={ itemsToAdd }
				currencySymbol={ currencySymbol }
				onHide={ hideOrderModifyDialog }
				setOrder={ setCurrentOrder }
				removeAddedItem={ removeAddedItem }
				onShow={ resetOrder }
			/>

			<OrderItemAddDialog
				show={ showOrderItemAddDialog }
				menus={ menuStore.orderMenus }	// for selection
				items={ menuStore.orderItems }	// for search
				currencySymbol={ currencySymbol }
				onHide={ hideOrderItemAddDialog }
			/>

			<OrderItemModifiersDialog
				show={ showOrderItemModifiersDialog }
				item={ itemsToAdd.length ? itemsToAdd[itemsToAdd.length - 1].item : null }
				currencySymbol={ currencySymbol }
				onHide={ hideOrderItemModifiersDialog }
			/>

			<ConfirmDialog
				heading={ _t('HDG_LOST_CONNECTION', true) }
				message={ <Notification type="warning">{ _t('TXT_LOST_CONNECTION') }</Notification> }
				okText={ _t('BTN_RECONNECT', true) }
				show={ showConfirmDialog }
				onHide={ hideConfirmDialogHandler }
			/>

		</Fragment>
	);
};

export default OrdersListContainer;

const calcPageSize = fullscreen => {
	const furnitureHeight = fullscreen ? 150 : 360;
	const pageSize = Math.max(Math.round((window.innerHeight - furnitureHeight) / 40), 1);	// - a) / b) a = rough height of page minus rows, b = height of row
	return pageSize;
}