import useL10n from 'L10n';

const Notification = ({ type, design, children, ...props }) => {
	const _t = useL10n().getText;

	return (
		<div className={ 'notification notification_' + type } { ...props }>
			<span className="material-icons" title={ _t('TTP_' + type.toUpperCase()) }>
				{ designs[design || 'solid'][type] }
			</span>
			{ children }
		</div>
	);
};

export default Notification;

const designs = {
	outline: {
		error: 'error_outline',
		warning: 'warning_amber',
		info: 'info_outline'
	},
	solid: {
		error: 'error',
		warning: 'warning',
		info: 'info'
	}
}