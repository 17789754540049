import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownButton from 'components/DropdownButton';
import { l10nActionCreators } from 'store/l10n';

const Translate = props => {

	const [language, setLanguage] = useState();
	const l10nStore = useSelector(state => state.l10n);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(l10nActionCreators.fetchTranslationsForAdmin('en'));
	}, [dispatch]);

	useEffect(() => {
		if(language) {
			dispatch(l10nActionCreators.fetchTranslationsForAdmin(language));
		}
	}, [language, dispatch]);

	const renderMissingKeys = () => {
		return Object.keys(l10nStore.en).map(key => {
			if(!l10nStore.check[key]) {
				const entry = l10nStore.en[key];
				if(typeof entry === 'string' && !entry.startsWith('__')) {
				const arr = l10nStore.en[key].split(' // ');	// check for comments in translation string
				const en = arr[0];
				return <div key={ key }>
					"{ key }": "", &nbsp;&nbsp;&gt;&gt;&gt;&nbsp;&nbsp;
					{ en }
					{ arr[1] &&
						<em> (hint: { arr[1] })</em>
					}
				</div>
				}
			} else return '';
		});
	}

	const renderExtraKeys = () => {
		return Object.keys(l10nStore.check).map(key => {
			if(!l10nStore.en[key]) {
				return <div key={ key }>"{ key }": "{ l10nStore.check[key] }",</div>
			} else return ''
		});
	}

	return (
		<div>
			<h3>Check For Missing Translations</h3>

			<DropdownButton placeholder="Choose language to check" name="language" value={ language } onChange={ (event, field, value, label) => setLanguage(value) }>
				<option value="el">Greek</option>
			</DropdownButton>

			<br/>

			{ language && l10nStore.en && l10nStore.check ?
					<Fragment>
						<h4>Missing Keys</h4>
						{ renderMissingKeys() }
						<br/>
						<h4>Extra Keys</h4>
						{ renderExtraKeys() }
					</Fragment>
				:
				<Fragment>
					<h4>Hints:</h4>
					<ul>
						<li>HDG_ = heading: <b>T</b>his <b>S</b>hould <b>B</b>e <b>C</b>apitalised</li>
						<li>BTN_ = button: <b>T</b>his <b>S</b>hould <b>B</b>e <b>C</b>apitalised</li>
						<li>PLH_ = placeholder: <b>T</b>his <b>S</b>hould <b>B</b>e <b>C</b>apitalised</li>
						<li>LBL_ = label: <b>T</b>his should be in sentence case</li>
						<li>TTP_ = tooltip: this should be all in lower case</li>
						<li>TXT_ = generic text: this can be anything - please follow the English styling</li>
					</ul>
					<p>
						${ '{' }n{ '}' } represents a substitution - the code will replace this with something appropriate on the actual page.
						Simply copy these to the appropriate place in the translated text.
					</p>
					<p>
						For languages where uppercase text varies from lowercase,
						HDG_ and BTN_ entries should be an array with the lowercase (actually: capitalised) version first,
						followed by the uppercase, i.e. ["&lt;Capitalised&gt;", "&lt;UPPERCASE&gt;"].
					</p>
				</Fragment>
			}
		</div>
	);
};

export default Translate;