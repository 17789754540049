// I think the previous version used to automatically select the venue
// if say a venue owner only had one (probably a common scenario)
// however this is not yet implemented
import { Fragment, useRef } from 'react';
import utilities from 'services/utilities';
import DropdownButton from 'components/DropdownButton';
import { InputWithButton } from 'components/controls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { PaneItem } from 'components/Pane';
import Notification from 'components/Notification';

const VenuesDialog = props => {

	const menuRef = useRef();
	const type = props?.user?.hasPrivilegedAccess ? 'Search' : 'Selector';
	
	const options = type === 'Selector' ? props.venueStore.venues?.map(venue => {
		return { value: venue.venueUUID, label: venue.name };
	}) : null;

	const venueClickHandler = (e, id, value, label) => {
		e.preventDefault();
		let venueId;
		const source = e.currentTarget;
		if(source.tagName === 'A') {
			venueId = source.getAttribute('data-id');
		} else {
			venueId = value;
		}
		props.setVenue(venueId);
	}

	const searchKeyDownHandler = e => {
		switch(e.key) {
			case 'ArrowUp':
			case 'ArrowDown':
				e.preventDefault();
				const menu = menuRef.current.childNodes;
				const option = menu[0];
				if(option) option.focus();
				break;
			case 'Enter': props.fetchVenues(); break;
			default: break;
		}
	}

	const clickHandler = e => {
		props.onHide(e.currentTarget.id);
	}

	const optionKeyDownHandler = e => {
		let index, option;
		const items = menuRef.current.children.length;
		switch(e.key) {
			case 'ArrowUp':
				e.preventDefault();
				index = utilities.nodeIndex(e.currentTarget);
				if(index) {
					option = menuRef.current.childNodes[--index];
				} else {
					option = menuRef.current.childNodes[items - 1];
				}
				if(option) option.focus();
				break;
			case 'ArrowDown':
				e.preventDefault();
				index = utilities.nodeIndex(e.currentTarget);
				if(index < (items - 1)) {
					option = menuRef.current.childNodes[++index];
				} else {
					option = menuRef.current.childNodes[0];
				}
				if(option) option.focus();
				break;
			case 'Enter':
				// allow the selecting of the currently focussed item with the enter key
				index = utilities.nodeIndex(e.currentTarget);
				const venueId = props.venueStore.venues[index].venueUUID;
				props.setVenue(venueId);
				break;
			default: break;
		}
	}

	return (
		<Modal id="venuesDialog" show={ props.show }>
			<ModalHeader>{ utilities.getText('HDG_VENUE_' + type.toUpperCase()) }</ModalHeader>
			<ModalBody>
				{ type === 'Selector'
					?
						options?.length === 0
							?
								<Notification type="error" design="outline">{ utilities.getText('TXT_NO_VENUES') }</Notification>
							:
								<DropdownButton
									placeholder="Select Venue"
									className={ options?.length ? '' : 'async' }
									options={ options }
									disabled={ !options }
									value={ props.venueStore.venue?.venueUUID }
									onChange={ venueClickHandler }
								/>
					:
						<Fragment>
							<InputWithButton
							type="search"
								placeholder="Venue Name"
								className="primary"
								icon="search"
								focus={ true }
								value={ props.search }
								isLoading={ !!props.venueStore.loading }
								onChange={ props.onChange }
								onButtonClick={ props.fetchVenues }
								onKeyDown={ searchKeyDownHandler }
							/>
							{ props.venueStore.venues && props.venueStore.venues.length > 0 &&
								<ul ref={ menuRef } className="Pane-items Pane-items_inline scrolling" role="menu">
									{ props.venueStore.venues.map((venue, ix) => {
										return (
											<PaneItem
												role="menuitem"
												tabIndex="-1"
												key={ venue.venueUUID }
												index={ venue.venueUUID }
												onClick={ props.setVenue }
												onKeyDown={ optionKeyDownHandler }
											>
												{ venue.name }<br/>{ venue.address }
											</PaneItem>
										)
									})}
								</ul>
							}
						</Fragment>
				}
			</ModalBody>
				<ModalFooter>
					<button type="button" id="btnCancel" className="btn btn-secondary focus" onClick={ clickHandler }>{ utilities.getText('LBL_CANCEL') }</button>
					{ props.user?.hasPrivilegedAccess &&
						<button type="button" id="btnAddNew" className="btn btn-primary" onClick={ clickHandler }>{ utilities.getText('LBL_ADD_VENUE') }</button>
					}
				</ModalFooter>
			</Modal>
	);
};

export default VenuesDialog;