// TO DO:
// not currently wrapping elements in quotes or indicating value "types" in any way as I don't think our usage needs it but could make it more generally useful?

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './JsonViewer.scss';

const JsonViewer = props => {

	if(!props.json) return null;

	const stateIcons = {
		open: 'keyboard_arrow_down',
		closed: 'keyboard_arrow_right'
	}

	const getType = node => {
		let nodeType = typeof node;
		if(node === null) return 'null';
		if(nodeType === 'object') {
			nodeType = Array.isArray(node) ? 'array' : 'object';
		}
		return nodeType;
	}

	const toggleNode = e => {
		// if you don't like DOM manipulation, look away now...
		// the app never needs to know the toggle state (this is purely a visual assist-thing), so no need to maintain in React state
		let icon = e.currentTarget.children[0];
		const open = icon.innerHTML === stateIcons.open;
		icon.innerHTML = open ? stateIcons.closed : stateIcons.open;
		const container = e.currentTarget.parentNode;
		const list = container.children[2];
		list.hidden = open;
		if(open) {
			container.classList.add('collapsed');
		} else {
			container.classList.remove('collapsed');
		}
	}
	
	const renderNode = (json, showComma) => {
		if(!json) return null;
		const nodeType = getType(json);
		const keys = Object.keys(json);
		const lastIx = keys.length - 1;
		return (
			<Fragment>
				{ nodeType === 'array' ? '[' : '{' }
				<span className="JsonViewer-ellipsis">&hellip;</span>
				<ul>
						{
							keys.map((key, ix) => {
								const item = json[key];
								const itemType = getType(item);
								// console.log(key, item, itemType, nodeType);
								return <li key={ ix }>
									{ (itemType === 'object' || itemType === 'array') &&
										<button className="JsonViewer-toggle" onClick={ toggleNode }><i className="material-icons">{ stateIcons.open }</i></button>
									}
									{ nodeType === 'object' && key + ': ' }
									{ itemType === 'object' || itemType === 'array'
										? renderNode(item, ix !== lastIx)
										: <span className="JsonViewer-value">{ item + (ix < lastIx ? ',' : '') }</span>
									}
								</li>
							})
						}
				</ul>
				{ (nodeType === 'array' ? ']' : '}') + (showComma ? ',' : '') }
			</Fragment>
		);
	}
	
	const classes = classNames('JsonViewer', props.className);
	return (
		<div className={ classes }>
			<button className="JsonViewer-toggle" onClick={ toggleNode }><i className="material-icons">{ stateIcons.open }</i></button>
			{ renderNode(props.json) }
		</div>
	);
};

JsonViewer.propTypes = {
	json: PropTypes.object
};

export default JsonViewer;