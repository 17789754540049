import React from 'react';
import PropTypes from 'prop-types';
import { FormInput } from './formControls';
import { Radio } from './controls';
import './PriceOptions.scss';

const PriceOptions = props => {

	return (
		<div className="form-row PriceOptions PriceOptions_stacked" id={ props.id }>
			<div className="col">
				<div className="input-group">
					<div className="input-group-prepend">
						<div className="input-group-text">
							<Radio name={ props.name } value={ props.config[0].value } checked={ props.which === props.config[0].value } disabled={ props.disabled } onChange={ props.form.onChange }>{ props.config[0].label }</Radio>
						</div>
					</div>
					<FormInput type="money" form={ props.form } field={ props.config[0].field }/>
				</div>
				<div className="input-group">
					<div className="input-group-prepend">
						<div className="input-group-text">
							<Radio name={ props.name } value={ props.config[1].value } checked={ props.which === props.config[1].value } disabled={ props.disabled } onChange={ props.form.onChange }>{ props.config[1].label }</Radio>
						</div>
					</div>
					<FormInput type="money" form={ props.form } field={ props.config[1].field }/>
				</div>
			</div>
		</div>
	);
};

PriceOptions.propTypes = {
	form: PropTypes.object.isRequired,
	config: PropTypes.array.isRequired
};

export default PriceOptions;