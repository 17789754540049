// combined photo uploader / carousel / lightbox-style component
// built for use on menus > menu item pane
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import utilities from 'services/utilities';
import PhotoCarousel from './PhotoCarousel';
import MessageDialog from 'dialogs/MessageDialog';
import './UploaderViewer.scss';

const UploaderViewer = props => {

	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const preventDefaults = e => {
		e.preventDefault();
		e.stopPropagation();
	}

	const addClass = e => {
		const dropZone = uploaderRef.current;
		if(dropZone.classList.contains('UploaderViewer_disabled')) return;
		dropZone.classList.add('UploaderViewer_dragover');
		e.dataTransfer.dropEffect = 'copy';
	}

	const removeClass = e => {
		const dropZone = uploaderRef.current;
		if(dropZone.classList.contains('UploaderViewer_disabled')) return;
		dropZone.classList.remove('UploaderViewer_dragover');
	}

	const helpClickHandler = e => {
		setShowMessageDialog(true);
	}

	const hideMessageDialogHandler = () => {
		setShowMessageDialog(false);
	}

	const uploaderRef = useRef();

	useEffect(() => {
		const onDrop = e => {
			if(uploaderRef.current.classList.contains('UploaderViewer_disabled')) return;
			const dt = e.dataTransfer;
			const { files } = dt;
			props.onPhotoDrop(files);
		}
	
		// console.log('initialise drag and drop...');
		const dropZone = uploaderRef.current;
		['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropZone.addEventListener(eventName, preventDefaults, false));
		['dragenter', 'dragover'].forEach(eventName => dropZone.addEventListener(eventName, addClass, false));
		['dragleave', 'drop'].forEach(eventName => dropZone.addEventListener(eventName, removeClass, false));
		dropZone.addEventListener('drop', onDrop, false);

		return () => {
			// console.log('deactivate drag and drop...');
			['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropZone.removeEventListener(eventName, preventDefaults, false));
			['dragenter', 'dragover'].forEach(eventName => dropZone.removeEventListener(eventName, addClass, false));
			['dragleave', 'drop'].forEach(eventName => dropZone.removeEventListener(eventName, removeClass, false));
			dropZone.removeEventListener('drop', onDrop, false);
		}
	}, [props]);

	const classes = classNames('UploaderViewer', props.className, { 'UploaderViewer_disabled': props.disabled });
	
	return (
		<div ref={ uploaderRef } className={ classes }>

			<PhotoCarousel
				photos={ props.photos }
				visibleSlides={ 1 }
			>
				<button type="button" disabled={ props.disabled } className="btn" title="how to upload a photo" id="btnHelp" onClick={ helpClickHandler }>
					<i className="material-icons">info_outline</i>
				</button>

				<label>{ props.photos?.length ? 'UPLOAD ANOTHER PHOTO' : 'UPLOAD A PHOTO' }</label>

			</PhotoCarousel>

			<MessageDialog
				title={ utilities.getText('HDG_PHOTO_UPLOAD_HELP') }
				message={ utilities.getText('TXT_PHOTO_UPLOAD_HELP') }
				show={ showMessageDialog }
				onHide={ hideMessageDialogHandler }
			/>

		</div>
	);

};

export default UploaderViewer;