import Content from 'layout/Content';
import Report from './Report';
import LoyaltyRewards from './LoyaltyRewards';
import './index.scss';

const CustomersNav = props => {

	const nav = [
		{ path: 'customers', label: 'Customers', component: Report },
		{ path: 'loyalty-rewards', label: 'Loyalty Rewards', component: LoyaltyRewards }
	];

	return (
		<Content path="/venues/customers/" nav={ nav }/>
	);

}

export default CustomersNav;