// converts reactstrap's Dropdown control into a typeahead control by hiding the actuating button (see css) and replacing it with an input control
// note: any props passed that are not specifically destructured are passed to the input element - useful for event handlers, etc

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';
import './Typeahead.scss';

const Typeahead = ({ className, keys, labels, data, minLength, maxMatches, highlight, onChange, value, ...props }) => {

	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState();
	const [matches, setMatches] = useState([]);

	useEffect(() => {
		// initialise if there's initialisation data provided
		if(keys) {
			if(value && data) {
				const initialEntry = data.find(entry => {
					return entry[keys] === value;
				});
				if(initialEntry) {
					setSelected(initialEntry);
				}
			} else {
				setSelected();
				setOpen(false);
			}
		} else {
			setSelected(value ?? null);
			if(!value) setOpen(false);	// needed when used in TagList
	}
	}, [value, data, keys, labels]);

	const toggle = e => {
		e.preventDefault();
		setOpen(!open);
	}

	const onInputChange = e => {
		let open, input = e.target.value;
		let matches = [];
		if(minLength && input.length < minLength) {
			open = false;
		} else if(data) {
			matches = data.filter(entry => {
				if(typeof(entry) === 'string') {
					return entry.toLowerCase().indexOf(input.toLowerCase()) !== -1;
				} else {
					return entry[labels].toLowerCase().indexOf(input.toLowerCase()) !== -1;
				}
			});
			open = !!matches.length;
		} else {
			console.warn('Typeahead: no data provided.');
		}
		if(keys) {
			setSelected({
				[keys]: null,
				[labels]: input
			});
		} else {
			setSelected(input);
		}
		setOpen(open);
		setMatches(matches);
		if(!keys) {
			// the options are just string suggestions so return the typed value (so externally works a bit like a regular input)
			onChange(e, (props.name ? props.name : props.id), input);
		}
	}

	const onSelection = e => {
		toggle(e);
		const target = e.currentTarget;
		if(target.tagName !== 'BUTTON') return;
		const key = target.value;
		const label = target.textContent;
		if(keys) {
			setSelected({
				[keys]: key,
				[labels]: label
			});
			} else {
			setSelected(label);
		}
		onChange(e, props.name ? props.name : props.id, keys ? key : label, label);
	}
	
	const highlighter = label => {
		if(highlight) {
			const input = (selected && labels) ? selected[labels] : (selected || '');
			const re = new RegExp(input.toLowerCase(), 'i');	// maybe a little error prone if users type in regex style text although highly unlikely
			label = label.replace(re, '<mark>$&</mark>');
		}
		return label;
	}

	const renderOptions = () => {
		let options;
		if(maxMatches && matches.length > maxMatches) {
			options = matches.slice(0, maxMatches);
		} else {
			options = matches;
		}
		return options.map((option, ix) => {
			return (
				<li key={ ix }>
					<button
						role="menuitem"
						value={ keys ? option[keys] : null }
						dangerouslySetInnerHTML={{ __html: highlighter(typeof option === 'string' ? option : option[labels]) }}
						onClick={ onSelection }
					/>
				</li>
			);
		})
	}

	const inputClassName = classNames('form-control', className);
	return (
		<Dropdown className="Typeahead input-group" isOpen={ open } toggle={ toggle }>

			<input
				type="text"
				autoComplete="off"
				className={ inputClassName }
				value={ (labels && selected) ? selected[labels] : (selected ?? '') }
				{ ...props }
				onChange={ onInputChange }
			/>

			<DropdownToggle className="btn btn-block" tag="button"/>

			<DropdownMenu tag="ul">
				{ renderOptions() }
			</DropdownMenu>

		</Dropdown>
	);
};

Typeahead.propTypes = {
	className: PropTypes.string,	// add a custom class to the component's own
	name: PropTypes.string,
	data: PropTypes.array,
	keys: PropTypes.string,
	labels: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxMatches: PropTypes.number,
	minLength: PropTypes.number,
	onChange: PropTypes.func
};

export default Typeahead;