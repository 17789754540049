import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { posActionCreators } from '../store/pos';
import PosConsole from './PosConsole';

const PosConsoleContainer = props => {

	const posStore = useSelector(state => state.pos);
	const dispatch = useDispatch();

	useEffect(() => {
		if(props.show && !posStore.data) {	// && !posStore.exception) { why did I have this?
			dispatch(posActionCreators.sync());
		}
	}, [dispatch, posStore.data, props.show]);

	const sync = e => {
		dispatch(posActionCreators.sync());
	}

	const close = e => {
		props.close();
	}

	if(props.y) {
		return (
			<PosConsole
				posStore={ posStore }
				show={ props.show }
				position={{ x: window.innerWidth - 510, y: props.y }}
				sync={ sync }
				close={ close }
			/>
		);
	} else {
		return '';
	}
};

export default PosConsoleContainer;