// functionality needed for both "Branding" anf menu item photo management started off fairly similar
// however, if the functionality diverges too much, might be worth bifurcating this component
import React, { Fragment, useState } from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import utilities from '../services/utilities';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { IconButton } from './buttons';
import PhotoViewer from './PhotoViewer';
import './PhotoCarousel.scss';

const PhotoCarousel = props => {
	const [currentPhoto, setCurrentPhoto] = useState();
	const [showFullscreen, setShowFullscreen] = useState();
	const [confirmDialogShow, setConfirmDialogShow] = useState(false);

	const width = props.aspectRatio === 1 ? 100 : 400;
	const height = props.aspectRatio === 1 ? 100 : 300;
	const number = props.photos ? props.photos.length : 0;

	const onSlideClick = e => {
		const index = utilities.nodeIndex(e.currentTarget);
		// console.log(props.photos[index]);
		setCurrentPhoto(props.photos[index]);
		if(!props.showToolbar) {
			// if there's no toolbar, then clicking on a photo should show the photo fullscreen (menu item usage)
			setShowFullscreen(true);
		}
	}

	const onShowFullscreen = e => {
		setShowFullscreen(true);
	}

	const onCloseClick = e => {
		setShowFullscreen(false);
	}

	const onDelete = e => {
		// console.log('delete', currentPhoto.photoId, props.type);
		setConfirmDialogShow(true);
	}

	const confirmDialogHide = response => {
		if(response.button === 'btnOk') {
			props.onDeletePhoto(currentPhoto, props.type);
		}
		setConfirmDialogShow(false);
	}

	const onSetFavourite = () => {
		props.setFavourite(currentPhoto);
	}

	return (
		<Fragment>
			{ !!props.showToolbar &&
				<div className="Pane-toolbar">
					<IconButton
						title={ utilities.getText('TTP_DELETE_SELECTED_PHOTO') }
						disabled={ !currentPhoto || props.photos?.length === 1 || (props.favourite && props.favourite === currentPhoto.photoId) }	// not a good idea to delete the only photo!
						async={ props.action === 'deleting' }
						icon="delete"
						onClick={ onDelete }
					/>
					<IconButton
						title={ utilities.getText('TTP_VIEW_SELECTED_PHOTO') }
						disabled={ !currentPhoto }
						icon="fullscreen"
						onClick={ onShowFullscreen }
					/>
					{ props.favourite &&
						<IconButton
							title={ utilities.getText('TTP_MAKE_FEATURED_PHOTO') }
							disabled={ !currentPhoto || currentPhoto.photoId === props.favourite }
							async={ props.action === 'featuring' }
							icon="favorite_border"
							onClick={ onSetFavourite }
						/>
					}
				</div>
			}

			<CarouselProvider
				className="PhotoCarousel"
				naturalSlideWidth={ width }
				naturalSlideHeight={ height }
				totalSlides={ number }
				currentSlide={ (number && props.showLast) ? number - 1 : 0 }
				visibleSlides={ props.visibleSlides }
				hasMasterSpinner={ false }
				touchEnabled={ true }
				// dragEnabled={ true }
			>
				{ props.photos &&
					<Slider>
						{ props.photos?.map((photo, ix) => {
								let photoUrl = photo.photoURL;
								const width = photo.widths.length ? utilities.findNearest(photo.widths, props.targetWidth ?? 320) : 'full';
								photoUrl = photoUrl.replace('xxxx', width);
								const classes = classNames('PhotoCarousel-photo', { 'PhotoCarousel-photo_selected': photo.photoId === currentPhoto?.photoId });
								return <Slide key={ ix } index={ ix } onClick={ onSlideClick }>
									<div className={ classes } style={{ backgroundImage: 'url(' + photoUrl + ')' }}>
										{ props.favourite === photo.photoId &&
											<i className="material-icons">favorite_border</i>
										}
									</div>
								</Slide>
							})
						}
					</Slider>
				}

				<ButtonBack className="btn btn-back"><i className="material-icons">keyboard_arrow_left</i></ButtonBack>
				<ButtonNext className="btn btn-next"><i className="material-icons">keyboard_arrow_right</i></ButtonNext>

				{ !!props.showDots &&
					<DotGroup/>
				}

				{ props.children }

			</CarouselProvider>

			<ConfirmDialog
				action="Delete Photo"
				message="Are you sure you want to delete this photo?"
				show={ confirmDialogShow }
				onHide={ confirmDialogHide }
			/>

			<PhotoViewer show={ showFullscreen } photoURL={ currentPhoto?.photoURL } onHide={ onCloseClick }/>

		</Fragment>
	);
};

// PhotoCarousel.propTypes = {
	
// };

export default PhotoCarousel;