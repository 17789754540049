// Note although this project uses Reactstrap, this is not connected to the Bootstrap Modal
// Why? 'cause I hate the Bootstrap Modal
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import './Modal.scss';

export const Modal = ({ show, className, size, onHide, draggable, children, ...props }) => {
	const maskClasses = classNames('Modal_mask', { 'Modal_show': show });
	const modalClasses = classNames('Modal', className, size ? 'Modal_' + size : '');

	const modalRef = useRef();
	const showRef = useRef();

	useEffect(() => {
		const escapeHandler = e => {
			if(e.key === 'Escape' && onHide && showRef.current) onHide();
		}
		window.addEventListener('keydown', escapeHandler);
		return () => window.removeEventListener('keydown', escapeHandler);
	}, [onHide]);

	useEffect(() => {
		showRef.current = show;	// needed for above event handler
		if(show) {
			const component = modalRef.current;
			let list = component.querySelectorAll('.focus');
			if(list.length === 0) list = modalRef.current.querySelectorAll('.btn-secondary');	// focus on (what is probably) the close button if nothing with focus class
			if(list.length) {
				setTimeout(() => {
					list[0].focus();
				}, 100);
			}
		}
	}, [show]);

	const keyDownHandler = e => {
		if(e.key !== 'Tab') return;
		// if tab pressed, trap focus...
		const focusableElements = modalRef.current.querySelectorAll('a, button:not([disabled]), input, [tabindex="0"]');
		const firstElement = focusableElements[0];
		const lastElement = focusableElements[focusableElements.length - 1];
		if(!e.shiftKey && document.activeElement === lastElement) {
			firstElement.focus();
			return e.preventDefault();
		}
		if(e.shiftKey && document.activeElement === firstElement) {
			lastElement.focus();
			e.preventDefault();
		}
	}

	const renderModal = () => {
		return (
			<div role="dialog" ref={ modalRef } className={ modalClasses } onKeyDown={ keyDownHandler } { ...props }>
				{ children }
			</div>
		);
	}

	return (
		<div className={ maskClasses }>
			{ draggable
				? <Draggable disabled={ !draggable } handle='.Modal-header'>{ renderModal() }</Draggable>
				: renderModal()
			}
		</div>
	);
};

export const ModalHeader = props => {
	const classes = classNames('Modal-header', props.className);
	return (
		<header className={ classes }>
			{ typeof props.children === 'string'
				? <h1>{ props.children }</h1>
				: props.children
			}
		</header>
	)
}

export const ModalBody = props => {
	const classes = classNames('Modal-body', props.className);
	return (
		<div className={ classes }>
			{ props.children }
		</div>
	);
}

export const ModalFooter = props => {
	const classes = classNames('Modal-footer', props.className);
	return (
		<footer className={ classes }>
			{ props.children }
		</footer>
	)
}