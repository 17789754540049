const deliveryRulesFromVenue = (venue) => {	// , deliveryRangeOption) => {
	const data = {};
	data.deliveryRange = '' + venue.deliveryRangeInMiles;
	// we've altered the design so conversion no longer necessary...
	// data.deliveryRangeOption = deliveryRangeOption || (data.deliveryRange ? '_MILES' : undefined);
	// if(data.deliveryRangeOption === '_KM') {
	// 	// round up to nearest integer
	// 	data.deliveryRange = Math.ceil(venue.deliveryRangeInMiles * 1.609344); // calc miles -> KM
	// }

	// free delivery
	if(venue.deliveryFee === 0 && venue.deliveryMinOrderSize === 0) {
		data.deliveryChargeOption = '_FREE_DELIVERY';
	}

	// free delivery above deliveryMinOrderSize, no delivery permitted below this
	if(venue.deliveryFee === 0 && venue.deliveryMinOrderSize > 0) {
		data.deliveryChargeOption = '_FREE_ABOVE_SIZE';
		data.deliveryMinOrderSize = venue.deliveryMinOrderSize;
		data.freeDeliveryOption = '_UNAVAILABLE_4_SMALLER';
	}

	// free delivery above deliveryMinOrderSize, deliveryFee below that amount
	if(venue.deliveryFee > 0 && venue.deliveryMinOrderSize > 0) {
		data.deliveryChargeOption = '_FREE_ABOVE_SIZE';
		data.deliveryMinOrderSize = venue.deliveryMinOrderSize;
		data.freeDeliveryOption = '_FLAT_FEE_4_SMALLER';
		data.deliveryFee = venue.deliveryFee;
	}

	// flat delivery fee of deliveryFee
	if(venue.deliveryFee > 0 && venue.deliveryMinOrderSize === 0) {
		data.deliveryChargeOption = '_FLAT_FEE';
		data.deliveryFee = venue.deliveryFee;
	}
	data.deliveryTimeInMinutes = venue.deliveryTimeInMinutes;
	return data;
}

const orderTypesFromDistributionTypes = distributionTypes => {
	const data = {
		eatIn: 'none',
		delivery: 'none'
	};
	if((distributionTypes & 1) === 1) {
		data.eatIn = 'selfService';
	}
	if((distributionTypes & 2) === 2) {
		data.eatIn = 'waitstaff'
	}
	if((distributionTypes & 4) === 4) {
		data.takeOut = true;
	}
	if((distributionTypes & 8) === 8) {
		data.delivery = 'restaurant';
	}
	if((distributionTypes & 16) === 16) {
		data.delivery = 'Pictabite';
	}
	if((distributionTypes & 32) === 32) {
		data.roomService = true;
	}
	return data;
}

const venueFromOrderTypesForm = data => {
	const venue = {};
	switch(data.eatIn) {
		case 'selfService': venue.distributionTypes = 1; break;
		case 'waitstaff': venue.distributionTypes = 2; break;
		default: venue.distributionTypes = 0; break;
	}
	if(data.takeOut) {
		venue.distributionTypes = venue.distributionTypes |= 4;
	}
	switch(data.delivery) {
		case 'restaurant': venue.distributionTypes |= 8; break;
		case 'Pictabite': venue.distributionTypes |= 16; break;
		default: break;
	}
	if(data.roomService) {
		venue.distributionTypes = venue.distributionTypes |= 32;
	}
	// we've altered the design so conversion no longer necessary...
	// if(data.deliveryRangeOption === '_KM') {
	// 	// we need to track locally if KM is selected as it is not persisted via the API
	// 	// this way, we do all the conversion to/from miles in the UI and only persist miles
	// 	localStorage.setItem('pb_deliveryRangeOption', data.deliveryRangeOption);
	// 	venue.deliveryRangeInMiles = Math.ceil(data.deliveryRange / 1.609344); // calc KM -> miles
	// } else {
	// 	localStorage.removeItem('pb_deliveryRangeOption');
	// 	venue.deliveryRangeInMiles = parseInt(data.deliveryRange, 10);
	// }
	venue.deliveryRangeInMiles = parseInt(data.deliveryRange, 10);
	venue.deliveryFee = data.deliveryFee || 0;
	venue.deliveryMinOrderSize = data.deliveryMinOrderSize || 0;
	venue.deliveryTimeInMinutes = parseInt(data.deliveryTimeInMinutes || 0, 10);
	venue.deliversBelowMinOrderSize = 0;	// *** TODO *** temp until this gets designed out (see comments on PB-56)
	return venue;
	
}

export default {
	deliveryRulesFromVenue,
	orderTypesFromDistributionTypes,
	venueFromOrderTypesForm
}