import { useEffect, useState, useRef } from 'react';
import { Checkbox, Radio } from 'components/controls';
import { TextButton } from 'components/buttons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';
import PosConsole from './PosConsole';

const Popups = props => {

	const [showValidationMessage, setShowValidationMessage] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showFloatingPane, setShowFloatingPane] = useState(false);
	const [startingPos, setStartingPos] = useState();

	const posRef = useRef();

	useEffect(() => {
		// need to do a bit of a calculation to work out position relative to content to satisfy react-rnd
		// warning: if we do any biggish changes to layout, this will probably change
		const rect = posRef.current.getBoundingClientRect();
		const right = window.innerWidth - rect.x;	// right pos of <main> to work back from
		setStartingPos({
			x: right - (500 + 15),	// subtract floating pane width + margins
			y: 40
		});
	}, []);
	
	const toggleValidationMessage = e => {
		setShowValidationMessage(!showValidationMessage);
	}

	const toggleDialog = e => {
		setShowValidationMessage(false);
		setShowDialog(!showDialog);
	}

	const toggleFloatingPane = e => {
		setShowFloatingPane(!showFloatingPane);
	}

	const closePosConsole = e => {
		e.preventDefault();
		e.stopPropagation();
		setShowFloatingPane(false);
	}

	return (
		<div className="row justify-content-between animated fadeIn" ref={ posRef }>

			<button className="col-auto btn btn-primary" onClick={ toggleDialog }>draggable modal</button>

			<button className="col-auto btn btn-primary" onClick={ toggleFloatingPane }>{ showFloatingPane ? 'hide' : 'show'} floating pane</button>

			{ startingPos &&
				<PosConsole show={ showFloatingPane } position={ startingPos } close={ closePosConsole }/>
			}

			<Modal show={ showDialog } onHide={ toggleDialog } draggable={ true }>
				<ModalHeader>Modal Header</ModalHeader>
				<ModalBody>

					<ValidationMessage open={ showValidationMessage }/>

					<div className="form-row">
						<div className="col">
							<Checkbox label="Checkbox"/>
						</div>
						<div className="col">
							<input type="text" placeholder="Item 2" className="form-control"/>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Radio label="Radio" name="r1"/>
						</div>
						<div className="col">
							<input type="text" placeholder="Item 1" className="form-control"/>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Radio label="Radio" name="r1"/>
						</div>
						<div className="col">
							<input type="text" placeholder="Item 1" className="form-control"/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="Modal-footer_buttons">
					<TextButton className="btn-primary" async={ showValidationMessage } onClick={ toggleValidationMessage }>Submit</TextButton>
					<TextButton className="btn-secondary focus" onClick={ toggleDialog }>Close</TextButton>
				</ModalFooter>
			</Modal>

		</div>
	);

}

export default Popups;