import classNames from 'classnames';
import React, { useState } from 'react';
import { actionTypes } from '../store/tags';
import { IconButton } from '../components/buttons';
import { ItemPaneFooter, Pane, PaneBody, PaneHeader, PaneItem, PaneItemButton } from '../components/Pane';

const TagCategory = ({ category, ...props }) => {
	let shiftData;
	const [currentTag, setCurrentTag] = useState();

	const onTagSelect = tag => {
		setCurrentTag(tag);
	}

	const removeTag = id => {
		props.removeTag(id);
	}
	
	const shiftTag = isUp => {
		props.shiftTag(currentTag.id, isUp);
	}

	const clickHandler = e => {
		props.onClick(category);
	}

	const renderTags = (tags) => {
		return tags?.map((tag, ix) => {
			if(tag.id === currentTag?.id) {
				// this is the current item, so store data needed for disabling shift buttons
				shiftData = [tags.length - 1, ix];
			}
			return (
				<PaneItem key={ ix } index={ tag } selected={ tag.id === currentTag?.id } onClick={ onTagSelect }>
					<div className="col">{ tag.tagName }</div>
					<PaneItemButton icon="delete" index={ tag.id } onClick={ removeTag } propagate={ true }/>
				</PaneItem>
			);
		});
	}

	return (
		<Pane className={ classNames('Pane_category', { active: props.selected })} onClick={ clickHandler }>
			<PaneHeader>{ category.categoryName }</PaneHeader>
			<PaneBody tag="ul" className="scrolling Pane-items Pane-items_p0">
				{ renderTags(category.tags) }
			</PaneBody>
			<ItemPaneFooter
				shiftData={ shiftData }
				shiftElement={ shiftTag }
				loading={ props.selected && props.loading === actionTypes.reorderCommonTagsRequest }
			>
				<IconButton title="add new tag" icon="add" onClick={ props.addTag }/>
			</ItemPaneFooter>
		</Pane>
	);
};

export default TagCategory;