import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import utilities from '../services/utilities';
import photo from '../services/photo';
import { Modal, ModalBody, ModalFooter, ModalHeader } from './Modal';
import { Slider } from './controls';
import './ResizeDialog.scss';

const ResizeDialog = props => {

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const rotation = 0;

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const calcCroppedImageAndClose = useCallback(async () => {
		try {
			const canvas = await photo.getCroppedImg(
				props.imageData,
				croppedAreaPixels,
				rotation
			);
			props.onHide(canvas);
		} catch(e) {
			console.error(e);
		}
	}, [croppedAreaPixels, rotation, props]);

	useEffect(() => {
		// reset
		if(props.show) {
			setZoom(1);
		}
	}, [props.show]);

	const onButtonClick = e => {
		if(e.target.id === 'btnOk') {
			calcCroppedImageAndClose();
		} else {
			props.onHide();
		}
	}

	const onZoom = e => {
		setZoom(e.target.value);
	}

	return (
		<Modal id="resizeDialog" show={ props.show }>
			<ModalHeader>Resize Image</ModalHeader>
			<ModalBody>

				<div className="Cropper-container">
					<Cropper
						image={ props.imageData }
						crop={ crop }
						zoom={ zoom }
						aspect={ props.aspectRatio }
						onCropChange={ setCrop }
						onCropComplete={ onCropComplete }
						onZoomChange={ setZoom }
					/>
				</div>


			</ModalBody>
			<ModalFooter className="Modal-footer_buttons">
				<Slider
					title={ utilities.getText('LBL_ZOOM') }
					value={ zoom }
					min={ 1 }
					max={ 3 }
					step={ 0.1 }
					onChange={ onZoom }
				/>
				<button className="btn btn-secondary" onClick={ onButtonClick } id="btnCancel">Cancel</button>
				<button className="btn btn-primary" onClick={ onButtonClick } id="btnOk">OK</button>
			</ModalFooter>
		</Modal>
	);
};

export default ResizeDialog;