import classNames from 'classnames';
import { Rnd } from 'react-rnd';
import { IconButton } from 'components/buttons';
import JsonViewer from 'components/JsonViewer';
import { PaneBody, PaneFooter, PaneHeader } from 'components/Pane';

const json2 = {
	"firstName": "Fred",
	"secondName": "Bloggs",
	"address": {
		"line1": "10 Main St",
		"line2": "Glasgow"
	},
	"age": 25,
	"array": [1, 2, 3, 4]
}

const json1 = {
	"nestedModifierItems": [{
			"omnivoreId": "123",
			"posId": "123",
			"retailName": "Salad",
			"kitchenName": "Salad",
			"price": "0.0"
	}],
	"test": json2
};

const PosConsole = props => {

	const classes = classNames('Pane', 'Pane_floating', { 'Pane_show' : props.show });
	return (
		<Rnd minHeight="200" minWidth="320" className={ classes } bounds="window"
			id="posConsole"
			default={{
				x: props.position.x,
				y: props.position.y,
				width: 500,
				height: 300
			}}
			style={{
				cursor: 'default',
				display: 'flex'
			}}
		>
			<PaneHeader>
				<h1>HEADER</h1>
				<div className="Pane-toolbar">
						<IconButton title="sync" icon="autorenew"/>
						<IconButton className="focus" title="close" icon="close" onClick={ props.close }/>
					</div>
			</PaneHeader>
			<PaneBody className="scrolling container">
				<JsonViewer json={ json1 }/>
			</PaneBody>
			<PaneFooter>
				<h1 style={{ flex: 1, textAlign: 'center' }}>Footer</h1>
			</PaneFooter>
		</Rnd>
	);

};

export default PosConsole;