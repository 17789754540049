import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import photo from 'services/photo';
import utilities from 'services/utilities';
import { errorsActionCreators } from 'store/errors';
import { actionTypes, menusActionCreators } from 'store/menus';
import { tagsActionCreators } from 'store/tags';
import useForm from 'components/Form';
import RouterPrompt from 'components/RouterPrompt';
import AddEditDialog from './dialogs/AddEditDialog';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import MenusOptionsDialog from './dialogs/MenusOptionsDialog';
import MessageDialog from 'dialogs/MessageDialog';
import ModifierGroupDialog from './dialogs/ModifierGroupDialog';
import ResizeDialog from 'components/ResizeDialog';
import TagEditorDialog from './dialogs/TagEditorDialog';
import TaxCodeDialog from 'components/TaxCodeDialog';
import MenuItem from 'models/MenuItem';
import MenusPane from './MenusPane';
import MenuItemPane from './MenuItemPane';
import './Menus.scss';

const MenusContainer = props => {

	let { id } = useParams();
	const params = utilities.parseParameters(id, { menuId: null, itemId: null });
	const [imageData, setImageData] = useState();
	const [addEditDialogShow, setAddEditDialogShow] = useState(false);
	const [addEditDialogDetails, setAddEditDialogDetails] = useState({ tab: 'all' });
	const [menusOptionsShow, setMenusOptionsShow] = useState(false);
	const [showMessageDialog, setShowMessageDialog] = useState(false);
	const [showResizeDialog, setShowResizeDialog] = useState(false);
	const [confirmDeleteItemDialogShow, setConfirmDeleteItemDialogShow] = useState();
	const [modifierGroupDialogShow, setModifierGroupDialogShow] = useState(false);
	const [confirmDeleteModifierDialogShow, setConfirmDeleteModifierDialogShow] = useState(false);
	const [newItemSalesTaxCode, setNewItemSalesTaxCode] = useState();
	const [salesTaxDialogShow, setSalesTaxDialogShow] = useState(false);
	const [currentModifierGroup, setCurrentModifierGroup] = useState();
	const [showTagEditorDialog, setShowTagEditorDialog] = useState(false);
	const [isNewMenu, setIsNewMenu] = useState(false);
	const dispatch = useDispatch();
	const venueStore = useSelector(state => state.venues);
	const tagStore = useSelector(state => state.tags);
	const menuPricesIncludeVAT = venueStore.venue?.menuPricesIncludeVAT;

	const menuStore = useSelector(state => state.menus);
	const modifierStore = useSelector(state => state.modifiers);
	const taxCodeStore = useSelector(state => state.taxCodes);
	const user = useSelector(state => state.user.user);
	
	const history = useHistory();

	if(!params.menuId && menuStore.menus?.length) {
		// if menus loaded but no menu selected, default to 1st menu
		params.menuId = menuStore.menus[0].menuId;
	}

	const currentMenu = menuStore.menus?.find(menu => menu.menuId === params.menuId);	// we need this a lot so figure out once

	const formSave = () => {
		// console.log('formSave', form.data);
		if(params.itemId) {
			dispatch(menusActionCreators.updateItem(currentMenu, form.data));
		} else {
			dispatch(menusActionCreators.addItem(currentMenu, form.data));
		}
	}

	const saveEntity = (type, entity) => {
		switch(type) {
			case 'menu':
				if(entity.menuId) {
					dispatch(menusActionCreators.updateMenu(entity));
				} else {
					dispatch(menusActionCreators.addMenu(entity));
				}
				break;
			case 'section':
				if(entity.menuSectionId) {
					dispatch(menusActionCreators.updateSection(currentMenu, entity));
				} else {
					dispatch(menusActionCreators.addSection(currentMenu, entity));
				}
				break;
			case 'item':
				dispatch(menusActionCreators.addItem(currentMenu, entity));
				break;
			default: break;
		}
	}

	const form = useForm(formSave, validationRules, new MenuItem());
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(typeof form.data.onPromo === 'string') {
			// these radios return "0" or "1" but we need it to be 0 or 1 (although I'd prefer false or true!)
			form.onChange(null, 'onPromo', parseInt(form.data.onPromo, 10));
		}
		// not dependent on changes to form.onChange...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.data.onPromo]);

	useEffect(() => {
		// this effect causes AddEditDialog to open at ADD MENU ITEM once a new menu is successfully added (for user entry speed)
		if(menuStore.loading === actionTypes.addMenuRequest) {
			setIsNewMenu(true);	// hold in state until new menu is successfully added
		}
		if(!menuStore.loading) {
			if(isNewMenu) {
				setIsNewMenu(false);
				const newMenu = menuStore.menus[menuStore.menus.length - 1];
				history.push('./' + newMenu.menuId);	// open newly added menu (always the last one)
				setAddEditDialogShow(true);
				setAddEditDialogDetails({ tab: 'item' });	// open AddEditDialog ready to enter a new item
			}
		}
	}, [menuStore.loading, menuStore.menus, isNewMenu, history]);

	useEffect(() => {
		// loads selected menu item
		if(params.itemId) {
			if(params.itemId !== menuStore.item?.menuItemId) {
				// attempt to get the item...
				dispatch(menusActionCreators.fetchItem(params.menuId, params.itemId)).then(result => {
					if(result === 'ITEM_DELETED') {
						resetRef.current(new MenuItem(), false);
					}
				});
			} else {
				// ok, item is in store, so get it into "local" form state...
				resetRef.current({ ...menuStore.item }, false);
			}
		} else {
			// or clear currently selected menu item
			// I am not clearing item in the menuStore but I'm thinking I don't need to?
			resetRef.current(new MenuItem(), false);
		}
	}, [dispatch, menuStore.item, params.menuId, params.itemId]);

	const onMenuEdit = menu => {
		setAddEditDialogShow(true);
		setAddEditDialogDetails({ tab: 'menu', entity: menu });
	}

	const onSectionEdit = (index, e) => {
		const editSection = currentMenu.menuSections[index];
		setAddEditDialogShow(true);
		setAddEditDialogDetails({ tab: 'section', entity: editSection });
	}

	const onAddEditClick = e => {
		setNewItemSalesTaxCode();
		setAddEditDialogShow(true);
		setAddEditDialogDetails({ tab: 'all' });
	}

	const addEditDialogHide = () => {
		setAddEditDialogShow(false);
	}

	const onMenusOptionsClick = () => {
		setMenusOptionsShow(true);
	}

	const menusOptionsDialogHide = () => {
		setMenusOptionsShow(false);
	}

	const confirmDialogHide = response => {
		// console.log(response);
		switch(response.action) {
			case 'Delete Modifier Group': {
				if(response.button === 'btnOk') {
					// console.log(currentMenu, form.data, currentModifierGroup);
					dispatch(menusActionCreators.deleteItemModifierGroup(currentMenu, form.data, currentModifierGroup));
				}
				setConfirmDeleteModifierDialogShow(false);
				break;
			}
			case 'Delete Menu Item': {
				if(response.button === 'btnOk') {
					// console.log(currentMenu, form.data);
					dispatch(menusActionCreators.deleteItem(currentMenu, form.data));
				}
				setConfirmDeleteItemDialogShow(false);
				break;
			}
			default: break;
		}
	}

	const onDeleteItem = e => {
		setConfirmDeleteItemDialogShow(true);
	}

	const deleteEntity = (type, entity) => {
		// console.log('delete', type, params, entity);
		if(type === 'menu') {
			dispatch(menusActionCreators.deleteMenu(entity));
			if(params.menuId === entity.menuId) {
				// we're deleting the currently selected menu, so...
				history.push('./');
			}
		} else {
			dispatch(menusActionCreators.deleteSection(currentMenu, entity));
		}
	}

	const onAddModifierGroup = e => {
		setCurrentModifierGroup(null);
		if(modifierStore.groups.length === 0) {
			setShowMessageDialog(true);
		} else {
			setModifierGroupDialogShow(true);
		}
	}

	const modifierGroupDialogHide = (e, group) => {
		if(group) {
			dispatch(menusActionCreators.updateItemModifierGroup(currentMenu, form.data, group));
		}
		setModifierGroupDialogShow(false);
	}

	const editModifierGroup = group => {
		setCurrentModifierGroup(group);
		setModifierGroupDialogShow(true);
	}

	const onDeleteModifierGroup = e => {
		setConfirmDeleteModifierDialogShow(true);
	}

	const onSalesTaxButtonClick = e => {
		setSalesTaxDialogShow(true);
	}

	const salesTaxDialogHide = e => {
		setSalesTaxDialogShow(false);
	}

	const onSalesTaxCodeSelect = code => {
		// console.log(code);
		salesTaxDialogHide();
		if(addEditDialogShow) {
			setNewItemSalesTaxCode(code);
		} else {
			form.onChange(null, 'salesTaxCode', code);
		}
	}

	const shiftItem = isUp => {
		dispatch(menusActionCreators.shiftItem(currentMenu, form.data, isUp));
	}

	const shiftGroup = isUp => {
		console.log(isUp, currentModifierGroup);
		// dispatch(menusActionCreators.shiftItemModifierGroup(currentModifierGroup.modifierGroupId, isUp));
		dispatch(menusActionCreators.shiftItemModifierGroup(currentMenu, form.data, currentModifierGroup, isUp));
	}

	const shiftMenu = (id, isUp) => {
		dispatch(menusActionCreators.shiftMenu(id, isUp));
	}

	const shiftSection = (id, isUp) => {
		dispatch(menusActionCreators.shiftSection(currentMenu, id, isUp));
	}

	const onPhotoDrop = async files => {
		if(files[0].type !== 'image/jpeg') {
			dispatch(errorsActionCreators.setErrors([utilities.getText('ERR_WRONG_EXTENSION', files[0].name)]));
		} else {
			const imageDataUrl = await photo.readFile(files[0]);
			setImageData(imageDataUrl);
			setShowResizeDialog(true);
		}
	}

	const onResizeDialogHide = canvas => {
		if(canvas) {
			canvas.toBlob(blob => {
				// console.log(form.data);
				dispatch(menusActionCreators.uploadMenuItemPhoto(currentMenu, form.data, blob));
			}, 'image/jpeg');
		}
		setShowResizeDialog(false);
	}

	const onHideMessageDialog = () => {
		setShowMessageDialog(false);
	}

	const editTags = e => {
		if(!user?.hasPrivilegedAccess) return;
		dispatch(tagsActionCreators.fetchTags());
		setShowTagEditorDialog(true);
	}

	const hideTagEditorDialog = payload => {
		setShowTagEditorDialog(false);
		if(payload) {
			const item = { ...form.data };
			item.tags = payload;
			// we need to fiddle with the tag data shape to conform with add / update api endpoints
			const tagIds = [], tagNames = [];
			payload.forEach(tag => {
				if(tag.id) {
					tagIds.push(tag.id);
				} else {
					tagNames.push(tag);
				}
			});
			item.tagIds = tagIds;
			item.tagNames = tagNames;
			resetRef.current(item, true);
		}
	}

	const classes = classNames('row animated fadeIn', { 'with-sales-tax': !menuPricesIncludeVAT });	// we want the Menus pane to adjust its height if the MenuItem pane changes height
	return (
		<div className={ classes }>

			<div className="col-md-4 col-lg-5">
				<MenusPane
					menuStore={ menuStore }
					item={ form.data }
					activeItemId={ params.itemId }	// gets set on click rather than on item load - needed for async class
					menuId={ params.menuId }
					currencySymbol={ venueStore.venue?.currencySymbol }
					onAddEditClick={ onAddEditClick }
					onMenusOptionsClick={ onMenusOptionsClick }
					shiftItem={ shiftItem }
					// onModifiersLoad={ this.onModifiersLoad }
					onSectionEdit={ onSectionEdit }
					onMenuEdit={ onMenuEdit }
				/>
			</div>

			<div className="col-md-8 col-lg-7">
				<MenuItemPane
					menuStore={ menuStore }
					form={ form }
					group={ currentModifierGroup }
					menuSections={ menuStore.sections }
					errors={ form.errors }
					user={ user }
					shiftGroup={ shiftGroup }
					onAddModifierGroup={ onAddModifierGroup }
					editModifierGroup={ editModifierGroup }
					setCurrentModifierGroup={ setCurrentModifierGroup }
					onSalesTaxButtonClick={ !menuPricesIncludeVAT ? onSalesTaxButtonClick : null }	// no need to pass the flag too - the presence of the handler does this for us
					onDeleteItem={ onDeleteItem }
					onDeleteModifierGroup={ onDeleteModifierGroup }
					onPhotoDrop={ onPhotoDrop }
					editTags={ editTags }
				/>
			</div>

			<AddEditDialog
				menuStore={ menuStore }
				details={ addEditDialogDetails }
				show={ addEditDialogShow }
				onSalesTaxButtonClick={ menuPricesIncludeVAT ? null : onSalesTaxButtonClick }
				salesTaxCode={ newItemSalesTaxCode }
				onHide={ addEditDialogHide }
				saveEntity={ saveEntity }
				deleteEntity={ deleteEntity }
			/>

			<MenusOptionsDialog
				menuStore={ menuStore }
				currentMenu={ currentMenu }
				shiftMenu={ shiftMenu }
				shiftSection={ shiftSection }
				show={ menusOptionsShow }
				onHide={ menusOptionsDialogHide }
			/>

			<ModifierGroupDialog
				groups={modifierStore.groups }
				group={ currentModifierGroup }
				item={ form.data }
				show={ modifierGroupDialogShow }
				onHide={ modifierGroupDialogHide }
			/>

			<TaxCodeDialog
				codes={ taxCodeStore.data }
				show={ salesTaxDialogShow }
				onHide={ salesTaxDialogHide }
				onSalesTaxCodeSelect={ onSalesTaxCodeSelect }
			/>

			<ConfirmDialog
				action="Delete Menu Item"
				message="Are you sure you want to delete this menu item?"
				show={ confirmDeleteItemDialogShow }
				onHide={ confirmDialogHide }
			/>

			<ConfirmDialog
				action="Delete Modifier Group"
				message="Are you sure you want to delete this modifier group?"
				show={ confirmDeleteModifierDialogShow }
				onHide={ confirmDialogHide }
			/>

			<MessageDialog
				title="Add Modifier Group"
				message="Please create one or more modifier groups first."
				show={ showMessageDialog }
				onHide={ onHideMessageDialog }
			/>

			<ResizeDialog show={ showResizeDialog } imageData={ imageData } aspectRatio={ 4 / 3 } onHide={ onResizeDialogHide }/>

			<TagEditorDialog
				show={ showTagEditorDialog }
				tags={ tagStore.tags }
				commonTags={ tagStore.commonTags }
				itemTags={ form.data?.tags }
				onHide={ hideTagEditorDialog }
			/>

			<RouterPrompt when={ form.isDirty }/>

		</div>
	);

};

export default MenusContainer;

const validationRules = {
	menuSectionTitle: true,
	name: true,
	price: true
}