import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import { errorsActionCreators, errorCodes } from 'store/errors';
import { userActionCreators } from 'store/user';
import { actionTypes, venuesActionCreators } from 'store/venues';
import { FormInput } from 'components/formControls';
import useForm from 'components/Form';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneFooter, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import PasswordDialog from './PasswordDialog';
import OwnersDialog from './OwnersDialog';

const Owner = props => {
	const _t = useL10n().getText;
	const [newUserId, setNewUserId] = useState();
	const [showOwnersDialog, setShowOwnersDialog] = useState();
	const [footerMessage, setFooterMessage] = useState();
	const [search, setSearch] = useState('');
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);

	const dispatch = useDispatch();

	const venuesStore = useSelector(state => state.venues);
	const userStore = useSelector(state => state.user);
	const errors = useSelector(state => state.errors.errors);

	const formSave = () => {
		// console.log('formSave', form.data);
		// form.data.userId != null - userId might be a genuine userId or 0 if editing a record just created by addVenueOwner
		if(form.data.userId != null && !newUserId) {
			dispatch(venuesActionCreators.updateVenueOwner(form.data));
		} else {
			dispatch(venuesActionCreators.addVenueOwner(form.data));
		}
	}

	const form = useForm(formSave, validationRules);
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(venuesStore.venue) {
			resetRef.current({});	// clear the form in case the following fetch returns nothing
			dispatch(venuesActionCreators.fetchVenueOwner());
		}
	}, [venuesStore.venue, dispatch]);

	useEffect(() => {
		if(venuesStore.venueOwner) {
			resetRef.current(venuesStore.venueOwner);
		}
	}, [venuesStore.venueOwner]);

	useEffect(() => {
		if(!showPasswordDialog && errors && errors[0].code === errorCodes.INCORRECT_PASSWORD) {
			setShowPasswordDialog(true);
		}
	}, [errors, showPasswordDialog]);

	const hidePasswordDialog = payload => {
		setShowPasswordDialog(false);
		if(payload.password) {
			dispatch(userActionCreators.updatePassword(payload.password, payload.newPassword));
		} else {
			dispatch(errorsActionCreators.clearErrors());
		}
	}

	const showPasswordDialogHandler = e => {
		setShowPasswordDialog(!showPasswordDialog);
	}

	const newOwnerHandler = e => {
		// if(e.currentTarget.textContent === 'search') {
			setSearch('');
			dispatch(userActionCreators.clearUsers());
			setShowOwnersDialog(true);
		// } else {
		// 	resetRef.current({});
		// 	setFooterMessage('This user will be linked to the current venue on save.')
		// }
	}

	const ownersDialogHideHandler = e => {
		if(e.currentTarget.id === 'btnCreate') {
			resetRef.current({});
			setFooterMessage(_t('TXT_OWNER_ON_SAVE'));
		}
		setShowOwnersDialog(false);
	}


	const searchChangeHandler = e => {
		setSearch(e.target.value);
	}

	const searchUsers = e => {
		dispatch(userActionCreators.searchUsers(search));
	}

	const setOwner = user => {
		dispatch(venuesActionCreators.fetchVenueOwnerCandidate(user.userId));
		setNewUserId(user.userId);
		setFooterMessage(_t('TXT_LINKED_ON_SAVE'));
		setShowOwnersDialog(false);
	}

	const isSaving = () => {
		switch(venuesStore.loading) {
			case actionTypes.updateVenueOwnerRequest:
			case actionTypes.addVenueOwnerRequest:
				return true;
			default: return false;
		}
		
	}

	return (
		<Fragment>

					<Pane className="max-width-800 animated fadeIn">
						<PaneHeader>

							<h1>{ _t('HDG_VENUE_OWNER', true) }</h1>
							<div className="Pane-toolbar">

								{ userStore.user?.hasPrivilegedAccess &&
									<Fragment>
										<IconButton
											title={ _t('TTP_LOOK_UP_NEW_OWNER') }
											icon="search"
											onClick={ newOwnerHandler }
										/>

										{/* <IconButton
											title="create new owner"
											icon="person_add"
											onClick={ newOwnerHandler }
										/> */}
									</Fragment>
								}

								<IconButton
									title={ _t('TTP_SAVE') }
									icon="save"
									async={ isSaving() }
									onClick={ form.onSave }
								/>

							</div>

						</PaneHeader>
						<PaneBody className="container">

							<ValidationMessage open={ !!form.errors }/>

							<div className="form-row">
								<div className="col-md form-stacked">
									<FormInput placeholder={ _t('PLH_EMAIL_ADDRESS') } form={ form } field="email"/>
									<button className="btn btn-secondary form-button" disabled={ !form.data?.userId } onClick={ showPasswordDialogHandler }>{ _t('BTN_CHANGE_PASSWORD', true) }</button>
									<FormInput placeholder={ _t('PLH_FIRST_NAME') } form={ form } field="firstName"/>
									<FormInput placeholder={ _t('PLH_LAST_NAME') } form={ form } field="lastName"/>
									<FormInput type="tel" placeholder={ _t('PLH_MOBILE_PHONE') } form={ form } field="phone"/>
								</div>
								<div className="col-md form-stacked">
									<FormInput placeholder={ _t('PLH_MAILING_STREET') } form={ form } field="street"/>
									<FormInput placeholder={ _t('PLH_CITY') } form={ form } field="city"/>
									<FormInput placeholder={ _t('PLH_POST_CODE') } form={ form } field="postCode"/>
									<FormInput placeholder={ _t('PLH_PROVINCE') } form={ form } field="provinceName"/>
									<FormInput placeholder={ _t('PLH_COUNTRY') } form={ form } field="countryName"/>
								</div>
							</div>

						</PaneBody>
						<PaneFooter>

							<h1 className="text-center w-100">{ footerMessage || _t('TXT_EMAIL_FOR_SIGNING_IN') }</h1>

						</PaneFooter>
					</Pane>


			<PasswordDialog
				show={ showPasswordDialog }
				incorrectPassword={ errors && errors[0].code === errorCodes.INCORRECT_PASSWORD }
				onHide={ hidePasswordDialog }
			/>

			<OwnersDialog
				show={ showOwnersDialog }
				search={ search }
				userStore={ userStore }
				onChange={ searchChangeHandler }
				onHide={ ownersDialogHideHandler }
				searchUsers={ searchUsers }
				setOwner={ setOwner }
			/>

			<RouterPrompt when={ form.isDirty }/>

		</Fragment>
	);
};

export default Owner;

const validationRules = {
	email: true,
	firstName: true,
	lastName: true,
	phone: true,
	street: true,
	city: true,
	postCode: true,
	// provinceName: true,
	countryName: true
}