import React, { Suspense, useEffect, useRef, useState } from 'react';
import useL10n from 'L10n';
import useForm from '../components/Form';
import { FormPassWord } from '../components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';
import ValidationMessage from '../components/ValidationMessage';

const Strengthometer = React.lazy(() => import('../components/Strengthometer'));

const PasswordDialog = props => {
	const _t = useL10n().getText;

	const [score, setScore] = useState();

	const formSave = () => {
		const errors = {};
		let hasErrors = false;
		// some custom error checking needed now...
		if(score < 2) {
			// we need to balance annoyance with security
			errors.newPassword = 'please choose a more complex password';
			hasErrors = true;
		}
		if(hasErrors) {
			form.setErrors(errors);
		} else {
			props.onHide(form.data);
		}
	}

	const form = useForm(formSave, validationRules);
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.show && !props.incorrectPassword) {
			resetRef.current({});
		}
	}, [props.show, props.incorrectPassword]);

	return (
		<Modal id="passwordDialog" size="small" show={ props.show } draggable={ true }>
			<ModalHeader>{ _t('HDG_CHANGE_PASSWORD', true) }</ModalHeader>
			<ModalBody>
	
				<ValidationMessage open={ !!form.errors }/>
	
				<ValidationMessage open={ props.incorrectPassword } message={ _t('TXT_INCORRECT_PASSWORD') }/>

				<div className="form-stacked">
					<FormPassWord placeholder="Current Password" form={ form } field="password"/>
					<FormPassWord placeholder="New Password" form={ form } field="newPassword"/>
					<Suspense fallback={ <div>...</div> }>
						<Strengthometer password={ form.data.newPassword } onChange={ score => setScore(score) }/>
					</Suspense>
				</div>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary focus" onClick={ form.onSave } id="btnOk">
					{ _t('BTN_SAVE', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
}

export default PasswordDialog;

const validationRules = {
	password: true,
	newPassword: true
}