import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActionCreators } from 'store/settings';
import { errorCodes } from 'store/errors';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormCheckbox, FormInput, FormLabel, FormPassWord } from 'components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';

const initFormData = {
	username: null,
	password: null,
	rememberMe: false
};

const validationRules = {
	username: true,
	password: true
}

const SignInDialog = props => {
	const _t = useL10n().getText;

	const settingStore = useSelector(state => state.settings);
	const dispatch = useDispatch();

	const formSave = () => {
		dispatch(settingsActionCreators.updateSetting('username', form.data.rememberMe ? form.data.username : ''));
		resetRef.current(initFormData);
		props.onHide('btnOk', form.data);
	}

	const form = useForm(formSave, validationRules, initFormData);
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.show) {
			const pbUsername = settingStore.username;
			if(pbUsername) {
				const showFormData = {
					username: pbUsername,
					password: null,
					rememberMe: true
				};
				resetRef.current(showFormData);
			}
		}
		// only want this to fire on props.show, so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	const resetHandler = e => {
		e.preventDefault();
		props.onHide('btnReset');
	}

	const getMessage = () => {
		switch (props.err?.code) {
			case errorCodes.INCORRECT_PASSWORD: return _t(`ERR_AUTH_FAILED`);
			case errorCodes.LOGIN_FAILURE: return _t(`ERR_LOGIN_FAILURE`);
			default: return undefined;
		}
	}

	const message = getMessage();

	return (
		<Modal id="signInDialog" size="small" show={ props.show } draggable={ true }>
			<ModalHeader>{ _t('HDG_SIGN_IN', true) }</ModalHeader>

			<ModalBody>

				<ValidationMessage
					open={ !!form.errors || !!message }
					message={ message }
				/>

				<div className="form-row">
					<FormLabel htmlFor="username" className="col-sm-3" label="_USERNAME"/>
					<div className="col-sm-9">
						<FormInput form={ form } field="username"/>
					</div>
				</div>

				<div className="form-row">
					<FormLabel htmlFor="password" className="col-sm-3" label="_PASSWORD"/>
					<div className="col-sm-9">
						<FormPassWord form={ form } field="password"/>
					</div>
				</div>

				<div className="form-row">
					<div className="col-sm-9 offset-sm-3">
						<FormCheckbox form={ form } field="rememberMe">
							{ _t('LBL_REMEMBER_ME') }
						</FormCheckbox>
					</div>
				</div>

				<div className="form-row">
					<div className="col-sm-9 offset-sm-3">
						<a href="/" onClick={ resetHandler }>{ _t('LBL_FORGOTTEN_PASSWORD') }</a>
					</div>
				</div>

			</ModalBody>

			<ModalFooter className="Modal-footer_buttons">
				{ props.allowCancel === true &&
					<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">{ _t('BTN_CANCEL', true) }</button>
				}
				<button className="btn btn-primary" onClick={ form.onSave } id="btnOk">{ _t('BTN_SIGNIN', true) }</button>
			</ModalFooter>

		</Modal>
	);
};

export default SignInDialog;