// note this also currently incorporates most of the photosSrvc endpoints as they are all mainly venue related
import utilities from '../services/utilities';
import storeUtils from './storeUtils';
import { actionTypes as billingActionTypes, billingActionCreators } from './billing';
import { actionTypes as ordersActionTypes } from './orders';
import { actionTypes as posActionTypes } from './pos';

export const actionTypes = {
	venuesFail: 'VENUES_FAIL',

	fetchVenuesRequest: 'FETCH_VENUES_VENUES_REQUEST',
	fetchVenuesSuccess: 'FETCH_VENUES_VENUES_SUCCESS',

	fetchVenuesByOwnerRequest: 'FETCH_VENUESBYOWNER_VENUES_REQUEST',
	fetchVenuesByOwnerSuccess: 'FETCH_VENUESBYOWNER_VENUES_SUCCESS',

	fetchVenueRequest: 'FETCH_VENUE_VENUES_REQUEST',
	fetchVenueSuccess: 'FETCH_VENUE_VENUES_SUCCESS',

	setSelectedVenueRequest: 'SETSELECTED_VENUE_VENUES_REQUEST',
	setSelectedVenueSuccess: 'SETSELECTED_VENUE_VENUES_SUCCESS',

	addVenueRequest: 'ADD_VENUE_VENUES_REQUEST',
	addVenueSuccess: 'ADD_VENUE_VENUES_SUCCESS',

	updateVenueRequest: 'UPDATE_VENUE_VENUES_REQUEST',
	updateVenueSuccess: 'UPDATE_VENUE_VENUES_SUCCESS',
	updateVenueSizeRequest: 'UPDATE_VENUESIZE_VENUES_REQUEST',
	updateVenueSizeSuccess: 'UPDATE_VENUESIZE_VENUES_SUCCESS',

	// we don't seem to have a way of deleting venues
	deleteVenueRequest: 'DELETE_VENUE_VENUES_REQUEST',
	deleteVenueSuccess: 'DELETE_VENUE_VENUES_SUCCESS',

	updateWiFiSettingsRequest: 'UPDATE_WIFISETTINGS_VENUES_REQUEST',
	updateWiFiSettingsSuccess: 'UPDATE_WIFISETTINGS_VENUES_SUCCESS',

	setVenueSuccess: 'SETVENUE_VENUES_SUCCESS',

	fetchCuisineTypesRequest: 'FETCH_CUISINETYPES_VENUES_REQUEST',
	fetchCuisineTypesSuccess: 'FETCH_CUISINETYPES_VENUES_SUCCESS',

	// getOpeningHoursRequest: 'GET_OPENINGHOURS_VENUES_REQUEST',
	// getOpeningHoursSuccess: 'GET_OPENINGHOURS_VENUES_SUCCESS',
	updateOpeningHoursRequest: 'UPDATE_OPENINGHOURS_VENUES_REQUEST',
	updateOpeningHoursSuccess: 'UPDATE_OPENINGHOURS_VENUES_SUCCESS',

	uploadVenueLogoRequest: 'UPLOAD_VENUELOGO_VENUES_REQUEST',
	uploadVenueLogoSuccess: 'UPLOAD_VENUELOGO_VENUES_SUCCESS',
	uploadVenuePhotoRequest: 'UPLOAD_VENUEPHOTO_VENUES_REQUEST',
	uploadVenuePhotoSuccess: 'UPLOAD_VENUEPHOTO_VENUES_SUCCESS',
	uploadFeaturedPhotoRequest: 'UPLOAD_FEATUREDPHOTO_VENUES_REQUEST',
	uploadFeaturedPhotoSuccess: 'UPLOAD_FEATUREDPHOTO_VENUES_SUCCESS',
	setFeaturedPhotoRequest: 'SET FEATUREDPHOTO_VENUES_REQUEST',
	setFeaturedPhotoSuccess: 'SET FEATUREDPHOTO_VENUES_SUCCESS',
	deleteFeaturedPhotoRequest: 'DELETE_FEATURED_PHOTO_VENUES_REQUEST',
	deleteVenuePhotoRequest: 'DELETE_VENUE_PHOTO_VENUES_REQUEST',
	deletePhotoSuccess: 'DELETE_PHOTO_VENUES_SUCCESS',

	getWaitstaffCountRequest: 'GET_WAITSTAFFCOUNT_VENUES_REQUEST',
	getWaitstaffCountSuccess: 'GET_WAITSTAFFCOUNT_VENUES_SUCCESS',

	fetchVenueOwnerRequest: 'FETCH_VENUEOWNER_VENUES_REQUEST',
	fetchVenueOwnerSuccess: 'FETCH_VENUEOWNER_VENUES_SUCCESS',
	updateVenueOwnerRequest: 'UPDATE_VENUEOWNER_VENUES_REQUEST',
	updateVenueOwnerSuccess: 'UPDATE_VENUEOWNER_VENUES_SUCCESS',
	addVenueOwnerRequest: 'ADD_VENUEOWNER_VENUES_REQUEST',
	addVenueOwnerSuccess: 'ADD_VENUEOWNER_VENUES_SUCCESS',
	fetchVenueOwnerCandidateRequest: 'FETCH_VENUEOWNERCANDIDATE_VENUES_REQUEST',
	fetchVenueOwnerCandidateSuccess: 'FETCH_VENUEOWNERCANDIDATE_VENUES_SUCCESS',

	getMenuProviderRequest: 'GET_MENUPROVIDER_VENUES_REQUEST',
	getMenuProviderSuccess: 'GET_MENUPROVIDER_VENUES_SUCCESS',
	clearMenuProviderRequest: 'CLEAR_MENUPROVIDER_VENUES_REQUEST',
	clearMenuProviderSuccess: 'CLEAR_MENUPROVIDER_VENUES_SUCCESS',

	fetchVenuesSizesReportRequest: 'FETCH_VENUESIZESREPORT_VENUES_REQUEST',
	fetchVenuesSizesReportSuccess: 'FETCH_VENUESIZESREPORT_VENUES_SUCCESS',

	forceOpenRequest: 'FORCEOPEN_VENUES_REQUEST',
	forceOpenSuccess: 'FORCEOPEN_VENUES_SUCCESS',
	cancelForceOpenRequest: 'CANCEL_FORCEOPEN_VENUES_REQUEST',
	cancelForceOpenSuccess: 'CANCEL_FORCEOPEN_VENUES_SUCCESS'
}

const initialState = {
	venues: null,
	venue: null,
	id: null,
	cuisineTypes: null,
	waitstaffCount: null,
	venueOwnersloading: null,
	menuProvider: null,
	errors: null
};

const setSelectedVenue = async (venueId, dispatch) => {
	// hoisted as shared between 2 action creators
	dispatch({ type: actionTypes.setSelectedVenueRequest });
	const data = await storeUtils.execute('other', 'setSelectedVenue', { venueUUID: venueId });
	if(data.errors) {
		dispatch({ type: actionTypes.venuesFail, errors: data.errors });
	} else {
		dispatch({ type: actionTypes.setSelectedVenueSuccess, id: venueId });
	}
}

export const venuesActionCreators = {
	fetchVenues: name => async dispatch => {
		dispatch({ type: actionTypes.fetchVenuesRequest });
		const data = await storeUtils.execute('venues', 'getVenuesByName', { name: name });
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchVenuesSuccess, venues: data.venues });
		}
	},

	fetchVenuesByOwner: () => async (dispatch, getState) => {
		if(getState().venues.venues) return;	// simple caching
		dispatch({ type: actionTypes.fetchVenuesRequest });
		const data = await storeUtils.execute('venues', 'getVenuesByOwner');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchVenuesSuccess, venues: data.venues });
		}
	},

	fetchVenue: () => async dispatch => {
		dispatch({ type: actionTypes.fetchVenueRequest });
		const data = await storeUtils.execute('venues', 'getVenue');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchVenueSuccess, venue: data });
		}
	},

	setSelectedVenue: venueId => async dispatch => {
		setSelectedVenue(venueId, dispatch);
	},

	addVenue: (venue, makeSelected) => async dispatch => {
		dispatch({ type: actionTypes.addVenueRequest });
		const data = await storeUtils.execute('venues', 'addVenue', venue);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			venue.venueUUID = data.venueUUID;
			dispatch({ type: actionTypes.addVenueSuccess, venue: venue });
			if(makeSelected) {
				setSelectedVenue(venue.venueUUID, dispatch);
			}
		}
	},
	
	updateVenue: venue => async dispatch => {
		dispatch({ type: actionTypes.updateVenueRequest });
		const data = await storeUtils.execute('venues', 'updateVenue', venue);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateVenueSuccess, venue: venue, timezone: data.timezone });
		}
	},

	updateVenueSize: venueSize => async dispatch => {
		dispatch({ type: actionTypes.updateVenueSizeRequest });
		const data = await storeUtils.execute('venues', 'updateVenueSize', venueSize);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateVenueSizeSuccess, venueSize: venueSize });
		}
	},
	
	deleteVenue: venueId => async dispatch => {
		dispatch({ type: actionTypes.deleteVenueRequest });
		const data = await storeUtils.execute('venues', 'deleteVenue', venueId);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteVenueSuccess, venueId: venueId });
		}
	},

	updateWiFiSettings: settings => async dispatch => {
		dispatch({ type: actionTypes.updateWiFiSettingsRequest });
		const data = await storeUtils.execute('venues', 'updateWiFiSettings', settings);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateWiFiSettingsSuccess, settings: settings });
		}
	},

	setVenue: venue => {
		return { type: actionTypes.setVenueSuccess, venue: venue };
	},

	fetchCuisineTypes: () => async (dispatch, getState) => {
		if(getState().venues.cuisineTypes) return;	// simple caching
		dispatch({ type: actionTypes.fetchCuisineTypesRequest });
		const data = await storeUtils.execute('venues', 'getCuisineTypes');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
				dispatch({ type: actionTypes.fetchCuisineTypesSuccess, cuisineTypes: data.cuisineTypes });
		}
	},

	// can't currently see any need for this as getVenue loads this anyway
	// fetchOpeningHours: () => async dispatch => {
	// 	dispatch({ type: actionTypes.getOpeningHoursRequest });
	// 	const data = await storeUtils.execute('venues', 'getOpeningHours');
	// 	if(data.errors) {
	// 		dispatch({ type: actionTypes.venuesFail, errors: data.errors });
	// 	} else {
	// 		dispatch({ type: actionTypes.getOpeningHoursSuccess, openingHours: data });
	// 	}
	// },
	
	updateOpeningHours: openingHours => async dispatch => {
		dispatch({ type: actionTypes.updateOpeningHoursRequest });
		const data = await storeUtils.execute('venues', 'updateOpeningHours', openingHours);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateOpeningHoursSuccess, openingHours: data });
		}
	},

	uploadVenueLogo: (venueId, venueLogo) => async dispatch => {
		const formData = new FormData();
		const body = JSON.stringify({ venueUUID: venueId });
		formData.append('json', body);
		formData.append('photo', venueLogo);
		dispatch({ type: actionTypes.uploadVenueLogoRequest });
		const data = await storeUtils.execute('photos', 'uploadVenueLogo', formData);
		if(data.errors) {
			dispatch({ type: actionTypes.photosFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.uploadVenueLogoSuccess, logoUrl: data.logoUrl });
		}
	},

	uploadVenuePhoto: (venueId, venuePhoto) => async dispatch => {
		const formData = new FormData();
		const body = JSON.stringify({ venueUUID: venueId });
		formData.append('json', body);
		formData.append('photo', venuePhoto);
		dispatch({ type: actionTypes.uploadVenuePhotoRequest });
		const data = await storeUtils.execute('photos', 'uploadVenuePhoto', formData);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.uploadVenuePhotoSuccess, venuePhoto: data.photo });
		}
	},

	uploadFeaturedPhoto: (venueId, featuredPhoto) => async dispatch => {
		const formData = new FormData();
		const body = JSON.stringify({ venueUUID: venueId });
		formData.append('json', body);
		formData.append('photo', featuredPhoto);
		dispatch({ type: actionTypes.uploadFeaturedPhotoRequest });
		const data = await storeUtils.execute('photos', 'uploadFeaturedPhoto', formData);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.uploadFeaturedPhotoSuccess, featuredPhoto: data.photo });
		}
	},

	setFeaturedPhoto: photo => async dispatch => {
		dispatch({ type: actionTypes.setFeaturedPhotoRequest });
		const data = await storeUtils.execute('venues', 'setFeaturedPhoto', { featuredPhotoId: photo.photoId });
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.setFeaturedPhotoSuccess, photoId: photo.photoId });
		}
	},

	deletePhoto: (photo, type) => async dispatch => {
		switch(type) {
			case 'f': dispatch({ type: actionTypes.deleteFeaturedPhotoRequest }); break;
			case 'v': dispatch({ type: actionTypes.deleteVenuePhotoRequest }); break;
			default: break;
		}
		// const body = 'json=' + encodeURIComponent(`{photoType:"${type}",photoId:${ photo.photoId }}`);
		// const data = await storeUtils.execute('photos', 'deletePhoto', body);
		const data = await storeUtils.execute('photos', 'deletePhoto', { photoType: type, photoId: photo.photoId });
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deletePhotoSuccess, photo: photo, photoType: type });
		}
	},

	getWaitstaffCount: () => async dispatch => {
		dispatch({ type: actionTypes.getWaitstaffCountRequest });
		const data = await storeUtils.execute('venues', 'getTodaysWaitstaffCount');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.getWaitstaffCountSuccess, waitstaffCount: data.todaysWaitstaffCount });
		}
	},

	fetchVenueOwner: () => async dispatch => {
		dispatch({ type: actionTypes.fetchVenueOwnerRequest });
		const data = await storeUtils.execute('venueOwners', 'getVenueOwner');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchVenueOwnerSuccess, venueOwner: data.venueOwner });
		}
	},
	
	updateVenueOwner: venueOwner => async dispatch => {
		dispatch({ type: actionTypes.updateVenueOwnerRequest });
		const data = await storeUtils.execute('venueOwners', 'updateVenueOwner', venueOwner);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateVenueOwnerSuccess, venueOwner: venueOwner });
		}
	},

	addVenueOwner: venueOwner => async dispatch => {
		dispatch({ type: actionTypes.addVenueOwnerRequest });
		const data = await storeUtils.execute('venueOwners', 'addVenueOwner', venueOwner);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.addVenueOwnerSuccess, venueOwner: venueOwner });
		}
	},

	fetchVenueOwnerCandidate: userId => async dispatch => {
		dispatch({ type: actionTypes.fetchVenueOwnerCandidateRequest });
		const data = await storeUtils.execute('venueOwners', 'getVenueOwnerCandidate', { userId: userId });
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchVenueOwnerCandidateSuccess, data: data });
		}
	},

	getMenuProvider: () => async dispatch => {
		dispatch({ type: actionTypes.getMenuProviderRequest });
		const data = await storeUtils.execute('venues', 'getMenuProvider');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.getMenuProviderSuccess, menuProvider: data.menuProvider });
		}
	},
	
	clearMenuProvider: () => async dispatch => {
		dispatch({ type: actionTypes.clearMenuProviderRequest });
		const data = await storeUtils.execute('venues', 'clearMenuProvider');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.clearMenuProviderSuccess });
		}
	},

	fetchVenuesSizesReport: payload => async dispatch => {
		dispatch({ type: actionTypes.fetchVenuesSizesReportRequest });
		const data = await storeUtils.execute('venues', 'getVenuesSizesReport', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
				dispatch({ type: actionTypes.fetchVenuesSizesReportSuccess, venueSizes: data.venueSizes || data.venueSizes_csv});
		}
	},

	forceOpen: () => async dispatch => {
		dispatch({ type: actionTypes.forceOpenRequest });
		const data = await storeUtils.execute('venues', 'forceOpen');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.forceOpenSuccess, openingHours: data });
		}
	},

	cancelForceOpen: () => async dispatch => {
		dispatch({ type: actionTypes.cancelForceOpenRequest });
		const data = await storeUtils.execute('venues', 'cancelForceOpen');
		if(data.errors) {
			dispatch({ type: actionTypes.venuesFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.cancelForceOpenSuccess, openingHours: data });
		}
	}

};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('VENUES_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null,
			venueSizes: null	// useful to reset Waistaff Admin Report between requests
		}
	}

	switch(action.type) {

		case actionTypes.venuesFail: {
			let venue = state.venue;
			if(state.loading === actionTypes.fetchVenueRequest) {
				// not sure what is best approach but the following seems to match how backend handles errors loading a new venue
				// if venue load fails, revert to previous locally persisted venue if exists
				const pbVenue = localStorage.getItem('pb_venue');
				venue = pbVenue ? JSON.parse(pbVenue) : null;
			}
			return {
				...state,
				loading: null,
				venue: venue,
				errors: action.errors
			}
		}

		case actionTypes.fetchVenuesSuccess: {
			utilities.sortByField(action.venues, 'name');
			return {
				...state,
				loading: null,
				venues: action.venues,
				errors: null
			}
		}

		case actionTypes.fetchVenueSuccess: {
			// need to simplify cuisineTypes first
			const venue = { ...action.venue };
			const cuisineTypes = action.venue.cuisineTypes;
			if(cuisineTypes) venue.cuisineTypes = cuisineTypes.map(type => type.dscr);
			// photoIds are generally larger than MAX_SAFE_INTEGER, so replacing the id with a string taken from the filename
			// venue.photos = venue.photos?.map(photo => {
			// 	return fixPhotoId(photo);
			// });
			// venue.featuredPhotos = venue.featuredPhotos?.map(photo => {
			// 	return fixPhotoId(photo);
			// });
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue,
				errors: null
			}
		}

		case actionTypes.setSelectedVenueSuccess: {
			return {
				...state,
				loading: null,
				venue: null,
				id: action.id,
				errors: null
			}
		}

		case actionTypes.addVenueSuccess: {
			return {
				...state,
				loading: null
			}
		}

		case actionTypes.updateVenueSuccess: {
			const venue = { ...state.venue, ...action.venue };
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			if(action.timezone) {
				// console.log(action.timezone);
				if(!action.venue.openingHours) action.venue.openingHours = {};
				action.venue.openingHours.timezone = action.timezone;
			}
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.updateVenueSizeSuccess: {
			const venue = { ...state.venue };
			venue.venueSize = action.venueSize;
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.deleteVenueSuccess: {
			// should we remove from localStorage? depends what this is for
			// localStorage.removeItem('pb_venue');
			const venues = storeUtils.deleteElement(state.venues, action.venueId, 'id');
			return {
				...state,
				loading: null,
				venues: venues
			}
		}

		case actionTypes.updateWiFiSettingsSuccess: {
			const venue = { ...state.venue, ...action.settings };
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.setVenueSuccess: {
			return {
				...state,
				loading: null,
				venue: action.venue,
				id: action.venue.venueUUID,
				errors: null
			}
		}

		case actionTypes.fetchCuisineTypesSuccess: {
			const cuisineTypes = action.cuisineTypes?.map(type => type.name).sort((el1, el2) => {
				if(el1.name > el2.name) return 1;
				if(el1.name < el2.name) return -1;
				return 0;
			});
			return {
				...state,
				loading: null,
				cuisineTypes: cuisineTypes,
				errors: null
			}
		}

		case actionTypes.fetchOpeningHoursSuccess: {
			return {
				...state,
				loading: null,
				openingHours: action.openingHours
			}
		}

		case actionTypes.updateOpeningHoursSuccess: {
			const venue = { ...state.venue };
			venue.openingHours = action.openingHours
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		// photos service...
		case actionTypes.uploadVenueLogoSuccess: {
			const venue = { ...state.venue };
			venue.logoUrl = action.logoUrl;
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.uploadVenuePhotoSuccess: {
			const venue = { ...state.venue };
			if(!venue.photos) venue.photos = [];
			venue.photos = [...venue.photos, action.venuePhoto];
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.uploadFeaturedPhotoSuccess: {
			const venue = { ...state.venue };
			if(!venue.featuredPhotos) venue.featuredPhotos = [];
			venue.featuredPhotos = [...venue.featuredPhotos, action.featuredPhoto];
			venue.featuredPhotoId = action.featuredPhoto.photoId;
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.setFeaturedPhotoSuccess: {
			const venue = { ...state.venue };
			venue.featuredPhotoId = action.photoId;
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.deletePhotoSuccess: {
			const venue = { ...state.venue };
			switch(action.photoType) {
				case 'v': venue.photos = venue.photos.filter(photo => photo.photoId !== action.photo.photoId); break;
				case 'f': venue.featuredPhotos = venue.featuredPhotos.filter(photo => photo.photoId !== action.photo.photoId); break;
				default: break;
			};
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case actionTypes.getWaitstaffCountSuccess: {
			return {
				...state,
				loading: null,
				waitstaffCount: action.waitstaffCount
			}
		}

		case actionTypes.fetchVenueOwnerSuccess: {
			return {
				...state,
				loading: null,
				venueOwner: action.venueOwner
			}
		}


		case actionTypes.updateVenueOwnerSuccess: {
			return {
				...state,
				loading: null
			}
		}

		case actionTypes.addVenueOwnerSuccess: {
			action.venueOwner.userId = 0;	// so we know later that this is a new record (see Owner.js : formSave())
			return {
				...state,
				loading: null,
				venueOwner: action.venueOwner
			}
		}

		case actionTypes.fetchVenueOwnerCandidateSuccess: {
			return {
				...state,
				loading: null,
				venueOwner: action.data.venueOwner || action.data.user
			}
		}

		case actionTypes.getMenuProviderSuccess: {
			return {
				...state,
				loading: null,
				menuProvider: action.menuProvider
			}
		}

		case actionTypes.clearMenuProviderSuccess: {
			return {
				...state,
				loading: null,
				menuProvider: null
			}
		}

		case actionTypes.fetchVenuesSizesReportSuccess: {
			return {
				...state,
				loading: null,
				venueSizes: action.venueSizes,
				errors: null
			}
		}

		case actionTypes.cancelForceOpenSuccess:
		case actionTypes.forceOpenSuccess: {
			const venue = { ...state.venue };
			venue.openingHours = action.openingHours;
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		// now for some odd stuff - non venue actions can also lead to venue state updates so I am handling these here:
		// I've now decided to compress these into a single block by simply checking for the existence of the appropriate property in the response,
		// making the code work for all venue properties affected by other reducers
		case billingActionTypes.disableOrderingSuccess:
		case billingActionTypes.enableOrderingSuccess:
		case billingActionCreators.addUpdateBillingSettingsSuccess:
		case billingActionTypes.getOrderingStatusSuccess:
		case billingActionTypes.fetchFixedInvoiceItemsSuccess:
		case billingActionTypes.addFixedInvoiceItemSuccess:
		case billingActionTypes.updateFixedInvoiceItemSuccess:
		case posActionTypes.updatePOSinterfaceConfigSuccess:
		case posActionTypes.deletePOSinterfaceConfigSuccess:
		case ordersActionTypes.updateOrderFlowSuccess: {
			// note: other parts of this response are handled by other reducers
			const venue = { ...state.venue };
			if(typeof action.data.orderingEnabled !== 'undefined') venue.orderingEnabled = !!action.data.orderingEnabled;
			if(typeof action.data.receivesOrdersInOwnPOS !== 'undefined') venue.receivesOrdersInOwnPOS = !!action.data.receivesOrdersInOwnPOS;
			if(typeof action.data.usesStatus_ReadyForService !== 'undefined') venue.usesStatus_ReadyForService = !!action.data.usesStatus_ReadyForService;
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		case ordersActionTypes.updateOrderDistributionTypesSuccess: {
			// note: other parts of this response are handled by the orders reducer
			const venue = { ...state.venue, ...action.orderDistributionTypes };
			localStorage.setItem('pb_venue', JSON.stringify(venue));
			return {
				...state,
				loading: null,
				venue: venue
			}
		}

		default: return state;
	}
};