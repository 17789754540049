export default class MenuItem {
	menuId = null;
	menuItemId = null;
	menuSectionTitle = null;
	name = null;
	orderIndex = null;
	price = null;

	kitchenName = null;
	dscr = null;
	calories = null;
	promoPrice = null;
	salesTaxCode = null;
	onPromo = null;
	isHidden = null;
	isInStock = null;
	deliveryOK = null;
	carryOutOK = null;
	veggieStatus = null;
	spicyStatus = null;
	nutSafe = null;
	glutenFree = null;
	dairyFree = null
};