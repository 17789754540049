import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { settingsActionCreators } from 'store/settings';
import { IconButton } from 'components/buttons';
import IconButtonGroup from 'components/IconButtonGroup';
import useL10n from 'L10n';

import { config } from 'store';
import storeUtils from 'store/storeUtils';
import { distributionTypes } from "store/orders";

const OrdersFooter = props => {
	// normally I wouldn't expose the store to an presentation component, but happy to make an exception for settings
	const settingStore = useSelector(state => state.settings);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	const toggleFullscreen = e => {
		dispatch(settingsActionCreators.updateSetting('fullscreen', !props.fullscreen));
	}

	const toggleNotifications = () => {
		dispatch(settingsActionCreators.updateSetting('soundEnabled', !settingStore.soundEnabled));
	}

	const columnIncrementer = n => {
		props.form.onChange(null, 'columns', n);
		const columns = { ...settingStore.orderColumns };
		columns[props.station] = n;
		dispatch(settingsActionCreators.updateSetting('orderColumns', columns));
	}

	const priorityIncrementer = n => {
		props.form.onChange(null, 'priority', n);
		const priority = { ...settingStore.orderPriority };
		priority[props.station] = n;
		dispatch(settingsActionCreators.updateSetting('orderPriority', priority));
	}

	const rushIncrementer = n => {
		props.form.onChange(null, 'rush', n);
		const rush = { ...settingStore.orderRush };
		rush[props.station] = n;
		dispatch(settingsActionCreators.updateSetting('orderRush', rush));
	}

	const pageIncrementer = n => {
		if(n < 0) n = 0;
		props.form.onChange(null, 'page', n);
	}

	const classes = classNames('Orders-footer', 'Venues-footer', { 'async' : props.loading });
	return (
		<footer className={ classes }>

			<div>
				<label>{ _t('LBL_MAXIMISE') }</label>
				<IconButton
					title={ props.fullscreen ? _t('LBL_MINIMISE') : _t('LBL_MAXIMISE') }
					icon={ props.fullscreen ? 'fullscreen_exit' : 'fullscreen' }
					onClick={ toggleFullscreen }
				/>
			</div>

			<div>
				<label>{ _t('LBL_NOTIFICATIONS') }</label>
				<IconButton
					icon={ settingStore.soundEnabled ? 'notifications' : 'notifications_off' }
					onClick={ toggleNotifications }
				/>
			</div>

			<div>
				<label>{ _t('LBL_COLUMNS') }</label>
				<IconButtonGroup
					value={ props.form.data.columns }
					min={ 1 }
					max={ 10 }
					incrementer={ columnIncrementer }
				/>
			</div>

			<div>
				<label>{ _t('LBL_PRIORITY') } </label>
				<IconButtonGroup
					className="control-priority"
					value={ props.form.data.priority }
					min={ 1 }
					max={ 100 }
					incrementer={ priorityIncrementer }
				/>
			</div>

			<div>
				<label>{ _t('LBL_RUSH') }</label>
				<IconButtonGroup
					className="control-rush"
					value={ props.form.data.rush }
					min={ 1 }
					max={ 100 }
					incrementer={ rushIncrementer }
				/>
			</div>

			<div>
				<label>{ _t('LBL_PAGE') }</label>
				<IconButtonGroup
					value={ props.form.data.page }
					min={ 1 }
					max={ props.pages[props.pages.length - 1].page }
					step={ 1 }
					leftIcon="keyboard_arrow_left"
					rightIcon="keyboard_arrow_right"
					incrementer={ pageIncrementer }
				/>
			</div>

			<button className="btn btn-primary" onClick={ props.undoHandler }>{ _t('BTN_UNDO') }</button>

			{ props.station === 'test' &&
				<Fragment>
					<IconButton icon="note_add" onClick={ () => triggerTest('pushTest') }/>
					<IconButton icon="delete_forever" onClick={ () => triggerTest('removeTest', props.lastOrder) }/>
				</Fragment>
			}

		</footer>
	);
};

export default OrdersFooter;

const triggerTest = (serviceName, order) => {
	let payload;
	// console.log(serviceName, order);
	if(serviceName === "pushTest") {
		payload = {
			externalOrderId: 'test-' + Math.floor(Math.random() * 100),
			distributionType: distributionTypes.EAT_IN_WAITSTAFF,
			waitstaffTarget: '2'
		}
	} else {
		if(!order) return;
		payload = {
			externalOrderId: order.externalOrderId
		}
	}
	const url = config.ssePath + '/orders/' + serviceName;
	storeUtils.execute(null, url, payload);
}