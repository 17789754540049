import { errorCodes } from 'store/errors';
import useL10n from 'L10n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Notification from 'components/Notification';
import './ErrorDialog.scss';

const ErrorDialog = ({ errors, onHide, ...dialogProps }) => {
	const _t = useL10n().getText;

	const processError = error => {
		switch(error.code) {
			case errorCodes.UNAUTHORISED_ACCESS: return { message: _t('ERR_UNAUTHORISED_ACCESS') };
			case errorCodes.GENERIC_API_ERROR: return { msg: _t('ERR_GENERIC') };
			default: return error;
		}
	}

	const showDialog = () => {
		if(errors == null) return false;
		// some errors have to be handled "locally" so this "global" error dialog should ignore them
		switch(errors[0].code) {
			case errorCodes.LOGIN_FAILURE:
			case errorCodes.INCORRECT_PASSWORD: return false;
			default: return true;
		}
	}

	const renderErrors = () => {
		if(errors) {
			return errors.map((error, ix) => {
				if(typeof error === 'string') {
					return (
						<Notification key={ ix } type="error" design="outline">
							{ error }
						</Notification>);
				} else {
					const processedError = processError(error);
					const type = error.type || 'error';
					return (
						<Notification key={ ix } type={ type } design="outline">
							{ processedError.msg + (processedError.code ? ' (' + processedError.code + ')' : '') }
						</Notification>);
				}
			});
		}
	}

	return (
		<Modal
			id="errorDialog"
			show={ showDialog() }
			size={ errors ? (errors[0].code === errorCodes.GENERIC_API_ERROR ? '' : 'large') : '' }
			onHide={ onHide }
			{ ...dialogProps }
		>
			<ModalHeader>{ _t('HDG_ERROR') }</ModalHeader>
			<ModalBody className="scrolling">
				{ renderErrors() }
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-primary focus" onClick={ onHide } id="btnClose">{ _t('BTN_CLOSE') }</button>
			</ModalFooter>
		</Modal>
	);

}

export default ErrorDialog;