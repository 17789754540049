import { useEffect, useRef } from 'react';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormInput } from 'components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';

const ResetDialog = props => {
	const _t = useL10n().getText;

	const formSave = () => {
		props.onHide(form.data);
	}

	const form = useForm(formSave, validationRules);
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.show) {
			resetRef.current({});
		}
		// only want this to fire on props.show, so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	return (
		<Modal id="resetDialog" size="small" show={ props.show } draggable={ true }>
			<ModalHeader>{ _t('HDG_RESET_PASSWORD', true) }</ModalHeader>
			<ModalBody>
	
				<ValidationMessage open={ !!form.errors }/>
	
				{ _t('TXT_RESET_PASSWORD') }
				{ props.email }

				<FormInput className="mt10" form={ form } field="email"/>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary focus" onClick={ form.onSave } id="btnOk">
					{ _t('BTN_SEND_EMAIL', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
}

export default ResetDialog;

const validationRules = {
	email: true
}