import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import utilities from 'services/utilities';
import { actionTypes, ordersActionCreators } from 'store/orders';
import useForm from 'components/Form';
import { FormRadio } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import Feedback from './Feedback';

const OrderFlow = props => {

	const venue = useSelector(state => state.venues.venue);
	const ordersStore = useSelector(state => state.orders);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	const formSave = () => {
		form.data.usesStatus_ReadyForService = form.data.usesStatus_ReadyForService === 'true';
		// console.log(form.data);
		dispatch(ordersActionCreators.updateOrderFlow(form.data.usesStatus_ReadyForService));
	}

	const form = useForm(formSave, validationRules);
	const resetRef = useRef(form.reset);

	// useEffect(() => {
	// 	// ready for appearance of suggested endpoint to refresh venue data
	// 	// dispatch(ordersActionCreators.getOrderingStatus());
	// }, [dispatch]);

	useEffect(() => {
		if(venue) {
			resetRef.current({ ...venue });
		}
	}, [venue]);

	return (
		<Fragment>

			<Pane className="animated fadeIn">
				<PaneHeader>
					<h1>{ _t('HDG_ORDERFLOW', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							// disabled={ we need to implement a dirty form option so this is disabled until a change is made }
							async={ ordersStore.loading === actionTypes.updateOrderFlowRequest }
							onClick={ form.onSave }
						/>
					</div>
				</PaneHeader>
				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<Pane>
						<PaneHeader>
							<FormRadio form={ form } field="usesStatus_ReadyForService" value="false">
								{ utilities.getText('LBL_CLOSE_ON_LEAVE_KITCHEN') }
							</FormRadio>
						</PaneHeader>
						<PaneBody className="container">
							<p>{ utilities.getText('TXT_SERVICE_NOT_TRACKED') }</p>
							<div>
								<div className="pill">{ utilities.getText('TXT_COOKING') }</div>
								<i className="material-icons">forward</i>
								<div className="pill">{ utilities.getText('TXT_COMPLETED') }</div>
							</div>
						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>
							<FormRadio form={ form } field="usesStatus_ReadyForService" value="true">
								{ utilities.getText('LBL_CLOSE_LATER') }
							</FormRadio>
						</PaneHeader>
						<PaneBody className="container">
							<p>{ utilities.getText('TXT_SERVICE_TRACKED') }</p>
							<div>
								<div className="pill">{ utilities.getText('TXT_COOKING') }</div>
								<i className="material-icons">forward</i>
								<div className="pill">{ utilities.getText('TXT_SERVICE') }</div>
								<i className="material-icons">forward</i>
								<div className="pill">{ utilities.getText('TXT_COMPLETED') }</div>
							</div>
						</PaneBody>
					</Pane>

					<Feedback className="mt15" status={ ordersStore.status }/>

				</PaneBody>
			</Pane>

			<RouterPrompt when={ form.isDirty }/>

		</Fragment>
	);

}

export default OrderFlow;

const validationRules = {
	usesStatus_ReadyForService: true
}