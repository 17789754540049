import React from 'react';
import { actionTypes } from '../store/tags';
import { IconButton } from '../components/buttons';
import { ItemPaneFooter, Pane, PaneBody, PaneHeader } from '../components/Pane';
import TagCategory from './TagCategory';

const TagCategories = props => {
	let shiftData;

	return (
		<Pane id="maintainCategories">
			<PaneHeader>
				<h1>Maintain Tag Categories</h1>
				<div className="Pane-toolbar">
					<IconButton icon="edit" disabled={ !props.category } onClick={ props.onEditCategory }/>
					<IconButton icon="delete" disabled={ !props.category } onClick={ props.onDeleteCategory }/>
				</div>
			</PaneHeader>
			<PaneBody className="tagCategories">

				{ props.commonTags?.map((category, ix) => {
						if(category.categoryId === props.category?.categoryId) {
							// this is the current item, so store data needed for disabling shift buttons
							shiftData = [props.commonTags.length - 1, ix];
						}
						return <TagCategory
							key={ ix }
							category={ category }
							selected={ category.categoryName === props.category?.categoryName }
							loading={ props.loading }
							addTag={ props.addCommonTag }
							removeTag={ props.removeCommonTag }
							shiftTag= { props.shiftCommonTag }
							onClick={ props.onCategorySelect }
						/>
					})
				}

			</PaneBody>

			<ItemPaneFooter
				sideways={ true }
				loading={ props.loading === actionTypes.reorderCategoriesRequest }
				shiftData={ shiftData }
				shiftElement={ props.shiftCategory }
			>
				<IconButton title="add new category" icon="add" onClick={ props.onAddCategoryClick }/>
			</ItemPaneFooter>

		</Pane>

	);
};

export default TagCategories;