import React from 'react';
import Content from '../layout/Content';
import Payouts from './Payouts';
// import Customers from './Customers';
import './index.scss';

const Reports = props => {

	const nav = [
		{ path: 'payouts', label: 'Payouts', component: Payouts }
		// { path: 'customers', label: 'Customers', component: Customers }
	];

	return (
		<Content path="/venues/reports/" nav={ nav }/>
	);

}

export default Reports;