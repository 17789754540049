// *** TODO *** this is a temp dialog and should not go into prod!!!
import React from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActionCreators } from '../store/settings';
import { Checkbox } from '../components/controls';
import JsonViewer from '../components/JsonViewer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../components/Modal';

const UserInfoDialog = props => {

	const settingStore = useSelector(state => state.settings);
	const dispatch = useDispatch();

	let username = settingStore.username;

	const getUsername = e => {
		let username = e.target.textContent;
		username = (username === 'me' ? 'bruce.morrison' : username) + '@pictabite.com';
		return username;
	}

	const setUsername = e => {
		dispatch(settingsActionCreators.updateSetting('username', getUsername(e)));
	}

	const users = ['me', 'system', 'tester', 'venueowner', 'jake', 'roger'];

	return (
		<Modal id="userInfoDialog" show={ props.show } draggable={ true }>
			<ModalHeader>User Info</ModalHeader>

			<ModalBody>

				<JsonViewer json={ props.userStore }/>

				<Checkbox
					className="mt10"
					disabled={ !props.userStore.user }
					checked={ props.userStore.user?.hasPrivilegedAccess || false }
					onChange={ props.privilegeChangeHandler }>hasPrivilegedAccess
				</Checkbox>
				<br/>
				<ButtonGroup style={{ display: 'flex ', flexWrap: 'wrap' }}>
					{ users.map(name => {
						return <Button key={ name } onClick={ setUsername } active={ username === name + '@pictabite.com' }>{ name }</Button>
					})}
				</ButtonGroup>

			</ModalBody>

			<ModalFooter className="Modal-footer_buttons">
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">Cancel</button>
				<button className="btn btn-primary" onClick={ props.signIn } id="btnOk">Sign In</button>
				<button className="btn btn-primary" onClick={ props.signOut } id="btnOk">Sign Out</button>
			</ModalFooter>

		</Modal>
	);
};

export default UserInfoDialog;