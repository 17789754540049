import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import useL10n from "L10n";
import verbiage from "L10n/verbiage";
import { actionTypes, otherActionCreators } from "store/other";
import ContactUsDialog from 'dialogs/ContactUsDialog';
import MessageDialog from 'dialogs/MessageDialog';
import Home from './Home';
import './Home.scss';

const HomeContainer = props => {
	const _t = useL10n().getText;
	const [messageData, setMessageData] = useState({ show: false });
	const [showContactUsDialog, setShowContactUsDialog] = useState(false);
	const dispatch = useDispatch();
	const otherStore = useSelector(state => state.other);
	const l10nStore = useSelector(state => state.l10n);

	const requestDemo = formData => {
		dispatch(otherActionCreators.requestDemo(formData));
	}

	const hideThanksMessageDialog = () => {
		dispatch(otherActionCreators.clearMessage());
	}

	const hideContactUsDialog = formData => {
		setShowContactUsDialog(false);
		if(formData) {
			dispatch(otherActionCreators.contactUs(formData));
		}
	}

	const contactUsHandler = e => {
		setShowContactUsDialog(true);
	}

	const showMessage = page => {
		const text = verbiage.get(page, l10nStore.locale);
		setMessageData({
			show: true,
			title: _t('HDG_WEB_' + page.toUpperCase(), true),
			message: text,
			class: 'info-' + page
		});
	}

	const hideMessageDialog = () => {
		setMessageData({ ...messageData, show: false });
	}

	const messageClickHandler = e => {
		const msg = e.target.className.substring(4);
		if(msg) {
			hideMessageDialog();
			setTimeout(() => {
					showMessage(msg);
			}, 300);
		} else {
			console.error('Invalid link format in verbiage.')
		}
	}

	return (
		<Fragment>

			<Home
				requestDemo={ requestDemo }
				contactUs={ contactUsHandler }
				showMessage={ showMessage }
			/>

			<ContactUsDialog
				show={ showContactUsDialog }
				onHide={ hideContactUsDialog }
			/>

			<MessageDialog
				title={ _t('HDG_THANK_YOU', true) }
				message={ _t(otherStore.loading === actionTypes.requestDemoRequest ? 'TXT_DEMO_THANK_YOU' : 'TXT_CONTACT_US_THANK_YOU' ) }
				show={ otherStore.loading === actionTypes.requestDemoRequest || otherStore.loading === actionTypes.contactUsRequest }
				onHide={ hideThanksMessageDialog }
			/>

			<MessageDialog
				size="large"
				className={ classNames('Modal_fullHeight', 'info', messageData.class )}
				bodyClass="scrolling"
				title={ messageData.title ?? '' }
				message={ messageData.message ?? '' }
				show={ messageData.show }
				onMessageClick={ messageClickHandler }
				onHide={ hideMessageDialog }
			/>

		</Fragment>
	);
};

export default HomeContainer;