import dates from "services/dates";

export const actionTypes = {
	l10nFail: 'L10N_FAIL',
	
	fetchTranslationsRequest: 'FETCH_TRANSLATIONS_L10N_REQUEST',
	fetchTranslationsSuccess: 'FETCH_TRANSLATIONS_L10N_SUCCESS',

	fetchTranslationsAdminRequest: 'FETCH_TRANSLATIONS_ADMIN_L10N_REQUEST',
	fetchTranslationsAdminSuccess: 'FETCH_TRANSLATIONS_ADMIN_L10N_SUCCESS',

	setLocaleSuccess: 'SET_LOCALE_SUCCESS',
}

const initialState = { translations: null, locale: null, loading: null, errors: null };

export const l10nActionCreators = {

	fetchTranslations: locale => async dispatch => {
		dispatch({ type: actionTypes.fetchTranslationsRequest });
		try {
			// if I pass import a variable it fails (babel? webpack?), so...
			const data = await import(`../L10n/${ locale }.json`);
			dispatch({ type: actionTypes.fetchTranslationsSuccess, translations: data, locale: locale });
		} catch(ex) {
			// q: do we want to display missing locale data to the user - what can they do about it?
			// a: fail silently with a console error for our benefit?
			// dispatch({ type: actionTypes.l10nFail, errors: [ ex.message ] });
			console.error('L10n:', ex.message);
		}
	},

	fetchTranslationsForAdmin: locale => async dispatch => {
		dispatch({ type: actionTypes.fetchTranslationsAdminRequest });
		try {
			// if I pass import a variable it fails (babel? webpack?), so...
			const data = await import(`../L10n/${ locale }.json`);
			dispatch({ type: actionTypes.fetchTranslationsAdminSuccess, translations: data, locale: locale });
		} catch(ex) {
			// q: do we want to display missing locale data to the user - what can they do about it?
			// a: fail silently with a console error for our benefit?
			// dispatch({ type: actionTypes.l10nFail, errors: [ ex.message ] });
			console.error('L10n:', ex.message);
		}
	},

	setLocale: locale => {
		// only using this currently to clear the locale ahead of a user-initiated language change - rename accordingly?
		return { type: actionTypes.setLocaleSuccess, locale: locale };
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('L10N_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.l10nFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchTranslationsSuccess: {
			// console.log('LOCALE:', action.locale);
			const translations = { ...state.translations, ...action.translations };
			delete(translations.default);
			translations.localeData = dates.getLocaleData(action.locale);
			return {
				...state,
				loading: null,
				translations: translations,
				locale: action.locale
			}
		}

		case actionTypes.fetchTranslationsAdminSuccess: {
			const translations = { ...action.translations };
			delete(translations.default);
			if(action.locale === 'en') {
				return {
					...state,
					loading: null,
					en: translations,
					locale: action.locale
				}
			} else {
				return {
					...state,
					loading: null,
					check: translations,
					locale: action.locale
				}
			}
		}

		case actionTypes.setLocaleSuccess: {
			return {
				...state,
				loading: null,
				locale: action.locale
			}
		}

		default: {
			return state;
		}
	}
}