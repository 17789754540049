import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import dates from 'services/dates';
import utilities from 'services/utilities';
import { actionTypes, venuesActionCreators } from 'store/venues';
import useForm from 'components/Form';
import { FormDatePicker, FormLabel, FormRadio } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import { Row, Cell, Col } from 'components/Table';
import ValidationMessage from 'components/ValidationMessage';
import WaitstaffDetailsDialog from './dialogs/WaitstaffDetailsDialog';

const WaitStaff = props => {

	const [showDialog, setShowDialog] = useState(false);
	const [currentRow, setCurrentRow] = useState();
	const venues = useSelector(state => state.venues);
	const venueSizes = venues?.venueSizes;
	const timeZone = venues?.venue?.openingHours?.timezone.timezone;
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();

	const form = useForm(null, null, {
		reportType: 'selectedVenueSizes',
		reportFormat: 'json'
	});

	useEffect(() => {
		if(typeof venueSizes === 'string') {
			const blob = new Blob([venueSizes], { type: 'text/csv' });
			const url = URL.createObjectURL(blob);
			utilities.download(url, 'waitstaff.csv');
			URL.revokeObjectURL(url);
		}
		return () => {
		}
	}, [venueSizes]);

	const fetchData = e => {
		const payload = {
			reportType: form.data.reportType,
			reportFormat: form.data.reportFormat
		};
		if(form.data.fromDate) {
			if(form.data.reportType === 'allVenuesSizes') {
				// venues can be in any time zone, so simply use the user's in this case
				payload.from = form.data.fromDate.getTime();
				if(payload.to) {
					payload.to = form.data.toDate.getTime() + (dates.day - 1);
				}
			} else {
				// adjust for the selected venue's time zone
				payload.from = dates.zonedDateToUtc(timeZone, form.data.fromDate).getTime();
				if(payload.to) {
					payload.to = dates.zonedDateToUtc(timeZone, form.data.toDate).getTime() + (dates.day - 1);
				}
			}
		}
		dispatch(venuesActionCreators.fetchVenuesSizesReport(payload));
	}

	const selectRow = row => {
		setCurrentRow(row);
		setShowDialog(true);
	}

	const hideDialog = e => {
		setShowDialog(false);
	}

	const renderRows = () => {
		let venueId = 0, stripe = true;
		return venueSizes.map((row, ix) => {
			if(venueId !== row.venueUUID) {
				venueId = row.venueUUID;
				stripe = !stripe;
			}
			return <Row className={ classNames({ striped: stripe })} key={ ix } data={ row } onClick={ selectRow }>
				<Cell w="20%" title={ row.name }>{ row.name }</Cell>
				<Cell w="10%">{ dates.format(row.date) }</Cell>
				<Cell w="10%">{ row.weekDiff }</Cell>
				<Cell>{ row.numOfTables }</Cell>
				<Cell>{ row.numOfChairs }</Cell>
				<Cell>{ row.waitstaff_Mon }</Cell>
				<Cell>{ row.waitstaff_Tue }</Cell>
				<Cell>{ row.waitstaff_Wed }</Cell>
				<Cell>{ row.waitstaff_Thu }</Cell>
				<Cell>{ row.waitstaff_Fri }</Cell>
				<Cell>{ row.waitstaff_Sat }</Cell>
				<Cell>{ row.waitstaff_Sun }</Cell>
			</Row>
		});
	}

	return (
		<div className="animated fadeIn">

			<div className="row">
				<div className="col">
					<FormLabel className="pt-0" htmlFor="fromDate" label="_DATE_FROM"/>
					<FormDatePicker className="mt5" form={ form } field="fromDate"/>
				</div>
				<div className="col">
					<FormLabel className="pt-0" htmlFor="toDate" label="_DATE_TO"/>
					<FormDatePicker className="mt5" form={ form } field="toDate"/>
				</div>
				{ user?.hasPrivilegedAccess &&
					<div className="col-auto">
						<FormLabel className="pt-0" htmlFor="toDate" label="_VENUES"/>
						<FormRadio className="Radio_stacked" form={ form } field="reportType" value="selectedVenueSizes">current</FormRadio>
						<FormRadio className="Radio_stacked" form={ form } field="reportType" value="allVenuesSizes">all</FormRadio>
					</div>
				}
				<div className="col-auto">
					<FormLabel className="pt-0" htmlFor="toDate" label="_OUTPUT"/>
					<FormRadio className="Radio_stacked" form={ form } field="reportFormat" value="json">on screen</FormRadio>
					<FormRadio className="Radio_stacked" form={ form } field="reportFormat" value="csv">as CSV</FormRadio>
				</div>
				<div className="col-auto row no-gutters align-items-center">
					<IconButton
						className="btn-primary"
						async={ venues.loading === actionTypes.fetchVenuesSizesReportRequest }
						icon="refresh"
						onClick={ fetchData }
					/>
				</div>
			</div>

			{ venueSizes && (typeof venueSizes === 'object') &&
				(venueSizes.length > 0
					?
						<Pane className="Table_selectable mt10" id="waitstaffReport" role="table">
							<PaneHeader>
								<Col w="20%">Name</Col>
								<Col w="10%">Date</Col>
								<Col w="10%">Week Diff</Col>
								<Col>Tables</Col>
								<Col>Seats</Col>
								<Col>MON</Col>
								<Col>TUE</Col>
								<Col>WED</Col>
								<Col>THU</Col>
								<Col>FRI</Col>
								<Col>SAT</Col>
								<Col>SUN</Col>
							</PaneHeader>
							<PaneBody className="pb5">
								{ renderRows() }
							</PaneBody>
						</Pane>
					:
						<ValidationMessage className="mt10" open={ true } message="No data found matching the selected criteria."/>
				)
			}

			<WaitstaffDetailsDialog
				show={ showDialog }
				row={ currentRow }
				onHide={ hideDialog }
			/>

		</div>
	);

}

export default WaitStaff;