import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import utilities from '../services/utilities';
import { actionTypes } from '../store/modifiers';
import { Checkbox } from '../components/controls';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { IconButton } from '../components/buttons';
import { Pane, ItemPaneFooter, PaneItem, PaneItemButton, PaneBody, PaneHeader } from '../components/Pane';
import { FormInput } from '../components/formControls';
import ValidationMessage from '../components/ValidationMessage';
import './Modifiers.scss';

const ModifierGroups = props => {

	const firstInput = useRef();
	const [currentItemId, setCurrentItemId] = useState();
	const [itemRemoveDialogShow, setItemRemoveDialogShow] = useState(false);
	const [groupDeleteDialogShow, setGroupDeleteDialogShow] = useState(false);
	let groupShiftData;
	let itemShiftData;

	const renderModifierGroups = () => {
		if(!props.modifierStore.groups) return null;
		const groups = props.modifierStore.groups;
		groups.sort((g1, g2) => g1.orderIndex - g2.orderIndex);
		return groups.map((group, ix) => {
			if(group.id === props.form.data.id) {
				// this is the current group, so store data needed for disabling shift buttons
				groupShiftData = [groups.length - 1, ix];
			}
			return (
				<li key={ group.id }>
					<NavLink className="Pane-item" to={ '' + group.id }>{ group.name }</NavLink>
				</li>
			);
		});
	}

	const onItemSelect = id => {
		// console.log('onItemSelect');
		setCurrentItemId(id);
	}
	
	const onDeleteItemClick = () => {
		setItemRemoveDialogShow(true);
	}

	const onGroupDelete = () => {
		setGroupDeleteDialogShow(true);
	}

	const toggleItemDefault = () => {
		props.toggleItemDefault(props.form.data.modifierItems.find(item => item.id === currentItemId));
	}

	const onAddClick = e => {
		const sourceId = e.currentTarget.id;
		props.onAddClick(sourceId);
		if(sourceId === 'newGroup') {
			const input = firstInput.current.childNodes[0];
			input.focus();
		}
	}

	const shiftItem = isUp => {
		props.shiftItem(currentItemId, isUp);
	}

	const confirmDialogHide = response => {
		if(response.action === "Delete Modifier Group") {
			if(response.button === 'btnOk') {
				props.onGroupDelete(props.form.data);
			}
			setGroupDeleteDialogShow(false);
		} else {
			if(response.button === 'btnOk') {
				props.onItemRemove(currentItemId);
				setCurrentItemId(null);
			}
			setItemRemoveDialogShow(false);
		}
	}

	const renderModifierItems = () => {
		if(!props.form.data) return null;
		const items = props.form.data.modifierItems;
		if(!items) return null;
		items.sort((i1, i2) => i1.orderIndex - i2.orderIndex);
		return items.map((item, ix) => {
			const modifierItem = props.modifierStore.items.find(i => i.id === item.id);
			if(!modifierItem) return null;
			if(item.id === currentItemId) {
				// this is the current item, so store data needed for disabling shift buttons
				itemShiftData = [items.length - 1, ix];
				// console.log(itemShiftData);
			}
			return (
				<PaneItem key={ ix } index={ modifierItem.id } selected={ modifierItem.id === currentItemId } onClick={ onItemSelect }>
					<div className="col-2"><Checkbox checked={ item.isDefault } onChange={ toggleItemDefault }/></div>
					<div className="col-5">{ modifierItem.retailName }</div>
					<div className="col-2 text-right">{ utilities.formatPrice(modifierItem.price, props.currencySymbol) }</div>
					<PaneItemButton icon="delete" index={ modifierItem.id } propagate={ true } onClick={ onDeleteItemClick }/>
				</PaneItem>
			);
		});
	}

	const headerClassName = classNames('row', 'no-gutters', {async: props.modifierStore.loading === actionTypes.fetchRequest });
	return (
		<div className="row animated fadeIn">

		<div className="col-md-4 col-lg-5">
			<Pane id="modifierGroupsPane">

				<PaneHeader className={ headerClassName }>
					<h1>Modifier Group</h1>
				</PaneHeader>

				<PaneBody tag="ul" className="scrolling Pane-items">
					{ renderModifierGroups() }
				</PaneBody>

				<ItemPaneFooter shiftData={ groupShiftData } shiftElement={ props.shiftGroup }>
					<IconButton id="newGroup" to="./" title="add new group" icon="add" onClick={ onAddClick }/>
				</ItemPaneFooter>

			</Pane>
		</div>

		<div className="col-md-8 col-lg-7">
			<Pane id="modifierGroupPane">

				<PaneHeader>
					<h1>{ (props.form.data.id && props.form.data.name) ? props.form.data.name : 'New modifier group' }</h1>
					<div className="Pane-toolbar">
						<IconButton
							async={ props.modifierStore.loading === actionTypes.deleteGroupRequest }
							title="delete modifier group"
							disabled={ !props.form.data.id }
							icon="delete"
							onClick={ onGroupDelete }
						/>
						<IconButton
							async={ props.modifierStore.loading === actionTypes.updateGroupRequest || props.modifierStore.loading === actionTypes.addGroupRequest }
							title="save modifier group"
							icon="save"
							onClick={ props.onGroupSave }
						/>
					</div>
				</PaneHeader>

				<PaneBody className="container">
					<ValidationMessage open={ !!props.form.errors }/>

					<div className="form-row form-row_simple">
						<div className="col-sm-6" ref={ firstInput }>
							<FormInput placeholder="Group Name" form={ props.form } field="name"/>
							{/* <FormInput placeholder="Group Name" entity={ props.form.data } field="name" errors={ props.errors } onChange={ props.onChange }/> */}
						</div>
						<div className="col-sm-6">
							<FormInput placeholder="Retail Group Name" form={ props.form } field="retailName"/>
							{/* <FormInput placeholder="Retail Group Name" entity={ props.form.data } field="retailName" errors={ props.errors } onChange={ props.onChange }/> */}
						</div>
						<div className="col-sm-6">
							<FormInput placeholder="Omnivore ID" form={ props.form } field="omnivoreId"/>
							{/* <FormInput placeholder="Omnivore ID" entity={ props.form.data } field="omnivoreId" onChange={ props.onChange }/> */}
						</div>
						<div className="col-sm-6">
							<FormInput placeholder="POS ID" form={ props.form } field="posId"/>
							{/* <FormInput placeholder="POS ID" entity={ props.form.data } field="posId" onChange={ props.onChange }/> */}
						</div>
					</div>

					<label>CONTAINS MODIFIER ITEMS</label>
					<Pane id="containsModifierItems">

						<PaneHeader className="Pane-header_headings">
							<div className="col-2">Default</div>
							<div className="col-5">Modifier Item</div>
							<div className="col-2 text-right">Price</div>
						</PaneHeader>

						<PaneBody tag="ul" className="scrolling Pane-items Pane-items_p0">
							{ renderModifierItems() }
						</PaneBody>
					
						<ItemPaneFooter shiftData={ itemShiftData } shiftElement={ shiftItem }>
							<IconButton
								async={ props.modifierStore.loading === actionTypes.addGroupItemRequest }
								disabled={ !props.form.data.id }
								title="add new item"
								icon="add"
								id="newItem"
								onClick={ onAddClick }
							/>
						</ItemPaneFooter>
					</Pane>

				</PaneBody>

			</Pane>
		</div>

		<ConfirmDialog
			action="Delete Modifier Group"
			message="Are you sure you want to delete this modifier group?"
			show={ groupDeleteDialogShow }
			onHide={ confirmDialogHide }
		/>

		<ConfirmDialog
			action="Remove Modifier Item"
			message="Are you sure you want to remove this modifier item?"
			show={ itemRemoveDialogShow }
			onHide={ confirmDialogHide }
		/>

	</div>
	);

}

export default ModifierGroups;