import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import verbiage from 'L10n/verbiage';
import useL10n from 'L10n';
import { actionTypes, printersActionCreators } from 'store/printers';
import useForm from 'components/Form';
import usePrinterEvents from 'store/PrinterEvents';
import { FormCheckbox, FormInput, FormLabel } from 'components/formControls';
import { IconButton } from 'components/buttons';
import Notification from 'components/Notification';
import { Pane, PaneBody, PaneFooter, PaneHeader } from 'components/Pane';
import { Row, Cell, Col } from 'components/Table';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import MessageDialog from 'dialogs/MessageDialog';
import AddPrinterDialog from './AddPrinterDialog';

const Printers = props => {
	const [showAddPrinterDialog, setShowAddPrinterDialog] = useState(false);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const [showConfirmReconnectDialog, setShowConfirmReconnectDialog] = useState(false);
	const [showMessageDialog, setShowMessageDialog] = useState(false);
	const printersStore = useSelector(state => state.printers);
	const user = useSelector(state => state.user.user);
	const venueId = useSelector(state => state.venues.venue?.venueUUID);
	const dispatch = useDispatch();

	const lostConnectionHandler = lost => {
		setShowConfirmReconnectDialog(lost);
	}

	const printerEvents = usePrinterEvents(lostConnectionHandler);

	const _t = useL10n().getText;
	let { id } = useParams();
	id = id ? parseInt(id, 10) : null;

	const formSave = () => {
		const payload = {
			printerId: form.data.printerId,
			dscr: form.data.dscr,
			name: form.data.name,
			enabled: form.data.enabled,
			pbPassword: form.data.pbPassword,
			adminPassword: form.data.adminPassword
		}
		// console.log('formSave', payload);
		dispatch(printersActionCreators.updatePrinter(payload));
	}

	const form = useForm(formSave, validationRules);
	const formRef = useRef(form);

	useEffect(() => {
		if(venueId) {
			dispatch(printersActionCreators.fetchPrinters());
		}
	}, [venueId, dispatch]);

	useEffect(() => {
		if(id) {
			const printer = printersStore.printers?.find(printer => printer.printerId === '' + id);
			if(!printer) return;
			const common = printersStore.common;
			const formData = { ...printer, ...common };
			formRef.current.reset(formData, false);
		}
	}, [id, printersStore.printers, printersStore.common]);

	// useEffect(() => {
	// 	// see if we need to update printers in the store with SSE data
	// 	if(printers.data?.printers.length) {
	// 		if(printersStore.printers && (printersStore.loading === actionTypes.updatePrinterRequest || printersStore.loading === actionTypes.fetchPrinterRequest)) {
	// 			console.log(printersStore);
	// 			const newPrinters = printersStore.printers.map(printer => {
	// 				console.log(printer);
	// 				return printer;
	// 			});
	// 		}
	// 	}
	// }, [printers.data?.printers]);

	useEffect(() => {
		// see if we need to update printers in the store with SSE data
		if(printerEvents.event) {
			// the next line does its best to prevent updating the store for changes initiated in this page (as these changes already update the store)
			if(printersStore.printers && !(printersStore.loading === actionTypes.updatePrinterRequest || printersStore.loading === actionTypes.fetchPrinterRequest)) {
				if(printerEvents.event.type === 'updatePrinterStatus') {
					const newPrinterData = printersStore.printers.map(printer => printer.printerId === printerEvents.event.data.printerId ? printerEvents.event.data : printer);
					dispatch(printersActionCreators.setPrinters(newPrinterData));
				}
			}
		}
	// we don't want a dependency on the printerStore or we'll have an infinite loop
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [printerEvents.event]);


	const hideAddPrinterDialogHandler = printer => {
		if(printer.printerId) {	// i.e. not [CANCEL]ed
			dispatch(printersActionCreators.addPrinter(printer));
		}
		setShowAddPrinterDialog(false);
	}

	const onAddPrinter = e => {
		setShowAddPrinterDialog(true);
	}

	const onDeleteClick = () => {
		setShowConfirmDeleteDialog(true);
	}

	const hideConfirmDeleteDialogHandler = response => {
		if(response.button === 'btnOk') {
			dispatch(printersActionCreators.removePrinter(form.data));
			formRef.current.reset({});
		}
		setShowConfirmDeleteDialog(false);
	}

	const hideConfirmReconnectDialogHandler = response => {
		if(response.button === 'btnOk') {
			// user confirms they want to attempt to reconnect to SSE service
			printerEvents.reset();
		}
		setShowConfirmReconnectDialog(false);
	}

	const hideMessageDialogHandler = () => {
		setShowMessageDialog(false);
	}

	const onInfoClick = e => {
		setShowMessageDialog(true);
	}

	const classes = classNames({ async: printersStore.loading });
	return (
		<Fragment>

			<div className="row">
				<div className="col-lg">

					<Pane className="Printers Table_selectable animated fadeIn" role="table">
						<PaneHeader className={ classes }>
							<Col w="30%">{ _t('HDG_PRINTER_ID', true) }</Col>
							<Col w="40%">{ _t('HDG_PRINTER', true) }</Col>
							<Col>{ _t('HDG_STATUS', true) }</Col>
						</PaneHeader>
						<PaneBody className={ user?.hasPrivilegedAccess ? '' : 'pb5' }>
							{ printersStore.printers?.map((printer, ix) => {
									let status = 'status_ok', statusIcon = 'check';
									if(printer.statusDescription?.length) {
										// find the "worst" status to display...
										const maxLevel = printer.statusDescription.reduce((accum, current) => {
											return (current.severity === 'ERROR' && accum < 2) ? 2 : (current.severity === 'WARNING' && accum < 1) ? 1 : accum;
										}, 0);
										switch(maxLevel) {
											case 2: status = 'status_errors'; statusIcon = 'error_outline'; break;
											case 1: status = 'status_warnings'; statusIcon = 'warning_amber'; break;
											default: break;
										}
									}
									if(!printer.enabled && status === 'status_ok') {
										status = '';
										statusIcon = null;
									}
									return (
										<Row key={ ix } to={ '/venues/orders/settings/printers/' + printer.printerId }>
											<Cell w="30%">{ printer.printerId }</Cell>
											<Cell w="40%">{ printer.name }</Cell>
											<Cell className={ classNames('status', 'pv0', status) }>
												<span className="material-icons">{ statusIcon }</span>
											</Cell>
										</Row>
									);
								})
							}

						</PaneBody>
						{ !!user?.hasPrivilegedAccess &&
							<PaneFooter className="toolbar">
								<IconButton title={ _t('TTP_PRINTER_ADD')} icon="add" onClick={ onAddPrinter }/>
							</PaneFooter>
						}
					</Pane>

				</div>
				<div className="col-lg">
					{ form.data.printerId &&
						<Pane>
							<PaneHeader>
								<h1>{ form.data.printerId + ' - ' + form.data.name }</h1>
								<div className="Pane-toolbar">
									<IconButton title={ _t('TTP_INFO') } icon="info_outline" onClick={ onInfoClick }/>
									{ !!user?.hasPrivilegedAccess &&
										<IconButton
											title={ _t('TTP_DELETE') }
											icon="delete"
											async={ printersStore.loading === actionTypes.removePrinterRequest }
											onClick={ onDeleteClick }
										/>
									}
									<IconButton
										title={ _t('TTP_SAVE') }
										icon="save"
										async={ printersStore.loading === actionTypes.updatePrinterRequest }
										onClick={ form.onSave }
									/>
								</div>
							</PaneHeader>

							<PaneBody>
								{ form.data.statusDescription?.length > 0 &&
								<section className="Pane-body_section" id="printerStatus">
									{
										form.data.statusDescription.map((status, ix) => {
											const type = status.severity.toLowerCase();
											return <Notification key={ ix } type={ type } design="outline">{ status.dscr }</Notification>
										})
									}
								</section>
								}

								<section className="Pane-body_section" id="printerForm">

									<ValidationMessage open={ !!form.errors }/>

									<div className="form-row">
										<FormLabel htmlFor="enabled" className="col-sm-4" label="_PRINTER_ENABLED"/>
										<div className="col-sm-8">
											<FormCheckbox form={ form } field="enabled"/>
										</div>
									</div>
									<div className="form-row">
										<FormLabel htmlFor="dscr" className="col-sm-4" label="_PRINTER_TYPE"/>
										<div className="col-sm-8">
											<FormInput readOnly form={ form } field="dscr"/>
										</div>
									</div>
									<div className="form-row">
										<FormLabel htmlFor="name" className="col-sm-4" label="_PRINTER_NAME"/>
										<div className="col-sm-8">
											<FormInput form={ form } field="name"/>
										</div>
									</div>

								</section>

								{ !!user?.hasPrivilegedAccess &&
									<Fragment>
										<section className="Pane-body_section">
											<h2>Configuration &gt; Password</h2>

											<div className="form-row">
												<FormLabel htmlFor="adminUsername" className="col-sm-4" label="_USERNAME"/>
												<div className="col-sm-8">
													<FormInput readOnly form={ form } field="adminUsername"/>
												</div>
											</div>
											<div className="form-row">
												<FormLabel htmlFor="adminPassword" className="col-sm-4" label="_PASSWORD"/>
												<div className="col-sm-8">
													<FormInput form={ form } field="adminPassword"/>
												</div>
											</div>
										</section>
										<section className="Pane-body_section">
											<h2>Epson Config for TM-i &gt; Server Direct Print</h2>
											<div className="form-row">
												<FormLabel htmlFor="printJobURL printerStatusURL" className="col-sm-4" label="_PRINT_JOB_URL"/>
												<div className="col-sm-8">
													<FormInput form={ form } field="printJobURL"/>
												</div>
											</div>
											<div className="form-row">
												<FormLabel htmlFor="printJobSecondInterval" className="col-sm-4" label="_PRINT_INTERVAL"/>
												<div className="col-sm-8">
												<FormInput readOnly form={ form } field="printJobSecondInterval"/>
												</div>
											</div>
										</section>
										<section className="Pane-body_section">
											<h2>Epson Config for TM-i &gt; Status Notification</h2>
											<div className="form-row">
												<FormLabel htmlFor="printerStatusURL" className="col-sm-4" label="_PRINT_STATUS_URL"/>
												<div className="col-sm-8">
													<FormInput form={ form } field="printerStatusURL"/>
												</div>
											</div>
											<div className="form-row">
												<FormLabel htmlFor="printerStatusSecondInterval" className="col-sm-4" label="_PRINT_INTERVAL"/>
												<div className="col-sm-8">
												<FormInput readOnly form={ form } field="printerStatusSecondInterval"/>
												</div>
											</div>
										</section>
										<section className="Pane-body_section">
											<h2>Epson Config for TM-i &gt; Common (all services)</h2>
											<div className="form-row">
												<FormLabel htmlFor="pbPassword" className="col-sm-4" label="_PASSWORD"/>
												<div className="col-sm-8">
													<FormInput form={ form } field="pbPassword"/>
												</div>
											</div>
										</section>
									</Fragment>
								}

							</PaneBody>
						</Pane>
				}
				</div>
			</div>

			<AddPrinterDialog
				types={ printersStore.types }
				show={ showAddPrinterDialog }
				onHide={ hideAddPrinterDialogHandler }
			/>

			<ConfirmDialog
				heading={ _t('HDG_CONFIRM_DELETE', true, _t('TXT_PRINTER')) }
				message={ _t('TXT_CONFIRM_DELETE_PRINTER', false, form.data?.printerId + ' - ' + form.data?.name) }
				draggable={ true }
				show={ showConfirmDeleteDialog }
				onHide={ hideConfirmDeleteDialogHandler }
			/>

			<ConfirmDialog
				heading={ _t('HDG_LOST_CONNECTION', true) }
				message={ <Notification type="warning">{ _t('TXT_LOST_CONNECTION') }</Notification> }
				okText={ _t('BTN_RECONNECT', true) }
				show={ showConfirmReconnectDialog }
				onHide={ hideConfirmReconnectDialogHandler }
			/>

			<MessageDialog
				title={ _t('HDG_CONNECTIVITY_TIPS') }
				message={ verbiage.get('connectivity') }
				className="info"
				show={ showMessageDialog }
				onHide={ hideMessageDialogHandler }
			/>

			<RouterPrompt when={ form.isDirty }/>

		</Fragment>
	);

}

export default Printers;

const validationRules = {
	name: true
}