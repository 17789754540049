import { useEffect, useRef } from 'react';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormInput } from 'components/formControls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';

const TagEditDialog = props => {
	const _t = useL10n().getText;

	const formSave = e => {
		props.onHide(form.data);
	}

	const form = useForm(formSave, { tagName: true });
	const formRef = useRef(form);

	useEffect(() => {
		if(props.tag) {
			formRef.current.reset(props.tag);
		}
	}, [props.tag]);

	return (
		<Modal id="tagEditDialog" show={ props.show } draggable={ true }>
			<ModalHeader>Rename Tag</ModalHeader>
			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<FormInput placeholder="Tag Name" className="focus" form={ form } field="tagName"/>

			</ModalBody>

			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">{ _t('BTN_CANCEL', true) }</button>
				<button className="btn btn-primary" onClick={ form.onSave } id="btnAdd">{ _t('BTN_SAVE', true) }</button>
			</ModalFooter>

		</Modal>
	);
};

export default TagEditDialog;