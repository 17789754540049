import { actionTypes } from 'store/menus';
import { Checkbox } from 'components/controls';
import CustomerRating from 'components/CustomerRating';
import { FormCheckbox, FormImageControl } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneHeader, PaneBody, PaneFooter, ItemPaneFooter, PaneItem, PaneItemButton } from 'components/Pane';
import UploaderViewer from 'components/UploaderViewer';
import ValidationMessage from 'components/ValidationMessage';
import MenuItemForm from './MenuItemForm';
import './MenuItemPane.scss';

const MenuItemPane = props => {

	let shiftData;

	const modifierGroupSelect = group => {
		props.setCurrentModifierGroup(group);
	}

	return (
		<Pane id="menuItemPane">
			<PaneHeader>
				<h1>{ props.form.data.name ? props.form.data.name : 'Menu item' }</h1>
				<div className="Pane-toolbar">
					{ props.user?.hasPrivilegedAccess &&
						<IconButton
							title="edit tags"
							disabled={ !props.form.data.menuItemId }
							icon="bookmark"
							onClick={ props.editTags }
						/>
					}
					<IconButton
						title="delete menu item"
						disabled={ !props.form.data.menuItemId }
						async={ props.menuStore.loading === actionTypes.deleteItemRequest }
						icon="delete"
						onClick={ props.onDeleteItem }
					/>
					<IconButton
						title="save menu item"
						disabled={ !props.form.data.menuItemId }
						async={ props.menuStore.loading === actionTypes.updateItemRequest || props.menuStore.loading === actionTypes.addItemRequest }
						icon="save"
						onClick={ props.form.onSave }
					/>
				</div>
			</PaneHeader>
			<PaneBody className="container">
				<ValidationMessage open={ !!props.errors }/>

				<div className="form-row">
					<div className="col-sm">
						<UploaderViewer disabled={ !props.form.data.menuItemId } photos={ props.form.data.photos } onPhotoDrop={ props.onPhotoDrop }/>
					</div>
					<div className="col-sm">
						<MenuItemForm
							form={ props.form }
							// item={ props.form.data }
							// errors={ props.form.errors }
							disabled={ !props.form.data.menuItemId }
							menuSections={ props.menuSections }
							onSalesTaxButtonClick={ props.onSalesTaxButtonClick }
							// onChange={ props.form.onChange }
						/>
					</div>
				</div>

				{ props.form.data.tags && props.form.data.tags.length > 0 &&
					<ul className="tagList mb10" onClick={ props.editTags }>
						{	props.form.data.tags.map(tag => {
								return <li key={ tag.id || tag } className="tag">
									{ tag.tagName || tag }
								</li>
						})}
					</ul>
				}

				<div className="form-row">
					<div className="col-sm-6">
						<Pane id="modifiers">
							<PaneHeader className="Pane-header_headings">
								<div className="col-4">Modifiers</div>
								<div className="col-2 text-right">REQ</div>
								<div className="col-2 text-right">MIN</div>
								<div className="col-2 text-right">MAX</div>
							</PaneHeader>
							<PaneBody tag="ul" className="Pane-items Pane-items_p0 scrolling">
								{ props.form.data.modifierGroups && props.form.data.modifierGroups.map((group, ix) => {
									if(props.group) {
										if(props.group.modifierGroupId === group.modifierGroupId) {
											shiftData = [props.form.data.modifierGroups.length - 1, ix];
										}
									}
									return (
										<PaneItem key={ group.modifierGroupId } index={ group } selected={ group.modifierGroupId === props.group?.modifierGroupId } onClick={ modifierGroupSelect }>
											<div className="col-4">{ group.name }</div>
											<div className="col-2 text-right"><Checkbox disabled readOnly checked={ group.required }/></div>
											<div className="col-2 text-right">{ group.min }</div>
											<div className="col-2 text-right">{ group.max || '–' }</div>
											<PaneItemButton index={ group } icon="edit" onClick={ props.editModifierGroup }/>
										</PaneItem>
									);
								}) }
							</PaneBody>
							<ItemPaneFooter shiftData={ shiftData } shiftElement={ props.shiftGroup }>
								<IconButton disabled={ !props.form.data.menuItemId } title="add new modifier group" icon="add" onClick={ props.onAddModifierGroup }/>
								<IconButton disabled={ !props.group } title="delete selected modifier group" icon="delete" onClick={ props.onDeleteModifierGroup }/>
							</ItemPaneFooter>
					</Pane>
					</div>
					<div className="col-sm-6">
						<Pane>
							<PaneHeader>Settings</PaneHeader>
							<PaneBody>
								<div className="row no-gutters">
									<div className="col">
										<div className="Pane-body container">
											<FormImageControl type="checkbox" form={ props.form } field="dairyFree" label="Dairy Free" disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="checkbox" form={ props.form } field="glutenFree" label="Gluten Free" disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="checkbox" form={ props.form } field="nutSafe" label="Nut Safe" disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="input" form={ props.form } field="veggieStatus" label="Veggie" options={ ['n', 'a', 'b'] } disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="input" form={ props.form } field="spicyStatus" label="Spicy" options={ [0, 1, 2, 3] } disabled={ !props.form.data?.menuItemId }/>
										</div>
									</div>
									<div className="col">
										<div className="Pane-body container" id="itemOptions">
											<FormCheckbox className="mt5 mb20" form={ props.form } field="isHidden" disabled={ !props.form.data.menuItemId }>Hidden</FormCheckbox>
											<FormCheckbox className="mb20" form={ props.form } field="isInStock" disabled={ !props.form.data.menuItemId }>In stock</FormCheckbox>
											{/*
												These are hidden for now - see PB-56
											 <FormCheckbox className="mb20" form={ props.form } field="deliveryOK" disabled={ !props.form.data.menuItemId }>Delivery OK</FormCheckbox>
											<FormCheckbox form={ props.form } field="carryOutOK" disabled={ !props.form.data.menuItemId }>Carry out OK</FormCheckbox>
											*/}
										</div>
									</div>
								</div>
							</PaneBody>
							<PaneFooter id="customerSatisfaction">
								<label disabled={ !props.form.data.menuItemId }>Customer satisfaction</label>
								<CustomerRating item={ props.form.data }/>
							</PaneFooter>
						</Pane>
					</div>
				</div>
			</PaneBody>
		</Pane>
	);

};

export default MenuItemPane;