export default class {
	name = null;
	dscr = null;
	webUrl = null;
	phone = null;
	street = null;
	city = null;
	postCode = null;
	countryName = null;
	provinceName = null;
	lat = null;
	lon = null;
}