import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import useL10n from 'L10n';
import './ValidationMessage.scss';

const ValidationMessage = props => {
	const _t = useL10n().getText;
	const msgRef = useRef();

	let message = props.message ? props.message : _t('TXT_VALIDATION');

	useEffect(() => {
		// this and the addition of tabIndex below makes this more accessible
		if(props.open && msgRef.current) {
			setTimeout(() => {
				msgRef.current.focus();
			});
		}
	}, [props.open]);

	return (
		<Collapse isOpen={ props.open }>
			<div tabIndex={ props.open ? 0: -1 } ref={ msgRef } className={ classNames('ValidationMessage', 'animated', props.className, {'fadeIn': props.open, 'fadeOut': !props.open }) }>
				{ message }
			</div>
		</Collapse>
	);
}

ValidationMessage.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	open: PropTypes.bool
};

export default ValidationMessage;