// note I am using useForm for menus and items but just working with section locally as it is just one field
// also, some controls have id defined otherwise their auto-assigned ids clash with MenuItemForm
import { useEffect, useRef, useState } from 'react';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import MenuItem from 'models/MenuItem';
import utilities from 'services/utilities';
import useForm from 'components/Form';
import { FormCheckbox, FormRadio, FormInput, FormTypeahead, FormInputWithButton } from 'components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';
import UploaderPanel from 'components/UploaderPanel';
import { TextButton } from 'components/buttons';
import './AddEditDialog.scss';

const AddEditDialog = props => {
	const [activeTab, setActiveTab] = useState(0);
	const [confirmFooter, setConfirmFooter] = useState(false);
	const [section, setSection] = useState({ title: '' });
	const sections = props.menuStore.sections?.map(menuSection => menuSection.title);

	const menuFormSave = () => {
		props.saveEntity('menu', menuForm.data);
		props.onHide();
	}

	const menuForm = useForm(menuFormSave, menuValidationRules);
	const menuResetRef = useRef(menuForm.reset);

	const itemFormSave = () => {
		props.saveEntity('item', itemForm.data);
		itemForm.reset(new MenuItem());
	}

	const itemForm = useForm(itemFormSave, itemValidationRules);
	const itemFormRef = useRef(itemForm);

	useEffect(() => {
		if(props.salesTaxCode) {
			itemFormRef.current.onChange(null, 'salesTaxCode', props.salesTaxCode);
		}
	}, [props.salesTaxCode]);

	useEffect(() => {
		if(props.show) {
			switch(props.details.tab) {
				case 'menu':
					setActiveTab(0);
					menuResetRef.current(props.details.entity);
					break;
				case 'section':
					setActiveTab(1);
					setSection(props.details.entity);
					break;
				case 'item': setActiveTab(2); break;	// this currently never happens
				default: {	// 'all'
					// rather than setting the active tab, we let it open at whatever tab was last opened
					menuResetRef.current(menu);
					setSection({ title: '' });
					itemFormRef.current.reset(new MenuItem());
					if(props.menuStore.menus?.length === 0 && activeTab) {
						setActiveTab(0);
					}
					break;
				}
			}
		}
	// don't want this dependent on props.menuStore.menus.length or activeTab
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show, props.details]);

	const tabSelect = e => {
		if(e.currentTarget.getAttribute('disabled') == null) {
			const tabIndex = utilities.nodeIndex(e.currentTarget);
			if(activeTab !== tabIndex) setActiveTab(tabIndex);
		}
	}

	const buttonClickHandler = e => {
		// add/update code goes here...
		switch(e.target.id) {
			case 'btnAdd':
			case 'btnUpdate':
				switch(activeTab) {
					case 0: menuForm.onSave(); break;
					case 1: props.saveEntity('section', section); props.onHide(); break;
					case 2: itemForm.onSave(); break;
					default: break;
				}
				break;
			case 'btnDelete':
				setConfirmFooter(true);
				break;
			case 'btnOk':
				switch(activeTab) {
					case 0: props.deleteEntity('menu', menuForm.data); break;
					case 1: props.deleteEntity('section', section); break;
					default: break;
				}
				setConfirmFooter(false);
				props.onHide();
				break;
			default:
				if(confirmFooter) {
					setConfirmFooter(false);
				} else {
					props.onHide(e.target.id);
				}
				break;
		}
	}

	const onSectionChange = e => {
		setSection({ ...props.details.entity, title: e.target.value });
	}

	// console.log(menuForm.data);
	return (
		<Modal className="AddEditDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader className="Modal-header_nav">
				{ (props.details.tab === 'all' || props.details.tab === 'item') &&
					<Nav justified>
						<NavItem active={ activeTab === 0 } onClick={ tabSelect }>Add Menu</NavItem>
						<NavItem disabled={ props.menuStore.menus?.length === 0 } active={ activeTab === 1 } onClick={ tabSelect }>Add Menu Section</NavItem>
						<NavItem disabled={ props.menuStore.menus?.length === 0 } active={ activeTab === 2 } onClick={ tabSelect }>Add Menu Item</NavItem>
					</Nav>
				}
				{ props.details.tab === 'menu' &&
					<h1>Edit menu</h1>
				}
				{ props.details.tab === 'section' &&
					<h1>Edit section</h1>
				}
			</ModalHeader>
			<ModalBody padding={ true }>

				<TabContent activeTab={ '' + activeTab }>
					<TabPane tabId="0" className="animated fadeIn">

						<ValidationMessage open={ !!menuForm.errors }/>

						<div className="row mb10">
							<div className="col-sm-6">
								<FormInput form={ menuForm } field="title" placeholder="Menu Name"/>
						</div>
							<div className="col-sm-6">
								<FormRadio form={ menuForm } field="isVisible" value="1">
									Menu visible to customers
								</FormRadio>
								<FormRadio form={ menuForm } field="isVisible" value="0">
									Hide menu (out of season)
								</FormRadio>
							</div>
						</div>
						<div className="row mb10">
							<div className="col">
								<FormCheckbox form={ menuForm } field="displaysItemsOrderIndex">
									Menu displays dish no. <small>(for menus with numbered dishes)</small>
								</FormCheckbox>
								<FormCheckbox form={ menuForm } field="autoMarkItemsOutOfStock">
									Automatically mark menu items as unavailable when recipe ingredients are out of stock
								</FormCheckbox>
							</div>
						</div>
						<UploaderPanel id="menuUploader">
							Upload your menu (photos or PDFs); our system will attempt to extract the required information automatically
						</UploaderPanel>

					</TabPane>
					<TabPane tabId="1" className="animated fadeIn simple-controls">

						<input placeholder="Section Title" className="form-control" value={ section.title } onChange={ onSectionChange }/>

					</TabPane>
					<TabPane tabId="2" className="animated fadeIn simple-controls">

						<ValidationMessage open={ !!itemForm.errors }/>

						<FormTypeahead
							placeholder="Menu Section"
							form={ itemForm }
							field="menuSectionTitle"
							data={ sections }
							maxMatches={ 5 }
							minLength={ 2 }
							disabled={ props.disabled }
							highlight={ true }
						/>

						<FormInput placeholder="Menu Item" form={ itemForm } field="name" id="amiName" disabled={ props.disabled }/>

						{ !props.onSalesTaxButtonClick
								? <FormInput type="money" placeholder="Price" form={ itemForm } field="price" disabled={ props.disabled }/>
								: (
										<div className="form-row">
											<div className="col">
			
												<FormInput type="money" placeholder="Price" form={ itemForm } field="price" disabled={ props.disabled }/>
			
											</div>
			
											<div className="col">
												<FormInputWithButton
													placeholder="Sales Tax Code"
													icon="more_horiz"
													form={ itemForm }
													field="salesTaxCode"
													id="amiSalesTaxCode"
													disabled={ props.disabled }
													onButtonClick={ props.onSalesTaxButtonClick }
												/>
											</div>
										</div>
									)
						}

						<FormInput type="textarea" placeholder="Description" form={ itemForm } field="dscr" disabled={ props.disabled }/>

						<FormInput placeholder="Calories" form={ itemForm } field="calories" id="amiCalories" disabled={ props.disabled }/>

					</TabPane>
				</TabContent>

			</ModalBody>
			{ confirmFooter
				?
					<ModalFooter>

						<span className="Modal-footer-message">Are you sure you want to delete?</span>
						<TextButton id="btnCancel" className="btn-secondary focus" onClick={ buttonClickHandler }>Cancel</TextButton>
						<TextButton id="btnOk" className="btn-primary" onClick={ buttonClickHandler }>OK</TextButton>

					</ModalFooter>
				:
					<ModalFooter className="Modal-footer_buttons">

						<TextButton
							id="btnCancel"
							className="btn-secondary focus"
							async={ props.menuStore.loading }
							onClick={ buttonClickHandler }
						>
							Cancel
						</TextButton>

						<TextButton
							id="btnDelete"
							className="btn-primary"
							async={ props.menuStore.loading }
							hidden={ props.details.tab === 'all' || props.details.tab === 'item' }
							onClick={ buttonClickHandler }
						>
							Delete
						</TextButton>

						<TextButton
							id="btnUpdate"
							className="btn-primary"
							async={ props.menuStore.loading }
							hidden={ props.details.tab === 'all' || props.details.tab === 'item' }
							disabled={ props.details.tab === 'section' && !section.title }
							onClick={ buttonClickHandler }
						>
							Update
						</TextButton>

						<TextButton
							id="btnAdd"
							className="btn-primary"
							async={ props.menuStore.loading }
							hidden={ props.details.tab !== 'all' && props.details.tab !== 'item' }
							disabled={ activeTab === 1 && !section.title }
							onClick={ buttonClickHandler }
						>
							Add
							{ activeTab === 2 && '+NEXT' }
						</TextButton>

					</ModalFooter>
			}
		</Modal>
	);
};

export default AddEditDialog;

const itemValidationRules = {
	menuSectionTitle: true,
	name: true,
	price: true
}

const menuValidationRules = {
	title: true,
	isVisible: true
}

const menu = {
	title: null,
	isVisible: null,
	displaysItemsOrderIndex: 0,
	autoMarkItemsOutOfStock: 0
}