// *** TODO *** This is not yet responsive - needs to work on a tablet
import React, { useEffect, useRef, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import utilities from '../services/utilities';
import { taxCodesActionCreators } from '../store/taxCodes';
import { Checkbox } from './controls';
import DropdownButton from './DropdownButton';
import { Modal, ModalBody, ModalFooter, ModalHeader } from './Modal';
import { Pane, PaneBody, PaneHeader, PaneItem } from './Pane';
import './TaxCodeDialog.scss';
import { useDispatch, useSelector } from 'react-redux';

const sections = ['Prepared Foods', 'Beverages', 'Snacks', 'Food Ingredients', 'Services', 'Search All Foods'];

const TaxCodeDialog = props => {
	const [activeTab, setActiveTab] = useState(props.defaultTab || 0);
	const [activeCodes, setActiveCodes] = useState([]);
	const [availableFilters, setAvailableFilters] = useState(null);
	const [activeFilters, setActiveFilters] = useState(['Common']);	// default category
	const [venueType, setVenueType] = useState(null);
	const [tablesAndChairs, setTablesAndChairs] = useState();
	const searchControl = useRef();
	const dispatch = useDispatch();

	const taxCodeStore = useSelector(state => state.taxCodes);

	useEffect(() => {
		if(props.show && !taxCodeStore.data) {
			dispatch(taxCodesActionCreators.fetchTaxCodes());
		}
		if(props.show && taxCodeStore.data) {
			// ensure tab displays relevant filters (CATEGORIES)
			if(activeTab === 5) {		// Search All Foods
				setActiveCodes([]);
				searchControl.current.focus();
			} else {
				const section = taxCodeStore.data[sections[activeTab]];
				if(!section) return;
				const availableFilters = [];
				section.forEach(code => {
					if(code.filter && availableFilters.indexOf(code.filter) === -1) availableFilters.push(code.filter);
				});
				setAvailableFilters(availableFilters);
			}
		}
	}, [taxCodeStore.data, props.show, activeTab, dispatch]);

	useEffect(() => {
		if(props.show && taxCodeStore.data) {
			// we need to update the list of displayed TAX CODES based on to user selections
			const section = taxCodeStore.data[sections[activeTab]];
			if(!section) return;
			let filters;
			if(activeTab > 1) {
				// all tabs other than 'Prepared Foods' & 'Beverages' only have common, so force it active and we'll hide the (pointless) selection
				filters = 'Common';
			} else {
				filters = activeFilters;
			}
			const activeCodes = [];
			section.forEach(code => {
				// if(code.code === 'PF160019') {
				// 	console.log(code);
				// }
				if((!code.filter || filters.indexOf(code.filter) !== -1) && (!code.venueType || code.venueType === venueType) && (!code.tablesAndChairs || code.tablesAndChairs === tablesAndChairs)) {
					activeCodes.push({
						code: code.code,
						description: code.description
					});
				}
			});
			setActiveCodes(activeCodes);
		}
	}, [props.show, taxCodeStore.data, activeTab, activeFilters, venueType, tablesAndChairs]);

	const searchCodes = text => {
		const activeCodes = [];
		if(text.length < 3) {
			if(activeCodes.length) setActiveCodes(activeCodes);
			return;
		};
		text = text.toLowerCase();
		for(const key in taxCodeStore.data) {
			const section = taxCodeStore.data[key];
			// console.log(section.length);
			section.forEach(code => {
				if(code.description.toLowerCase().indexOf(text) !== -1) {
					activeCodes.push({
						code: code.code,
						description: code.description
					});
				}
			});
		}
		setActiveCodes(activeCodes);
	}

	const onChange = (event, field, value, label) => {
		const control = utilities.processFormControl(event, field, value, label);
		// console.log(control);
		// this.setState({ [control.field]: control.value });
		switch(control.field) {
			case 'venueType': setVenueType(control.value); break;
			case 'tablesAndChairs': setTablesAndChairs(control.value); break;
			default: searchCodes(control.value); break;
		}
	}

	const onNavClick = e => {
		let tabIndex = utilities.nodeIndex(e.currentTarget.parentNode);
		if(activeTab !== tabIndex) {
			setActiveTab(tabIndex);
		}
	}

	const onFiltersChange = e => {
		const filter = e.target.parentNode.innerText;
		let filters = [ ...activeFilters ];
		if(e.target.checked) {
			filters.push(filter);
		} else {
			filters = filters.filter(item => item !== filter);
		}
		setActiveFilters(filters);
	}

	const renderFilters = () => {
		if(!availableFilters) return null;
		return availableFilters.map((filter, ix) => {
			return <Checkbox key={ ix } className="row-mb10" checked={ activeFilters.indexOf(filter) !== -1 } onChange={ onFiltersChange }>{ filter }</Checkbox>;
		});
	}

	const renderCodes = () => {
		if(!activeCodes) return null;
		return activeCodes.map((code, ix) => {
			return (
				<PaneItem key={ ix } index={ code.code } onClick={ props.onSalesTaxCodeSelect }>
					<label className="col-auto">{ code.code }</label>
					<div className="col">{ code.description }</div>
				</PaneItem>
			);
		});
	}

	return (
		<Modal id="taxCodeDialog" size="large" show={ props.show }>

			<ModalHeader>Select Sales Tax Code</ModalHeader>

			<ModalBody>

				{ activeTab === 5 ?		// search
					<div className="form-row">
						<div className="col-6">
							<input type="search" ref={ searchControl } placeholder="search" className="form-control" name="search" onChange={ onChange }/>
						</div>
					</div>
				:
					<div className="form-row">
						<div className="col-6">
							<DropdownButton placeholder="Venue Type" name="venueType" value={ venueType } onChange={ onChange }>
								<option value="Restaurant">Restaurant</option>
								<option value="Deli">Deli</option>
								<option value="utensilsNotProvided">Utensils not provided</option>
							</DropdownButton>
						</div>
						<div className="col-6">
							<DropdownButton placeholder="Tables & chairs" name="tablesAndChairs" value={ tablesAndChairs } onChange={ onChange }>
								<option value="yes">Tables & chairs provided</option>
								<option value="no">Tables & chairs NOT provided</option>
							</DropdownButton>
						</div>
					</div>
				}

				<Nav tabs justified>
					<NavItem>
						<NavLink className={ classNames({ active: activeTab === 0 })} onClick={ onNavClick }>
							{ sections[0] }
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={ classNames({ active: activeTab === 1 })} onClick={ onNavClick }>
							{ sections[1] }
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={ classNames({ active: activeTab === 2 })} onClick={ onNavClick }>
							{ sections[2] }
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={ classNames({ active: activeTab === 3 })} onClick={ onNavClick }>
							{ sections[3] }
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={ classNames({ active: activeTab === 4 })} onClick={ onNavClick }>
							{ sections[4] }
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={ classNames({ active: activeTab === 5 })} onClick={ onNavClick }>
							{ sections[5] }
						</NavLink>
					</NavItem>
				</Nav>

				<div className="tab-content">
					<div className="row">
						{ (activeTab < 2) &&
							<div className="col-auto">
								<Pane className="Pane_inner">
									<PaneHeader>Categories</PaneHeader>
									<PaneBody className="container">
										{ renderFilters() }
									</PaneBody>
								</Pane>
							</div>
						}
						<div className="col">
							<Pane id="taxCodes" className="Pane_inner">
								<PaneHeader>Tax Codes</PaneHeader>
								<PaneBody tag="ul" className="scrolling Pane-items Pane-items_p5">
									{ renderCodes() }
								</PaneBody>
							</Pane>
						</div>
					</div>
				</div>

			</ModalBody>

			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">Cancel</button>
			</ModalFooter>

		</Modal>
	);

}

export default TaxCodeDialog;