// This component establishes an open SSE connection with the server and in so doing,
// should receive an initial list of open orders and needed config data,
// plus be notified of any other changes to orders it needs to track.
// Subsequent changes to request parameters and pagination requests are then
// done via calls to fetchOrders in the orders store.

// NB bumping up against the limitations of EventSource (no access to response so for example cannot differentiate errors)
// try replacing with https://www.npmjs.com/package/@microsoft/fetch-event-source

import { useEffect, useRef, useState } from "react";
import add from 'date-fns/add';
import Order from "models/Order";
import dates from 'services/dates';
import sound from "services/sound";
import utilities from "services/utilities";
import { config } from 'store';
import storeUtils from "./storeUtils";
import { distributionTypes } from './orders';

const useOrderEvents = (options) => {
	const [isBusy, setIsBusy] = useState(true);
	const dataRef = useRef(options.orderData);	// used to get around the event listener / stale store data issue
	const soundRef = useRef();
	const [resetValue, setResetValue] = useState(Math.random());

	useEffect(() => {
		// make the current value of this setting available to eventHandler
		soundRef.current = options.settings.soundEnabled;
	}, [options.settings.soundEnabled]);


	useEffect(() => {
		const verbose = options.station === 'test' ?? options.verbose;
		const log = (...args) => {
			if(verbose) console.log(...args);
		}

		const eventHandler = evt => {
			const event = {
				type: evt.type,
				data: evt.data ? JSON.parse(evt.data) : null
			};
			log('EVENT:', event, dataRef.current);
			setIsBusy(false);
			let orders;
			let updateOrders = false;
			switch(event.type) {
				case 'existingOrders':
					if(options.station === 'test') event.data.orders = genDummyOrders(20);
					options.setOrders(event.data);
					break;
				case 'addOrders':
					// if(options.station === 'test') {
					// 	// make it look like the order is 20 mins old - this emulates a bumped order getting restored
					// 	event.data[0].timestamp -= 1200000;
					// 	console.log(new Date(event.data[0].timestamp));
					// }
					if(options.orderData?.hasNextPage !== 1) {
						// we always stick new orders at the end - this will be right most times
						// but for a restored bumped order it won't be, but the store sorts orders by timestamp anyway
						orders = dataRef.current.orders.concat(event.data);
						updateOrders = true;
					}
					break;
				case 'removeOrders':
					orders = dataRef.current.orders.filter(order => event.data.findIndex(o => o.externalOrderId === order.externalOrderId) === -1);
					if(orders.length !== dataRef.current.orders.length) {
						updateOrders = true;
					}
					break;
				case 'updateOrders':
					orders = utilities.updateArray(dataRef.current.orders, event.data, 'externalOrderId');
					if(!orders) {
						// updated order is not in memory, so it must be the result of an "UNDO" therefore append SSE order(s)
						orders = [...dataRef.current.orders, ...event.data];
					}
					updateOrders = true;
					break;
				// case 'keepAlive':
				// 	break;
					case 'pberror':
						console.log('Need to log in again!');
						options.setErrors(event.data.errors);
						break;
				default: break;
			}
			if(updateOrders) {
				const orderData = { ...dataRef.current };
				orderData.orders = orders;
				options.setOrders(orderData);
				if(soundRef.current) {
					console.log(options.settings.soundFile);
					sound.play(options.settings.soundFile);
				}
			}
		}

		const events = ['existingOrders', 'addOrders', 'updateOrders', 'removeOrders', 'pberror'];
		let source;
		if(options.venue) {
			const timeZone = options.venue.openingHours?.timezone.timezone;
			if(!timeZone) return;
			let url = config.ssePath + '/orders/subscribe' + utilities.capitalise(options.station);
			if(options.station === 'all') {
				const todayInt = dates.getLocalToday(timeZone).getTime();	// get 'today' at 00:00 in appropriate TZ
				const payloadOptions = {
					orderStatus: options.settings.ordersFilter,
					fromOption: options.settings.ordersFromOption,
					fromDate: options.settings.ordersFromDate ? new Date(options.settings.ordersFromDate) : null,
					toDate: options.settings.ordersToDate ? new Date(options.settings.ordersToDate) : null,
					page: options.settings.ordersPage,
					today: todayInt
				}
				const payload = options.getPayload(payloadOptions);
				url += '?' + storeUtils.encodeJSON(payload);
			};
			log('Attempting SSE on', url);
			source = new EventSource(url, { withCredentials: true });
			setIsBusy(true);
			if(source) {
				source.onopen = event => log('open', event, 'readyState:', source.readyState);
				source.onmessage = event => log('message:', event);
				source.onerror = event => {
					log('error:', event);
					if(source.readyState === 2 && options.lostConnectionHandler) {
						setIsBusy(false);
						options.lostConnectionHandler(true);
					}
				}
				events.forEach(eventType => source.addEventListener(eventType, eventHandler, false));
			} else {
				console.error('call to EventSource failed');
			}
		}

		return () => {
			if(source && options.venue) {
				events.forEach(eventType => source.removeEventListener(eventType, eventHandler, false));
				source.close();
				log('SSE connection closed', source.readyState);
				source = undefined;
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.venue, options.station, resetValue]);

	const reset = () => {
		// consumers can call this to force reopening the existing connection
		setResetValue(Math.random());
	}

	const setOrderData = orderData => {
		// allows OrderEvents to be aware of the current state of the orders store (consumers should call this on orders store changes)
		// console.log('setOrderData', orderData);
		dataRef.current = orderData;
	}

	return {
		isBusy,
		reset,
		setOrderData
	}
}

export default useOrderEvents;

const rnd = (multiplier, offset) => {
	// if need a random no between 20 and 30: rnd(10, 20)
	return Math.floor(Math.random() * multiplier) + (offset ?? 0);
}

const genDummyOrders = (n, same) => {
	const names = ['John', 'Pete', 'Jeremy', 'Sally', 'Janice', 'Mona', 'Brett', 'Freddy', 'Susanna', 'Maurice'];
	const items = ['Hot Apple Pie', 'Chicken Sandwich', 'Eggs Benedict', 'Scrambled Eggs', 'Greek Omelette', 'Coke', 'Jumbo Shrimp', 'Surf & Turf', 'Seafood Sampler', 'Prawn Cocktail'];
	const orders = [];
	for(let i = 0; i < n; i++) {
		const order = new Order();
		order.name = same ? names[0] : names[rnd(10)];
		order.externalOrderId = 'test-' + (i + 1);
		order.distributionType = distributionTypes.EAT_IN_WAITSTAFF;
		order.waitstaffTarget = same ? 5 : (i === (n - 2)) ? '2' : rnd(30, 1);	// 2nd last order is always for table 2 to test grouping
		order.distributionTypeDesc = 'TABLE ' + order.waitstaffTarget;
		order.timestamp = add(new Date(), { minutes: -(n - i) * 2 });
		order.total = rnd(3000, 100);
		// order items
		if(same) {
			order.orderItems.push({
				qty: 1,
				name: items[0],
				itemCost: 599,
				modifierItems: []
			});
		} else {
			for(let j = 0, l = Math.floor(Math.random() * 3) + 1; j < l; j++) {
				const item = {
					qty: rnd(3, 1),
					name: items[rnd(10)],
					itemCost: rnd(2000, 100),
					modifierItems: []
				};
				order.orderItems.push(item);
			}
		}
		// console.log(order);
		orders.push(order);
	}
	return orders;
}

// const cancelledOrder = {
// 	deliveryFee: 0,
// 	distributionType: 2,
// 	distributionTypeDesc: "TABLE ?",
// 	externalOrderId: "57x3-2",
// 	firstName: "Aleksa ",
// 	itemsCost: 1400,
// 	itemsCostAfterDiscounts: 0,
// 	lastName: "Djordjevic ",
// 	netAmountAvailableOn: 1659571200000,
// 	netAmountForResto: 119,
// 	orderItems: [{qty: 1, name: "Jimmy's Crab Hash", itemCost: 1400, orderItemId: 1, modifierItems: []}],
// 	orderStatus: "dn",
// 	orderStatusStr: "Canceled",
// 	otherCharges: 140,
// 	otherDiscounts: 21,
// 	paymentStatus: "Accepted",
// 	reward: 1379,
// 	salesTaxAmount: 0,
// 	subTotal: 140,
// 	timestamp: 1659013357198,
// 	tips: 21,
// 	total: 140,
// 	totalBilled: 161,
// 	transactionFee: 37
// }