import { useEffect, useRef } from 'react';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormInput } from 'components/formControls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';

const TagAddDialog = props => {
	const _t = useL10n().getText;

	const formSave = e => {
		props.onHide(form.data);
	}

	const form = useForm(formSave);
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show) {
			formRef.current.reset({});
		}
	}, [props.show]);

	return (
		<Modal id="tagAddDialog" show={ props.show }>
			<ModalHeader>Add Common Tag</ModalHeader>
			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<FormInput placeholder="Tag Name" className="focus" form={ form } field="tagName"/>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">{ _t('BTN_CANCEL', true) }</button>
				<button className="btn btn-primary" onClick={ form.onSave } id="btnAdd">Add Common Tag</button>
			</ModalFooter>
		</Modal>
	);
};

export default TagAddDialog;