import storeUtils from './storeUtils';

export const actionTypes = {
	modifiersFail: 'MODIFIERS_FAIL',
	fetchModifiersRequest: 'FETCH_MODIFIERS_MODIFIERS_REQUEST',
	fetchModifiersSuccess: 'FETCH_MODIFIERS_MODIFIERS_SUCCESS',

	addItemRequest: 'ADD_ITEM_MODIFIERS_REQUEST',
	addItemSuccess: 'ADD_ITEM_MODIFIERS_SUCCESS',
	updateItemRequest: 'UPDATE_ITEM_MODIFIERS_REQUEST',
	updateItemSuccess: 'UPDATE_ITEM_MODIFIERS_SUCCESS',
	deleteItemRequest: 'DELETE_ITEM_MODIFIERS_REQUEST',
	deleteItemSuccess: 'DELETE_ITEM_MODIFIERS_SUCCESS',
	reorderItemsRequest: 'REORDER_ITEMS_MODIFIERS_REQUEST',
	reorderItemsSuccess: 'REORDER_ITEMS_MODIFIERS_SUCCESS',

	addGroupRequest: 'ADD_GROUP_MODIFIERS_REQUEST',
	addGroupSuccess: 'ADD_GROUP_MODIFIERS_SUCCESS',
	updateGroupRequest: 'UPDATE_GROUP_MODIFIERS_REQUEST',
	updateGroupSuccess: 'UPDATE_GROUP_MODIFIERS_SUCCESS',
	deleteGroupRequest: 'DELETE_GROUP_MODIFIERS_REQUEST',
	deleteGroupSuccess: 'DELETE_GROUP_MODIFIERS_SUCCESS',
	reorderGroupsRequest: 'REORDER_GROUPS_MODIFIERS_REQUEST',
	reorderGroupsSuccess: 'REORDER_GROUPS_MODIFIERS_SUCCESS',

	addGroupItemRequest: 'ADD_GROUPITEM_MODIFIERS_REQUEST',
	addGroupItemSuccess: 'ADD_GROUPITEM_MODIFIERS_SUCCESS',
	updateGroupItemRequest: 'UPDATE_GROUPITEM_MODIFIERS_REQUEST',
	updateGroupItemSuccess: 'UPDATE_GROUPITEM_MODIFIERS_SUCCESS',
	deleteGroupItemRequest: 'DELETE_GROUPITEM_MODIFIERS_REQUEST',
	deleteGroupItemSuccess: 'DELETE_GROUPITEM_MODIFIERS_SUCCESS',
	reorderGroupItemsRequest: 'REORDER_GROUPITEMS_MODIFIERS_REQUEST',
	reorderGroupItemsSuccess: 'REORDER_GROUPITEMS_MODIFIERS_SUCCESS'
}

const initialState = { groups: null, items: null, venueId: null, loading: null, errors: null };

export const modifiersActionCreators = {

	fetchModifiers: (reload = false) => async (dispatch, getState) => {
		const state = getState();
		if(!reload && state.modifiers.venueId && state.modifiers.venueId === state.venues.venue?.venueUUID) {
			// looks like we already have this venue's modifier data in state so....
			return;
		}
		dispatch({ type: actionTypes.fetchModifiersRequest });
		const data = await storeUtils.execute('modifiers', 'getModifiers');
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchModifiersSuccess, data: data, venueId: state.venues.venue?.venueUUID });
		}
	},

	addItem: item => async dispatch => {
		dispatch({ type: actionTypes.addItemRequest });
		if(item.price === null) delete(item.price);
		const data = await storeUtils.execute('modifiers', 'addModifierItem', convertForPayload(item));
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			item.id = data.modifierItemId;
			dispatch({ type: actionTypes.addItemSuccess, item: item });
		}
	},

	updateItem: item => async dispatch => {
		dispatch({ type: actionTypes.updateItemRequest });
		if(item.price === 0) item.price = null;
		const data = await storeUtils.execute('modifiers', 'updateModifierItem', convertForPayload(item));
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateItemSuccess, item: item });
		}
	},

	deleteItem: item => async dispatch => {
		dispatch({ type: actionTypes.deleteItemRequest });
		const data = await storeUtils.execute('modifiers', 'deleteModifierItem', { modifierItemId: item.id });
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteItemSuccess, item: item });
		}
	},

	shiftItem: (id, isUp) => async (dispatch, getState) => {
		dispatch({ type: actionTypes.reorderItemsRequest });
		const items = getState().modifiers.items;
		const request = storeUtils.getShiftElementRequest(items, id, isUp, 'id', 'modifierItemId');
		const data = await storeUtils.execute('modifiers', 'reorderModifierItems', request);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderItemsSuccess, orderIndexes: request.orderIndexes });
		}
	},

	addGroup: group => async (dispatch) => {
		dispatch({ type: actionTypes.addGroupRequest });
		const data = await storeUtils.execute('modifiers', 'addModifierGroup', group);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			group.id = data.modifierGroupId;
			dispatch({ type: actionTypes.addGroupSuccess, group: group });
		}
	},

	updateGroup: group => async (dispatch) => {
		dispatch({ type: actionTypes.updateGroupRequest });
		const data = await storeUtils.execute('modifiers', 'updateModifierGroup', storeUtils.fixEntityId(group, 'modifierGroupId'));
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateGroupSuccess, group: group });
		}
	},

	deleteGroup: group => async (dispatch) => {
		dispatch({ type: actionTypes.deleteGroupRequest });
		const data = await storeUtils.execute('modifiers', 'deleteModifierGroup', { modifierGroupId: group.id });
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteGroupSuccess, group: group });
		}
	},

	shiftGroup: (id, isUp) => async (dispatch, getState) => {
		dispatch({ type: actionTypes.reorderGroupsRequest });
		const groups = getState().modifiers.groups;
		const request = storeUtils.getShiftElementRequest(groups, id, isUp, 'id', 'modifierGroupId');
		const data = await storeUtils.execute('modifiers', 'reorderModifierGroups', request);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderGroupsSuccess, orderIndexes: request.orderIndexes });
		}
	},

	addGroupItem: groupItem => async dispatch => {
		dispatch({ type: actionTypes.addGroupItemRequest });
		const data = await storeUtils.execute('modifiers', 'addModifierGroupItem', groupItem);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			groupItem.id = data.id;
			dispatch({ type: actionTypes.addGroupItemSuccess, groupItem: groupItem });
		}
	},
	
	updateGroupItem: (group, item) => async dispatch => {
		// since all you can do (at present) is change the default setting, this is implemented as a toggle
		dispatch({ type: actionTypes.updateGroupItemRequest });
		const request = {
			modifierGroupId: group.id,
			modifierItemId: item.id,
			isDefault: item.isDefault ? 0 : 1	// toggle but keep as number
		};
		const data = await storeUtils.execute('modifiers', 'updateModifierGroupItem', request);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateGroupItemSuccess, request: request });
		}
	},
	
	deleteGroupItem: request => async dispatch => {
		dispatch({ type: actionTypes.deleteGroupItemRequest });
		const data = await storeUtils.execute('modifiers', 'deleteModifierGroupItem', request);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteGroupItemSuccess, request: request });
		}
	},
	
	shiftGroupItem: (group, id, isUp) => async dispatch => {
		dispatch({ type: actionTypes.reorderGroupItemsRequest });
		const groupItems = group.modifierItems;
		const request = storeUtils.getShiftElementRequest(groupItems, id, isUp, 'id', 'modifierItemId');
		request.modifierGroupId = group.id;
		const data = await storeUtils.execute('modifiers', 'reorderModifierGroupItems', request);
		if(data.errors) {
			dispatch({ type: actionTypes.modifiersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderGroupItemsSuccess, groupId: group.id, orderIndexes: request.orderIndexes });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('_MODIFIERS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.modifiersFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchModifiersSuccess: {
			const groups = action.data.modifierGroups.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			const items = action.data.modifierItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				groups: groups,
				items: items,
				venueId: action.venueId
			}
		}

		case actionTypes.addItemSuccess: {
			action.item.modifierGroupIds = [];
			const items = [...state.items, action.item];
			return {
				...state,
				loading: null,
				items: items
			}
		}

		case actionTypes.updateItemSuccess: {
			const items = state.items.map(item => item.id === action.item.id ? action.item : item);
			return {
				...state,
				loading: null,
				items: items
			}
		}

		case actionTypes.deleteItemSuccess: {
			const items = storeUtils.deleteElement(state.items, action.item.id);
			return {
				...state,
				loading: null,
				items: items
			}
		}

		case actionTypes.reorderItemsSuccess: {
			const items = state.items.map(item => {
				const newItem = { ...item };
				newItem.orderIndex = action.orderIndexes.find(item => item.modifierItemId === newItem.id).orderIndex;
				return newItem;
			});
			items.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				items: items
			}
		}

		case actionTypes.addGroupSuccess: {
			const groups = [...state.groups, action.group];
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.updateGroupSuccess: {
			const groups = state.groups.map(group => group.id === action.group.id ? action.group : group);
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.deleteGroupSuccess: {
			const groups = storeUtils.deleteElement(state.groups, action.group.id);
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.reorderGroupsSuccess: {
			const groups = state.groups.map(group => {
				const newGroup = { ...group };
				newGroup.orderIndex = action.orderIndexes.find(group => group.modifierGroupId === newGroup.id).orderIndex;
				return newGroup;
			});
			groups.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.addGroupItemSuccess: {
			const newItem = {
				id: action.groupItem.modifierItemId,
				isDefault: action.groupItem.isDefault,
				orderIndex: action.groupItem.orderIndex
			};
			const groups = storeUtils.processChildCollection(state.groups, 'id', action.groupItem.modifierGroupId, 'modifierItems', items => [...items, newItem]);
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.updateGroupItemSuccess: {
			const groups = storeUtils.processChildCollection(state.groups, 'id', action.request.modifierGroupId, 'modifierItems', items => {
				return items.map(item => {
					if(item.id === action.request.modifierItemId) {
						const newItem = { ...item };
						newItem.isDefault = action.request.isDefault;
						return newItem;
					} else {
						return item;
					}
				});
			});
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.deleteGroupItemSuccess: {
			const groups = storeUtils.processChildCollection(state.groups, 'id', action.request.modifierGroupId, 'modifierItems', items => {
				return storeUtils.deleteElement(items, action.request.modifierItemId);
			});
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		case actionTypes.reorderGroupItemsSuccess: {
			const groups = storeUtils.processChildCollection(state.groups, 'id', action.groupId, 'modifierItems', items => {
				return items.map(item => {
					const newItem = { ...item };
					newItem.orderIndex = action.orderIndexes.find(item => item.modifierItemId === newItem.id).orderIndex;
					return newItem;
				});
			});
			return {
				...state,
				loading: null,
				groups: groups
			}
		}

		default: {
			return state;
		}
	}
}

const convertForPayload = item => {
	// convert to an object more suitable for saving (and taking care of api inconsistencies)...
	let data = {};
	for(const key in item) {
		if(Object.hasOwnProperty.call(item, key)) {
			if(key === 'id') {
				data.modifierItemId = item.id;
			} else {
				data[key] = item[key];
			}
		}
	}
	return data;
}