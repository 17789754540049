import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import useL10n from 'L10n';
import { FormCheckbox, FormInput } from 'components/formControls';
import { IconButton } from 'components/buttons';
import MapControl from 'components/MapControl';
import { Pane, PaneHeader, PaneBody } from 'components/Pane';

const VenueAddress = props => {
	const _t = useL10n().getText;
	const [centre, setCentre] = useState(props.id === 'modalVenueEditor');
	const [isPlacesActive, setIsPlacesActive] = useState(false);

	useEffect(() => {
		if(props.form.data.lat != null) {
			const newCentre = [];
			newCentre[0] = props.form.data.lat;
			newCentre[1] = props.form.data.lon;
			setCentre(newCentre);
			setIsPlacesActive(false);
		}
	}, [props.form.data.lat, props.form.data.lon]);

	const mapInitHandler = map => {
		const placeHandler = () => {
			const place = autocomplete.getPlace();
			const address = buildAddress(place);
			props.updateAddress(address);
		}

		const venueInput = document.querySelector('#' + props.id + 'Input');
		const autocomplete = new window.google.maps.places.Autocomplete(venueInput, {
			// componentRestrictions: { country: ['us', 'ca'] },
			fields: ['address_components', 'geometry'],
			types: ['establishment'],
		});
		autocomplete.addListener('place_changed', placeHandler);
		const center = map.getCenter();
		const pin = new window.google.maps.Marker({
			position: center,
			map,
			title: 'centre',
		});
		map.addListener('center_changed', () => {
			// every time the map is moved, reposition the pin in the middle
			const position = map.getCenter();
			// console.log('Map moved to:', [position.lat(), position.lng()]);
			pin.setPosition(position);
			props.updateMapPosition([position.lat(), position.lng()]);
		});
	}

	const togglePlaces = e => {
		if(!isPlacesActive) {
			setTimeout(() => {
				// on showing the venue "places" control, clear it and give it focus...
				const venueInput = document.querySelector('#' + props.id + 'Input');
				venueInput.value = '';
				venueInput.focus();
			}, 100);
		}
		setIsPlacesActive(!isPlacesActive);
	}

	const shouldShowUseMap = () => {
		if(props.mapPosition && props.form.data.lat) {
			if(props.mapPosition[0] !== props.form.data.lat || props.mapPosition[1] !== props.form.data.lon) {
				return true;
			}
		}
		return false;
	}

	const useMapClickHandler = e => {
		if(!e.currentTarget.checked) {
			// reset map back to previous centre
			const newCentre = [];
			newCentre[0] = props.form.data.lat;
			newCentre[1] = props.form.data.lon;
			setCentre(newCentre);
		}
	}

	return (
		<Pane id="venueAddress" className="Pane_inner">
			<PaneHeader>
				<h1>{ _t('HDG_ADDRESS') }</h1>
				<div className="Pane-toolbar">
					<IconButton
						className={ isPlacesActive ? 'active' : '' }
						title={ _t('TTP_ADDRESS_LOOKUP') }
						icon="search"
						// async={ billingStore.loading === actionTypes.addUpdateBillingSettingsRequest }
						onClick={ togglePlaces }
					/>
				</div>
			</PaneHeader>
			<PaneBody className="container">

				<Collapse isOpen={ isPlacesActive }>
					<div className={ classNames('animated', 'mb10', props.className, {'fadeIn': isPlacesActive, 'fadeOut': !isPlacesActive }) }>
						<input className="form-control" placeholder={ _t('PLH_ADDRESS_SEARCH') } id={ props.id + 'Input' }/>
					</div>
				</Collapse>

				<div className="form-row">
					<div className="col-sm">
						<div className="form-row">
							<div className="col">
								<FormInput placeholder={ _t('PLH_ADDRESS_STREET') } form={ props.form } field="street"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col">
								<FormInput placeholder={ _t('PLH_ADDRESS_CITY') } form={ props.form } field="city"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col">
								<FormInput placeholder={ _t('PLH_ADDRESS_STATE') } form={ props.form } field="provinceName"/>
							</div>
							<div className="col">
								<FormInput placeholder={ _t('PLH_ADDRESS_ZIPCODE') } form={ props.form } field="postCode"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col">
								<FormInput placeholder={ _t('PLH_ADDRESS_COUNTRY') } form={ props.form } field="countryName"/>
							</div>
						</div>
					</div>
					<div className="col-sm">
						<div className="form-row">
							<div className="col">
								{ centre &&

									<Fragment>

										<MapControl
											className={ classNames('form-control', { 'validation-fail': props.form.errors?.map }) }
											centre={ centre }
											onInit={ mapInitHandler }
										/>

										<Collapse isOpen={ shouldShowUseMap() }>
											<FormCheckbox className="mt10 text-center" form={ props.form } field="useMap" onClick={ useMapClickHandler }>
											{ _t('LBL_ADDRESS_USE_LOCN') }
											</FormCheckbox>
										</Collapse>

									</Fragment>
								}
							</div>
						</div>
					</div>
				</div>
			</PaneBody>
		</Pane>
	);
};

export default VenueAddress;

class Address {
	street = null;
	city = null;
	postCode = null;
	countryName = null;
	provinceName = null;	// state
	lat = null;
	lon = null;
}

const buildAddress = (place) => {
	const address = new Address();
	let street = '';
	let postCode = '';
	for(const component of place.address_components) {
		const componentType = component.types[0];
		// console.log(componentType, component.short_name, component.long_name);
		switch(componentType) {
			case 'street_number': {
				street = `${ component.long_name } ${ street }`;
				break;
			}
			case 'route': {
				street += component.short_name;
				break;
			}
			case 'postal_code': {
				postCode = `${ component.long_name }${ postCode }`;
				break;
			}
			case 'postal_code_suffix': {
				postCode = `${ postCode }-${ component.long_name }`;
				break;
			}
			case 'locality':
			case 'postal_town': {
				address.city = component.long_name;
				break;
			}
			case 'administrative_area_level_1': {
				address.provinceName = component.long_name;
				break;
			}
			case 'country': {
				address.countryName = component.long_name;
				break;
			}
			default: break;
		}
	}
	address.street = street;
	address.postCode = postCode;
	address.lat = place.geometry.location.lat();
	address.lon = place.geometry.location.lng();
	return address;
}