// see FormControls.js for some controls designed to integrate simply with Form.js
// sample usages:
//	supplying validationRules on save event: LoyaltyRewards.js
//	handle some validation outside: OrderTypes.js, OpeningHours.js
// Possible improvements: would be nice to be able to handle 'must be greater than zero' and similar
import { useState } from "react";
import utilities from "../services/utilities";

const useForm = (saveCallback, validationRules, initialData) => {

	const [data, setData] = useState(initialData || {});
	const [errors, setErrors] = useState(null);
	const [isDirty, setIsDirty] = useState(false);

	const onSave = (e, rules = validationRules) => {
		let errs = null;
		if(e) e.preventDefault();
		// console.log(rules);
		if(rules) {
			Object.keys(rules).forEach(rule => {
				// only does "required" for now
				if(typeof rules[rule] === 'boolean') {
					if(data[rule] == null || data[rule] === '') {
						if(!errs) errs = {};
						errs[rule] = utilities.getText('TTP_VALIDATION_REQUIRED');
					}
				}
			});
			// console.log(data, errs);
			setErrors(errs);
		} else {
			if(errors) setErrors(null);
		}
		if(!errs) {
			setIsDirty(false);
			return saveCallback();	// your callback fn should return true if it saves or false if not, then you'll have access to this as the retval from here
		}
		return false;
	};

	const onChange = (e, field, value, label) => {
		// console.log(e, field, value, label);
		if(!field) {
			e.persist();
			const target = e.target;
			if(target.type === 'checkbox') {
				value = target.checked;
			} else {
				value = target.classList.contains('form-control_money') ? Math.round(target.value * 100) : target.value;
			}
			field = target.name || target.id;
		}
		// console.log(field, value);
		setData(data => ({
			...data,
			[field]: value
		}));
		setIsDirty(true);
	};

	const reset = (data, isDirty) => {
		// to use this, add 'const resetRef = useRef(form.reset);' so that the function ref doesn't change every render
		setData(data);
		setErrors(null);
		if(typeof isDirty === 'boolean') {
			setIsDirty(isDirty);
		}
	}

	return {
		onChange,
		onSave,
		reset,
		data,
		setErrors,
		errors,
		isDirty
	};
};

export default useForm;