import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import useL10n from 'L10n';
import { locationActionCreators } from 'store/location';
import { actionTypes as venuesActionTypes, venuesActionCreators } from 'store/venues';
import openingTimes, { OpeningTimes } from 'services/openingTimes';
import DropdownButton from 'components/DropdownButton';
import useForm from 'components/Form';
import { FormCheckbox } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneHeader, PaneBody, PaneItem, PaneFooter } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import TimePicker from 'components/TimePicker';
import ValidationMessage from 'components/ValidationMessage';

const OpeningHours = props => {
	const [selected, setSelected] = useState();
	const [schedule, setSchedule] = useState(openingTimes.getNewSchedule());
	const [timezone, setTimezone] = useState();
	const [isDirty, setIsDirty] = useState(false);	// timezone + minutes before closing are not handled by useForm so need local isDirty check
	const [acceptsOrdersMinBeforeClosing, setAcceptsOrdersMinBeforeClosing] = useState();
	const venueStore = useSelector(state => state.venues);
	const locationStore = useSelector(state => state.location);
	const l10nStore = useSelector(state => state.l10n);
	const dispatch = useDispatch();

	const _t = useL10n().getText;

	const formSave = () => {
		const payload = {};
		const errs = {};
		try {
			for(let i = 0; i < 7; i++) {
				const day = schedule[i];
				if(day.open24h) {
					payload[openingTimes.days[i]] = '24h';
				} else if(!day.open1) {
					payload[openingTimes.days[i]] = 'closed';
				} else {
					payload[openingTimes.days[i]] = [openingTimes.formatTimeForPayload(day.from1) + '-' + openingTimes.formatTimeForPayload(day.to1)];
					if(!openingTimes.isValidOpenClose(day.from1, day.to1)) {
						throw new Error('Invalid times');
					}
					if(day.from2) {
						payload[openingTimes.days[i]].push(openingTimes.formatTimeForPayload(day.from2) + '-' + openingTimes.formatTimeForPayload(day.to2));
						if(!openingTimes.isValidOpenClose(day.from2, day.to2)) {
							throw new Error('Invalid times');
						}
					}
				}
			}
			if(timezone) {
				payload.timezone = timezone;
			} else {
				errs['timezone'] = _t('TTP_VALIDATION_REQUIRED');
			}
			if(acceptsOrdersMinBeforeClosing) {
				payload.acceptsOrdersMinBeforeClosing = acceptsOrdersMinBeforeClosing;
			} else {
				errs['minutes'] = _t('TTP_VALIDATION_REQUIRED');
			}
			if(Object.getOwnPropertyNames(errs).length) {
				form.setErrors(errs);
			} else {
				dispatch(venuesActionCreators.updateOpeningHours(payload));
			}
		} catch(ex) {
			form.setErrors(ex);
		}
	}

	const form = useForm(formSave, null, new OpeningTimes());
	const resetRef = useRef(form.reset);

	useEffect(() => {
		// need to process schedule text into usable local data whenever the venue changes
		// const structuredFormat = venueStore.venue?.openingHours?.structuredFormat;
		const openingHours = venueStore.venue?.openingHours;
		if(openingHours) {
			if(openingHours.acceptsOrdersMinBeforeClosing) setAcceptsOrdersMinBeforeClosing(openingHours.acceptsOrdersMinBeforeClosing);
			if(openingHours.timezone) setTimezone(openingHours.timezone.timezone);
			if(openingHours.structuredFormat) {
				const schedule = openingTimes.populateShedule(openingHours.structuredFormat);
				setSchedule(schedule);
			}
		} else {
			setSchedule(openingTimes.getNewSchedule());
			setTimezone();
			setAcceptsOrdersMinBeforeClosing();
			setSelected();
		}
		if(venueStore.venue?.countryId) {
			dispatch(locationActionCreators.fetchTimeZones(venueStore.venue.countryId));
		}
		setIsDirty(false);
	}, [dispatch, venueStore.venue]);

	useEffect(() => {
		// need to update the form.data to the newly selected day
		if(selected !== undefined) {
			resetRef.current(schedule[selected]);
		}
		// we don't want this to fire when schedule (via form.data) changes (handled separately) so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	useEffect(() => {
		if(selected !== undefined) {
			// update schedule with changed form data
			const newSchedule = [...schedule];
			newSchedule[selected] = { ...form.data };
			setSchedule(newSchedule);
		}
		// we only want this to fire when form.data changes so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.data]);

	const onDayClick = ix => {
		setSelected(ix);
	}

	const onChange = (e, field, value, label) => {
		// console.log(e, field, value, label);
		if(field === 'timezone') {
			setTimezone(value);
		} else {
			setAcceptsOrdersMinBeforeClosing(parseInt(e.target.value, 10));
		}
		setIsDirty(true);
	}

	const renderTime = (time1, time2) => {
		if(!time1) return <em>???</em>;
		if(time2) {
			return openingTimes.isValidOpenClose(time2, time1) ? time1 : <em>{ time1 }</em>;
		}
		return openingTimes.validateTime(time1) ? time1 : <em>{ time1 }</em>;
	}
	
	const renderDaySchedule = day => {
		if(day.open24h) {
			return <div className="col text-center">{ _t('TXT_24H') }</div>
		}
		if(!day.open1) {
			return <div className="col text-center">{ _t('TXT_CLOSED') }</div>
		}
		return <Fragment>
			<div className="col text-center">
				{ renderTime(day.from1) }
				{ ' – ' }
				{ renderTime(day.to1, day.from1) }
			</div>
			{ day.open2 &&
				<Fragment>
					<div className="col-auto">&amp;</div>
					<div className="col text-center">
						{ renderTime(day.from2) }
						{ ' – ' }
						{ renderTime(day.to2, day.from2) }
					</div>
				</Fragment>
			}
		</Fragment>
	}

	return (
		<div className="OpeningHours row animated fadeIn max-width-1200">

			<div className="col-lg-6">

				<Pane id="openingHours">
				<PaneHeader>
					<h1>{ _t('HDG_OPENING_HOURS', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							async={ venueStore.loading === venuesActionTypes.updateOpeningHoursRequest }
							onClick={ form.onSave }
						/>
					</div>
				</PaneHeader>
					<PaneBody className="container">
						<ValidationMessage message={ _t('TXT_VALIDATION_RESOLVE') } open={ !!form.errors }/>
						<ul className="Pane-items Pane-items_p0">
							{ schedule.map((day, ix) => {
								return <PaneItem selected={ selected !== undefined && selected === ix } key={ openingTimes.days[ix] } index={ ix } onClick={ onDayClick }>
									<div className="col-3">{ l10nStore.translations?.localeData.daysLong[ix] }</div>
									{ renderDaySchedule(day, ix) }
								</PaneItem>
							})}
						</ul>
						<div className="form-row">
							<label className="col-sm-auto col-form-label">{ _t('LBL_TIMEZONE') }</label>
							<div className="col-sm">
								{ !!venueStore.venue?.countryId
									? <DropdownButton
											name="timezone"
											options={ locationStore.timeZones }
											value={ timezone }
											title={ form.errors?.timezone }
											className={ classNames({ 'validation-fail': form.errors?.timezone }) }
											onChange={ onChange }
										/>
									: <p className="form-message text-center">{ timezone !== null ? timezone : _t('TXT_ADD_ADDRESS_1ST') }</p>
								}
							</div>
						</div>
						<div className="form-row">
							<label className="col-sm-auto col-form-label">{ _t('LBL_MINUTES_BEFORE')}</label>
							<div className="col-sm">
								<input
									type="number"
									min="0"
									max="255"
									name="minutes"
									value={ acceptsOrdersMinBeforeClosing || '' }
									title={ form.errors?.minutes }
									className={ classNames('form-control', { 'validation-fail': form.errors?.minutes }) }
									onChange={ onChange }
								/>
							</div>
						</div>
					</PaneBody>
				</Pane>

			</div>
			<div className="col-lg-6">

				{ selected != null &&
					<Pane id="openingTimes" className="animated fadeIn">
						<PaneHeader>
							<h1>{ l10nStore.translations?.localeData.daysLong[selected] }</h1>
							<ul><li>{ _t('TXT_24H_FORMAT') }</li></ul>
						</PaneHeader>
						<PaneBody className="container">

							<fieldset>
								<div className="form-row">
									<div className="col-sm-auto">
										<FormCheckbox
											form={ form }
											field="open1"
											disabled={ selected === undefined || schedule[selected].open24h }
										>
											{ _t('TXT_OPEN') }
										</FormCheckbox>
									</div>
									<div className="col-sm">
										<TimePicker
											disabled={ selected === undefined || !form.data.open1 || schedule[selected].open24h }
											placeholder={ _t('PLH_FROM') }
											name="from1"
											default="morning"
											value={ form.data.from1 || '' }
											onChange={ form.onChange }
										/>
									</div>
									<div className="col-sm">
										<TimePicker
											disabled={ selected === undefined || !form.data.open1 || schedule[selected].open24h }
											placeholder={ _t('PLH_TO') }
											name="to1"
											value={ form.data.to1 || '' }
											onChange={ form.onChange }
										/>
									</div>
								</div>
							</fieldset>

							<fieldset>
								<div className="form-row">
									<div className="col-sm-auto">
										<FormCheckbox
											form={ form }
											field="open2"
											disabled={ selected === undefined || schedule[selected].open24h }
										>
											{ _t('TXT_OPEN') }
										</FormCheckbox>
									</div>
									<div className="col-sm">
										<TimePicker
											disabled={ selected === undefined || !form.data.open2 || schedule[selected].open24h }
											placeholder={ _t('PLH_FROM') }
											name="from2"
											value={ form.data.from2 || '' }
											onChange={ form.onChange }
										/>
									</div>
									<div className="col-sm">
										<TimePicker
											disabled={ selected === undefined || !form.data.open2 || schedule[selected].open24h }
											placeholder={ _t('PLH_TO') }
											name="to2"
											value={ form.data.to2 || '' }
											onChange={ form.onChange }
										/>
									</div>
								</div>
							</fieldset>

						</PaneBody>
						<PaneFooter className="align-items-center justify-content-center">
							<FormCheckbox form={ form } field="open24h" disabled={ selected === undefined }>{ _t('LBL_OPEN_24H') }</FormCheckbox>
						</PaneFooter>
					</Pane>
				}

			</div>

			<footer className="Venues-footer Venues-footer_message">{ _t('TXT_OPENING_HOURS_FOOTER') }</footer>

			<RouterPrompt when={ form.isDirty || isDirty }/>

		</div>
	);
}

export default OpeningHours;