export const actionTypes = {
	taxCodesFail: 'TAXCODES_FAIL',

	fetchTaxCodesRequest: 'FETCH_TAXCODES_TAXCODES_REQUEST',
	fetchTaxCodesSuccess: 'FETCH_TAXCODES_TAXCODES_SUCCESS'
}

const initialState = { data: null, loading: null, errors: null };

export const taxCodesActionCreators = {

	fetchTaxCodes: () => async (dispatch, getState) => {
		// at the moment, this is the only case of json needing loaded into the store
		// if we need more, we should add a jsonFetch method to storeUtils (based on execute)
		if(getState().taxCodes.data) return;
		dispatch({ type: actionTypes.fetchTaxCodesRequest });
		try {
			// this is loaded from a local json file - need to bump the filename on every edit to prevent browser caching
			const url = '/taxCodes.v1.4.json';
			const response = await fetch(url);
			if(response.ok) {
				const data = await response.json();
				if(data.errors) {
					dispatch({ type: actionTypes.taxCodesFail, errors: data.errors });
				} else {
					dispatch({ type: actionTypes.fetchTaxCodesSuccess, data: data });
				}
			} else {
				const errors = [
					{ code: response.status, msg: response.statusText }
				];
				dispatch({ type: actionTypes.taxCodesFail, errors: errors });
			}
		} catch(ex) {
			dispatch({ type: actionTypes.taxCodesFail, errors: [ ex.message ] });
		}
	}

};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('TAXCODES_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.taxCodesFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchTaxCodesSuccess: {
			return {
				...state,
				isLoading: false,
				data: action.data
			}
		}

		default: return state;

	}
}