import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormCheckbox } from 'components/formControls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { PaneItem } from 'components/Pane';
import ValidationMessage from 'components/ValidationMessage';

const OrderItemModifiersDialog = props => {
	const _t = useL10n().getText;

	const formSave = e => {
		// *** this defo needs some tests!!! ***
		const errors = {};
		let hasErrors = false;
		// do some validation of minimums...
		let selections = [];
		// get user selections into a more useful form...
		for(const key in form.data) {
			if(form.data[key]) {
				const ixs = key.substr(1).split('.');
				if(!selections[ixs[0]]) selections[ixs[0]] = [];
				selections[ixs[0]].push(ixs[1]);
			}
		}
		props.item.modifierGroups.forEach((group, ix) => {
			const count = selections[ix] ? selections[ix].length : 0;
			if(group.required) {
				if(count < group.min) {
					hasErrors = true;
					errors[ix] = true;
				}
			} else {
				if(count > 0 && count < group.min) {
					hasErrors = true;
					errors[ix] = true;
				}
			}
		});
		if(hasErrors) {
			// selection rules not met
			form.setErrors(errors);
		} else {
			const payload = [];
			const items = [];
			selections.every((group, ix) => {
				group.every(selection => {
					const selectionNo = parseInt(selection, 10);
					const modifierItem = props.item.modifierGroups[ix].modifierItems[selectionNo];
					if(items[modifierItem.id]) {
						// no duplicate modifiers rule not met
						errors[`i${ ix }.${ selectionNo }`] = true;
						hasErrors = true;
						return false;
					}
					items[modifierItem.id] = true;
					payload.push({
						qty: 1,
						modifierItemId: modifierItem.id,
						name: modifierItem.name,	// not needed for api payload but needed for display later
						itemCost: modifierItem.price	// not needed for api payload but needed for display later
					});
					return true;
				});
				return !hasErrors;
			});
			// console.log(items, payload, errors);
			if(hasErrors) {
				form.setErrors(errors);
			} else {
				props.onHide(payload);
				return true;
			}
		}
		return false;
	}

	const form = useForm(formSave);
	const changeRef = useRef(form.onChange);
	const resetRef = useRef(form.reset);

	useEffect(() => {
		// reset the form whenever the modal is shown
		if(props.show) {
			const resetData = {};
			props.item?.modifierGroups.forEach((group, gix) => {
				group.modifierItems.forEach((item, iix) => {
					resetData['i' + gix + '.' + iix] = !!item.isDefault;
				});
			});
			resetRef.current(resetData);
		}
	}, [props.show, props.item?.modifierGroups]);

	const changeHandler = e => {
		// do some selection validation before calling the form change handler
		if(e.target.tagName !== 'INPUT') return;
		const id = e.target.id;
		const ixs = id.substr(1).split('.');
		const group = props.item.modifierGroups[ixs[0]];
		if(group.max === 1) {
			// only one item allowed, so validate...
			for(const key in form.data) {
				const keyGroupIndex = key.substr(1).split('.')[0];
				if(keyGroupIndex === ixs[0] && form.data[key]) {
					// one this group has already been selected, so we need to unselect it
					changeRef.current(null, key, false);
				}
			}
		} else {
			// multiple selections allowed
			if(group.max > 0 && e.target.checked) {
				// check we haven't exceeded max
				let groupCount = 0;
				for(const key in form.data) {
					const keyGroupIndex = key.substr(1).split('.')[0];
					if(keyGroupIndex === ixs[0] && form.data[key]) {
						groupCount++;
					}
				}
				if(groupCount >= group.max) {
					console.error('oops - too many selected');
					return;
				}
			}
		}
		changeRef.current(e);
	}

	const saveHandler = e => {
		const saved = form.onSave(e);
		if(!saved) {
			// if validation fails, we want to be able to see ValidationMessage (this scrolls itself into view, but only once)
			const modal = document.getElementById('orderItemModifiersDialog');
			modal?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		}
	}

	const renderErrorMsg = () => {
		let errorMsg;
		if(form.errors) {
			const firstError = Object.keys(form.errors)[0];
			if(isNaN(firstError)) {
				// error will look like { 'i1.2': true }
				errorMsg = _t('TXT_VALIDATION_FIX_DUP');
			} else {
				// error will look like { 2: true }
				errorMsg = _t('TXT_VALIDATION_FIX');
			}
		}
		return errorMsg;
	}

	const renderGroupLabel = group => {
		let range = '';
		if(group.min === 0) {
			range = group.max === 0 ? _t('TXT_ANY') : _t('TXT_UP_TO', false, group.max);
		} else {
			if(group.max === 0) {
				range = _t('TXT_AT_LEAST', false, group.min);
			} else {
				range = group.min === group.max ? group.min : _t('TXT_FROM_TO', false, group.min, group.max);
			}
		}
		return [_t('TXT_SELECT'), range, group.required ? '' : _t('TXT_OPTIONAL')];
	}

	return (
		<Modal id="orderItemModifiersDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader>{ _t('HDG_ORDER_ITEM_MODIFIERS') }</ModalHeader>
			<ModalBody className="Modal-body_padding0">

				<ValidationMessage open={ !!form.errors } message={ renderErrorMsg() }/>

				{ props.item?.modifierGroups.map((group, gix) => {
					// console.log(group);
					// if(group.name !== '2 Sides Combo Deal') return null;
					// if(group.name !== 'Doneness') return null;
					const labelClassName = classNames({ 'validation-fail': form.errors && form.errors[gix]});
					return (
						<section key={ gix } className="modifierGroup">
							<h2>
								<div>{ group.name }</div>
								<div className={ labelClassName }>{ renderGroupLabel(group) }</div>
							</h2>
							<ul className="Pane-items">
								{ group.modifierItems.map((item, iix) => {
									return <PaneItem key={ iix }>
										<div className="col">
											<FormCheckbox form={ form } field={ 'i' + gix + '.' + iix } onChange={ changeHandler }>{ item.name }</FormCheckbox>
										</div>
										<div className="col-auto">
											{ item.price && item.price !== '0' && utilities.formatPrice(item.price, props.currencySymbol) }
										</div>
									</PaneItem>
								})}
							</ul>
						</section>
					);
				})}

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">
					{ utilities.getText('LBL_CANCEL') }
				</button>
				<button className="btn btn-primary" onClick={ saveHandler } id="btnOk">
					{ utilities.getText('LBL_OK') }
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default OrderItemModifiersDialog;