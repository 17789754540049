import React from 'react';
import './SadFace.scss';

const SadFace = props => {
	return (
		<div className="SadFace" { ...props }>
			<div className="SadFace-eye SadFace-eye_left"></div>
			<div className="SadFace-eye SadFace-eye_right"></div>
			<div className="SadFace-frown"/>
		</div>
	);
};

export default SadFace;