import React, { Fragment, useEffect, useRef, useState } from 'react';
import Venue from 'models/Venue';
import useForm from 'components/Form';
import { FormCheckbox } from 'components/formControls';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import VenueInfo from './VenueInfo';
import VenueAddress from './VenueAddress';

const VenueEditorForm = props => {
	const [address, setAddress] = useState();
	const [mapPosition, setMapPosition] = useState();

	const formSave = () => {
		// prep payload...
		if(!form.data.lat && !mapPosition) {
			form.setErrors({ map: true });
			return;
		}
		const venue = new Venue();
		for(const key in venue) {
			if(form.data[key]) {
				venue[key] = form.data[key];
			} else {
				delete(venue[key]);
			}
		}
		if(form.data.useMap) {
			venue.lat = mapPosition[0];
			venue.lon = mapPosition[1];
		}
		if(form.data.cuisineTypes) venue.cuisineTypes = form.data.cuisineTypes;
		props.saveVenue(venue, form.data.makeSelected);
	}

	const form = useForm(formSave, validationRules, new Venue());
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.venue) {
			resetRef.current({ ...props.venue }, false);
		}
	}, [props.venue]);

	useEffect(() => {
		if(props.reset) {
			resetRef.current(new Venue(), false);
		}
	}, [props.reset]);

	const updateAddress = (address, dscr) => {
		// I can't update the form (using reset) directly here as it uses (I think) an initial value of form.data caused by the way MapControl initialises
		// so instead...
		setAddress(address);
	}

	const updateMapPosition = position => {
		setMapPosition(position);
		if(form.data.lat !== position[0] || form.data.lon !== position[1]) {
			// it looks like user moved the map...
			form.onChange(null, 'useMap', true);
		}
	}

	useEffect(() => {
		if(address && form.data) {
			const venue = { ...form.data, ...address };
			resetRef.current(venue, true);
		}
		// mustn't be dependent on form.data, so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address]);

	return (
		<Fragment>

			<ValidationMessage
				open={ !!form.errors }
				message={ form.errors?.map ? 'Place adjust the map to the venue\'s location then try again.' : undefined }
			/>

			<button className="d-none" id={ props.id + 'SaveButton' } onClick={ form.onSave }>SAVE</button>

			<div className="form-row">
				<div className={ props.columnClass }>

					<VenueInfo form={ form } cuisineTypes={ props.cuisineTypes }/>

				</div>
				<div className={ props.columnClass }>

					<VenueAddress
						form={ form }
						updateAddress={ updateAddress }
						mapPosition={ mapPosition }
						updateMapPosition={ updateMapPosition }
						id={ props.id }
					/>

					{ props.id === 'modalVenueEditor' &&
						<FormCheckbox className="mt10 text-center" form={ form } field="makeSelected">
							On save, make this the currently selected venue
						</FormCheckbox>
					}

				</div>

				{ props.id === 'inlineVenueEditor' &&
					<RouterPrompt when={ form.isDirty }/>
				}

			</div>

		</Fragment>
	);

};

export default VenueEditorForm;

const validationRules = {
	name: true,
	phone: true,
	cuisineTypes: true,
	street: true,
	city: true,
	// provinceName: true,
	postCode: true,
	countryName: true
}