import React from 'react';
import PriceOptions from '../components/PriceOptions';
import { FormInput, FormInputWithButton, FormTypeahead } from '../components/formControls';

const MenuItemForm = props => {

	const sections = props.menuSections?.map(menuSection => menuSection.title);
	return (
		<div className="form">
			<div className="form-row">
				<div className="col">
					<FormTypeahead
						placeholder="Menu Section"
						form={ props.form }
						field="menuSectionTitle"
						data={ sections }
						maxMatches={ 5 }
						minLength={ 2 }
						disabled={ props.disabled }
						highlight={ true }
					/>
				</div>
			</div>
			<div className="form-row">
				<div className="col">
					<FormInput placeholder="Menu Item" form={ props.form } field="name" disabled={ props.disabled }/>
				</div>
			</div>
			{ props.showDishNo &&
			<div className="form-row">
				<div className="col-sm-3">
					<FormInput placeholder="Dish No." form={ props.form } field="menuItemId" disabled={ props.disabled }/>
				</div>
				<div className="col-sm-9">
					<FormInput placeholder="Short name for Kitchen" form={ props.form } field="kitchenName" disabled={ props.disabled }/>
				</div>
			</div>
			}
			{ !props.showDishNo &&
			<div className="form-row">
				<div className="col">
					<FormInput placeholder="Short name for Kitchen" form={ props.form } field="kitchenName" disabled={ props.disabled }/>
				</div>
			</div>
			}
			<div className="form-row">
				<div className="col">
					<FormInput type="textarea" placeholder="Description" form={ props.form } field="dscr" disabled={ props.disabled }/>
				</div>
			</div>
			<div className="form-row">
				<div className="col">
					<FormInput placeholder="Calories" form={ props.form } field="calories" disabled={ props.disabled }/>
				</div>
			</div>
			<PriceOptions
				name="onPromo"
				form={ props.form }
				which={ props.form.data.onPromo }
				disabled={ props.disabled }
				config={ [
					{ label: 'Price', field: 'price', value: 0, price: props.form.data.price },
					{ label: 'On promo', field: 'promoPrice', value: 1, price: props.form.data.promoPrice }
				] }
				stacked={ !props.dialog }
			/>
			{ props.onSalesTaxButtonClick &&
				<div className="form-row">
					<div className="col">
						<FormInputWithButton
							placeholder="Sales Tax Code"
							icon="more_horiz"
							form={ props.form }
							field="salesTaxCode"
							disabled={ props.disabled }
							onButtonClick={ props.onSalesTaxButtonClick }
						/>
					</div>
				</div>
			}

		</div>
	);

};

export default MenuItemForm;