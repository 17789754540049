import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import intervalToDuration from 'date-fns/intervalToDuration';
import dates from 'services/dates';
import utilities from 'services/utilities';
import { distributionTypes, actionTypes } from 'store/orders';
import useL10n from 'L10n';
import { Checkbox } from 'components/controls';
import { CollapserButton, Collapsible } from 'components/Collapsible';
import useForm from 'components/Form';
import { FormDatePicker, FormDropdown, FormLabel } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader, PaneItemButton } from 'components/Pane';
import Paginator from 'components/Paginator';

const OrdersList = props => {
	const [useAsKitchen, setUseAsKitchen] = useState(false);	// temp code to test 2 alternate use cases
	const [options, setOptions] = useState(fromOptions);
	const [collapsiblesOpen, setCollapsiblesOpen] = useState(useAsKitchen);
	const [showFromDate, setShowFromDate] = useState(false);
	const [now, setNow] = useState();
	const [showPrices, setShowPrices] = useState(false);
	const orders = props.orderStore.orderData?.orders;
	const orderStatusFilter = props.orderStore.orderData?.orderStatusFilter;
	const loading = props.orderStore.loading === actionTypes.fetchOrdersRequest || props.loading;

	const _t = useL10n().getText;
	const getDistributionType = useL10n().getDistributionType;

	useEffect(() => {
		if(props.orderStore.orderData?.nextPageCancelled) {
			// due to the fact the server doesn't always know another page is actually available, sometimes we need to undo the next page
			props.updateSetting('ordersPage', props.settings.ordersPage - 1);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.orderStore.orderData]);

	useEffect(() => {
		// ticker so that every minute the ELAPSED column can update - stores now in local state so that page updates
		setNow(Date.now());
		const id = setInterval(() => {
			setNow(Date.now());
		}, 60000);
		return () => clearInterval(id);
	}, []);

	const getInitialFormState = () => {
		const formState = {
			orderStatus: props.settings.ordersFilter,
			fromOption: props.settings.ordersFromOption,
			fromDate: props.settings.ordersFromDate ? new Date(props.settings.ordersFromDate) : null,
			toDate: props.settings.ordersToDate ? new Date(props.settings.ordersToDate) : null
		};
		if(formState.fromDate) {
			fromOptions[4] = { value: 4, label: 'from date: ' + dates.format(formState.fromDate)};
		} else {
			if(formState.fromOption === 4) {
				// not allowed, so
				formState.fromOption = 0;	// make it 'today' (the default)
				props.updateSetting('ordersFromOption', 0);
			}
		}
		return formState;
	}

	const form = useForm(null, null, getInitialFormState());
	// console.log('form init', form.data, fromOptions);
	const formRef = useRef(form);

	useEffect(() => {
		if(!loading && !(form.data.fromOption === '4' && !form.data.fromDate)) {
			// should not fetch via store if SSE is handling initial data load or if we're waiting for user to select from date
			fetchPage(1);
		}
		props.updateSetting('ordersFilter', form.data.orderStatus);
		props.updateSetting('ordersFromOption', form.data.fromOption);
		props.updateSetting('ordersFromDate', form.data.fromDate ? form.data.fromDate.getTime() : 0);
		props.updateSetting('ordersToDate', form.data.toDate ? form.data.toDate.getTime() : 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.data]);

	const fetchPageOffset = dir => {
		const newPage = props.settings.ordersPage + dir;
		fetchPage(newPage);
	}

	const fetchPage = page => {
		props.updateSetting('ordersPage', page);
		if(props.today) {
			let fromOption;
			if(typeof form.data.fromOption === 'string') {
				fromOption = parseInt(form.data.fromOption, 10);
				formRef.current.onChange(null, 'fromOption', fromOption);
			} else {
				fromOption = form.data.fromOption;
			}
			const options = { ...form.data, fromOption: fromOption, page: page, today: props.today.getTime() };
			// console.log('fetchPage', options);
			props.fetchOrders(options);
		}
	}

	const elapsedMinutes = timestamp => {
		if(!now) return '';
		const duration = intervalToDuration({ start: now, end: timestamp });
		if(duration.years) {
			return _t('TXT_DURATION_YEARS_MONTHS', false, duration.years, duration.months);
		}
		if(duration.months) {
			return _t('TXT_DURATION_MONTHS_DAYS', false, duration.months, duration.days);
		}
		if(duration.days) {
			return _t('TXT_DURATION_DAYS_HOURS', false, duration.days, duration.hours);
		}
		return _t('TXT_DURATION_HOURS_MINUTES', false, duration.hours, duration.minutes);
	}

	const toggleShowPrices = e => {
		setShowPrices(!showPrices);
	}

	const toggleCollapsibles = toggle => {
		setCollapsiblesOpen(!collapsiblesOpen);
	}

	const toggleFullscreen = e => {
		props.updateSetting('fullscreen', !props.settings.fullscreen);
	}

	const toggleNotifications = () => {
		props.updateSetting('soundEnabled', !props.settings.soundEnabled);
	}

	const fromDateChangeHandler = (e, field, date) => {
		// console.log(e, field, date);
		setShowFromDate(false);
		if(!date && !form.data.fromDate) {
			// use must pick a date so we are going to change back to "today"
			formRef.current.onChange(null, 'fromOption', 0);
			props.updateSetting('ordersFromOption', 0);
		} else if(field === 'fromDate') {
			fromOptions[4] = { value: 4, label: 'from date: ' + dates.format(date)};
			setOptions(fromOptions);
		}
	}

	const fromDropdownChangeHandler = (e, field, value, label) => {
		// console.log(e, field, value, label);
		if(value === '4') {
			setShowFromDate(true);
		} else {
			formRef.current.onChange(null, 'toDate', null);
		}
	}

	const renderDistributionType = order => {
		if(order.distributionType === distributionTypes.DELIVERY_PICTABITE ||
			order.distributionType === distributionTypes.DELIVERY_RESTAURANT) {
			const type = utilities.getDistributionTypeKey(order.distributionType);
			return useAsKitchen ? _t(type) : (order.deliveryAddress?.street || '?')
		} else {
			return getDistributionType(order);
		}
	}

	const renderDateTime = timestamp => {
		const date = new Date(timestamp);
		// console.log(today, date);
		if(timestamp > props.today?.getTime()) {
			// if this order is today, no need to show the date, just the time
			return <div>{ dates.format(date, dates.styles.TIME, props.timeZone) }</div>
		} else {
			return <Fragment>
					<div>{ dates.format(date, dates.styles.DATE, props.timeZone) }</div>
					<div className="Order_detail">{ dates.format(date, dates.styles.TIME, props.timeZone) }</div>
				</Fragment>
		}
	}

	const renderOrders = () => {
		if(!orders) return null;
		if(orders[0].name) return null;	// it's got Orders data, not OrdersList data
		return orders.map((order, ix) => {
			let groupingType = 0;
			if(order.grouped) {
				if(ix && orders[ix - 1].waitstaffTarget === order.waitstaffTarget) {
					groupingType = 1;
				}
				if(ix < orders.length - 1) {
					if(orders[ix + 1].waitstaffTarget !== order.waitstaffTarget) {
						groupingType = 2;
					}
				} else {
					groupingType = 2;
				}
			}
			const classes = classNames(
				{ 'order-grouped': order.grouped },
				{ 'order-grouped_first': order.grouped && groupingType === 0 },
				{ 'order-grouped_middle': order.grouped && groupingType === 1 },
				{ 'order-grouped_last': order.grouped && groupingType === 2 },
				{ 'async': order.externalOrderId === props.currentOrder?.externalOrderId &&
						(props.orderStore.loading === actionTypes.bumpOrdersRequest || props.orderStore.loading === actionTypes.cancelOrderRequest) }
			);
			return <Collapsible className={ classes } collapse={ !collapsiblesOpen } key={ 'o-' + ix } header={
					<Fragment>
						<div className="col">{ formatOrderId(order.externalOrderId) }</div>
						<div className="col" aria-describedby={ order.comments ? 'tp-' + ix : null }>
							{ formatName(order) }
							{ !useAsKitchen &&
								<div className="Order_detail">{ order.deliveryAddress?.phone }</div>
							}
						</div>
						<div className="col">
							{ renderDateTime(order.timestamp) }
						</div>
						<div className="col">{ elapsedMinutes(order.timestamp) }</div>
						<div className="col">{ utilities.formatPrice(order.total, props.currencySymbol) }</div>
						<div className="col">{ renderDistributionType(order) }</div>
						<div className="col">{ _t(utilities.getOrderStatusKey(order.orderStatus)) }</div>
						<div className="buttons">
							{ (groupingType === 2 || !order.grouped) &&
								<PaneItemButton className="Pane-item_textButton" disabled={ !order.isBumpable } index={ ix } onClick={ props.bumpHandler }>BUMP</PaneItemButton>
							}
							<PaneItemButton className="btn-details" title="details" icon="more_horiz" index={ order } onClick={ props.orderDetailsHandler }/>
						</div>
					</Fragment>
				}>
					{ order.comments &&
						<li className="Order-item Order-item_comment">
							<div className="comment" id={ 'tp-'+ ix } role="tooltip">{ order.comments }</div>
						</li>
					}
					{
						order.orderItems.map((item, ix) => {
							const classes = classNames('Order-item description', { 'Order-item_withModifiers': item.modifierItems.length })
							return <Fragment key={ 'i-' + ix }>
								<li className={ classes }>
									<div>
										{ item.qty } &nbsp;&nbsp; { item.name }
									</div>
									{ showPrices &&
										<div>
											{ utilities.formatPrice(item.itemCost, props.currencySymbol) }
										</div>
									}
								</li>
								{ item.modifierItems.length > 0 &&
									<li className="Order-item modifiers">
										{ item.modifierItems?.map((mod, ix) => {
											return <div key={ 'm-' + ix } className="modifier">
												{ mod.name + (mod.itemCost && showPrices ? ' ' + utilities.formatPrice(mod.itemCost, props.currencySymbol) : '') }
											</div>
										})}
									</li>
								}
							</Fragment>
						})
					}
			</Collapsible>
		});
	}

	const footerClasses = classNames('Orders-footer', 'Venues-footer', { 'async' : loading });
	return (
		<div className="animated fadeIn fixed-footer">
			{ orderStatusFilter &&
				<div className="OrdersList-filters row">
					<div className="col">
						<FormLabel htmlFor="orderStatus" className="" label="_ORDER_STATUS"/>
						<FormDropdown form={ form } field="orderStatus" options={ getOptionsArray(orderStatusFilter.allOptions) }/>
					</div>
					<div className="col">
						<div className="row">
							<div className="col">
								<FormLabel htmlFor="fromOption" className="" label="_DATES"/>
								{ showFromDate === true
										? <FormDatePicker form={ form } field="fromDate" open={ true } onChange={ fromDateChangeHandler } onClose={ fromDateChangeHandler }/>
										: <FormDropdown form={ form } field="fromOption" options={ options } onChange={ fromDropdownChangeHandler }/>
								}
								</div>
							<div className="col">
								<FormLabel htmlFor="toDate" className="" label="_DATE_TO"/>
								<FormDatePicker form={ form } field="toDate" disabled={ form.data.fromOption !== 4 } clear={ true }/>
							</div>
						</div>
					</div>
				</div>
			}

			{ orders?.length > 0 &&
				<Pane className={ 'OrdersList-pane' + (useAsKitchen ? ' KitchenList' : '') }>
					<PaneHeader>
						<div className="col">{ _t('LBL_ORDER_ID') }</div>
						<div className="col">{ _t('LBL_PATRON') }</div>
						<div className="col">{ _t('LBL_ORDER_DATE') }</div>
						<div className="col">{ _t('LBL_MINUTES_PASSED') }</div>
						<div className="col">{ _t('LBL_TOTALS') }</div>
						<div className="col">{ _t('LBL_DISTRIBUTION') }</div>
						<div className="col">{ _t('HDG_STATUS') }</div>
					</PaneHeader>
					<PaneBody tag="ul" className="Pane-items">
						{ renderOrders() }
					</PaneBody>
				</Pane>
			}

			<footer className={ footerClasses }>
				<div>
					<label>{ _t('LBL_MAXIMISE') }</label>
					<IconButton
						title={ props.fullscreen ? _t('LBL_MINIMISE') : _t('LBL_MAXIMISE') }
						icon={ props.fullscreen ? 'fullscreen_exit' : 'fullscreen' }
						onClick={ toggleFullscreen }
					/>
				</div>

				<div>
				<label>{ _t('LBL_NOTIFICATIONS') }</label>
				<IconButton
					// disabled={ false }
					// title="maximise / minimise"	// if this is mainly going to be used on tablets not much point adding tooltips
					icon={ props.settings.soundEnabled ? 'notifications' : 'notifications_off' }
					onClick={ toggleNotifications }
				/>
			</div>

				<div>
					<label>{ collapsiblesOpen ? _t('LBL_COLLAPSE_ALL') : _t('LBL_EXPAND_ALL') }</label>
					<CollapserButton
						open={ collapsiblesOpen }
						disabled={ !props.orderStore.orderData?.orders?.length }
						onClick={ toggleCollapsibles }
					/>
				</div>

				<div>
					<label>{ showPrices ? _t('LBL_PRICE_DETAILS_HIDE') : _t('LBL_PRICE_DETAILS_SHOW') }</label>
					<IconButton
						title={ showPrices ? _t('LBL_PRICE_DETAILS_HIDE') : _t('LBL_PRICE_DETAILS_SHOW') }
						icon={ showPrices ? 'money_off' : 'attach_money' }
						onClick={ toggleShowPrices }
					/>
				</div>

				<div>
					<label>{ _t('LBL_PAGE') }</label>
					<div className="IconButtonGroup">
						<Paginator
							page={ props.settings.ordersPage }
							alwaysVisible={ true }
							hasNextPage={ props.orderStore.orderData?.hasNextPage }
							fetchPage={ fetchPageOffset }
						/>
					</div>
				</div>

				<Checkbox onClick={ () => {
					setCollapsiblesOpen(!useAsKitchen);
					setUseAsKitchen(!useAsKitchen);
				}}>
					{ _t('LBL_KITCHEN_VIEW') }
				</Checkbox>

				<button
					className={ classNames('btn', 'btn-primary', { 'async': props.orderStore.loading === actionTypes.undoBumpOrdersRequest }) }
					onClick={ props.undoHandler }
				>
					{ _t('BTN_UNDO') }
				</button>

			</footer>
			
		</div>
	);
};

export default OrdersList;

const fromOptions = [
	{ value: 0, label: 'today' },
	{ value: 1, label: 'yesterday' },
	{ value: 2, label: 'last 2 days' },
	{ value: 3, label: 'last 3 days' },
	{ value: 4, label: 'from date:' }
];

const getOptionsArray = options => {
	const optionsArray = [];
	for(const key in options) {
		const el = {
			value: key,
			label: options[key]
		}
		optionsArray.push(el);
	}
	return optionsArray;
}

const formatOrderId = orderId => {
	const parts = orderId.split('-');
	if(parts.length === 1) {
		return orderId;
	} else {
		return <Fragment><small>{ parts[0] + '-' }</small>{ parts[1] }</Fragment>;
	}
}

const formatName = order => {
	if(!order.firstName) return '';
	return order.firstName + (order.lastName ? ' ' + order.lastName : '');
}