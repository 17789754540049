import React from 'react';
import useForm from '../components/Form';
import { FormImageControl } from '../components/formControls';
import { Pane, PaneHeader, PaneBody } from '../components/Pane';

export const Pane4 = props => {

	const formSave = () => {
		console.log('formSave', form.data);
	}

	const form = useForm(formSave);

	return (
		<Pane>
			<PaneHeader>Settings</PaneHeader>
			<PaneBody className="container">

				<FormImageControl type="checkbox" form={ form } field="dairyFree" label="Dairy Free" onChange={ form.onChange }/>
				<FormImageControl type="checkbox" form={ form } field="glutenFree" label="Gluten Free" onChange={ form.onChange }/>
				<FormImageControl type="checkbox" form={ form } field="nutSafe" label="Nut Safe" onChange={ form.onChange }/>
				<FormImageControl type="input" form={ form } field="veggieStatus" label="Veggie" options={ ['n', 'a', 'b'] } onChange={ form.onChange }/>
				<FormImageControl type="input" form={ form } field="spicyStatus" label="Spicy" options={ [0, 1, 2, 3] } onChange={ form.onChange }/>

			</PaneBody>
		</Pane>
	);

}

export default Pane4;