import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Content from 'layout/Content';
import OrdersListContainer from './OrdersListContainer';
import OrdersContainer from './OrdersContainer';
import Printers from './Printers';
import Settings from './Settings';
import './index.scss';

const Orders = props => {
	const user = useSelector(state => state.user.user);

	const nav = [
		{ path: 'list', label: 'List', component: OrdersListContainer },
		{ path: 'kitchen', label: 'Kitchen', component: OrdersContainer },
		{ path: 'service', label: 'Service', component: OrdersContainer },
		{ path: 'test', label: 'Test', component: OrdersContainer, hide: !user?.hasPrivilegedAccess },
		{ path: 'printers', label: 'Printers', component: Printers },
		{ path: 'settings', component: Settings, label: <Fragment>
				<span className="material-icons">settings</span>
				<span>Settings</span>
			</Fragment>
		}
	];

	return (
		<Content path="/venues/orders/" nav={ nav } className="Orders"/>
	);
}

export default Orders;