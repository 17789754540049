import { ReactComponent as Logo } from 'assets/img/logo.svg';
import FlyoutNav from './FlyoutNav';

const Flyout = props => {
	return (
		<section className="Flyout">

			<div className="logo">
				<Logo/>
				<span className="sr-only">Pictabite</span>
			</div>

			<div className="flyout-control">
				<button type="button" className="btn btn-icon" onClick={ props.onClick }>
					<span className="material-icons">{ props.collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }</span>
				</button>
			</div>

			<FlyoutNav signInOutHandler={ props.signInOutHandler }/>

		</section>
	);
}

export default Flyout;