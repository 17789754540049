import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import useL10n from 'L10n';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Calendar from './Calendar';

const DatePicker = props => {

	const _t = useL10n().getText;
	const translations = useSelector(state => state.l10n.translations);
	const localeData = { ...translations?.localeData, clear: _t('BTN_CLEAR', true) };

	const [open, setOpen] = useState(props.open ?? false);

	const toggle = e => {
		e.preventDefault();
		if(open && props.onClose) props.onClose();
		setOpen(!open);
	}

	const onChange = (date, e) => {
		if(props.onChange) props.onChange(e, props.name ? props.name : props.id, date);
		setOpen(false);
	}

	const clearHandler = e => {
		if(props.onChange) props.onChange(e, props.name ? props.name : props.id, null);
		setOpen(false);
	}

	const classes = classNames('DropdownButton', props.className);
	const spanClassName = (!props.date) ? 'placeholder' : '';
	let display;
	if(props.date) {
		if(props.date instanceof Date) {
			if(props.formatter) {
				display = props.formatter(props.date);
			} else {
				console.error('DatePicker: no formatter supplied to format dates.');
			}
		} else {
			console.error('DatePicker: date is not an instance of Date.');
		}
	}

	return (
		<Dropdown className={ classes } isOpen={ open } toggle={ toggle }>
			<DropdownToggle
				id={ props.id }
				name={ props.name }
				title={ props.title }
				className="form-control form-control-btn"
				disabled={ props.disabled }
				tag="button"
				data-toggle="dropdown"
				aria-expanded={ open }
			>
				<span className={ spanClassName }>{ display || props.placeholder }</span>
				<i className="material-icons">today</i>
			</DropdownToggle>
			<DropdownMenu className="Calendar-container">
				<Calendar
					date={ props.date }
					minDate={ props.minDate }
					maxDate={ props.maxDate }
					compactness={ 0 }
					localeData={ localeData }
					onChange={ onChange }
					onClear={ props.clear ? clearHandler : undefined }
				/>
			</DropdownMenu>
		</Dropdown>
	);

};

DatePicker.propTypes = {
	className: PropTypes.string,	// add a custom class to the component's own
	placeholder: PropTypes.string,
	name: PropTypes.string,
	// date: PropTypes.string,
	onChange: PropTypes.func
};

export default DatePicker;