import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useEffect, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import classNames from 'classnames';
// import utilities from 'services/utilities';
import { venuesActionCreators } from 'store/venues';
// import useForm from 'components/Form';

const Overview = props => {

	// const waitstaffCount = useSelector(state => state.venues.waitstaffCount);
	const dispatch = useDispatch();

	// const form = useForm();
	// const formRef = useRef(form);

	// console.log(formRef, waitstaffCount);

	useEffect(() => {
		dispatch(venuesActionCreators.getWaitstaffCount());
	}, [dispatch]);

	return (
		<div id="content">
			<div className="contentNav" style={ { height: '51px' }}/>
			<main>

				{/* <label>Max waitstaff on duty today</label> */}

			</main>
		</div>
	);
};

export default Overview;