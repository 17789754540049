import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import rules from 'services/rules';
import utilities from 'services/utilities';
import { actionTypes, ordersActionCreators } from 'store/orders';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import useForm from 'components/Form';
import { FormDropdown, FormInput, FormCheckbox, FormRadio } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import Feedback from './Feedback';

const OrderTypes = props => {

	const [confirmDialogShow, setConfirmDialogShow] = useState(false);
	const ordersStore = useSelector(state => state.orders);
	const venue = useSelector(state => state.venues.venue);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	const formSave = () => {
		const errors = {};
		let hasErrors = false;
		// some custom error checking needed now...
		if(form.data.deliveryChargeOption === '_FREE_ABOVE_SIZE' && typeof form.data.deliveryMinOrderSize === 'undefined') {
			errors.deliveryMinOrderSize = 'this field is required';
			hasErrors = true;
		}
		if(form.data.deliveryChargeOption === '_FREE_ABOVE_SIZE' && typeof form.data.freeDeliveryOption === 'undefined') {
			errors.freeDeliveryOption = 'this field is required';
			hasErrors = true;
		}
		if(form.data.freeDeliveryOption === '_FLAT_FEE_4_SMALLER' && typeof form.data.deliveryFee === 'undefined') {
			errors.deliveryFee = 'this field is required';
			hasErrors = true;
		}
		if(form.data.deliveryChargeOption === '_FLAT_FEE' && typeof form.data.deliveryFee === 'undefined') {
			errors.deliveryFee = 'this field is required';
			hasErrors = true;
		}
		if(hasErrors) {
			form.setErrors(errors);
		} else {
			const venueUpdates = rules.venueFromOrderTypesForm(form.data);
			dispatch(ordersActionCreators.updateOrderDistributionTypes(venueUpdates));
		}
	}

	const form = useForm(formSave, validationRules);
	const formRef = useRef(form);

	useEffect(() => {
		if(venue) {
			const deliveryRules = rules.deliveryRulesFromVenue(venue);
			const orderingRules = rules.orderTypesFromDistributionTypes(venue.distributionTypes);
			const data = { ...deliveryRules, ...orderingRules };
			formRef.current.reset(data);
		}
	}, [venue]);

	const onSave = e => {
		if(form.data.deliveryRange === 0) {
			setConfirmDialogShow(true);
		} else {
			form.onSave();
		}
	}

	const confirmDialogHide = response => {
		if(response.button === 'btnOk') {
			form.onSave();
		}
		setConfirmDialogShow(false);
	}

	return (
		<Fragment>

			<Pane className="OrderTypes animated fadeIn max-width-600">
				<PaneHeader>
					<h1>{ _t('HDG_ORDERTYPES', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							// disabled={ we need to implement a dirty form option so this is disabled until a change is made }
							async={ ordersStore.loading === actionTypes.updateOrderDistributionTypesRequest }
							onClick={ onSave }
						/>
					</div>
				</PaneHeader>
				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<Pane>
						<PaneHeader>{ utilities.getText('HDG_IN_RESTAURANT_ORDERS') }</PaneHeader>
						<PaneBody className="container">

							<FormRadio form={ form } field="eatIn" value="none">
								{ utilities.getText('LBL_DISABLED') }
								<br/>{ utilities.getText('TXT_NO_DINING_IN') }
							</FormRadio>
							<FormRadio form={ form } field="eatIn" value="selfService">
								{ utilities.getText('LBL_SELF_SERVICE') }
								<br/>{ utilities.getText('TXT_SELF_SERVICE') }
							</FormRadio>
							<FormRadio form={ form } field="eatIn" value="waitstaff">
								{ utilities.getText('LBL_WAITSTAFF') }
								<br/>{ utilities.getText('TXT_WAITSTAFF') }
							</FormRadio>

						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>{ utilities.getText('HDG_TAKEOUT_ORDERS') }</PaneHeader>
						<PaneBody className="container">
							<FormCheckbox form={ form } field="takeOut">
								{ utilities.getText('LBL_TAKEOUT_ORDERS') }
								<br/>{ utilities.getText('TXT_TAKEOUT_ORDERS') }
							</FormCheckbox>
						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>{ utilities.getText('HDG_DELIVERY') }</PaneHeader>
						<PaneBody className="container">

							<FormRadio form={ form } field="delivery" value="none">
								{ utilities.getText('LBL_DISABLED') }
								<br/>{ utilities.getText('TXT_NO_DELIVERY') }
							</FormRadio>
							{/* FOR FUTURE USE...
							<FormRadio form={ form } field="delivery" value="Pictabite">
								{ utilities.getText('LBL_PB_DELIVERS') }
								<br/>{ utilities.getText('TXT_PB_DELIVERS') }
							</FormRadio> */}
							<FormRadio form={ form } field="delivery" value="restaurant">
								{ utilities.getText('LBL_RESTAURANT_DELIVERS') }
								<br/>{ utilities.getText('TXT_RESTAURANT_DELIVERS') }
							</FormRadio>

							{ form.data.delivery === "restaurant" &&
								<div id="deliveryOptions">

									<div className="form-row">
										<label className="col-form-label col-7">
											{ utilities.getText('LBL_ESTD_DELIVERY_TIME') }
										</label>
										<div className="col-5">
											<FormInput type="number" step="1" min="0" form={ form } field="deliveryTimeInMinutes"/>
										</div>
									</div>

									<div className="form-row">
										<label className="col-form-label col-7">
											{ utilities.getText('LBL_SELECT_DELIVERY_RANGE') }
										</label>
										<div className="col-5">
											<FormDropdown placeholder="_SELECT_DELIVERY_RANGE" form={ form } field="deliveryRange" options={ rangeOptions }/>
										</div>
									</div>

									{/* <div className="form-row">
										<div className="col-7">
											<FormDropdown placeholder="_SELECT_DELIVERY_RANGE" form={ form } field="deliveryRangeOption" options={ ['_MILES', '_KM'] }/>
										</div>
										<div className="col-5">
											{ typeof form.data.deliveryRangeOption !== 'undefined' &&
												<FormInput type="number" step="1" min="0" placeholder="_DELIVERY_RANGE" form={ form } field="deliveryRange"/>
											}
										</div>
									</div> */}

									<div className="form-row">
										<div className="col-7">
											<FormDropdown placeholder="_SELECT_DELIVERY_CHARGE" form={ form } field="deliveryChargeOption" options={ deliveryChargeOptions }/>
										</div>
										<div className="col-5">
											{ form.data.deliveryChargeOption === '_FREE_ABOVE_SIZE' &&
												<FormInput type="money" placeholder="_MIN_ORDER_SIZE" form={ form } field="deliveryMinOrderSize"/>
											}
											{ form.data.deliveryChargeOption === '_FLAT_FEE' &&
												<FormInput type="money" placeholder="_DELIVERY_CHARGE" form={ form } field="deliveryFee"/>
											}
										</div>
									</div>

									{ form.data.deliveryChargeOption === '_FREE_ABOVE_SIZE' &&
										<div className="form-row">
											<div className="col-7">
												<FormDropdown placeholder="_ACTION_BELOW" form={ form } field="freeDeliveryOption" options={ belowSizeOptions }/>
											</div>
											<div className="col-5">
												{ form.data.freeDeliveryOption === '_FLAT_FEE_4_SMALLER' &&
													<FormInput type="money" placeholder="_DELIVERY_CHARGE" form={ form } field="deliveryFee"/>
												}
											</div>
										</div>
									}
								</div>
							}
						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>{ utilities.getText('HDG_ROOM_SERVICE') }</PaneHeader>
						<PaneBody className="container">
							<FormCheckbox form={ form } field="roomService">
								{ utilities.getText('LBL_ROOM_SERVICE') }
								<br/>{ utilities.getText('TXT_ROOM_SERVICE') }
							</FormCheckbox>
						</PaneBody>
					</Pane>

					<Feedback className="mt15" status={ ordersStore.status }/>

				</PaneBody>
			</Pane>

			<ConfirmDialog
				action={ utilities.getText('HDG_PLEASE_CONFIRM') }
				message={ utilities.getText('TXT_DELIVERY_0_WARNING') }
				show={ confirmDialogShow }
				onHide={ confirmDialogHide }
			/>

			<RouterPrompt when={ form.isDirty }/>

		</Fragment>
	);

}

export default OrderTypes;

const validationRules = {
	eatIn: true,
	delivery: true
}

const deliveryChargeOptions = ['_FREE_DELIVERY', '_FREE_ABOVE_SIZE', '_FLAT_FEE'];

const belowSizeOptions = ['_FLAT_FEE_4_SMALLER', '_UNAVAILABLE_4_SMALLER'];

const rangeOptions = [
	{ value: '1', label: '1 mile (1.6 km)' },
	{ value: '2', label: '2 miles (3.2 km)' },
	{ value: '3', label: '3 miles (4.8 km)' },
	{ value: '4', label: '4 miles (6.4 km)' },
	{ value: '5', label: '5 miles (8 km)' },
	{ value: '6', label: '6 miles (9.7 km)' },
	{ value: '7', label: '7 miles (11.3 km)' },
	{ value: '8', label: '8 miles (12.9 km)' },
	{ value: '9', label: '9 miles (14.5 km)' },
	{ value: '10', label: '10 miles (16.1 km)' },
	{ value: '11', label: '11 miles (17.7 km)' },
	{ value: '12', label: '12 miles (19.3 km)' },
	{ value: '13', label: '13 miles (20.9 km)' },
	{ value: '14', label: '14 miles (22.5 km)' },
	{ value: '15', label: '15 miles (24.1 km)' },
	{ value: '16', label: '16 miles (25.7 km)' },
	{ value: '17', label: '17 miles (27.4 km)' },
	{ value: '18', label: '18 miles (29 km)' },
	{ value: '19', label: '19 miles (30.6 km)' },
	{ value: '20', label: '20 miles (32.2 km)' }
]