import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import './Table.scss';

export const Table = ({ className, ...props }) => {
	const classes = classNames('Table', className);
	return (
		<div role="table" className={ classes } { ...props }>
			{ props.children }
		</div>
	);
};

export const Row = ({ className, heading, data, onClick, ...props }) => {
	const clickHandler = e => {
		if(onClick)	onClick(data ?? e);
	}

	const keyDownHandler = e => {
		if(e.key === 'Enter' || e.key === ' ') clickHandler(e);
	}

	const classes = classNames('Table-row', className, { 'Table-heading': heading });
	if(props.to) {
		return (
			<NavLink role="row" className={ classes } { ...props }>
				{ props.children }
			</NavLink>
		);
	} else {
		return (
			<div role="row" tabIndex={ onClick ? 0 : undefined } className={ classes } onClick={ clickHandler } onKeyDown={ keyDownHandler } { ...props }>
				{ props.children }
			</div>
		);
	}
};

export const Col = ({ className, w, ...props }) => {
	const classes = classNames('Table-col', className);
	const style = w != null ? { flex: `0 0 ${ w }`, maxWidth: `${ w }` } : undefined;
	return (
		<div role="columnheader" className={ classes } style={ style } { ...props }>
			{ props.children }
		</div>
	);
};

export const Cell = ({ className, w, ...props }) => {
	const classes = classNames('Table-cell', className);
	const style = w != null ? { flex: `0 0 ${ w }`, maxWidth: `${ w }` } : undefined;
	return (
		<div role="cell" className={ classes } style={ style } { ...props }>
			{ props.children }
		</div>
	);
};