import React from 'react';
import { IconButton } from '../components/buttons';
import { Checkbox } from '../components/controls';
import { Pane, PaneHeader, PaneBody, PaneFooter, ItemPaneFooter, PaneItem, PaneItemButton } from '../components/Pane';

const Pane3 = props => {

	const renderInnerPane = () => {
		return (
			<Pane id="pane3_1">
				<PaneHeader>Pane header</PaneHeader>
				<PaneBody tag="ul" className="scrolling Pane-items Pane-items_p0 Pane-items_indexed">
					<PaneItem>
						<div className="col-auto">1</div>
						<div className="col">One</div>
						<PaneItemButton icon="settings"/>
					</PaneItem>
					<PaneItem>
						<div className="col-auto">2</div>
						<div className="col">Two</div>
						<PaneItemButton icon="settings"/>
					</PaneItem>
					<PaneItem>
						<div className="col-auto">3</div>
						<div className="col">Three</div>
						<PaneItemButton icon="settings"/>
					</PaneItem>
					<PaneItem>
						<div className="col-auto">4</div>
						<div className="col">Four</div>
						<PaneItemButton icon="settings"/>
					</PaneItem>
					<PaneItem>
						<div className="col-auto">5</div>
						<div className="col">Five</div>
						<PaneItemButton icon="settings"/>
					</PaneItem>
				</PaneBody>
				<ItemPaneFooter shiftData={ [3, 0] }>
					<IconButton icon="add"/>
				</ItemPaneFooter>
			</Pane>
		);
	}

	return (
		<Pane id="pane3">
			<PaneHeader>Pane header</PaneHeader>
			<PaneBody className="container">
				{ renderInnerPane() }
			</PaneBody>
			<PaneFooter className="toolbar">
				<IconButton icon="add"/>
				<Checkbox labelFirst>Is this a checkbox?</Checkbox>
			</PaneFooter>
		</Pane>
	);
}

export default Pane3;