// When using the options props, it is the implementer's responsibility to make sure the type of props.value matches props.options.value
// Currently, if just children is supplied, this is assumed to be a collection of option tags -
// but if children AND options are supplied, the latter is used to build the options and the former is used to build a custom button (cf LanguagePicker)
import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './DropdownButton.scss';

const DropdownButton = props => {

	const labelRef = useRef();
	const [open, setOpen] = useState(false);

	// if(props.placeholder === 'Choose Menu') {
	// 	console.log(props);
	// }

	const getLabel = value => {
		// if an option should be selected, get its label to display
		if(typeof value === 'undefined') return '';
		if(props.options) {
			if(typeof props.options[0] === 'object') {
				const option = props.options.find(option => {
					return option[props.keys ?? 'value'] === value;
				});
				return (option) ? option[props.labels ?? 'label'] : '';
			} else {
				return value;
			}
		} else if(props.children) {
			value = value.toString();
			const children = Array.isArray(props.children) ? props.children : [props.children];	// we need an array, even if only 1 option
			const option = children.find(option => {
				return option.props.value === value;
			});
			return (option && option.props.children) ? option.props.children : '';
		}
	}

	// remember: == null is also true when lh undefined, ah js
	let label = props.value == null ? props.placeholder : getLabel(props.value);

	const toggle = e => {
		e.preventDefault();
		setOpen(!open);
	}

	const selectionHandler = e => {
		let value;
		toggle(e);
		const target = e.target;
		if(target.hasAttribute('data-value')) {
			value = target.getAttribute('data-value');
			label = target.textContent;
		} else {
			value = undefined;
			label = props.placeholder;
		}
		if(props.onChange) props.onChange(e, props.name ? props.name : props.id, value, label);
	}
	
	const keyDownHandler = e => {
		if(e.key.length === 1) {
			// search for matching entry
			const key = e.key.toLowerCase();
			let option;
			let ix;
			if(props.options) {
				if(typeof props.options[0] === 'object') {
					const label = [props.labels ?? 'label'];
					ix = props.options.findIndex(option => {
						return option[label].toLowerCase().startsWith(key);
					});
					option = props.options[ix];
				} else {
					ix = props.options.findIndex(option => {
						return option.toLowerCase().startsWith(key);
					});
				}
			} else {
				// html options passed in
				ix = props.children?.findIndex(option => {
					const label = option.props.children;
					return label?.toLowerCase().startsWith(key);
				});
			}
			if(ix === -1) return;
			const menu = labelRef.current.parentNode.nextSibling.childNodes;
			option = menu[ix];
			if(option) option.focus();
		} else {
			switch(e.key) {
				case 'ArrowUp':
				case 'ArrowDown':
					const menu = labelRef.current.parentNode.nextSibling.childNodes;
					const option = menu[0];
					if(option) option.focus();
					break;
				case 'Escape': if(open) toggle(e); break;
				default: break;
			}
		}
	}

	const renderOption = (option, ix) => {
		let val, lbl;
		if(option.type && option.type === 'option') {
			// html options passed in
			val = option.props.value;
			lbl = option.props.children;
		} else if(typeof option === 'object') {
			val = option[props.keys ?? 'value'];
			lbl = option[props.labels ?? 'label'];
		} else {
			val = option;
			lbl = option;
		}
		// eslint-disable-next-line eqeqeq
		return <DropdownItem tag="li" key={ ix } active={ typeof val !== 'undefined' && val == props.value } data-value={ val } onClick={ selectionHandler }>{ lbl }</DropdownItem>
}

	const renderOptions = options => {
		if(Array.isArray(options)) {
			return options.map((option, ix) => {
				return renderOption(option, ix);
			});
		} else {
			// need to handle differently if only one option provided
			return renderOption(options);
		}
	}

	const classes = classNames('DropdownButton', props.className);
	const spanClassName = (!props.value && props.value !== 0) ? 'placeholder' : '';
	return (
		<Dropdown className={ classes } isOpen={ open } toggle={ toggle }>
			<DropdownToggle
				id={ props.id }
				name={ props.name }
				title={ props.title }
				className="form-control form-control-btn"
				disabled={ props.disabled }
				tag="button"
				data-toggle="dropdown"
				aria-expanded={ open }
				// onClick={ toggle }	// why is this here? should only be on Dropdown??
				onKeyDown={ keyDownHandler }
			>
				{ (props.options && props.children)
						? props.children
						:
							<Fragment>
								<span ref={ labelRef } className={ spanClassName }>{ label }</span>
								<i className="material-icons">{ open ? 'expand_less' : 'expand_more' }</i>
							</Fragment>
				}
			</DropdownToggle>
			<DropdownMenu tag="ul" className="dropdown-menu_scroll" right={ props.right }>
				{ props.options ? renderOptions(props.options) : ( props.children ? renderOptions(props.children) : '') }
				{/* { props.children &&
					renderOptions(props.children)
				}
				{ props.options &&
					renderOptions(props.options)
				} */}
			</DropdownMenu>
		</Dropdown>
	);

};

DropdownButton.propTypes = {
	className: PropTypes.string,	// add a custom class to the component's own
	placeholder: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	keys: PropTypes.string,
	labels: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func
};

export default DropdownButton;