import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { IconButton } from '../components/buttons';
import { Pane, PaneHeader, PaneBody } from '../components/Pane';
import { venuesActionCreators, actionTypes } from '../store/venues';
import VenueEditorForm from './VenueEditorForm';
import WaitStaffForm from './WaitStaffForm';

const About = props => {

	const dispatch = useDispatch();
	const venueStore = useSelector(state => state.venues);

	const saveClickHandler = e => {
		// the save button is actually part of VenueEditorForm - find it and click it
		const realSaveButton = document.getElementById('inlineVenueEditorSaveButton');
		if(realSaveButton) realSaveButton.click();
	}

	useEffect(() => {
		// fetch ref data just once
		dispatch(venuesActionCreators.fetchCuisineTypes());
	}, [dispatch]);

	const updateVenueSize = venueSize => {
		// console.log(venueSize);
		dispatch(venuesActionCreators.updateVenueSize(venueSize));
	}

	const updateVenue = venue => {
		// console.log(venue, mapPosition);
		dispatch(venuesActionCreators.updateVenue(venue));
	}

	const loading = venueStore.loading && venueStore.loading === actionTypes.fetchVenueRequest;
	return (
		<div className="row animated fadeIn">

			<div className="col">

				<Pane id="about">
					<PaneHeader>
						<h1 className={ classNames({ async: loading })}>{ venueStore.venue?.name || 'About' }</h1>
						<div className="Pane-toolbar">
							<IconButton
								title="save venue info"
								async={ venueStore.loading === actionTypes.updateVenueRequest }
								icon="save"
								onClick={ saveClickHandler }
							/>
						</div>
					</PaneHeader>
					<PaneBody className="container">

						<VenueEditorForm
							id="inlineVenueEditor"
							venue={ venueStore.venue }
							cuisineTypes={ venueStore.cuisineTypes }
							columnClass="col-md-6"
							saveVenue={ updateVenue }
						/>

					</PaneBody>
				</Pane>

				<WaitStaffForm
					venue={ venueStore.venue }
					updateVenueSize={ updateVenueSize }
					/>

			</div>

		</div>
	);

};

export default About;