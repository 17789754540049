// import { utcToZonedTime } from "date-fns-tz";
import dates from "./dates";

export class VenueStatus {	// if I ever seriously start using the models folder, move this there
	isOrderingEnabled = false;
	isOpen = false;
	isForcedOpen = false;
	until = null;
}

export class OpeningTimes {	// if I ever seriously start using the models folder, move this there
	open1 = false;	// default is always closed, set open1 to true to make the default open 24/7
	from1 = null;
	to1 = null;
	open2 = false;
	from2 = null;
	to2 = null;
	open24h = null;
}

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const formatTime = time => {
	// converts XXXX to XX:XX
	return time.slice(0, 2) + ':' + time.slice(2);
}

const formatTimeForPayload = time => {
	// converts XX:XX to XXXX with added validation
	if(!validateTime(time)) throw new Error('INVALID_TIME');
	return time.slice(0, 2) + time.slice(3);
}

const validateTime = time => {
	return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

const isValidOpenClose = (open, close) => {
	if(open && close) {
		const from = parseInt(open.slice(0, 2), 10) + parseInt(open.slice(3, 5), 10) / 60;
		const to = parseInt(close.slice(0, 2), 10) + parseInt(close.slice(3, 5), 10) / 60;
		if(to && from >= to) {
			return false;
		}
	}
	return true;
}

const getNewSchedule = () => {
	return [...Array(7)].map((el, ix) => {
		return new OpeningTimes();
	});
}

const populateShedule = structuredFormat => {
	const schedule = getNewSchedule();
	schedule.forEach((day, ix) => {
		const times = structuredFormat[days[ix]];
		if(times !== 'closed') {
			if(times !== '24h') {
				day.open1 = true;
				let time = times[0].split('-');
				day.from1 = formatTime(time[0]);
				if(time.length > 1) day.to1 = formatTime(time[1]);
				if(times.length > 1) {
					day.open2 = true;
					time = times[1].split('-');
					day.from2 = formatTime(time[0]);
					if(time.length > 1) day.to2 = formatTime(time[1]);
				}
			} else {
				day.open24h = true;
			}
		}
	});
	return schedule;
}

const alwaysClosed = { Mon: 'closed', Tue: 'closed', Wed: 'closed', Thu: 'closed', Fri: 'closed', Sat: 'closed', Sun: 'closed' };
const alwaysOpen = { Mon: '24h', Tue: '24h', Wed: '24h', Thu: '24h', Fri: '24h', Sat: '24h', Sun: '24h' };

const toTimeString = number => {
	// we should probably apply l10n to this - if so, we should probably return the fraction and let the caller deal with it (like the day part)
	const hour = ('0' + Math.floor(number)).slice(-2);
	const mins = ('0' + Math.floor((number - hour) * 60)).slice(-2);
	return hour + ':' + mins;
}

const getDay = (day, dayOfWeek) => {
	return (day - dayOfWeek) === 1 || (day - dayOfWeek) === -6 ? -1 : day;
}

const getVenueStatus = venue => {
	const tzDate = dates.getTzDate(venue.openingHours?.timezone?.timezone);
	// const tzDate = new Date(2022, 3, 16, 12);	// Saturday midday
	// console.log(tzDate);	// test data - remove
	// venue.orderingEnabled = true;	// test data - remove
	return _getVenueStatus(venue, tzDate);
}

const _getVenueStatus = (venue, tzDate) => {
	// testable version of getVenueStatus
	const venueStatus = new VenueStatus();
	// there are many quick checks we can do...
	if(!venue) return venueStatus;
	venueStatus.isOrderingEnabled = venue.orderingEnabled;
	if(!venue.orderingEnabled) return venueStatus;
	if(!venue.openingHours) return venueStatus;
	if(venue.openingHours.forceOpen) {
		venueStatus.isOpen = true;
		venueStatus.isForcedOpen = true;
		return venueStatus;
	}
	const venueOpeningTimes = venue.openingHours.structuredFormat;
	if(Object.keys(venueOpeningTimes).every(key => venueOpeningTimes[key] === alwaysOpen[key])) {
		venueStatus.isOpen = true;
		return venueStatus;
	}
	if(Object.keys(venueOpeningTimes).every(key => venueOpeningTimes[key] === alwaysClosed[key])) {
		venueStatus.isOpen = false;
		return venueStatus;
	}
	// if we got this far, we need more in-depth checks...
	const timeZone = venue.openingHours.timezone.timezone;
	// const tzDate = dates.getTzDate(timeZone);
	const nowAsFraction = tzDate.getHours() + tzDate.getMinutes() / 60;
	const dayOfWeek = dates.getTzDayOfWeek(tzDate, timeZone);
	const cutMinutes = venue.openingHours.acceptsOrdersMinBeforeClosing / 60 || 0;
	let findNext = false;
	for(let i = 0; i < 7; i++) {
		const day = (i + dayOfWeek) % 7;
		const times = venueOpeningTimes[days[day]];
		switch(times) {
			case '24h': {
				if(day === dayOfWeek) {
					venueStatus.isOpen = true;
					findNext = true;
				} else {
					if(findNext && !venueStatus.isOpen) {
						venueStatus.until = [getDay(day, dayOfWeek), '00:00'];	// -1 = "tomorrow"
					}
				}
				break;
			}
			case 'closed': {
				if(day === dayOfWeek) {
					venueStatus.isOpen = false;
					findNext = true;
				} else {
					if(findNext && venueStatus.isOpen) {
						venueStatus.until = [getDay(day, dayOfWeek), '00:00'];
					}
				}
				break;
			}
			default: {
				times.some((timeSetting, ix) => {
					const ts = timeSetting.split('-');
					const start = parseInt(ts[0].slice(0, 2), 10) + parseInt(ts[0].slice(2, 4), 10) / 60;
					let end = parseInt(ts[1].slice(0, 2), 10) + parseInt(ts[1].slice(2, 4), 10) / 60 || 24;	// currently, we don't allow a closing time after midnight
					end -= cutMinutes;
					if(day === dayOfWeek) {
						if(nowAsFraction > start && nowAsFraction < end) {
							venueStatus.isOpen = true;
							venueStatus.until = toTimeString(end);
							return true;
						}
						if(nowAsFraction < start) {
							venueStatus.until = toTimeString(start);
							return true;
						}
					} else {
						if(venueStatus.isOpen) {
							venueStatus.until = [getDay(day, dayOfWeek), start ? '00:00' : toTimeString(end)];
						} else {
							venueStatus.until = [getDay(day, dayOfWeek), toTimeString(start)];
						}
						return true;
					}
					return false;
				});
			}
		}
		if(venueStatus.until) break;
	}
	return venueStatus;
}

export default {
	validateTime,
	isValidOpenClose,
	formatTimeForPayload,
	getNewSchedule,
	populateShedule,
	_getVenueStatus,	// exposed only for tests - maybe think about changing to test VenueStatusControl instead?
	getVenueStatus,
	days
}