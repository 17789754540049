import React from 'react';
import SadFace from './components/SadFace';
import FlyoutPage from './layout/FlyoutPage';

// TODO need a reusable design for error pages
// we'll need to be careful how to display 404s for non-logged-in users - don't want to reveal too much
// no signInOutHandler is passed to FlyoutPage here, which means the primary nav SIGN IN / SIGN OUT option
// is not shown to keep this page simple

const Page404 = props => {
	return (
		<FlyoutPage>

			<div className="container-fluid error-page">
				<header className="pageHeader pageHeader_centred">
					<h1>
						404 NOT FOUND
					</h1>
				</header>
				<main>
					<SadFace style={{ margin: 'auto' }}/>
				</main>
			</div>

		</FlyoutPage>
	);
};

export default Page404;