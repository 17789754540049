
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tagsActionCreators } from '../store/tags';
import utilities from '../services/utilities';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import CategoryAddEditDialog from './dialogs/CategoryAddEditDialog';
import TagEditDialog from './dialogs/TagEditDialog';
import TagAddDialog from './dialogs/TagAddDialog';
import TagCategories from './TagCategories';
import TagsForm from './TagsForm';

const TagsContainer = props => {
	const [currentCategory, setCurrentCategory] = useState();
	const [showCategoryAddEditDialog, setShowCategoryAddEditDialog] = useState(false);
	const [showCategoryConfirmDialog, setShowCategoryConfirmDialog] = useState(false);
	const categoryText = utilities.getText('TXT_CATEGORY');
	const [currentCommonTagId, setCurrentCommonTagId] = useState();
	const [showTagAddDialog, setShowTagAddDialog] = useState(false);
	const tagText = utilities.getText('TXT_TAG');
	const [showTagConfirmDialog, setShowTagConfirmDialog] = useState(false);
	const [showTagEditDialog, setShowTagEditDialog] = useState(false);
	const [currentTag, setCurrentTag] = useState();
	const dispatch = useDispatch();
	const tagStore = useSelector(state => state.tags);
	const commonTags = tagStore.commonTags;

	useEffect(() => {
		dispatch(tagsActionCreators.fetchTags());
	}, [dispatch]);

	useEffect(() => {
		if(currentCategory) {
			// if the tagStore has updated, currentCategory will be stale so...
			const category = tagStore.commonTags.find(category => category.categoryId === currentCategory.categoryId);
			setCurrentCategory(category);
		}
	}, [currentCategory, tagStore.commonTags]);
	
	const categorySelectHandler = category => {
		setCurrentCategory(category);
	}

	const shiftCategory = isUp => {
		dispatch(tagsActionCreators.shiftCategory(currentCategory.categoryId, isUp));
	}

	const addCategoryClickHandler = e => {
		setCurrentCategory();
		setShowCategoryAddEditDialog(true);
	}

	const editClickHandler = e => {
		setShowCategoryAddEditDialog(true);
	}

	const hideCategoryAddEditDialog = payload => {
		setShowCategoryAddEditDialog(false);
		if(payload.categoryName) {
			if(currentCategory) {
				dispatch(tagsActionCreators.renameCategory(payload));
			} else {
				dispatch(tagsActionCreators.addCategory(payload));
			}
		}
	}

	const deleteCategoryHandler = e => {
		setShowCategoryConfirmDialog(true);
	}

	const hideConfirmDialogHandler = response => {
		if(response.action === 'category') {
			if(response.button === 'btnOk') {
				dispatch(tagsActionCreators.removeCategory(currentCategory));
			}
			setShowCategoryConfirmDialog(false);
		}
		if(response.action === 'tag') {
			if(response.button === 'btnOk') {
				dispatch(tagsActionCreators.removeCommonTag(currentCategory.categoryId, currentCommonTagId));
			}
			setShowTagConfirmDialog(false);
		}
	}

	const hideTagAddDialog = tag => {
		setShowTagAddDialog(false);
		if(tag.tagName) {
			const payload = { ...tag };
			payload.categoryId = currentCategory.categoryId;
			dispatch(tagsActionCreators.addCommonTag(payload));
		}
	}

	const addCommonTag = tag => {
		setShowTagAddDialog(true);
	}

	const removeCommonTag = tagId => {
		setCurrentCommonTagId(tagId);
		setShowTagConfirmDialog(true);
	}

	const shiftCommonTag = (tagId, isUp) => {
		dispatch(tagsActionCreators.shiftCommonTag(currentCategory, tagId, isUp));
	}

	const editTagClickHandler = tag => {
		setCurrentTag(tag);
		setShowTagEditDialog(true);
	}

	const hideTagEditDialog = payload => {
		setShowTagEditDialog(false);
		if(payload.tagName) {
			dispatch(tagsActionCreators.renameTag(payload));
		}
	}

	const deleteTag = tagId => {
		console.log(tagId);
		dispatch(tagsActionCreators.deleteTag(tagId));
	}

	return (
		<div className="animated fadeIn">

			<TagsForm
				tags={ tagStore.tags }
				onEditTag={ editTagClickHandler }
				deleteTag={ deleteTag }
			/>

			<TagCategories
				commonTags={ commonTags }
				loading={ tagStore.loading }
				category={ currentCategory }
				addCommonTag={ addCommonTag }
				removeCommonTag={ removeCommonTag }
				shiftCategory={ shiftCategory }
				shiftCommonTag={ shiftCommonTag }
				onCategorySelect={ categorySelectHandler }
				onAddCategoryClick={ addCategoryClickHandler }
				onEditCategory={ editClickHandler }
				onDeleteCategory={ deleteCategoryHandler }
			/>

			<CategoryAddEditDialog
				category={ currentCategory }
				show={ showCategoryAddEditDialog }
				onHide={ hideCategoryAddEditDialog }
			/>

			<ConfirmDialog
				action="category"
				heading={ utilities.getText('HDG_CONFIRM_DELETE', categoryText) }
				message={ utilities.getText('TXT_CONFIRM_DELETE', categoryText) }
				show={ showCategoryConfirmDialog }
				onHide={ hideConfirmDialogHandler }
			/>

			<ConfirmDialog
				action="tag"
				heading={ utilities.getText('HDG_CONFIRM_REMOVE', tagText) }
				message={ utilities.getText('TXT_CONFIRM_REMOVE', tagText) }
				show={ showTagConfirmDialog }
				onHide={ hideConfirmDialogHandler }
			/>

			<TagAddDialog
				show={ showTagAddDialog }
				onHide={ hideTagAddDialog }
			/>

			<TagEditDialog
				tag={ currentTag }
				show={ showTagEditDialog }
				onHide={ hideTagEditDialog }
			/>

		</div>
	);
};

export default TagsContainer;