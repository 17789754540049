import React from 'react';
import Content from '../layout/Content';
import About from './About';
import Branding from './Branding';
import OpeningHours from './OpeningHours';
import Owner from './Owner';
import Wifi from './Wifi';
import './index.scss';

const Locations = props => {

	const nav = [
		{ path: 'about', label: 'About', component: About },
		{ path: 'branding', label: 'Branding', component: Branding },
		{ path: 'opening-hours', label: 'Opening Hours', component: OpeningHours },
		{ path: 'owner', label: 'Owner', component: Owner },
		{ path: 'wifi', label: 'WiFi', component: Wifi }
	];

	return (
		<Content path="/venues/locations/" nav={ nav }/>
	);

}

export default Locations;