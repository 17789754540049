import React, { useEffect, useRef } from 'react';
import utilities from '../../services/utilities';
import useForm from '../../components/Form';
import { FormInput, FormInputWithButton, FormRadio } from '../../components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../components/Modal';
import ValidationMessage from '../../components/ValidationMessage';

const ExtraChargeDialog = props => {

	const validationRules = {
		dscr: true,
		amount: true,
		type: true,
		salesTaxCode: props.venue?.menuPricesIncludeVAT ? false : true
	}

	const formSave = () => {
		const item = {
			dscr: form.data.dscr,
			formula: form.data.type + form.data.amount,
			salesTaxCode: form.data.salesTaxCode
		};
		if(form.data.itemId) item.itemId = form.data.itemId;
		props.onHide(item);
	}

	const form = useForm(formSave, validationRules, new ExtraCharge());
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show) {
			// on showing the Dialog, populate the form data either with the selected item (editing) or empty data (adding new)
			if(props.item) {
				const negative = props.item.formula.charAt(1) === '-';
				const ec = new ExtraCharge();
				ec.itemId = props.item.itemId;
				ec.dscr = props.item.dscr;
				ec.salesTaxCode = props.item.salesTaxCode;
				ec.amount = (negative ? '-' : '') + props.item.formula.substr(negative ? 2 : 1);
				ec.type = props.item.formula.charAt(0);
				formRef.current.reset(ec);
				console.log(ec);
			} else {
				formRef.current.reset(new ExtraCharge());
			}
		}
	}, [props.show, props.item]);

	useEffect(() => {
		if(props.salesTaxCode) {
			formRef.current.onChange(null, 'salesTaxCode', props.salesTaxCode);
		}
	}, [props.salesTaxCode]);

	// if(!props.item) return null;
	const heading = utilities.getText(props.item ? 'HDG_EDIT_EXTRA_CHARGE' : 'HDG_NEW_EXTRA_CHARGE');
	return (
		<Modal id="extraChargeDialog" show={ props.show } draggable={ true }>
			<ModalHeader>{ heading }</ModalHeader>

			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<div className="form-row">
					<div className="col">
						<FormInput
							placeholder={ utilities.getText('PLH_ITEM_DESC') }
							form={ form }
							field="dscr"
							errors={ form.errors }
							onChange={ form.onChange }
						/>
					</div>
					{ !props.venue?.menuPricesIncludeVAT &&
						<div className="col">
							<FormInputWithButton
								placeholder={ utilities.getText('PLH_SALES_TAX_CODE') }
								icon="more_horiz"
								form={ form }
								field="salesTaxCode"
								onButtonClick={ props.onSalesTaxButtonClick }
							/>
						</div>
					}
				</div>

				<div className="form-row">
					<div className="col">
						<FormInput type="money" placeholder={ utilities.getText('PLH_AMOUNT') } form={ form } field="amount"/>
					</div>

					<div className="col">
						<FormRadio form={ form } field="type" value="F">
							{ utilities.getText('LBL_FIXED_AMOUNT', props.venue?.currencySymbol) }
						</FormRadio>
						<FormRadio form={ form } field="type" value="I">
							{ utilities.getText('LBL_PERCENTAGE_OF_TOTAL') }
						</FormRadio>
					</div>

				</div>
				<em>{ utilities.getText('TXT_NEG_4_DISCOUNTS') }</em>

			</ModalBody>

			<ModalFooter className="Modal-footer_buttons">
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">Cancel</button>
				<button className="btn btn-primary" onClick={ form.onSave } id="btnOk">OK</button>
			</ModalFooter>

		</Modal>
	);
};

export default ExtraChargeDialog;

class ExtraCharge {
	dscr;
	amount;
	type;
	salesTaxCode;
}