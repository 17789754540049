import { forwardRef, Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import intervalToDuration from 'date-fns/intervalToDuration';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import dates from 'services/dates';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import './Orders.scss';

const Orders = (props, ref) => {
	const [now, setNow] = useState(Date.now());
	const [today, setToday] = useState();
	const [fontSize, setFontSize] = useState(13);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);

	const _t = useL10n().getText;
	const getDistributionType = useL10n().getDistributionType;

	useEffect(() => {
		// set today to be today in appropriate TZ
		if(props.timeZone) {
			const today = dates.getLocalToday(props.timeZone);
			setToday(today);
		}
	}, [props.timeZone]);

	useEffect(() => {
		// console.log('useEffect 1');
		// 10 sec. ticker so that elapsed time indicators can update
		const id = setInterval(() => {
			setNow(Date.now());
		}, 10000);
		return () => clearInterval(id);
	}, []);

	useEffect(() => {
		// console.log('useEffect 2');
		// update fontSize once transition completes (0.5sec to match $transition-slow)
		const id = setTimeout(() => {
			const fontSize = Math.round(ref.current ? (ref.current.clientWidth / props.form.data.columns / 20) : 13);
			setFontSize(fontSize);
		}, 500);

		return () => clearTimeout(id);
	// shouldn't run on props.form.data.columns changes (handled by useEffect 3) so...
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.fullscreen, ref]);

	useEffect(() => {
		// console.log('useEffect 3');
		// if window height changes, window width will more than likely change too, so we need to recalc fontSize
		const fontSize = Math.round(ref.current ? (ref.current.clientWidth / props.form.data.columns / 20) : 13);
		setFontSize(fontSize);
	}, [windowHeight, props.form.data.columns, ref]);

	useEffect(() => {
		// console.log('useEffect 4');
		window.addEventListener('resize', () => setWindowHeight(window.innerHeight));
		return () => {
			window.removeEventListener('resize', () => setWindowHeight(window.innerHeight));
		};
	}, []);

	useEffect(() => {
		// safari doesn't always update when a user changes the number of columns - this will force it
		setNow(Date.now());
	}, [props.form.data.columns]);

	const elapsedTime = timestamp => {
		const duration = intervalToDuration({ start: now, end: timestamp });
		if(now - timestamp < 0) return '0:00:00';	// in case of slight timing issues between server and client
		return duration.hours + ':' + ('0' + duration.minutes).slice(-2) + ':' + ('0' + duration.seconds).slice(-2);
	}

	const calcContainerHeight = () => {
		const furnitureHeight = props.fullscreen ? 50 : 52 + 50 + 80 + 50;
		const containerHeight = window.innerHeight - furnitureHeight;
		return containerHeight;
	}

	const renderLines = () => {
		return [...Array(400)].map((x, ix) => {
			return <div key={ ix } className="order-line">line { ix }</div>
		});
	}

	const renderOrderId = orderId => {
		const parts = orderId.split('-');
		if(parts.length === 1) {
			return <div>{ orderId }</div>;
		} else {
			return (
				<Fragment>
				<div>{ parts[1] }</div>
				<div>{ orderId }</div>
				</Fragment>
			);
		}
	}

	const renderModifierItems = items => {
		return items.map((item, ix) => {
			return <div key={ ix } className="order-item_modifier">– { item.name }</div>;
		});
	}

	const renderOrderItems = items => {
		return items.map((item, ix) => {
			return <div key={ ix } className="order-item">
				<div>{ item.qty }</div>
				<div>
					{ item.name }
					{ renderModifierItems(item.modifierItems) }
				</div>
			</div>
		});
	}

	const renderDateTime = timestamp => {
		const date = new Date(timestamp);
		if(timestamp > today?.getTime()) {
			// if this order is today, no need to show the date, just the time
			return <div>{ dates.format(date, dates.styles.TIME, props.timeZone) }</div>
		} else {
			return <Fragment>
					<div>{ dates.format(date) }</div>
					<div>{ dates.format(date, dates.styles.TIME, props.timeZone) }</div>
				</Fragment>
		}
	}

	const renderOrder = (order, ix, groupingType) => {
		const age = differenceInMinutes(new Date(), order.timestamp);
		const rush = age >= props.form.data.rush;
		const priority = !rush && age >= props.form.data.priority;
		const containerClasses = classNames('order-container',
			{ 'order-grouped': order.grouped },
			{ 'order-grouped_first': order.grouped && groupingType === 0 },
			{ 'order-grouped_middle': order.grouped && groupingType === 1 },
			{ 'order-grouped_last': order.grouped && groupingType === 2 },
		);
		const orderClasses = classNames('order', { 'order_rush': rush }, { 'order_priority': priority });
		const tearClasses = classNames('order-tear', { 'order-tear_rush': rush }, { 'order-tear_priority': priority });
		return (
			<Fragment key={ ix }>
				<div className={ containerClasses }>
					{ groupingType > 0 &&
						<div className={ tearClasses }/>
					}
					<div className={ orderClasses }>
						<div className="order-header">
							<div className="order-number">
								{ renderOrderId(order.externalOrderId) }
							</div>
							<div>
								<div>{ order.name }</div>
								{ renderDateTime(order.timestamp) }
								<div>{ utilities.formatPrice(order.total, props.currencySymbol) }</div>
							</div>
							<div>
								<div className="order-dist">{ getDistributionType(order) }</div>
								<div className="order_lapsed">{ elapsedTime(order.timestamp) }</div>
								{ rush &&
									<div className="order_lapsed">RUSH</div>
								}
							</div>
						</div>
						{ order.comments &&
							<div className="order-comments">{ order.comments }</div>
						}
						<div className="order-body">
							{ renderOrderItems(order.orderItems) }
						</div>
						{ (groupingType === 2 || !order.grouped) &&
							<div className="order-footer">
								<button className="btn btn-secondary" onClick={ () => props.bumpHandler(ix) }>{ _t('BTN_BUMP', true) }</button>
							</div>
						}
					</div>
					{ (!order.grouped || groupingType === 2) &&
						<div className={ tearClasses }/>
					}
				</div>
		</Fragment>
		);
	}

	const renderOrders = () => {
		const from = props.pages[props.form.data.page - 1].offset;
		const orders = props.orderData?.orders;
		if(!orders) return null;
		let elements = [];
		for(let index = from; index < orders.length; index++) {
			const order = orders[index];
			let groupingType = 0;
			if(order.grouped) {
				// should it be grouped with the previous order?
				if(index &&
						orders[index - 1].distributionType === order.distributionType &&
						orders[index - 1].waitstaffTarget === order.waitstaffTarget &&
						differenceInMinutes(order.timestamp, orders[index - 1].timestamp) <= props.orderGroupingMinutes) {
					groupingType = 1;
				}
				// should it be grouped with the next order?
				if(index < orders.length - 1) {
					if(orders[index + 1].distributionType !== order.distributionType ||
							orders[index + 1].waitstaffTarget !== order.waitstaffTarget ||
							differenceInMinutes(orders[index + 1].timestamp, order.timestamp) > props.orderGroupingMinutes) {
						groupingType = 2;
					}
				} else {
					groupingType = 2;
				}
			}
			if(index >= from) {
				elements.push(renderOrder(order, index, groupingType));
			}
		}
		return elements;
	}

	return (
		<div
			ref={ ref }
			className="orders-container"
			style={{
				columnCount: props.form.data.columns,
				height: calcContainerHeight() + 'px',
				fontSize: fontSize + 'px'
			}}
		>
			{ renderOrders() }
			{ renderLines() }
		</div>
	);
};

export default forwardRef(Orders);