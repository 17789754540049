import React, { useEffect, useRef } from 'react';
import { Fragment } from 'react';
import { IconButton } from '../components/buttons';
import useForm from '../components/Form';
import { FormInput, FormLabel } from '../components/formControls';
import { Pane, PaneBody, PaneHeader } from '../components/Pane';
import RouterPrompt from '../components/RouterPrompt';
import ValidationMessage from '../components/ValidationMessage';

const WaitStaffForm = props => {
	const formSave = () => {
		props.updateVenueSize(form.data);
	}

	const form = useForm(formSave, validationRules);
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.venue?.venueSize) {
			resetRef.current(props.venue.venueSize);
		}
	}, [props.venue]);

	return (
		<Fragment>

			<Pane>
				<PaneHeader>
					<h1>Waitstaff</h1>
					<div className="Pane-toolbar">
						<IconButton
							title="save"
							icon="save"
							// async={ props.loading }
							onClick={ form.onSave }
						/>
					</div>
				</PaneHeader>

				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<div className="row">

						<div className="col-md-4">

							<div className="form-row">
								<FormLabel htmlFor="tables" className="col-4" label="Tables"/>
								<div className="col-8">
									<FormInput type="number" min="0" placeholder="" form={ form } field="numOfTables" id="tables"/>
								</div>
							</div>

							<div className="form-row">
								<FormLabel htmlFor="seats" className="col-4" label="Seats"/>
								<div className="col-8">
									<FormInput type="number" min="0" placeholder="" form={ form } field="numOfChairs" id="seats"/>
								</div>
							</div>

						</div>

						<div className="col-md-8">
							<Pane id="waitstaffDays">
								<PaneHeader className="row no-gutters">

									<div className="col">Mon</div>
									<div className="col">Tue</div>
									<div className="col">Wed</div>
									<div className="col">Thu</div>
									<div className="col">Fri</div>
									<div className="col">Sat</div>
									<div className="col">Sun</div>

								</PaneHeader>
								<PaneBody className="row no-gutters">
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Mon"/>
									</div>
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Tue"/>
									</div>
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Wed"/>
									</div>
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Thu"/>
									</div>
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Fri"/>
									</div>
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Sat"/>
									</div>
									<div className="col">
										<FormInput placeholder="" type="number" min="0" form={ form } field="waitstaff_Sun"/>
									</div>
									
								</PaneBody>
							</Pane>
						</div>

					</div>
				</PaneBody>
			</Pane>

			<RouterPrompt when={ form.isDirty }/>

		</Fragment>
	);
};

export default WaitStaffForm;

const validationRules = {
	numOfTables: true,
	numOfChairs: true,
	waitstaff_Mon: true,
	waitstaff_Tue: true,
	waitstaff_Wed: true,
	waitstaff_Thu: true,
	waitstaff_Fri: true,
	waitstaff_Sat: true,
	waitstaff_Sun: true
};