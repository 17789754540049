import utilities from '../services/utilities';
import storeUtils from './storeUtils';

export const actionTypes = {
	tagsFail: 'TAGS_FAIL',
	
	fetchTagsRequest: 'FETCH_TAGS_TAGS_REQUEST',
	fetchTagsSuccess: 'FETCH_TAGS_TAGS_SUCCESS',

	fetchCommonTagsRequest: 'FETCH_COMMONTAGS_TAGS_REQUEST',
	fetchCommonTagsSuccess: 'FETCH_COMMONTAGS_TAGS_SUCCESS',

	addCategoryRequest: 'ADD_CATEGORY_TAGS_REQUEST',
	addCategorySuccess: 'ADD_CATEGORY_TAGS_SUCCESS',
	renameCategoryRequest: 'RENAME_CATEGORY_TAGS_REQUEST',
	renameCategorySuccess: 'RENAME_CATEGORY_TAGS_SUCCESS',
	removeCategoryRequest: 'REMOVE_CATEGORY_TAGS_REQUEST',
	removeCategorySuccess: 'REMOVE_CATEGORY_TAGS_SUCCESS',
	reorderCategoriesRequest: 'REORDER_CATEGORIES_TAGS_REQUEST',
	reorderCategoriesSuccess: 'REORDER_CATEGORIES_TAGS_SUCCESS',

	addCommonTagRequest: 'ADD_COMMONTAG_TAGS_REQUEST',
	addCommonTagSuccess: 'ADD_COMMONTAG_TAGS_SUCCESS',
	renameCommonTagRequest: 'RENAME_COMMONTAG_TAGS_REQUEST',
	renameCommonTagSuccess: 'RENAME_COMMONTAG_TAGS_SUCCESS',
	removeCommonTagRequest: 'REMOVE_COMMONTAG_TAGS_REQUEST',
	removeCommonTagSuccess: 'REMOVE_COMMONTAG_TAGS_SUCCESS',
	reorderCommonTagsRequest: 'REORDER_COMMONTAGS_TAGS_REQUEST',
	reorderCommonTagsSuccess: 'REORDER_COMMONTAGS_TAGS_SUCCESS',

	renameTagRequest: 'RENAME_TAG_TAGS_REQUEST',
	renameTagSuccess: 'RENAME_TAG_TAGS_SUCCESS',
	deleteTagRequest: 'DELETE_TAG_TAGS_REQUEST',
	deleteTagSuccess: 'DELETE_TAG_TAGS_SUCCESS'
}

const initialState = { tags: null, commonTags: null, loading: null, errors: null };

export const tagsActionCreators = {

	fetchTags: () => async dispatch => {
		dispatch({ type: actionTypes.fetchTagsRequest });
		const data = await storeUtils.execute('tags', 'getTags');
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchTagsSuccess, data: data });
		}
	},

	fetchCommonTags: () => async dispatch => {
		dispatch({ type: actionTypes.fetchCommonTagsRequest });
		const data = await storeUtils.execute('tags', 'getCommonTags');
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchCommonTagsSuccess, commonTags: data.commonTags });
		}
	},

	addCategory: category => async (dispatch, getState) => {
		dispatch({ type: actionTypes.addCategoryRequest });
		category.orderIndex = utilities.getNextOrderIndex(getState().tags.commonTags);
		const data = await storeUtils.execute('tags', 'addCommonTagCategory', category);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.addCategorySuccess, category: data });
		}
	},
	
	renameCategory: category => async dispatch => {
		dispatch({ type: actionTypes.renameCategoryRequest });
		const data = await storeUtils.execute('tags', 'renameCommonTagCategory', category);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.renameCategorySuccess, category: category });
		}
	},
	
	removeCategory: category => async dispatch => {
		dispatch({ type: actionTypes.removeCategoryRequest });
		const data = await storeUtils.execute('tags', 'removeCommonTagCategory', category);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.removeCategorySuccess, category: category });
		}
	},

	shiftCategory: (categoryId, isUp) => async (dispatch, getState) => {
		const categories = getState().tags.commonTags;
		const request = storeUtils.getShiftElementRequest(categories, categoryId, isUp, 'categoryId');
		dispatch({ type: actionTypes.reorderCategoriesRequest });
		const data = await storeUtils.execute('tags', 'reorderCommonTagCategories', request);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderCategoriesSuccess, orderIndexes: request.orderIndexes });
		}
	},

	addCommonTag: commonTag => async (dispatch, getState) => {
		dispatch({ type: actionTypes.addCommonTagRequest });
		const category = getState().tags.commonTags.find(category => category.categoryId === commonTag.categoryId);
		commonTag.orderIndex = utilities.getNextOrderIndex(category.tags);
		const data = await storeUtils.execute('tags', 'addCommonTag', commonTag);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			data.tag.orderIndex = commonTag.orderIndex;
			data.tag.categoryId = commonTag.categoryId;
			dispatch({ type: actionTypes.addCommonTagSuccess, tag: data.tag });
		}
	},
	
	renameCommonTag: commonTag => async dispatch => {
		dispatch({ type: actionTypes.renameCommonTagRequest });
		const data = await storeUtils.execute('tags', 'renameTag', commonTag);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.renameCommonTagSuccess, commonTag: commonTag });
		}
	},
	
	removeCommonTag: (categoryId, tagId) => async dispatch => {
		dispatch({ type: actionTypes.removeCommonTagRequest });
		const data = await storeUtils.execute('tags', 'removeCommonTag', { categoryId: categoryId, tagId: tagId });
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.removeCommonTagSuccess, categoryId: categoryId, tagId: tagId });
		}
	},
	
	shiftCommonTag: (category, tagId, isUp) => async dispatch => {
		const request = storeUtils.getShiftElementRequest(category.tags, tagId, isUp, 'id', 'tagId');
		request.categoryId = category.categoryId;
		dispatch({ type: actionTypes.reorderCommonTagsRequest });
		const data = await storeUtils.execute('tags', 'reorderCommonTags', request);
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderCommonTagsSuccess, category: category, orderIndexes: request.orderIndexes });
		}
	},

	renameTag: tag => async dispatch => {
		dispatch({ type: actionTypes.renameTagRequest });
		const data = await storeUtils.execute('tags', 'renameTag', { tagId: tag.id, tagName: tag.tagName });
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.renameTagSuccess, tag: tag });
		}
	},
	
	deleteTag: tagId => async dispatch => {
		dispatch({ type: actionTypes.deleteTagRequest });
		const data = await storeUtils.execute('tags', 'deleteTag', { tagId: tagId });
		if(data.errors) {
			dispatch({ type: actionTypes.tagsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteTagSuccess, tagId: tagId });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('TAGS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.tagsFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchTagsSuccess: {
			const tags = action.data.tags.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			const commonTags = action.data.commonTags.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			for(const ix in commonTags) {
				commonTags[ix].tags.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			}
			return {
				...state,
				loading: null,
				tags: tags,
				commonTags: commonTags
			}
		}

		case actionTypes.fetchCommonTagsSuccess: {
			const commonTags = action.commonTags.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}
		
		case actionTypes.addCategorySuccess: {
			action.category.tags = [];
			const commonTags = [...state.commonTags, action.category];
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.renameCategorySuccess: {
			const commonTags = state.commonTags.map(category => category.categoryId === action.category.categoryId ? action.category : category);
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.removeCategorySuccess: {
			const commonTags = storeUtils.deleteElement(state.commonTags, action.category.categoryId, 'categoryId');
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.reorderCategoriesSuccess: {
			const commonTags = state.commonTags.map(commonTag => {
				const newCommonTag = { ...commonTag };
				newCommonTag.orderIndex = action.orderIndexes.find(commonTag => commonTag.categoryId === newCommonTag.categoryId).orderIndex;
				return newCommonTag;
			});
			commonTags.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.addCommonTagSuccess: {
			const commonTags = storeUtils.processChildCollection(state.commonTags, 'categoryId', action.tag.categoryId, 'tags', tags => {
				return [...tags, action.tag];
			});
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.removeCommonTagSuccess: {
			const commonTags = storeUtils.processChildCollection(state.commonTags, 'categoryId', action.categoryId, 'tags', tags => {
				return storeUtils.deleteElement(tags, action.tagId, 'id');
			});
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.reorderCommonTagsSuccess: {
			const commonTags = storeUtils.processChildCollection(state.commonTags, 'categoryId', action.category.categoryId, 'tags', tags => {
				const newTags = tags.map(tag => {
					const newTag = { ...tag };
					newTag.orderIndex = action.orderIndexes.find(tag => tag.tagId === newTag.id).orderIndex;
					return newTag;
				});
				return newTags.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			});
			return {
				...state,
				loading: null,
				commonTags: commonTags
			}
		}

		case actionTypes.renameTagSuccess: {
			const tags = state.tags.map(tag => tag.id === action.tag.id ? action.tag : tag);
			return {
				...state,
				loading: null,
				tags: tags
			}
		}

		case actionTypes.deleteTagSuccess: {
			const tags = storeUtils.deleteElement(state.tags, action.tagId, 'id');
			return {
				...state,
				loading: null,
				tags: tags
			}
		}

		default: {
			return state;
		}
	}
}