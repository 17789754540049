import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import dates from 'services/dates';
import useL10n from 'L10n';
import { printersActionCreators } from 'store/printers';
import usePrinterEvents from 'store/PrinterEvents';
import { Checkbox } from 'components/controls';
import { IconButton } from 'components/buttons';
import Notification from 'components/Notification';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import { Row, Cell, Col } from 'components/Table';
import ConfirmDialog from 'dialogs/ConfirmDialog';

const Printers = props => {
	const [showConfirmReconnectDialog, setShowConfirmReconnectDialog] = useState(false);
	const venue = useSelector(state => state.venues.venue);
	const timeZone = venue?.openingHours?.timezone.timezone;
	const printersStore = useSelector(state => state.printers);
	const dispatch = useDispatch();

	const [printJob, setPrintJob] = useState();	// to track printJob thru' delete confirmation path

	const _t = useL10n().getText;
	
	const lostConnectionHandler = lost => {
		setShowConfirmReconnectDialog(lost);
	}

	const printerEvents = usePrinterEvents(lostConnectionHandler);

	const pauseJob = job => {
		const payload = {
			printerId: job.printerId,
			externalOrderId: job.externalOrderId,
			printJobStatus: job.printJobStatus === 'paused' ? 'q' : 'p'
		}
		dispatch(printersActionCreators.updatePrintJob(payload));
	}

	const deleteJob = job => {
		setPrintJob(job);
	}

	const hideConfirmDeleteDialogHandler = response => {
		// console.log(response);
		if(response.button === 'btnOk') {
			const payload = {
				printerId: response.action.printerId,
				externalOrderId: response.action.externalOrderId
			}
			dispatch(printersActionCreators.deletePrintJob(payload));
		}
		setPrintJob();
	}

	const hideConfirmReconnectDialogHandler = response => {
		if(response.button === 'btnOk') {
			// user confirms they want to attempt to reconnect to SSE service
			printerEvents.reset();
		}
		setShowConfirmReconnectDialog(false);
	}

	const printerToggle = printer => {
		const payload = {
			printerId: printer.printerId,
			name: printer.printerName,
			enabled: !printer.enabled
		}
		dispatch(printersActionCreators.updatePrinter(payload));

	}

	return (
		<Fragment>

			<div className="animated fadeIn">

				<Pane className="Printers max-width-800" role="table">
					<PaneHeader className={ classNames({ async: (printersStore.loading?.indexOf('_PRINTER_') ?? -1) !== -1 || printerEvents.isBusy })}>
						<Col w="20%">{ _t('HDG_PRINTER_ENABLED', true) }</Col>
						<Col w="25%">{ _t('HDG_PRINTER_ID', true) }</Col>
						<Col>{ _t('HDG_PRINTER', true) }</Col>
						<Col w="20%" className="text-center">{ _t('HDG_STATUS', true) }</Col>
					</PaneHeader>
					{ !!venue && printerEvents.data && printerEvents.data.printers.length === 0
							? 
								<PaneBody className="container">
									<Notification type="warning">{ _t('WRN_NO_PRINTERS') }</Notification>
								</PaneBody>
							:
								<PaneBody>
									{ printerEvents.data?.printers?.map((printer, ix) => {
											let status = 'status_ok', statusIcon = 'check';
											if(printer.statusDescription?.length) {
												// find the "worst" status to display...
												const maxLevel = printer.statusDescription.reduce((accum, current) => {
													return (current.severity === 'ERROR' && accum < 2) ? 2 : (current.severity === 'WARNING' && accum < 1) ? 1 : accum;
												}, 0);
												switch(maxLevel) {
													case 2: status = 'status_errors'; statusIcon = 'error_outline'; break;
													case 1: status = 'status_warnings'; statusIcon = 'warning_amber'; break;
													default: break;
												}
											}
											if(!printer.enabled && status === 'status_ok') {
												status = '';
												statusIcon = null;
											}
											return (
												<Row key={ ix }>
													<Cell w="20%" className="pv0">
														<Checkbox checked={ printer.enabled } onChange={ () => printerToggle(printer) }/>
													</Cell>
													<Cell w="25%">{ printer.printerId }</Cell>
													<Cell>{ printer.printerName }</Cell>
													<Cell w="20%" className={ classNames('status', 'pv0', status) }>
														<span className="material-icons">{ statusIcon }</span>
													</Cell>
												</Row>
											);
										})
									}
								</PaneBody>
					}
				</Pane>

				{ !!venue && printerEvents.data && printerEvents.data.printers.length > 0 &&
					<Pane id="printJobs" className="max-width-800" role="table">
						<PaneHeader className={ classNames({ async: (printersStore.loading?.indexOf('_PRINTJOB_') ?? -1) !== -1 })}>
							<Col w="20%">{ _t('HDG_PRINTER', true) }</Col>
							<Col w="15%">{ _t('HDG_ORDER', true) }</Col>
							<Col w="20%">{ _t('HDG_JOB_STATUS', true) }</Col>
							<Col>{ _t('HDG_STATUS_TIMESTAMP', true) }</Col>
							<Col w="15%">{ _t('HDG_RETRIES', true) }</Col>
						</PaneHeader>

						<PaneBody>
							{ printerEvents.data?.printJobs?.map((job, ix) => {
									return <Row key={ ix }>
										<Cell w="20%">{ job.printerName }</Cell>
										<Cell w="15%">{ job.externalOrderId }</Cell>
										<Cell w="20%">{ job.printJobStatus }</Cell>
										<Cell>{ dates.format(job.timestamp, dates.styles.DATETIME, timeZone) }</Cell>
										<Cell w="15%">{ job.retryCount }</Cell>
										<IconButton
											title="pause print job"
											icon={ job.printJobStatus === 'paused' ? 'play_arrow' : 'pause' }
											disabled={ job.status === 'awaitingConfirmation' }
											onClick={ () => pauseJob(job) }
										/>
										<IconButton
											title="delete print job"
											icon="delete"
											disabled={ job.status === 'awaitingConfirmation' }
											onClick={ () => deleteJob(job) }
										/>
									</Row>
								})
							}
						</PaneBody>
					</Pane>
				}
			</div>

			<ConfirmDialog
				heading={ _t('HDG_CONFIRM_DELETE', true, _t('TXT_PRINT_JOB')) }
				message={ _t('TXT_CONFIRM_DELETE', false, _t('TXT_PRINT_JOB')) }
				draggable={ true }
				show={ !!printJob }
				action={ printJob }
				onHide={ hideConfirmDeleteDialogHandler }
			/>

			<ConfirmDialog
				heading={ _t('HDG_LOST_CONNECTION', true) }
				message={ <Notification type="warning">{ _t('TXT_LOST_CONNECTION') }</Notification> }
				okText={ _t('BTN_RECONNECT', true) }
				show={ showConfirmReconnectDialog }
				onHide={ hideConfirmReconnectDialogHandler }
			/>

		</Fragment>
	);

};

export default Printers;