import { useState } from 'react';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import utilities from 'services/utilities';
import { Modal, ModalHeader, ModalBody } from 'components/Modal';
import { ItemPaneFooter, PaneBody, PaneItem } from 'components/Pane';
import { IconButton } from 'components/buttons';

const MenusOptionsDialog = props => {

	const [activeTab, setActiveTab] = useState(0);
	const menus = props.menuStore.menus;
	const [shiftMenuId, setShiftMenuId] = useState(0);
	const [ShiftSectionId, setShiftSectionId] = useState();
	let menuShiftData;
	let sectionShiftData;

	const tabSelect = e => {
		let tabIndex = utilities.nodeIndex(e.currentTarget);
		if(activeTab !== tabIndex) setActiveTab(tabIndex);
	}

	const onMenuSelect = id => {
		setShiftMenuId(id);
	}

	const onSectionSelect = id => {
		setShiftSectionId(id);
	}

	const shiftMenu = isUp => {
		props.shiftMenu(shiftMenuId, isUp);
	}

	const shiftSection = isUp => {
		props.shiftSection(ShiftSectionId, isUp);
	}

	const renderMenus = () => {
		if(!menus) return null;
		return menus.map((menu, ix) => {
			if(menu.menuId === shiftMenuId) {
				// this is the current menu, so store data needed for disabling shift buttons
				menuShiftData = [menus.length - 1, ix];
				console.log(menuShiftData);
			}
			return <PaneItem key={ menu.menuId } index={ menu.menuId } selected={ menu.menuId === shiftMenuId } onClick={ onMenuSelect }>
				{ menu.title }
			</PaneItem>;
		});
	}

	const renderMenuSections = () => {
		if(!props.currentMenu) return null;
		return props.currentMenu.menuSections.map((section, ix) => {
			if(section.menuSectionId === ShiftSectionId) {
				// this is the current section, so store data needed for disabling shift buttons
				sectionShiftData = [props.currentMenu.menuSections.length - 1, ix];
			}
			return <PaneItem key={ section.menuSectionId } index={ section.menuSectionId } selected={ section.menuSectionId === ShiftSectionId } onClick={ onSectionSelect }>
				{ section.title }
			</PaneItem>;
		
		});
	}

	return (
		<Modal id="menusOptionsDialog" className="Pane" size="small" show={ props.show }>
			<ModalHeader className="Modal-header_nav">
				<Nav justified>
					<NavItem active={ activeTab === 0 } onClick={ tabSelect }>Reorder Menus</NavItem>
					<NavItem active={ activeTab === 1 } onClick={ tabSelect }>Reorder Menu Sections</NavItem>
				</Nav>
		</ModalHeader>

			<ModalBody className="Modal-body_padding0">
				<TabContent activeTab={ '' + activeTab }>

					<TabPane tabId="0" className="animated fadeIn">
						<PaneBody tab="ul" className="scrolling Pane-items">
							{ renderMenus() }
						</PaneBody>
					</TabPane>

					<TabPane tabId="1" className="animated fadeIn">
						<PaneBody tab="ul" className="scrolling Pane-items">
							{ renderMenuSections() }
						</PaneBody>
					</TabPane>

				</TabContent>
			</ModalBody>

			<ItemPaneFooter shiftData={ activeTab ? sectionShiftData : menuShiftData } shiftElement={ activeTab ? shiftSection : shiftMenu }>
				<IconButton title="close dialog" icon="close" onClick={ props.onHide }/>
			</ItemPaneFooter>
		</Modal>
	);
};

export default MenusOptionsDialog;