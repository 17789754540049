import { useEffect } from 'react';
import useL10n from 'L10n';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'components/Modal';
import VenueEditorForm from './VenueEditorForm';

const VenueEditorDialog = props => {
	const _t = useL10n().getText;

	useEffect(() => {
		if(props.show) {
			props.fetchCuisineTypes();
		}
		// naughty, but we don't care if the fetchCuisineTypes reference changes (should wrap in useCallback one day)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	const saveClickHandler = e => {
		// the save button is actually part of VenueEditorForm - find it and click it
		const realSaveButton = document.getElementById('modalVenueEditorSaveButton');
		if(realSaveButton) realSaveButton.click();
	}

	const saveVenue = (venue, makeSelected) => {
		props.saveVenue(venue, makeSelected);
	}

	return (
		<Modal id="venueEditorDialog" show={ props.show } draggable={ true }>

			<ModalHeader>{ _t('HDG_ADD_VENUE', true) }</ModalHeader>

			<ModalBody>

				{ props.show &&		// prevents the google maps api loading unnecessarily
					<VenueEditorForm
						id="modalVenueEditor"
						cuisineTypes={ props.venueStore.cuisineTypes }
						columnClass="w-100"
						reset={ props.show }
						saveVenue={ saveVenue }
					/>
				}

			</ModalBody>

			<ModalFooter>
				<button type="button" id="btnCancel" className="btn btn-secondary focus" onClick={ props.onHide }>{ _t('BTN_CANCEL', true) }</button>
				<button type="button" id="btnOk" className="btn btn-primary" onClick={ saveClickHandler }>{ _t('BTN_SAVE', true) }</button>
			</ModalFooter>

		</Modal>
	);
};

export default VenueEditorDialog;