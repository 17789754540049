import React from 'react';
import { Rnd } from 'react-rnd';
import classNames from 'classnames';
import { actionTypes } from '../store/pos';
import { IconButton } from '../components/buttons';
import JsonViewer from '../components/JsonViewer';
import { PaneBody, PaneFooter, PaneHeader } from '../components/Pane';

const PosConsole = props => {

	const syncTime = props.posStore.lastSynced ? props.posStore.lastSynced.toLocaleTimeString() : null;
	
	const classes = classNames('Pane', 'Pane_floating', { 'Pane_show' : props.show });
	return (
		<Rnd id="posConsole" minHeight="200" minWidth="320" className={ classes } bounds="window"
			dragHandleClassName='console-handle'
			default={{
				x: props.position.x,
				y: props.position.y,
				width: 500,
				height: 300
			}}
			style={{
				cursor: 'default',
				display: 'flex'
			}}
		>
			<PaneHeader>
				<h1 className="console-handle">POS Console</h1>
				<div className="Pane-toolbar">
					<IconButton
						title="sync"
						async={ props.posStore.loading === actionTypes.syncRequest }
						icon="autorenew"
						onClick={ props.sync }
					/>
					<IconButton title="close" icon="close" onClick={ props.close }/>
				</div>
			</PaneHeader>
			<PaneBody className="scrolling container">
				{ props.posStore.data && <JsonViewer json={ props.posStore.data }/> }
			</PaneBody>
			<PaneFooter>
				{ syncTime && <h1 style={{ flex: 1, textAlign: 'center' }}>last sync executed at { syncTime }</h1> }
			</PaneFooter>
		</Rnd>
	);

};

export default PosConsole;