const states = [
	{
		"display": "Alabama",
		"id": "AL"
	},
	{
		"display": "Alaska",
		"id": "AK"
	},
	{
		"display": "American Samoa",
		"id": "AS"
	},
	{
		"display": "Arizona",
		"id": "AZ"
	},
	{
		"display": "Arkansas",
		"id": "AR"
	},
	{
		"display": "California",
		"id": "CA"
	},
	{
		"display": "Colorado",
		"id": "CO"
	},
	{
		"display": "Connecticut",
		"id": "CT"
	},
	{
		"display": "Delaware",
		"id": "DE"
	},
	{
		"display": "District Of Columbia",
		"id": "DC"
	},
	{
		"display": "Federated States Of Micronesia",
		"id": "FM"
	},
	{
		"display": "Florida",
		"id": "FL"
	},
	{
		"display": "Georgia",
		"id": "GA"
	},
	{
		"display": "Guam",
		"id": "GU"
	},
	{
		"display": "Hawaii",
		"id": "HI"
	},
	{
		"display": "Idaho",
		"id": "ID"
	},
	{
		"display": "Illinois",
		"id": "IL"
	},
	{
		"display": "Indiana",
		"id": "IN"
	},
	{
		"display": "Iowa",
		"id": "IA"
	},
	{
		"display": "Kansas",
		"id": "KS"
	},
	{
		"display": "Kentucky",
		"id": "KY"
	},
	{
		"display": "Louisiana",
		"id": "LA"
	},
	{
		"display": "Maine",
		"id": "ME"
	},
	{
		"display": "Marshall Islands",
		"id": "MH"
	},
	{
		"display": "Maryland",
		"id": "MD"
	},
	{
		"display": "Massachusetts",
		"id": "MA"
	},
	{
		"display": "Michigan",
		"id": "MI"
	},
	{
		"display": "Minnesota",
		"id": "MN"
	},
	{
		"display": "Mississippi",
		"id": "MS"
	},
	{
		"display": "Missouri",
		"id": "MO"
	},
	{
		"display": "Montana",
		"id": "MT"
	},
	{
		"display": "Nebraska",
		"id": "NE"
	},
	{
		"display": "Nevada",
		"id": "NV"
	},
	{
		"display": "New Hampshire",
		"id": "NH"
	},
	{
		"display": "New Jersey",
		"id": "NJ"
	},
	{
		"display": "New Mexico",
		"id": "NM"
	},
	{
		"display": "New York",
		"id": "NY"
	},
	{
		"display": "North Carolina",
		"id": "NC"
	},
	{
		"display": "North Dakota",
		"id": "ND"
	},
	{
		"display": "Northern Mariana Islands",
		"id": "MP"
	},
	{
		"display": "Ohio",
		"id": "OH"
	},
	{
		"display": "Oklahoma",
		"id": "OK"
	},
	{
		"display": "Oregon",
		"id": "OR"
	},
	{
		"display": "Palau",
		"id": "PW"
	},
	{
		"display": "Pennsylvania",
		"id": "PA"
	},
	{
		"display": "Puerto Rico",
		"id": "PR"
	},
	{
		"display": "Rhode Island",
		"id": "RI"
	},
	{
		"display": "South Carolina",
		"id": "SC"
	},
	{
		"display": "South Dakota",
		"id": "SD"
	},
	{
		"display": "Tennessee",
		"id": "TN"
	},
	{
		"display": "Texas",
		"id": "TX"
	},
	{
		"display": "Utah",
		"id": "UT"
	},
	{
		"display": "Vermont",
		"id": "VT"
	},
	{
		"display": "Virgin Islands",
		"id": "VI"
	},
	{
		"display": "Virginia",
		"id": "VA"
	},
	{
		"display": "Washington",
		"id": "WA"
	},
	{
		"display": "West Virginia",
		"id": "WV"
	},
	{
		"display": "Wisconsin",
		"id": "WI"
	},
	{
		"display": "Wyoming",
		"id": "WY"
	}
]

export default states;