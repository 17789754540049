// for expediency, I have not fully localised this page
// - things like "opens tomorrow 12:00" should be a single translated entity with appropriate substitutions
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import useL10n from "L10n";
import openingTimes, { VenueStatus } from "services/openingTimes";
import { actionTypes as billingActionTypes, billingActionCreators } from 'store/billing';
import { actionTypes as venuesActionTypes, venuesActionCreators } from "store/venues";
import { ReactComponent as Led } from 'assets/img/led.svg';
import Feedback from "Orders/settings/Feedback";
import './VenueStatusControl.scss';

const VenueStatusControl = props => {
	const [open, setOpen] = useState(props.open ?? false);
	const [venueStatus, setVenueStatus] = useState(new VenueStatus());
	const venueStore = useSelector(state => state.venues);
	const venue = venueStore.venue;
	const billingStore = useSelector(state => state.billing);
	const translations = useSelector(state => state.l10n.translations);
	const dispatch = useDispatch();

	const _t = useL10n().getText;

	useEffect(() => {
		if(venue) {
			setVenueStatus(openingTimes.getVenueStatus(venue));
			// console.log(venue.openingHours, venueStatus);
		}
	}, [venue]);

	// an early attempt at hiding the menu if enableOrdering chosen - would need more complicated state tracking
	// useEffect(() => {
	// 	if(venue?.orderingEnabled && !billingStore.loading && open) {
	// 		console.log(venue, venueStatus);
	// 		// looks like we don't need to show the user anything so we can automatically close the control...
	// 		// setOpen(false);
	// 	}
	// }, [venue, billingStore.loading, open]);

	const toggle = e => {
		e.preventDefault();
		if(!venueStatus.isOrderingEnabled && !open) {
			dispatch(billingActionCreators.enableOrdering());
		} else {
			if(props.onClose) props.onClose();	// do we need this???
		}
		setOpen(!open);
	}

	const selectionHandler = e => {
		const action = e.target.getAttribute('data-action');
		// console.log(action);
		switch(action) {
			case 'disableOrdering': dispatch(billingActionCreators.disableOrdering()); break;
			case 'forceOpen': dispatch(venuesActionCreators.forceOpen()); break;
			case 'scheduledHours': dispatch(venuesActionCreators.cancelForceOpen()); break;
			default: break;
		}
	}

	const renderUntil = until => {
		if(Array.isArray(until)) {
			const day = until[0];
			return (day === -1 ? _t('TXT_TOMORROW') : translations?.localeData.daysLong[day]) + ' ' + until[1];
		} else {
			return until;
		}
	}

	const renderStatusMessages = () => {
		if(!venue) return <h1>{ _t('HDG_NO_VENUE_SELECTED') }</h1>
		if(venueStatus.isOrderingEnabled) {
			if(venueStatus.isOpen) {
				return (
					<Fragment>
						<h1>{ _t('HDG_ACCEPTING_ORDERS', true) }</h1>
						{ venueStatus.isForcedOpen
							? <h2>{ _t('TXT_OPEN_UNTIL_CANCELLED') }</h2>
							: venueStatus.until == null
								? <h2>{ _t('TXT_24H_DAILY') }</h2>
								: <h2>{ _t('TXT_UNTIL') + ' ' + renderUntil(venueStatus.until) }</h2>
						}
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						<h1>{ _t('HDG_NOT_ACCEPTING_ORDERS', true) }</h1>
						{ venueStatus.until == null
							? <h2>{ _t('TXT_CLOSED') }</h2>
							: <h2>{ _t('TXT_OPENS') + ' ' + renderUntil(venueStatus.until) }</h2>
						}
					</Fragment>
				);
			}
		}
		return (
			<Fragment>
				<h1>{ _t('HDG_ORDERING_DISABLED', true) }</h1>
				<h2>{ _t('TXT_START_ACCEPTING_ORDERS') }</h2>
			</Fragment>
		);
	}

	const renderMenu = () => {
		if(venueStatus.isForcedOpen) {
			return (
				<Fragment>
					<DropdownItem data-action="disableOrdering" onClick={ selectionHandler }>{ _t('TXT_DISABLE_ORDERING') }</DropdownItem>
					<DropdownItem data-action="scheduledHours" onClick={ selectionHandler }>{ _t('TXT_RETURN_TO_SCHEDULED_HOURS') }</DropdownItem>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<DropdownItem data-action="disableOrdering" onClick={ selectionHandler }>{ _t('TXT_DISABLE_ORDERING') }</DropdownItem>
					<DropdownItem data-action="forceOpen" onClick={ selectionHandler }>{ _t('TXT_FORCE_OPEN_UNTIL_CANCELLED') }</DropdownItem>
				</Fragment>
			);
		}
	}

	const isBusy = () => {
		const loading = billingStore.loading ?? venueStore.loading;
		switch(loading) {
			case venuesActionTypes.forceOpenRequest:
			case venuesActionTypes.cancelForceOpenRequest:
			case billingActionTypes.disableOrderingRequest: return 'async';
			default: return '';
		}
	}

	const controlClasses = classNames('form-control', 'form-control-btn', 'pageHeader-control', isBusy())
	const ledClass = 'led_' + (venue ? (venueStatus.isOrderingEnabled && venueStatus.isOpen ? 'green' : 'red') : 'off');
	return (
		<Dropdown className="VenueStatusControl DropdownButton" isOpen={ open } toggle={ toggle }>
			<DropdownToggle
				id={ props.id }
				name={ props.name }
				className={ controlClasses }
				disabled={ props.disabled || !venue }
				tag="button"
				data-toggle="dropdown"
				aria-expanded={ open }
			>
				<Led className={ ledClass }/>
				<div>{ renderStatusMessages() }</div>
				<i className="material-icons">{ open ? 'expand_less' : 'expand_more' }</i>
			</DropdownToggle>
			{ venueStatus.isOrderingEnabled
				?
					<DropdownMenu right={ true }>
						{ renderMenu() }
					</DropdownMenu>
				:
					<DropdownMenu right={ true }>
						{ billingStore.loading === billingActionTypes.enableOrderingRequest &&
							<div className="VenueStatusControl_loading async">{ _t('TXT_ENABLE_ORDERING') }</div>
						}
						{ !billingStore.loading && !venue?.orderingEnabled &&
							<Feedback className="VenueStatusControl-feedback" status={ billingStore.status }/>
						}
					</DropdownMenu>
			}
		</Dropdown>
	);

};

export default VenueStatusControl;