import React from 'react';
import Content from '../layout/Content';
import Panes from './Panes';
import Popups from './Popups';
import Other from './Other';
import Tables from './Tables';

const Test = props => {

	const nav = [
		{ path: 'panes', label: 'Panes', component: Panes },
		{ path: 'tables', label: 'Tables', component: Tables },
		{ path: 'popups', label: 'Popups', component: Popups },
		{ path: 'other', label: 'Other', component: Other }
	];

	return (
		<Content path="/venues/test/" nav={ nav } navRight={ 'v' + process.env.REACT_APP_VERSION }/>
	);

}

export default Test;