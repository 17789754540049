import React, { Fragment, useEffect, useState } from 'react';
import { InputWithButton } from '../components/controls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../components/Modal';
import { PaneItem } from '../components/Pane';

const OwnersDialog = props => {
	const [showCreateButton, setShowCreateButton] = useState();

	useEffect(() => {
		if(props.show) {
			setShowCreateButton(false);
		}
	}, [props.show]);

	const searchKeyDownHandler = e => {
		if(e.key === 'Enter') {
			searchUsers();
		}
	}

	const searchUsers = () => {
		props.searchUsers();
		setShowCreateButton(true);
	}

	return (
		<Modal id="ownersDialog" show={ props.show }>
			<ModalHeader>Find New Owner</ModalHeader>
			<ModalBody>
				<Fragment>

					<InputWithButton
						placeholder="Email Address"
						className="primary"
						icon="search"
						focus={ true }
						value={ props.search }
						isLoading={ !!props.userStore.loading }
						onChange={ props.onChange }
						onButtonClick={ searchUsers }
						onKeyDown={ searchKeyDownHandler }
					/>

					{ props.userStore.users && props.userStore.users.length > 0 &&
						<ul className="Pane-items Pane-items_inline scrolling">
							{ props.userStore.users.map((user, ix) => {
								return (
									<PaneItem key={ ix } index={ user } onClick={ props.setOwner }>
										{ user.firstName + (user.lastName ? ' ' + user.lastName : '') }<br/>{ user.email }
									</PaneItem>
								)
							})}
						</ul>
					}

				</Fragment>
			</ModalBody>
				<ModalFooter>
					<button type="button" id="btnCancel" className="btn btn-secondary focus" onClick={ props.onHide }>Cancel</button>
					{ showCreateButton &&
						<button type="button" id="btnCreate" className="btn btn-primary" onClick={ props.onHide }>Create New</button>
					}
				</ModalFooter>
			</Modal>
	);
};

export default OwnersDialog;