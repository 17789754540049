const updateSettingType = 'UPDATE_SETTING';
const settingPrefix = 'pb_';

const defaultState = {
	username: '',
	language: null,
	fullscreen: false,
	// payoutsReportType: null,
	// payoutsFromOption: null,
	orderColumns: {},
	orderPriority: {},
	orderRush: {},
	orderGroupingMinutes: 20,
	soundEnabled: false,
	soundFile: 1,
	ordersFilter: 'open',
	ordersFromOption: 0,
	ordersFromDate: 0,
	ordersToDate: 0,
	ordersPage: 1
};

function getInitialState() {
	// return a new state based on local storage else defaultState (supports boolean, string, object and integer settings)
	const initialState = { ...defaultState };
	Object.keys(initialState).forEach(setting => {
		let value = localStorage.getItem(settingPrefix + setting);
		if(value != null) {
			if(typeof initialState[setting] === 'boolean') {
				initialState[setting] = value === 'true';
			} else if(value.substring(0, 1) === '{') {
				// assume if starts with '{' then we've got a stringified object
				initialState[setting] = JSON.parse(value);
			} else {
				initialState[setting] = isNaN(value) ? value : parseInt(value, 10);
			}
		}
	});
	return initialState;
}

const initialState = getInitialState();

export const settingsActionCreators = {
	updateSetting: (setting, newValue) => ({ type: updateSettingType, setting: setting, newValue: newValue })
};

export const reducer = (state = initialState, action) => {
	if(action.type === updateSettingType) {
		let newValue = action.newValue;
		if(typeof newValue === 'object') newValue = JSON.stringify(newValue);
		localStorage.setItem(settingPrefix + action.setting, newValue);
		return {
			...state,
			[action.setting]: action.newValue
		}
	} else {
		return state;
	}
};