// this provides a wrapper for things like translated text that may contain relative links that we want to handle by router (not browser) nav
import React from 'react';
import { useHistory } from 'react-router-dom';

const ViaRouter = props => {

	const history = useHistory();
	const Tag = props.tag || 'div';

	const onClick = e => {
		// do I need to consider the target attribute? If it exists and is not _self then should probably bomb out
		// also, what if the user wanted the text to contain an external and an internal link? Is this at all likely? (no)
		if(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) return;
		e.preventDefault();
		const href = e.target.getAttribute('href');
		history.push(href);
	}

	return (
		<Tag className={ props.className } dangerouslySetInnerHTML={{ __html: props.children }} onClick={ onClick }/>
	);
};

export default ViaRouter;