import { NavLink } from 'react-router-dom';
import './FeatureNav.scss';

const FeatureNav = props => {
	return (
		<nav className="FeatureNav">
			{ props.nav.map((navItem, ix) => {
				if(navItem.label && !navItem.hide) {
					return <NavLink key={ ix } className="FeatureNav-link" to={ props.path + navItem.path + '/' }>
						{ navItem.label }
					</NavLink>
				} else {
					return '';
				}
			}) }
			{ props.navRight &&
				<div className="FeatureNav_end">{ props.navRight }</div>
			}
		</nav>
	);
}

export default FeatureNav;