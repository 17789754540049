// this supports tags as strings or more complex types
// it's designed to work with useForm (supports the form.onChange event)
// I'm worried about this component's integration with Typeahead...
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typeahead from './Typeahead';
import './TagList.scss';

const TagList = ({ list, onChange, className, ...props }) => {

	const [value, setValue] = useState();	// we need to track this locally just so we can clear the typeahead's input when we want (see addTag)
	let tags = list || [];
	const container = useRef();

	const addTag = tag => {
		// first, check chosen if tag already in tagList...
		if(typeof tag === 'string') {
			if(tags.indexOf(tag) !== -1 || !tag) return false;
			if(props.labels) {
				// this prevents a false duplicate if the keyboard (enter) is used to select from the dropdown as it also fires keyUpHandler
				if(tags[tags.length - 1][props.labels] === tag) return false;
			}
		} else {
			if(tags.findIndex(t => t[props.keys] === tag[props.keys]) !== -1) return false;
		}
		// if not, add it...
		const newTags = [...tags, tag];
		// console.log(tag, newTags);
		onChange(null, props.name, newTags);
		setValue();
		const input = container.current.querySelector('input');
		input.focus();
		return true;
	}

	const changeHandler = (e, name, value, label) => {
		// console.log(e, name, value, label);
		setValue(value);
		if(label) {
			// we can take advantage of the design of Typeahead that means we can detect when a tag is selected from the list by the fact that label is set when this happens
			if(props.keys) {
				addTag({
					[props.keys]: value,
					[props.labels]: label
				})
			} else {
				addTag(label);
			}
		}
	}

	const keyUpHandler = e => {
		setValue(e.target.value);	// this is a fiddle (uggh) so that addTag triggers a reset of the typeahead
		if(e.key === 'Enter') {
			addTag(e.target.value);
		}
	}

	const blurHandler = e => {
		// if not tabbing onto Typeahead dropdown then safe to add
		if(!container.current.contains(e.relatedTarget)) {
			addTag(e.target.value);
		}
	}

	const tagDelete = tag => {
		let newTags;
		if(typeof tag === 'string') {
			newTags = tags.filter(t => t !== tag);
		} else {
			newTags = tags.filter(t => t[props.keys] !== tag[props.keys]);
		}
		onChange(null, props.name, newTags);
	}

	const renderTags = () => {
		if(!tags) return null;
		if(typeof tags === 'string') tags = tags.split(', ');
		return tags.map((tag, ix) => {
			const label = typeof tag === 'string' ? tag : tag[props.labels];
			return (
				<li key={ ix } className="tag" onClick={ () => tagDelete(tag) }>
					<label>{ label }</label>
					<i className="material-icons">close</i>
				</li>
			);
		});
	}

	const classes = classNames('TagList', 'tagList', className);
	return (
		<ul className={ classes }>
			{ renderTags() }
			<div ref={ container } className="typeahead-container">
				<Typeahead
					value={ value }
					{ ...props }
					onKeyUp={ keyUpHandler }
					onChange={ changeHandler }
					onBlur={ blurHandler }
				/>
			</div>
		</ul>
	);
};

TagList.propTypes = {
	data: PropTypes.array
};

export default TagList;