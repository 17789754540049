import { format as tzFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
// import { eachDayOfInterval, endOfWeek, format, startOfWeek } from "date-fns";
import { locales } from 'L10n';

const day = 86400000;	// no. of msecs in a day
// const dateFormatter = new Intl.DateTimeFormat('default', { dateStyle: 'short' });
// const timeFormatter = new Intl.DateTimeFormat('default', { timeStyle: 'short' });
// const datetimeFormatter = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' });

const setToLocalMidnight = date => {
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	return date;	// this function mutates date but handy to return anyway
}

const getLocalToday = (timeZone) => {
	let today = new Date();
	if(timeZone) {
		today = zonedDateToUtc(timeZone, today);
	} else {
		setToLocalMidnight(today);
	}
	return today;
}

const zonedDateToUtc = (timeZone, dt1, dt2, dt3) => {
	// two possible "signatures":
	// dt1 is an instance of Date and dt2 & dt3 are empty
	// dt1 is a year, dt2 is a month and dt3 is a day (all numbers)
	let lclDate;
	if(dt2 != null && dt3) {	// dt2 (annoyingly, month can be 0 for Jan)
		lclDate = new Date(Date.UTC(dt1, dt2, dt3, 0, 0, 0));
	} else {
		if(!(dt1 instanceof Date)) {
			throw new RangeError('Invalid date value');	// zonedTimeToUtc can handle other argument error conditions
		}
		lclDate = new Date(Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate(), 0, 0, 0));
	}
	const utcDate = zonedTimeToUtc(lclDate, timeZone);
	return utcDate;
}

const getTzDate = timeZone => {
	const localDate = utcToZonedTime(new Date(), timeZone);
	return localDate;
}

// const getTzTime = (tzDate, timeZone) => {
// 	const tzTime = tzFormat(tzDate, 'HH:mm', { timeZone: timeZone });
// 	return tzTime;
// }

const getTzDayOfWeek = (tzDate, timeZone) => {
	let dayOfWeek = parseInt(tzFormat(tzDate, 'i', { timeZone: timeZone }), 10);	// day of week (where 0 = Sunday) in venue's timeZone;
	dayOfWeek = (dayOfWeek + 6) % 7;	// we want the week to start at Monday - not Sunday, so adjust
	return dayOfWeek;
}

// found a better way - see getLocaleData
// const getLongDayNames = loc => {
// 	const locale = locales.find(locale => locale.value === loc);
// 	const now = new Date();
// 	const arr = eachDayOfInterval({
// 		start: startOfWeek(now, { weekStartsOn: 1 }),
// 		end: endOfWeek(now, { weekStartsOn: 1 })
// 	});
// 	const arrOfDays = [];
// 	arr.map(dt => arrOfDays.push(format(dt, 'EEEE', { locale: locale.dfLocale })));
// 	return arrOfDays;
// }

const getLocaleData = lang => {
	// uses localize "provided" by date-fns - I think this is ok to use? Not too well documented!
	const localize = locales.find(locale => locale.value === lang).dfLocale.localize;
	const daysShort = [];
	const daysLong = [];
	for(let i = 0; i < 7; i++) {
		const weekDay = i === 6 ? 0 : i + 1;	// adjust so we start at Monday, not Sunday
		daysShort.push(localize.day(weekDay, { width: 'abbreviated' }));
		daysLong.push(localize.day(weekDay, { width: 'wide' }));
	}
	const monthsShort = [];
	const monthsLong = [];
	for(let i = 0; i < 12; i++) {
		monthsShort.push(localize.month(i, { width: 'abbreviated' }));
		monthsLong.push(localize.month(i, { width: 'wide' }));
	}
	return {
		daysShort,
		daysLong,
		monthsShort,
		monthsLong
	}
}

const styles = {
	DATE: 0,
	TIME: 1,
	DATETIME: 2
}

/**
 * @param {Date | string} date - either an instance of Date or a string in the form 'YYYY-MM-DD'
 * @param {string} timeZone - string representing a time zone supported by the Intl api
 * @param {styles} style
 * @returns {string} date as localised string
 */
const myFormat = (date, style, timeZone) => {
	let dt = date;
	if(!date) return null;
	if(typeof date === 'string') {
		// in order to ensure we end up in the local time zone (instead of UTC) we can't rely on Date.parse, so assuming 'YYYY-MM-DD'...
		const dateBits = date.split('-');
		if(dateBits.length !== 3) {
			console.error('Invalid date string:', date);
			return null;
		}
		dt = new Date(dateBits[0], parseInt(dateBits[1]) - 1, dateBits[2]);
	}
	const options = {};
	switch(style) {
		case styles.TIME:
			options.timeStyle = 'short';
			break;
		case styles.DATETIME:
			options.dateStyle = 'short';
			options.timeStyle = 'short';
			break;
		default:	// DATE
			options.dateStyle = 'short';
			break;
	}
	if(timeZone) options.timeZone = timeZone;
	dt = new Intl.DateTimeFormat('default', options).format(dt);
	// console.log(date, timeZone, dt, new Date(date));
	return dt;
}

export default {
	day,
	setToLocalMidnight,
	getLocalToday,
	// dateFormatter,
	// datetimeFormatter,
	// timeFormatter,
	format: myFormat,
	zonedDateToUtc,
	getTzDate,
	// getTzTime,
	getTzDayOfWeek,
	getLocaleData,
	styles
}