import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import { actionTypes, venuesActionCreators } from 'store/venues';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import { FormCheckbox, FormInput, FormLabel, FormPassWord } from 'components/formControls';
import useForm from 'components/Form';
import { IconButton } from 'components/buttons';
import ValidationMessage from 'components/ValidationMessage';
import printer from 'assets/img/wireless-printer.png';
import qr from 'assets/img/qr.png';

const Wifi = props => {
	const _t = useL10n().getText;
	const venueStore = useSelector(state => state.venues);
	const venue = venueStore.venue;
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();

	const formSave = () => {
		dispatch(venuesActionCreators.updateWiFiSettings(form.data));
	}

	const form = useForm(formSave, { wifiSSID: true, wifiPassword: true });
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(venue) {
			resetRef.current({
				wifiSSID : venue.wifiSSID,
				wifiPassword : venue.wifiPassword,
				wifiIsWEP: venue.wifiIsWEP
			}, false);
		}
	}, [venue]);

	return (
		<Fragment>

			<Pane className="max-width-800">
				<PaneHeader>
					<h1 style={{ display: 'flex'}}>
						<span className="material-icons mr10">warning_amber</span>
						{ _t('HDG_IMPORTANT') }
					</h1>
				</PaneHeader>
				<PaneBody className="container">
					<div className="row">
						<div className="col-sm-8 col-md-10" dangerouslySetInnerHTML={{ __html: _t('TXT_WIFI_WARNING_QR') }}/>
						<div className="col-sm-4 col-md-2">
							<img src={ qr } alt="example qr code" className="img-fluid"/>
						</div>
					</div>
				</PaneBody>
			</Pane>

			<Pane className="max-width-800">
				<PaneHeader>
					<h1 style={{ display: 'flex'}}>
						<span className="material-icons mr10">warning_amber</span>
						{ _t('HDG_IMPORTANT') }
					</h1>
				</PaneHeader>
				<PaneBody className="container">
					<div className="row">
						<div className="col-sm-8 col-md-10" dangerouslySetInnerHTML={{ __html: _t('TXT_WIFI_WARNING_PRINTER') }}/>
						<div className="col-sm-4 col-md-2">
							<img src={ printer } alt="wireless printer" className="img-fluid"/>
						</div>
					</div>
				</PaneBody>
			</Pane>

			<Pane className="max-width-800">
				<PaneHeader>
					<h1>{ _t('HDG_WIFI_SETTINGS')}</h1>
					{ user?.hasPrivilegedAccess &&
						<div className="Pane-toolbar">
							<IconButton
								title={ _t('TTP_SAVE') }
								icon="save"
								async={ venueStore.loading === actionTypes.updateWiFiSettingsRequest }
								onClick={ form.onSave }
							/>
						</div>
					}
					</PaneHeader>
				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<div className="form-row align-items-center">
						<FormLabel htmlFor="wifiSSID" className="col-sm-4" label="_SSID"></FormLabel>
						<div className="col-sm-8">
							<FormInput form={ form } field="wifiSSID" disabled={ !user?.hasPrivilegedAccess }/>
						</div>
					</div>

					<div className="form-row align-items-center">
						<FormLabel htmlFor="wifiPassword" className="col-sm-4" label="_PASSWORD"></FormLabel>
						<div className="col-sm-8">
							<FormPassWord form={ form } field="wifiPassword" disabled={ !user?.hasPrivilegedAccess }/>
						</div>
					</div>

					<div className="form-row">
					<FormLabel htmlFor="enabled" className="col-sm-4" label="_WEP"/>
					<div className="col-sm-8">
						<FormCheckbox form={ form } field="wifiIsWEP" disabled={ !user?.hasPrivilegedAccess }>{ _t('TXT_WPA') }</FormCheckbox>
					</div>
				</div>

				</PaneBody>
			</Pane>

		</Fragment>
	);
};

export default Wifi;