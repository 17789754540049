import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useL10n from 'L10n';
import './FlyoutNav.scss';

const FlyoutNav = props => {
	const _t = useL10n().getText;
	const user = useSelector(state => state.user.user);

	return (
		<nav className="nav flex-column">
			{ user != null &&
				<Fragment>
				<NavLink exact to="/venues/home/" className="Flyout_item">
					<span className="material-icons">home</span>
					<span>{ _t('HDG_OVERVIEW', true) }</span>
				</NavLink>
				<NavLink to="/venues/locations/" className="Flyout_item">
					<span className="material-icons">place</span>
					<span>{ _t('HDG_LOCATIONS', true) }</span>
				</NavLink>
				<NavLink to="/venues/products/" className="Flyout_item">
					<span className="material-icons">restaurant_menu</span>
					<span>{ _t('HDG_MENU', true) }</span>
				</NavLink>
				<NavLink to="/venues/orders/" className="Flyout_item">
					<span className="material-icons">list_alt</span>
					<span>{ _t('HDG_ORDERS', true) }</span>
				</NavLink>
				<NavLink to="/venues/customers/" className="Flyout_item">
					<span className="material-icons">group</span>
					<span>{ _t('HDG_CUSTOMERS', true) }</span>
				</NavLink>
				<NavLink to="/venues/reports/" className="Flyout_item">
					<span className="material-icons">leaderboard</span>
					<span>{ _t('HDG_REPORTS', true) }</span>
				</NavLink>
				{ user.hasPrivilegedAccess &&
					<NavLink to="/venues/admin/" className="Flyout_item">
						<span className="material-icons">settings</span>
						<span>{ _t('HDG_ADMIN', true) }</span>
					</NavLink>
				}
				{ process.env.REACT_APP_ENV === 'developer' &&
					<NavLink to="/venues/test/" className="Flyout_item">
						<span className="material-icons">lightbulb_outline</span>
						<span>Test</span>
					</NavLink>
				}
			</Fragment>
		}
			{ typeof props.signInOutHandler !== 'undefined'
					?
						<button className="Flyout_item" onClick={ props.signInOutHandler }>
							<span className="material-icons">power_settings_new</span>
							<span>{ _t(user ? 'HDG_SIGNOUT' : 'HDG_SIGN_IN', true) }</span>
						</button>
					: <div></div>
			}
		</nav>
	);
}

export default FlyoutNav;