import { useEffect, useState } from 'react';
import useL10n from 'L10n';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'components/Modal';
import { Pane, PaneBody, PaneHeader, PaneItem } from 'components/Pane';
import TagList from 'components/TagList';
import './TagEditorDialog.scss';

const TagEditorDialog = props => {
	const _t = useL10n().getText;
	const [itemTags, setItemTags] = useState([]);

	useEffect(() => {
		if(props.show && props.itemTags) {
			// console.log(props.itemTags);
			setItemTags([...props.itemTags]);
		}
	}, [props.show, props.itemTags]);

	const categoryTagHandler = tag => {
		if(itemTags.findIndex(item => item.id === tag.id) === -1) {
			setItemTags([...itemTags, {
				id: tag.id,
				tagName: tag.tagName
			}]);
		}
	}

	const tagListChangeHandler = (e, name, tags) => {
		setItemTags(tags);
	}

	const buttonClickHandler = e => {
		props.onHide(e.currentTarget.id === 'btnOk' ? itemTags : null);
	}

	const renderTags = (tags) => {
		return tags?.map((tag, ix) => {
			return (
				<PaneItem key={ ix } index={ tag } onClick={ categoryTagHandler }>
					<div className="col">{ tag.tagName }</div>
				</PaneItem>
			);
		});
	}

	return (
		<Modal id="tagEditorDialog" size="large" show={ props.show } draggable>
			<ModalHeader>Tag Editor</ModalHeader>
			<ModalBody>

				<Pane>
					<PaneHeader>Tag Categories</PaneHeader>
					<PaneBody>

						<div className="tagCategories">
							{ props.commonTags?.map((category, ix) => {
									return <Pane key={ ix } className="Pane_category">
										<PaneHeader>{ category.categoryName }</PaneHeader>
										<PaneBody tag="ul" className="scrolling Pane-items Pane-items_p0">
											{ renderTags(category.tags) }
										</PaneBody>
									</Pane>
								})
							}
						</div>

					</PaneBody>
				</Pane>

				<TagList
					placeholder="add tag"
					list={ itemTags }
					data={ props.tags }
					keys="id"
					labels="tagName"
					maxMatches={ 5 }
					minLength={ 2 }
					highlight={ true }
					onChange={ tagListChangeHandler }
				/>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ buttonClickHandler } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary" onClick={ buttonClickHandler } id="btnOk">
					{ _t('BTN_OK', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default TagEditorDialog;