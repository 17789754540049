import { useEffect, useRef } from 'react';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormInput } from 'components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';

const ContactUsDialog = props => {
	const _t = useL10n().getText;

	const formSave = () => {
		props.onHide(form.data);
	}

	const form = useForm(formSave, {
		name: true,
		email: true,
		subject: true,
		message: true
	});

	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.show) {
			resetRef.current({});
		}
		// only want this to fire on props.show, so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	const buttonClickHandler = e => {
		if(e.currentTarget.id === 'btnCancel') {
			props.onHide();
		} else {
			form.onSave();
		}
	}

	return (
		<Modal id="contactUsDialog" size="small" show={ props.show } draggable={ true } onHide={ props.onHide }>
			<ModalHeader>{ _t('HDG_CONTACT_US', true) }</ModalHeader>
			<ModalBody className="simple-form">
	
				<ValidationMessage open={ !!form.errors }/>

				<FormInput form={ form } field="name" className="focus" placeholder={ _t('PLH_CONTACT_NAME') }/>

				<FormInput form={ form } field="email" placeholder={ _t('PLH_CONTACT_EMAIL') }/>

				<FormInput form={ form } field="subject" placeholder={ _t('PLH_CONTACT_SUBJECT') }/>

				<FormInput type="textarea" form={ form } field="message" placeholder={ _t('PLH_CONTACT_MESSAGE') }/>
	
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ buttonClickHandler } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary" onClick={ buttonClickHandler } id="btnOk">
					{ _t('BTN_SUBMIT', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default ContactUsDialog;