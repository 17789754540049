import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import utilities from '../../services/utilities';
import { billingActionCreators } from '../../store/billing';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import { IconButton } from '../../components/buttons';
import { Pane, PaneHeader, PaneBody, ItemPaneFooter, PaneItem, PaneItemButton } from '../../components/Pane';
import TaxCodeDialog from '../../components/TaxCodeDialog';
import ExtraChargeDialog from './ExtraChargeDialog';
import Feedback from './Feedback';

const ExtraCharges = props => {

	let shiftData;
	// const [currentItem.itemId, setCurrentItemId] = useState(0);
	const [confirmDialogShow, setConfirmDialogShow] = useState();
	const [extraChargeDialogShow, setExtraChargeDialogShow] = useState(false);
	const [salesTaxCode, setSalesTaxCode] = useState();
	const [currentItem, setCurrentItem] = useState();
	const [salesTaxDialogShow, setSalesTaxDialogShow] = useState();
	const billingStore = useSelector(state => state.billing);
	const fixedInvoiceItems = billingStore.fixedInvoiceItems;
	const venue = useSelector(state => state.venues.venue);
	const venueId = venue?.venueUUID;
	const dispatch = useDispatch();

	useEffect(() => {
		if(venueId) {
			dispatch(billingActionCreators.fetchFixedInvoiceItems());
		}
	}, [venueId, dispatch]);

	const onItemSelect = item => {
		setCurrentItem(item);
	}
	
	const onDeleteItemClick = () => {
		setConfirmDialogShow(true);
	}

	const onAddItemClick = e => {
		// clear relevant data, so form is initialised to empty
		setSalesTaxCode();
		setCurrentItem();
		setExtraChargeDialogShow(true);
	}

	const onEditItemClick = item => {
		setCurrentItem(item);
		setExtraChargeDialogShow(true);
	}

	const shiftItem = isUp => {
		dispatch(billingActionCreators.shiftFixedInvoiceItem(currentItem.itemId, isUp));
	}

	const confirmDialogHide = response => {
		if(response.button === 'btnOk') {
			dispatch(billingActionCreators.deleteFixedInvoiceItem(currentItem.itemId));
			setCurrentItem();
		}
		setConfirmDialogShow(false);
	}

	const extraChargeDialogHide = item => {
		console.log('formSave', item);
		if(item.formula) {
			if(currentItem) {
				dispatch(billingActionCreators.updateFixedInvoiceItem(item));
			} else {
				dispatch(billingActionCreators.addFixedInvoiceItem(item));
			}
		}
		setExtraChargeDialogShow(false);
	}

	const onSalesTaxButtonClick = e => {
		setSalesTaxDialogShow(true);
	}

	const salesTaxDialogHide = e => {
		setSalesTaxDialogShow(false);
	}

	const onSalesTaxCodeSelect = code => {
		setSalesTaxCode(code);
		salesTaxDialogHide();
	}

	const convertFormula = formula => {
		// use "I" for % over Items Total, "F" for fixed amount charges. For discounts, use negative values e.g. "I-300"
		const negative = formula.charAt(1) === '-';
		const type = formula.charAt(0);
		const value = utilities.formatPrice(formula.substr(negative ? 2 : 1));
		return (negative ? '-' : '') + (type === 'F' ? venue?.currencySymbol : '') + value + (type === 'I' ? '%' : '');
	}

	// some design notes here: putting the Feedback component inside the pane is more consistent with other pages here,
	// but is a little "odd" overall - also it means the list of PaneItems doesn't scroll (so you can always see Feedback)
	// whether this works or not will depend on whether this list is often long - if it is, then it might be best to
	// move Feedback out of the main pane
	const classes = classNames('Pane-header_headings', { async: billingStore.loading });
	return (
		<Fragment>

			<Pane className="ExtraCharges animated fadeIn">
				<PaneHeader className={ classes }>
					<div className="col-4">{ utilities.getText('PLH_ITEM_DESC') }</div>
					<div className="col-3">{ utilities.getText('PLH_AMOUNT') }</div>
					<div className="col-3">{ utilities.getText('PLH_SALES_TAX_CODE') }</div>
				</PaneHeader>
				<PaneBody tag="ul" className="Pane-items Pane-items_p0">	{/* no scrolling here due to Feedback component below */}
					{ fixedInvoiceItems?.map((item, ix) => {
							if(item.itemId === currentItem?.itemId) {
								// this is the current item, so store data needed for disabling shift buttons
								shiftData = [fixedInvoiceItems.length - 1, ix];
							}
							return (
								<PaneItem key={ ix } index={ item } selected={ item.itemId === currentItem?.itemId } onClick={ onItemSelect }>
									<div className="col-4">{ item.dscr }</div>
									<div className="col-3">{ convertFormula(item.formula) }</div>
									<div className="col-3">{ item.salesTaxCode }</div>
									<PaneItemButton index={ item } icon="edit" onClick={ onEditItemClick }/>
								</PaneItem>
							);
						})
					}

					<Feedback status={ billingStore.billingStatus }/>

				</PaneBody>
				<ItemPaneFooter shiftData={ shiftData } shiftElement={ shiftItem }>
					<IconButton
						title="add new item"
						icon="add"
						onClick={ onAddItemClick }
					/>
					<IconButton
						disabled={ !currentItem?.itemId }
						title="delete selected item"
						icon="delete"
						onClick={ onDeleteItemClick }
					/>
				</ItemPaneFooter>
			</Pane>

			<ExtraChargeDialog
				show={ extraChargeDialogShow }
				item={ currentItem }
				venue={ venue }
				salesTaxCode={ salesTaxCode }
				onSalesTaxButtonClick={ onSalesTaxButtonClick }
				onHide={ extraChargeDialogHide }
			/>

			<TaxCodeDialog
				show={ salesTaxDialogShow }
				defaultTab={ 4 }
				onHide={ salesTaxDialogHide }
				onSalesTaxCodeSelect={ onSalesTaxCodeSelect }
			/>

			<ConfirmDialog
				action="Delete Extra Charge Item"
				message="Are you sure you want to delete this extra charge item?"
				show={ confirmDialogShow }
				onHide={ confirmDialogHide }
			/>

		</Fragment>
	);

}

export default ExtraCharges;