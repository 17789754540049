import { useEffect, useRef } from 'react';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormInput } from 'components/formControls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';

const CategoryAddEditDialog = props => {

	const _t = useL10n().getText;

	const formSave = e => {
		props.onHide(form.data);
	}

	const form = useForm(formSave, { categoryName: true });
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show && !props.category) {
			formRef.current.reset({});
		}
	}, [props.show, props.category]);

	useEffect(() => {
		if(props.category) {
			formRef.current.reset(props.category);
		}
	}, [props.category]);

	return (
		<Modal id="categoryAddEditDialog" show={ props.show } draggable={ true }>
			<ModalHeader>{ props.category ? 'Rename Category' : 'Add Category' }</ModalHeader>
			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<FormInput placeholder="Category Name" form={ form } field="categoryName"/>

			</ModalBody>

			<ModalFooter>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">{ _t('BTN_CANCEL', true) }</button>
				<button className="btn btn-primary" onClick={ form.onSave } id="btnAdd">{ _t('BTN_SAVE') }</button>
			</ModalFooter>

		</Modal>
	);
};

export default CategoryAddEditDialog;