// All that is left is the SSE comms with the backend in order to display the Stripe id
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import useL10n from 'L10n';
import utilities from 'services/utilities';
import { actionTypes, billingActionCreators } from 'store/billing';
import { actionTypes as stripeActionTypes, stripeActionCreators } from 'store/stripe';
import useForm from 'components/Form';
import { FormCheckbox, FormInput, FormInputGroup, FormLabel, FormRadio } from 'components/formControls';
import { Radio } from 'components/controls';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import Feedback from './Feedback';

const Billing = props => {

	const [fetchingStripeId, setFetchingStripeId] = useState(false);
	const billingStore = useSelector(state => state.billing);
	const stripeStore = useSelector(state => state.stripe);
	const venue = useSelector(state => state.venues.venue);
	const venueId = venue?.venueUUID;
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	const formSave = () => {
		// console.log('formSave', form.data);
		// trying something a little different / quicker but less rigorous - no "model" class,
		// instead, creating object (billingSettings) on the fly
		const billingSettings = {};
		for(const key in form.data) {
			if(key === 'feeType') {
				if(form.data.feeType === 'f') {
					if(form.data.pbFlatFee_onPrem) billingSettings.pbFlatFee_onPrem = form.data.pbFlatFee_onPrem;
					if(form.data.pbFlatFee_offPrem) billingSettings.pbFlatFee_offPrem = form.data.pbFlatFee_offPrem;
				}
				if(form.data.feeType === 'p') {
					if(form.data.pbPercentFee_onPrem) billingSettings.pbPercentFee_onPrem = form.data.pbPercentFee_onPrem;
					if(form.data.pbPercentFee_offPrem) billingSettings.pbPercentFee_offPrem = form.data.pbPercentFee_offPrem;
				}
			} else if(!key.endsWith('Prem')) {
				const value = form.data[key];
				console.log(key, value);
				if(value !== '') {
					billingSettings[key] = value;
				}
			}
		}
		console.log(billingSettings);
		dispatch(billingActionCreators.addUpdateBillingSettings(billingSettings));
	}

	const form = useForm(formSave, validationRules);
	const formRef = useRef(form);

	useEffect(() => {
		if(venueId) {
			dispatch(billingActionCreators.fetchBillingSettings());
		}
	}, [venueId, dispatch]);

	useEffect(() => {
		if(billingStore.billingSettings) {
			const settings = billingStore.billingSettings;
			if(fetchingStripeId) {
				formRef.current.onChange(null, 'stripeConnectedAccountId', settings.stripeConnectedAccountId);
				setFetchingStripeId(false);
			} else {
				if((settings.pbFlatFee_onPrem || settings.pbFlatFee_offPrem) && user?.hasPrivilegedAccess) settings.feeType = 'f';
				if((settings.pbPercentFee_onPrem || settings.pbPercentFee_offPrem) && user?.hasPrivilegedAccess) settings.feeType = 'p';
				formRef.current.reset(settings);
			}
		}
		// this should NOT run when fetchingStripeId changes so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billingStore.billingSettings]);

	useEffect(() => {
		if(stripeStore.restaurantOnboardingLink) {
			window.open(stripeStore.restaurantOnboardingLink, 'external');
		}
	}, [stripeStore.restaurantOnboardingLink]);

	const onStripeButtonClick = e => {
		if(stripeStore.restaurantOnboardingLink) {
			setFetchingStripeId(true);
			dispatch(billingActionCreators.fetchBillingSettings(true));
		} else {
			dispatch(stripeActionCreators.generateRestaurantOnboardingLink());
		}
	}

	return (
		<Fragment>
			<Pane className="Billing animated fadeIn max-width-600">
				<PaneHeader className={ billingStore.loading === actionTypes.fetchBillingSettingsRequest ? 'async': ''}>

					<h1>{ _t('HDG_BILLING', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							async={ billingStore.loading === actionTypes.addUpdateBillingSettingsRequest }
							onClick={ form.onSave }
						/>
					</div>

				</PaneHeader>
				<PaneBody>

					<section className="Pane-body_section" id="paymentSettings">
						<Radio
							name="receivesOrdersInOwnPOS"
							disabled={ true }
							defaultChecked={ venue?.receivesOrdersInOwnPOS }
						>
							{ utilities.getText('LBL_PAYMENTS_BY_POS') }
							<br/><em>{ utilities.getText('TXT_PAYMENTS_BY_POS') }</em>
						</Radio>
						<Radio
							name="receivesOrdersInOwnPOS"
							disabled={ true }
							defaultChecked={ !venue?.receivesOrdersInOwnPOS }
						>
							{ utilities.getText('LBL_PAYMENTS_BY_PICTABITE') }
						</Radio>
					</section>

					<section className="Pane-body_section" id="billingForm">
						<ValidationMessage open={ !!form.errors }/>

						<div className="form-row">
							<FormLabel htmlFor="menuPricesIncludeVAT" className="col-sm-5" label="_PRICES_INCLUDE_TAX"/>
							<div className="col-sm-7">
								<FormCheckbox form={ form } field="menuPricesIncludeVAT"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="stripe" className="col-sm-5" label="_STRIPE_ACCOUNT_ID"/>
							<div className="col-sm-7">
								{ form.data.stripeConnectedAccountId
									? <FormInput form={ form } readOnly={ true } field="stripeConnectedAccountId"/>
									: <button className={ classNames('btn', 'btn-primary', { async: stripeStore.loading === stripeActionTypes.generateRestaurantOnboardingLinkRequest })} onClick={ onStripeButtonClick }>
											{ stripeStore.restaurantOnboardingLink ? utilities.getText('LBL_FETCH_STRIPE_ID') : utilities.getText('LBL_CONNECT_STRIPE') }
										</button>
								}
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="legalName" className="col-sm-5" label="_LEGAL_NAME"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="legalName"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="taxId" className="col-sm-5" label="_TAX_ID"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="taxId"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="bankAccountNumber" className="col-sm-5" label="_ACCOUNT_NO"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="bankAccountNumber"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="bankRoutingNumber" className="col-sm-5" label="_ROUTING_NO"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="bankRoutingNumber"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="accountLinkedEmail" className="col-sm-5" label="_ACCOUNT_LINKED_EMAIL"/>
							<div className="col-sm-7">
								<FormInput type="email" form={ form } field="accountLinkedEmail"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="accountLinkedMobilePhone" className="col-sm-5" label="_ACCOUNT_LINKED_MOBILE"/>
							<div className="col-sm-7">
								<FormInput type="tel" form={ form } field="accountLinkedMobilePhone"/>
							</div>
						</div>

					</section>

					{ !!user?.hasPrivilegedAccess &&
						<section className="Pane-body_section" id="adminForm">

							<div className="form-row">
								<div className="offset-sm-5 col-sm-7 form-adjacent gap15 text-center">
									<label>{ utilities.getText('LBL_ON_PREM') }</label>
									<label>{ utilities.getText('LBL_OFF_PREM') }</label>
								</div>
							</div>

							<div className="form-row">
								<FormRadio className="col-form-label col-sm-5" form={ form } field="feeType" value="p">
									{ utilities.getText('LBL_PERCENT_FEE') }
								</FormRadio>
								<div className="col-sm-7 form-adjacent gap15">
									<FormInputGroup
										append="%"
										type="money"
										disabled={ form.data.feeType !== 'p' }
										form={ form }
										field={ form.data.feeType === 'p' ? 'pbPercentFee_onPrem' : 'unknown' }
									/>
									<FormInputGroup
										append="%"
										type="money"
										disabled={ form.data.feeType !== 'p' }
										form={ form }
										field={ form.data.feeType === 'p' ? 'pbPercentFee_offPrem' : 'unknown' }
									/>
								</div>
							</div>
							<div className="form-row">
								<FormRadio className="col-form-label col-sm-5" form={ form } field="feeType" value="f">
									{ utilities.getText('LBL_FLAT_FEE') }
								</FormRadio>
								<div className="col-sm-7 form-adjacent gap15">
									<FormInputGroup
										prepend={ venue?.currencySymbol }
										type="money"
										disabled={ form.data.feeType !== 'f' }
										form={ form }
										field={ form.data.feeType === 'f' ? 'pbFlatFee_onPrem' : 'unknown' }
									/>
									<FormInputGroup
										prepend={ venue?.currencySymbol }
										type="money"
										disabled={ form.data.feeType !== 'f' }
										form={ form }
										field={ form.data.feeType === 'f' ? 'pbFlatFee_offPrem' : 'unknown' }
									/>
								</div>
							</div>

						</section>
					}

					<Feedback status={ billingStore.status }/>

				</PaneBody>
			</Pane>

			<RouterPrompt when={ form.isDirty }/>

			</Fragment>
		);

}

export default Billing;

const validationRules = {
	// stripeConnectedAccountId: true,
	legalName: true,
	taxId: true,
}