import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import './Collapsible.scss';

export const Collapsible = props => {

	const [collapse, setCollapse] = useState(props.collapse);

	useEffect(() => {
		setCollapse(props.collapse);
	}, [props.collapse]);

	const toggle = e => {
		let newState = !collapse;
		if(props.onCollapseToggle) {
			props.onCollapseToggle(newState, props.id);
		}
		setCollapse(newState);
	}

	let className = classNames('Collapsible', props.className, { 'open': !collapse });
	let ulClassName = classNames('Pane-items', props.className);
	return (
		<section className={ className }>
			<header onClick={ toggle }>{ props.header }</header>
			<Collapse tag="ul" className={ ulClassName } isOpen={ !collapse }>
				{ props.children }
			</Collapse>
		</section>
	);

}

export const CollapserButton = props => {

	let className = classNames('btn btn-icon', props.className);
	return (
		<button type="button" className={ className } disabled={ props.disabled } title={ props.open ? 'collapse all' : 'expand all' } onClick={ props.onClick }>
			<i className="material-icons icon-stack">{ props.open ? 'expand_less' : 'expand_more' }</i>
			<i className="material-icons icon-stack">{ props.open ? 'expand_less' : 'expand_more' }</i>
		</button>
	);

}