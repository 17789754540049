import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { venuesActionCreators } from '../../store/venues';
import { Pane, PaneHeader, PaneBody } from '../../components/Pane';
import utilities from '../../services/utilities';
import { FormLabel, FormValue } from '../../components/formControls';
import useForm from '../../components/Form';

const MenuProvider = props => {
	const menuProvider = useSelector(state => state.venues.menuProvider);
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(venuesActionCreators.getMenuProvider());
	}, [dispatch]);

	const form = useForm();
	const resetRef = useRef(form.reset);

	const clearClickHandler = e => {
		dispatch(venuesActionCreators.clearMenuProvider());
	}

	useEffect(() => {
		if(menuProvider) {
			if(!menuProvider.provider) {
				menuProvider.provider = 'Pictabite'
			}
			resetRef.current(menuProvider);
		} else {
			resetRef.current({ provider: 'Pictabite' });
		}
	}, [menuProvider]);

	return (
		<Pane>
			<PaneHeader>
				<h1>{ utilities.getText('HDG_MENU_PROVIDER') }</h1>
				{ user?.hasPrivilegedAccess && form.data.provider && form.data.provider !== 'Pictabite' &&
					<div className="Pane-toolbar">
						<button className="btn" onClick={clearClickHandler }>{ utilities.getText('LBL_CLEAR_PROVIDER') }</button>
					</div>
				}
			</PaneHeader>
			<PaneBody className="container">

				<div className="form-row align-items-center">
					<FormLabel htmlFor="provider" className="col-sm-3" label="_PROVIDER"/>
					<div className="col-sm-9">
						<FormValue form={ form } field="provider"/>
					</div>
				</div>
				{ form.data.provider !== 'Pictabite' &&
					<Fragment>
						<div className="form-row align-items-center">
							<FormLabel htmlFor="attributionLink" className="col-sm-3" label="_ATTRIBUTION_LINK"/>
							<div className="col-sm-9">
								<FormValue form={ form } field="attributionLink"/>
							</div>
						</div>
						<div className="form-row align-items-center">
							<FormLabel htmlFor="attributionText" className="col-sm-3" label="_ATTRIBUTION_TEXT"/>
							<div className="col-sm-9">
								<FormValue form={ form } field="attributionText"/>
							</div>
						</div>
					</Fragment>
				}

			</PaneBody>

			</Pane>
	);
};

export default MenuProvider;