import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import { billingActionCreators } from 'store/billing';
import utilities from 'services/utilities';
import { Pane, PaneBody, PaneFooter, PaneHeader } from 'components/Pane';
import { Checkbox } from 'components/controls';
import ViaRouter from 'components/ViaRouter';
import Feedback from './Feedback';

const Status = props => {

	const billingStore = useSelector(state => state.billing);
	const venueStore = useSelector(state => state.venues);
	const venueId = venueStore.venue?.venueUUID;
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	useEffect(() => {
		if(venueId) {
			dispatch(billingActionCreators.getOrderingStatus());
		}
	}, [venueId, dispatch]);

	const onChange = e => {
		if(e.target.checked) {
			dispatch(billingActionCreators.enableOrdering());
		} else {
			dispatch(billingActionCreators.disableOrdering());
		}
	}

	return (
		<Pane className="Status animated fadeIn">
			<PaneHeader className={ billingStore.loading ? 'async': ''}>

				<h1>{ _t('HDG_STATUS', true) }</h1>

			</PaneHeader>
			<PaneBody className="container">

				<section className="Feedback">
					<ul>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ utilities.getText('HDG_POS_INTEGRATION') }:&nbsp;
							<em>{ venueStore.venue?.receivesOrdersInOwnPOS ? utilities.getText('LBL_ENABLED') : utilities.getText('LBL_DISABLED') }</em>
						</li>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ utilities.getText('TXT_PROCESSED_BY') }:&nbsp;
							<em>{ venueStore.venue?.receivesOrdersInOwnPOS ? 'the venue’s POS' : 'Pictabite' }</em>
						</li>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{/* when it comes to replacing getText with _t - probably can replace ViaRouter with technique used in OrdersListContainer -> ValidationMessage */}
							<ViaRouter tag="p">{ utilities.getText('TXT_ORDERS_WHEN', '/venues/locations/opening-hours') }</ViaRouter>
						</li>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ utilities.getText('TXT_MARK_OUT_OF_STOCK') }
						</li>
					</ul>
				</section>

				<Feedback status={ billingStore.status }/>

			</PaneBody>
			<PaneFooter className="align-items-center justify-content-center">

				<Checkbox checked={ venueStore.venue?.orderingEnabled ?? false } onChange={ onChange }>Accept Orders</Checkbox>

			</PaneFooter>
		</Pane>
	);

}

export default Status;