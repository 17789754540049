import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import utilities from '../services/utilities';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import useForm from '../components/Form';
import { FormInput } from '../components/formControls';
import { IconButton } from '../components/buttons';
import { Pane, PaneBody, PaneHeader } from '../components/Pane';
import ValidationMessage from '../components/ValidationMessage';
import { Fragment } from 'react';

const TagsForm = props => {

	const [tags, setTags] = useState([]);
	const [currentTag, setCurrentTag] = useState();
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const tag = utilities.getText('TXT_TAG');

	const formSave = e => {
		console.log(form.data);
		props.onHide(form.data);
	}

	const form = useForm(formSave);

	useEffect(() => {
		if(form.data.search && props.tags.length) {
			const search = form.data.search?.toLowerCase();
			const matches = props.tags?.filter(entry => {
				return entry.tagName.toLowerCase().indexOf(search) !== -1;
			});
			setTags(matches);
			setCurrentTag();
		}
	}, [form.data.search, props.tags]);

	const deleteHandler = () => {
		setShowConfirmDialog(true);
	}

	const editHandler = e => {
		props.onEditTag(currentTag);
	}

	const onHideConfirmDialog = response => {
		if(response.button === 'btnOk') {
			props.deleteTag(currentTag.id);
		}
		setShowConfirmDialog(false);
	}

	const renderTags = () => {
		return tags.map(tag => {
			const classes = classNames('tag', { active: currentTag?.id === tag.id });
			return <li key={ tag.id } className={ classes } onClick={ () => setCurrentTag(tag) }>
				{ tag.tagName }
			</li>
		});
	}

	return (
		<Fragment>

			<Pane id="maintainTags">
				<PaneHeader>
					<h1>Maintain Tags</h1>
					<div className="Pane-toolbar">
						<IconButton icon="edit" disabled={ !currentTag } onClick={ editHandler }/>
						<IconButton icon="delete" disabled={ !currentTag } onClick={ deleteHandler }/>
					</div>
				</PaneHeader>
				
				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<div className="search-container">
						<FormInput form={ form } field="search" autoComplete="off"/>
					</div>

					{ tags.length > 0 && form.data.search &&
						<ul className="tagList">
							{ renderTags() }
						</ul>
					}

				</PaneBody>
			</Pane>

			<ConfirmDialog
				action={ utilities.getText('HDG_CONFIRM_DELETE', tag) }
				message={ utilities.getText('TXT_CONFIRM_DELETE', tag) }
				show={ showConfirmDialog }
				onHide={ onHideConfirmDialog }
			/>

		</Fragment>
	);
};

export default TagsForm;