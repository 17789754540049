import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const LanguagePicker = props => {
	const [open, setOpen] = useState(props.open ?? false);

	const renderOptions = () => {
		return props.options?.map((option, ix) => {
			return <DropdownItem tag="li" key={ ix } active={ props.value === option.value } onClick={ e => props.onChange(e, null, option.value) }>
				{ option.label }
			</DropdownItem>
		});
	}

	return (
		<Dropdown className="LanguagePicker DropdownButton" isOpen={ open } toggle={ e => setOpen(!open) }>
			<DropdownToggle
				id={ props.id }
				name={ props.name }
				title={ props.title }
				className="form-control form-control-btn"
				disabled={ props.disabled }
				tag="button"
				data-toggle="dropdown"
				aria-expanded={ open }
			>
				<span><i className="material-icons">translate</i></span>
				<i className="material-icons">{ open ? 'expand_less' : 'expand_more' }</i>
			</DropdownToggle>
			<DropdownMenu tag="ul" className="dropdown-menu_scroll" right={ true }>
				{ renderOptions() ?? '' }
			</DropdownMenu>
		</Dropdown>
	);
};

export default LanguagePicker;