// links from FlyoutNav should always be to the "folder" rather than a specific "page" (i.e. /venues/admin/ rather than /venues/admin/tags/)
// this means that the NavLink component in FlyoutNav will correctly highlight the correct "folder" link regardless of child page navigated to
// so this component will automatically redirect to the first "page" supplied in the props.nav array
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import { settingsActionCreators } from 'store/settings';
import VenueStatusControl from 'components/VenueStatusControl';
import FeatureNav from './FeatureNav';
import './Content.scss';

const Content = props => {
	const fullscreen = useSelector(state => state.settings.fullscreen);
	const dispatch = useDispatch();

	if(fullscreen) {
		// so pages that shouldn't be fullscreen don't get inadvertantly left without navigation
		const feature = /venues\/(.*?)\//.exec(window.location.pathname)[1];
		// console.log('feature:', feature);
		if(feature !== 'orders') {
			dispatch(settingsActionCreators.updateSetting('fullscreen', false));
		}
	}

	const classes = classNames('Content', props.className);
	return (
		<div className={ classes }>
			<div className="Content-nav">

				<div className="VenueStatusControl-container">
					<VenueStatusControl/>
				</div>
				<FeatureNav path={ props.path } nav={ props.nav } navRight={ props.navRight }/>

			</div>
			<main>
				<Switch>

					<Route exact path={ props.path }>
						<Redirect to={ props.path + props.nav[0].path + '/' }/>
					</Route>

					{	props.nav.map((nav, ix) => {
						if(nav.hide) return null;
						return <Route key={ ix } path={ props.path + nav.path + '/:id?' } component={ nav.component } />
					})}

				</Switch>
			</main>
		</div>
	);
}

export default Content;