// The store design here is roughly ducks-based (https://github.com/erikras/ducks-modular-redux)
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as billing from './billing';
import * as errors from './errors';
import * as l10n from './l10n';
import * as location from './location';
import * as menus from './menus';
import * as modifiers from './modifiers';
import * as orders from './orders';
import * as other from './other';
import * as pos from './pos';
import * as printers from './printers';
import * as rewards from './rewards';
import * as settings from './settings';
import * as stripe from './stripe';
import * as tags from './tags';
import * as taxCodes from './taxCodes';
import * as user from './user';
import * as venues from './venues';

const apiDomains = {
	developer: 'http://dev.pictabite.com',
	local: 'http://localhost:9181',
	dev: '',
	prod: '',
	remote: 'http://139.138.209.156:9181'
}

// We're no longer maintaining separate backends for SSE - if this becomes a permanent change, we may want to simplify config below
// const sseDomains = {
// 	developer: 'http://sse.dev.pictabite.com',
// 	local: 'http://localhost:9181',
// 	dev: 'http://sse.dev.pictabite.com',
// 	prod: 'https://sse.pictabite.com',
// 	remote: 'http://139.138.209.156:9181'
// }

export const config = {
	fakeApis: false,
	apiPath: apiDomains[process.env.REACT_APP_ENV] + '/services/ws',
	// ssePath: sseDomains[process.env.REACT_APP_ENV] + '/services/sse',
	ssePath: apiDomains[process.env.REACT_APP_ENV] + '/services/sse',

	// reduxLogging: false,
	// requestLogging: true,
	// responseLogging: true,
	// exceptionLogging: true

	reduxLogging: false,
	requestLogging: false,
	responseLogging: false,
	exceptionLogging: true
};

export default function configureStore(history, initialState) {
	const reducers = {
		billing: billing.reducer,
		errors: errors.reducer,
		l10n: l10n.reducer,
		location: location.reducer,
		menus: menus.reducer,
		modifiers: modifiers.reducer,
		orders: orders.reducer,
		other: other.reducer,
		pos: pos.reducer,
		printers: printers.reducer,
		rewards: rewards.reducer,
		settings: settings.reducer,
		stripe: stripe.reducer,
		tags: tags.reducer,
		taxCodes: taxCodes.reducer,
		user: user.reducer,
		venues: venues.reducer
	};

	const middleware = [
		thunk
	];

	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if(config.reduxLogging) {
		const { logger } = require('redux-logger');
		middleware.push(logger);
	}
	// In development, use the browser's Redux dev tools extension if installed
	if(isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const appReducer = combineReducers({
		...reducers
	});

	const rootReducer = (state, action) => {
		if(action.type === 'FETCH_VENUE_VENUES_SUCCESS') {
			// if venue is changed, we need to clear all state associated with prev venue
			// technically, I'm doing the opposite, i.e. preserving everything NOT associated with prev venue
			// I may want to reverse this as it may eventually be less complex / easier to follow
			// (I'm preserving errors as they are not necessarily venue related but this may require more thought)
			const { errors, l10n, location, settings, tags, taxCodes, user, venues } = state;
			state = { errors, l10n, location, settings, tags, taxCodes, user, venues };
		}
		if(action.type === 'LOGIN_USER_SUCCESS' || action.type === 'LOGOUT_USER_SUCCESS') {
			// need a major state reset on sign-in/sign-out
			localStorage.removeItem('pb_venue');	// need to reset some local storage as well
			const { l10n, location, settings, tags, taxCodes } = state;
			state = { l10n, location, settings, tags, taxCodes };
		}
		return appReducer(state, action);
	}

	return createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);
}
