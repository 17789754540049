import { Fragment } from "react";
import { useSelector } from "react-redux";
import { enUS, el } from 'date-fns/locale';
import utilities from "services/utilities";
import { distributionTypes } from "store/orders";

const useL10n = props => {

	const l10nStore = useSelector(state => state.l10n);

	const getText = (id, capitalise, ...args) => {
		// if no translations available, on live, return a blank string to maintain spacing where possible
		if(!l10nStore.translations) return process.env.REACT_APP_ENV === 'developer' ? id : '          ';
		let translation = l10nStore.translations[id];
		if(!translation) {
			console.error('usel10n failed to find match for', id);
			return id;
		}
		if(typeof translation === 'object') {
			translation = translation[capitalise ? 1 : 0];
		}
		translation = translation.split(' // ')[0];	// remove trailing comments
		const regex = /(.*?)\${(\d+)\s*}/gm;
		let match, lastLastIndex;
		const chunks = [];
		while((match = regex.exec(translation)) !== null) {
			if(match.index === regex.lastIndex) regex.lastIndex++;
			chunks.push(match[1]);	// add text before the component
			chunks.push(args[match[2]]);	// add the "thing" passed as an argument (designed to be a jsx component but can be anything really)
			lastLastIndex = regex.lastIndex;
		}
		if(lastLastIndex == null) return translation;	// there were no args to substitute
		if(lastLastIndex !== translation.length) chunks.push(translation.substring(lastLastIndex));	// add any trailing text
		// now we need to return it in a way react can render it...
		return chunks.map((el, ix) => <Fragment key={ ix }>{ el }</Fragment>);
	}

	const getDistributionType = order => {
		// this seemed the most logical place to put this, but it's still not that logical!
		// if we ever get rid of the usage of lower and uppercase array in _t, then this will need to change too
		const type = utilities.getDistributionTypeKey(order.distributionType);
		if(type) {
			if(order.distributionType === distributionTypes.EAT_IN_WAITSTAFF || order.distributionType === distributionTypes.ROOM_SERVICE) {
				return getText(type, false, order.waitstaffTarget ?? '?');
			} else {
				return getText(type);
			}
		} else {
			return null;
		}
	}

	const getCurrentDfLocale = locale => {
		return l10nStore.locale ? locales.find(locale => locale.value === l10nStore.locale).dfLocale : null;
	}
	
	return {
		getText,
		getCurrentDfLocale,
		getDistributionType
	}
}

export default useL10n;

// For simplicity, more "complex" locales such as en-US, pt-BR, el-GR are represented by en, pt and el if they are the largest representatives of those languages.
// Supports a fallback: '<locale>' option but we may not need this as auto-fallback will work in the vast majority of cases (all?).
export const locales = [
	{ 'value': 'en', 'label': 'English', dfLocale: enUS },
	// { 'value': 'en-GB', 'label': 'English (UK)' },
	{ 'value': 'el', 'label': 'Ελληνική', dfLocale: el }
];