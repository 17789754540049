import classNames from 'classnames';
import useL10n from 'L10n';
import Notification from 'components/Notification';

const Feedback = props => {

	const _t = useL10n().getText;

	if(!props.status?.errors && !props.status?.warnings ) return '';

	const classes = classNames('Feedback', props.className);
	return (
		<section className={ classes }>
			{ props.status.errors && props.status.errors.length > 0 &&
				<ul>
					{ props.status?.errors.map((error, ix) => {
						return <Notification key={ ix } type="error" design="outline">{ _t(error) }</Notification>
					})}
				</ul>
			}
			{ props.status.warnings && props.status.warnings.length > 0 &&
				<ul>
					{ props.status.warnings.map((warning, ix) => {
						return <Notification key={ ix } type="warning" design="outline">{ _t(warning) }</Notification>
					})}
				</ul>
			}
		</section>
	);
};

export default Feedback;