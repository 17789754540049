// since every action passes thru' every reducer, check for any that contain an exception and handle here
const initialState = { errors: null };

const actionTypes = {
	setErrors: 'SET_ERRORS',
	clearErrors: 'CLEAR_ERRORS'
}

// the following is useful for defining error codes that the global error handling process should ignore, etc
// usually, this will be because they need to be handled differently i.e. "locally"
export const errorCodes = {
	'GENERIC_API_ERROR': 400,	// see ErrorDialog
	'UNAUTHORISED_ACCESS': 403,	// see ErrorDialog
	'INCORRECT_PASSWORD': 463,	// see Owner
	'INVALID_TOKEN': 481,	// see Venues
	'LOGIN_FAILURE': 100	// see user.js / SignInDialog.js
}

export const errorsActionCreators = {
	setErrors: errors => {
		return { type: actionTypes.setErrors, errors: errors }
	},
	clearErrors: () => {
		return { type: actionTypes.clearErrors }
	}
}

export const reducer = (state = initialState, action) => {
	// phasing out exception in favour of errors
	const { exception, errors } = action;
	if(exception) {
		return { errors: exception }
	}
	if(errors) {
		return { errors: errors }
	}

	if(action.type === actionTypes.clearErrors) {
		return { errors: null }
	}

	return state;
}