import React from 'react';
import dates from '../../services/dates';
import utilities from '../../services/utilities';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../components/Modal';

const WaitstaffDetailsDialog = props => {
	return (
		<Modal id="waitstaffDetailsDialog" show={ props.show } draggable={ true }>
			<ModalHeader><h1>Waitstaff Details – { props.row?.name }</h1></ModalHeader>
			<ModalBody>

				<div className="row">
					<div className="col">

						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Date</label>
							<div className="col-6">
								<div className="form-val text-right">{ dates.format(props.row?.date) }</div>
							</div>
						</div>

						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Year</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.year }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Week of year</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.weekOfYear }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Week since 1970</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.weekSince1970 }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Week diff</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.weekDiff }</div>
							</div>
						</div>

						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Tables</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.numOfTables }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Seats</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.numOfChairs }</div>
							</div>
						</div>

					</div>
					<div className="col">

						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Monday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Mon }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Tuesday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Tue }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Wednesday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Wed }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Thursday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Thu }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Friday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Fri }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Saturday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Sat }</div>
							</div>
						</div>
						<div className="form-row">
							<label htmlFor="" className="col-form-label col-6">Sunday</label>
							<div className="col-6">
								<div className="form-val text-right">{ props.row?.waitstaff_Sun }</div>
							</div>
						</div>

					</div>
				</div>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-primary focus" onClick={ props.onHide } id="btnClose">{ utilities.getText('LBL_CLOSE') }</button>
			</ModalFooter>
		</Modal>
	);
};

export default WaitstaffDetailsDialog;