// see https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b
// add <BrowserRouter getUserConfirmation={ () => {} }> to App.js in order to disable the default Prompt component
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useL10n from 'L10n';
import ConfirmDialog from 'dialogs/ConfirmDialog';

const RouterPrompt = props => {
	const _t = useL10n().getText;

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [currentPath, setCurrentPath] = useState('');
	const history = useHistory();
	const unblockRef = useRef();

	useEffect(() => {
		if(props.when) {
			unblockRef.current = history.block(prompt => {
				setCurrentPath(prompt.pathname);
				setShowConfirmDialog(true);
				return 'true';
			});
		} else {
			unblockRef.current && unblockRef.current();
		}

		return () => {
			unblockRef.current && unblockRef.current();
		};
	}, [history, props.when]);

	const onHideConfirmDialog = response => {
		if(response.button === 'btnCancel') {
			unblockRef.current && unblockRef.current();
			history.push(currentPath);
		}
		setShowConfirmDialog(false);
	}

	return (
		<ConfirmDialog
			action={ _t('HDG_UNSAVED_CHANGES', true) }
			message={ _t('TXT_UNSAVED_CHANGES') }
			okText={ _t('BTN_KEEP_EDITING', true) }
			cancelText={ _t('BTN_CONTINUE', true) }
			show={ showConfirmDialog }
			onHide={ onHideConfirmDialog }
		/>
	);
};

export default RouterPrompt;