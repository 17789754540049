import React, { useState } from 'react';
import classNames from 'classnames';
import Flyout from './Flyout';
import './FlyoutPage.scss';

const FlyoutPage = props => {

	const [collapsed, setCollapsed] = useState(window.innerWidth < 768);

	const toggle = e => {
		setCollapsed(!collapsed);
	}

	const className = classNames('FlyoutPage', { 'collapsed': collapsed}, { 'fullscreen': props.fullscreen }, props.className);
	return (
		<div className={ className }>
			<Flyout onClick={ toggle } collapsed={ collapsed } signInOutHandler={ props.signInOutHandler }/>
			{ props.children }
		</div>
	);

}

export default FlyoutPage;