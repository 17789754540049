// currently, nothing here results in a state change
// other "other" methods exist in users (login, logout) and venues (setSelectedVenue)
// pattern here is a little different - on success, no action is dispatched (so loading is not cleared)
// instead loading is used to display a response dialog, which when closed, clears loading
// any errors will automatically hide the response dialog
import storeUtils from './storeUtils';

export const actionTypes = {
	otherFail: 'OTHER_FAIL',
	
	requestDemoRequest: 'REQUESTDEMO_OTHER_REQUEST',
	contactUsRequest: 'CONTACTUS_OTHER_REQUEST',
	submitSuccess: 'SUBMIT_OTHER_SUCCESS'
}

const initialState = { loading: null, errors: null };

export const otherActionCreators = {
	
	requestDemo: formData => async dispatch => {
		dispatch({ type: actionTypes.requestDemoRequest });
		const data = await storeUtils.execute('other', 'POSTWebForm', formData);
		if(data.errors) {
			dispatch({ type: actionTypes.otherFail, errors: data.errors });
		}
	},
	
	contactUs: formData => async dispatch => {
		dispatch({ type: actionTypes.contactUsRequest });
		const data = await storeUtils.execute('other', 'contactUs', formData);
		if(data.errors) {
			dispatch({ type: actionTypes.otherFail, errors: data.errors });
		}
	},

	clearMessage: () => {
		return { type: actionTypes.submitSuccess };
	}

};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('OTHER_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.otherFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.submitSuccess: {
			return {
				...state,
				loading: null,
				errors: null
			}
		}

		default: {
			return state;
		}
	}
}