import React, { useEffect, useState } from 'react';
import utilities from '../../services/utilities';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../components/Modal';
import DropdownButton from '../../components/DropdownButton';

const OrderCancelDialog = props => {

	const [message, setMessage] = useState();

	useEffect(() => {
		// reset
		if(props.show) {
			setMessage('');
		}
	}, [props.show]);

	const messageChangeHandler = e => {
		setMessage(e.target.value);
	}

	const reasonHandler = (event, field, value, label) => {
		setMessage(label);
	}

	const buttonClickHandler = e => {
		let payload;
		if(e.target.id === 'btnOk') {
			payload = {
				cancellationReason: 'v',
				cancellationNotes: message
			}
		}
		props.onHide(payload);
	}

	return (
		<Modal id="orderCancelDialog" show={ props.show }>
			<ModalHeader>{ utilities.getText('LBL_ORDER_CANCEL') + ': ' + props.orderId }</ModalHeader>
			<ModalBody>

				<p>{ utilities.getText('TXT_ORDER_CANCEL') }</p>

				<textarea
					className="form-control"
					id="customerMessage"
					placeholder={ utilities.getText('PLH_CUSTOMER_MSG') }
					value={ message }
					maxLength="255"
					onChange={ messageChangeHandler }
				/>

				<DropdownButton placeholder="Choose a reason" className="mt10" onChange={ reasonHandler }>
					<option value="Restaurant">We are closing soon</option>
					<option value="Deli">Items out of stock</option>
					<option value="utensilsNotProvided">We can't fulfill your custom instructions</option>
				</DropdownButton>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ buttonClickHandler } id="btnCancel">
					{ utilities.getText('LBL_CANCEL') }
				</button>
				<button className="btn btn-primary focus" onClick={ buttonClickHandler } id="btnOk">
					{ utilities.getText('LBL_OK') }
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default OrderCancelDialog;