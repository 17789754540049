import Content from 'layout/Content';
import Tags from './Tags';
import Translate from './Translate';
import WaitStaff from './WaitStaff';
import './index.scss';

const Admin = props => {

	const nav = [
		{ path: 'tags', label: 'Tags', component: Tags },
		{ path: 'waitstaff', label: 'WaitStaff', component: WaitStaff },
		{ path: 'translate', label: 'Translate', component: Translate }
	];

	return (
		<Content path="/venues/admin/" nav={ nav }/>
	);

}

export default Admin;