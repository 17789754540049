import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dates from 'services/dates';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import Calendar from 'components/Calendar';
import useForm from 'components/Form';
import { FormDatePicker } from 'components/formControls';
import ImageControl from 'components/ImageControl';
import { ReactComponent as Led } from 'assets/img/led.svg';

const Other = props => {
	const [output, setOutput] = useState();
	const l10nStore = useSelector(state => state.l10n);

	// const timeZone = 'America/New_York';
	const timeZone = 'Australia/Melbourne';

	const _t = useL10n().getText;

	const formSave = e => {
		console.log(form.data);
		// props.save(form.data);
	}

	const changeHandler = (e, id, key, label) => {
		if(e instanceof Date) {
			console.log(e, id);	// (date, e)
		} else {
			console.log(utilities.processFormControl(e, id, key, label));
		}
	}

	const form = useForm(formSave);

	useEffect(() => {
		if(form.data?.startDate?.toISOString()) {
			const utcDate = dates.zonedDateToUtc(timeZone, form.data.startDate);
			console.log(utcDate);
			setOutput('UTC= ' + utcDate.toISOString() + ' (' + timeZone + ')');
		} else {
			setOutput(null);
		}
	}, [form.data]);

	return (
		<div className="row" id="other">
			<div className="col">

				<h3>Dates in other time zones</h3>

				<FormDatePicker placeholder="Start date" form={ form } field="startDate" clear={ true }/>

				<p>{ output }</p>

				<ImageControl
					name="imageControl"
					type="input"
					options={ ['one', 'two'] }
					onChange={ changeHandler }
				/>

				<div>
					<Led className="led_green"/>
				</div>

				<div>
					Current UI language: { _t('LANGUAGE') }
				</div>

			</div>
			<div className="col">
				
				<Calendar
					date={ new Date('2020-02-29') }
					localeData={ l10nStore.translations?.localeData }
					onChange={ changeHandler }
				/>

			</div>
		</div>

	);
};

export default Other;