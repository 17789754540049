import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';

export const IconButton = ({ className, icon, async, disabled, nav, ...props }) => {
	let btnClassName = classNames('btn', 'btn-icon', className, { 'async': async });
	const isDisabled = async || disabled;
	if(props.to) {
		return (
			<Link className={ btnClassName } disabled={ isDisabled } { ...props }>
				<span className="material-icons">{ icon }</span>
			</Link>
		);
	} else if(nav) {
		return (
			<NavLink className={ btnClassName } disabled={ isDisabled } to={ nav } { ...props }>
				<span className="material-icons">{ icon }</span>
			</NavLink>
		);
	} else {
		return (
			<button type="button" className={ btnClassName } disabled={ isDisabled } { ...props }>
				<span className="material-icons">{ icon }</span>
			</button>
		);
	}
}

export const TextButton = ({ className, children, async, disabled, ...props }) => {
	// this is useful if a button on a dialog does an api call, then button state (async + disabled) is handled for you
	let btnClassName = classNames('btn', className, { 'async': async });
	const isDisabled = async || disabled;
	return (
		<button type="button" className={ btnClassName } disabled={ isDisabled } { ...props }>
			{ children }
		</button>
	);
}