// If a centre property is provided, a map and the places api are available, otherwise just places api
import { useEffect, useRef } from 'react';
import classNames from 'classnames';

const MapControl = props => {
	const googleMapRef = useRef();
	const mapRef = useRef();
	const zoomFactor = 16	// equivalent to Google Maps reporting 200m in BR corner

	useEffect(() => {
		const createMap = (lat, lng, zoom) => {
			const map = new window.google.maps.Map(googleMapRef.current, {
				center: { lat: lat, lng: lng },
				zoom: zoom
			});
			mapRef.current = map;
			// console.log('MAP CREATED CENTERED ON: (', lat, lng, ')');
			if(props.onInit) {
				props.onInit(map);
			}
		}

		loadGoogleMapScript(() => {
			if(typeof props.centre === 'undefined') {
				// if no centre prop supplied, assume we don't want an actual map, just the places api
				if(props.onInit) {
					props.onInit();
				}
				return;
			}
			// centre prop supplied, so create a map to display
			if(typeof props.centre === 'boolean' && props.centre) {
				// try to centre on the user's location...
				navigator.geolocation.getCurrentPosition(
					position => {
						createMap(position.coords.latitude, position.coords.longitude, props.zoom || zoomFactor);
					},
					err => {
						console.error('unable to use browser geolocation:', err);
						createMap(0, 0, props.zoom || 2);	// no idea where to centre map so zoom out
					},
					{ timeout: 5000 }
				);
			} else {
				// centre on the supplied location...
				createMap(props.centre[0], props.centre[1], props.zoom || zoomFactor);
			}
		});
		// should only run once, not on changes so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(mapRef.current) {
			// console.log('recentering map to ', props.centre);
			mapRef.current.setCenter({ lat: props.centre[0], lng: props.centre[1] });
		}
	}, [props.centre]);

	const classes = classNames('MapControl', props.className);

	if(typeof props.centre === 'undefined') {
		return '';
	} else {
		return (
			<div
				className={ classes }
				ref={ googleMapRef }
				style={{ width: props.width, height: props.height }}
			/>
		);
	}
};

export default MapControl;

const loadGoogleMapScript = callback => {
	if(typeof window.google === 'object' && typeof window.google.maps === 'object') {
		callback();
	} else {
		// console.log('LOADING GOOGLE MAPS...');
		const mapScript = document.createElement('script');
		mapScript.async = true;
		mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${ process.env.REACT_APP_GOOGLE_API_KEY }&libraries=places`;
		window.document.body.appendChild(mapScript);
		mapScript.addEventListener('load', callback);
	}
}