import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ImageControl.scss';

const ImageControl = props => {

	let value = (typeof props.value === 'boolean') ? (props.value ? 1 : 0) : props.value;
	if(props.type === 'input' && !props.value) {
		// if no value passed, default value to first in provided options array
		value = props.options[0];
	}

	const onClick = e => {
		// if clicked, the control will cycle to the next available option
		if(!props.disabled && props.options) {
			if(typeof props.options[0] === 'number') value = parseInt(value, 10);
			let valIndex = props.options.indexOf(value);
			if(valIndex < props.options.length - 1) {
				valIndex++;
			} else {
				valIndex = 0;
			}
			value = props.options[valIndex];
			props.onChange(e, props.name, value);
		}
	}

	const doNothing = e => {
		// hmm, stop react compaining for now
		return null;
	}

	let classes = classNames('ImageControl', props.className, 'control-' + props.name, 'control-' + value);
	return (
		<div disabled={ props.disabled } className={ classes }>
			<label>
				{ props.type === "checkbox" &&
					<input type="checkbox" disabled={ props.disabled } name={ props.name } checked={ props.value } onChange={ props.onChange }/>
				}
				{ props.type === "input" &&
					<input type="text" disabled={ props.disabled } name={ props.name } value={ value === null ? '' : value } onClick={ onClick } onChange={ doNothing }/>
				}
				{ props.label }
			</label>
		</div>
	);

}

ImageControl.propTypes = {
	disabled: PropTypes.bool,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	options: PropTypes.array,
	onChange: PropTypes.func
};

export default ImageControl;