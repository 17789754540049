// in this context, 'links' in verbiage would be more appropriate if they were links rather than buttons
// but the primary use of verbiage is via a dialog on the home page hence the buttons
import { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import verbiage from "L10n/verbiage";

const Ext = props => {
	const { page } = useParams();
	const history = useHistory();
	const mainRef = useRef();

	const l10nStore = useSelector(state => state.l10n);

	const clickHandler = e => {
		if(e.target.tagName !== 'BUTTON') return;
		const newPage = e.target.className.substring(4);
		if(verbiage.hasPage(newPage)) {
			history.push(`/app-${ newPage }.html`);
			mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		} else {
			console.error('Invalid link format in verbiage.');
		}
	}

	const classes = classNames('info', `info-${ page }`);
	const text = verbiage.get(page, l10nStore.locale);
	if(text) {
		return <main ref={ mainRef } className={ classes } dangerouslySetInnerHTML={{ __html: text }} onClick={ clickHandler }/>;
	} else {
		return '';
	}
};

export default Ext;