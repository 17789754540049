import { Pane, PaneHeader, PaneBody, PaneFooter } from '../components/Pane';
import { Table, Row, Cell, Col } from 'components/Table';
import './Tables.scss';

const Tables = props => {
	return (
		<div id="tablesContainer" className="animated fadeIn pb10">

			<Table id="table1" className="Table_bordered Table_striped">
				<Row heading={ true }>
					<Col w="33%">Title</Col><Col w="33%">Release Date</Col><Col>Peak Chart Position</Col>
				</Row>
				<Row>
					<Cell w="33%">Please Please Me</Cell><Cell w="33%">22/03/1963</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">With The Beatles</Cell><Cell w="33%">22/11/1963</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">A Hard Day's Night</Cell><Cell w="33%">26/06/1964</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Beatles For Sale</Cell><Cell w="33%">04/12/1964</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Help!</Cell><Cell w="33%">13/08/1965</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Rubber Soul</Cell><Cell w="33%">03/12/1965</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Revolver</Cell><Cell w="33%">05/08/1966</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Sgt. Pepper's Lonely Hearts Club Band</Cell><Cell w="33%">26/05/1967</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Magical Mystery Tour</Cell><Cell w="33%">27/11/1967</Cell><Cell>31</Cell>
				</Row>
				<Row>
					<Cell w="33%">The Beatles (The White Album)</Cell><Cell w="33%">22/11/1968</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Yellow Submarine</Cell><Cell w="33%">13/01/1969</Cell><Cell>3</Cell>
				</Row>
				<Row>
					<Cell w="33%">Abbey Road</Cell><Cell w="33%">26/09/1969</Cell><Cell>1</Cell>
				</Row>
				<Row>
					<Cell w="33%">Let It Be</Cell><Cell w="33%">08/05/1970</Cell><Cell>1</Cell>
				</Row>
			</Table>

			<Pane id="table2" role="table">
				<PaneHeader>
					<Col w="30%">Customer</Col><Col w="28%">Rewards</Col><Col>ALL TIME</Col>
				</PaneHeader>
				<PaneBody>
					<Row heading={ true }>
						<Col w="5%">Photo</Col>
						<Col w="25%">Name</Col>
						<Col w="14%" className="divider-left"># Orders</Col>
						<Col w="14%">Value</Col>
						<Col w="14%" className="divider-left">Rank</Col>
						<Col w="14%"># Orders</Col>
						<Col w="14%">Value</Col>
					</Row>
					<Row>
						<Cell w="5%" className="pv0"><span className="material-icons">photo_camera</span></Cell>
						<Cell w="25%">Fred Bloggs</Cell>
						<Cell w="14%" className="divider-left">3/10</Cell>
						<Cell w="14%">$45.28</Cell>
						<Cell w="14%" className="divider-left">1</Cell>
						<Cell w="14%">36</Cell>
						<Cell w="14%">$512.54</Cell>
					</Row>
					<Row>
						<Cell w="5%"></Cell>
						<Cell w="25%">John Doe</Cell>
						<Cell w="14%" className="divider-left">2/15</Cell>
						<Cell w="14%">$25.40</Cell>
						<Cell w="14%" className="divider-left">2</Cell>
						<Cell w="14%">36</Cell>
						<Cell w="14%">$512.54</Cell>
					</Row>
					<Row>
						<Cell w="5%" className="pv0"><span className="material-icons">photo_camera</span></Cell>
						<Cell w="25%">Fred Bloggs</Cell>
						<Cell w="14%" className="divider-left">3/10</Cell>
						<Cell w="14%">$45.28</Cell>
						<Cell w="14%" className="divider-left">3</Cell>
						<Cell w="14%">30</Cell>
						<Cell w="14%">$412.54</Cell>
					</Row>
				</PaneBody>
				<PaneFooter>
					something goes here
				</PaneFooter>
			</Pane>

			<Pane id="table3" className="Table_striped Table_selectable" role="table">
				<PaneHeader>
					<Col w="42%">Name</Col><Col w="42%">Role</Col><Col>Sick Days</Col>
				</PaneHeader>
				<PaneBody className="pb5">
					<Row to="1">
						<Cell w="42%">Luigi DaVinci</Cell>
						<Cell w="42%">Manager</Cell>
						<Cell w="14%">6</Cell>
					</Row>
					<Row to="2">
						<Cell w="42%">Mateo DaVinci</Cell>
						<Cell w="42%">Chef</Cell>
						<Cell w="14%">3</Cell>
					</Row>
					<Row to="3">
						<Cell w="42%">Sara DaVinci</Cell>
						<Cell w="42%">Waitress</Cell>
						<Cell w="14%">2</Cell>
					</Row>
					<Row to="4">
						<Cell w="42%">Lina DaVinci</Cell>
						<Cell w="42%">Waitress</Cell>
						<Cell w="14%">4</Cell>
					</Row>
				</PaneBody>
			</Pane>

		</div>
	);
};

export default Tables;