import React from 'react';
import PropTypes from 'prop-types';
import utilities from 'services/utilities';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';

const MessageDialog = ({ title, message, onHide, bodyClass, onMessageClick, ...dialogProps }) => {

	return (
		<Modal id="messageDialog" onHide={ onHide } { ...dialogProps }>
			<ModalHeader>{ title }</ModalHeader>
			<ModalBody className={ bodyClass }>
				<div dangerouslySetInnerHTML={{ __html: message }} onClick={ onMessageClick }/>
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-primary focus" onClick={ onHide } id="btnOk">
					{ utilities.getText('LBL_CLOSE') }
				</button>
			</ModalFooter>
		</Modal>
	);
}

MessageDialog.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	// and now Dialog types...
	show: PropTypes.bool,
	className: PropTypes.string,	// add a custom class to the component's own
	size: PropTypes.string	// small, large, max (omit for default medium)
};

export default MessageDialog;