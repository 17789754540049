// the prop used for title is action. not title, so that if we ever go multilingual, we can use action to find the appropriate title for the language
import PropTypes from 'prop-types';
import useL10n from 'L10n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';

const ConfirmDialog = ({ action, heading, message, onHide, bodyClass, okText, cancelText, ...dialogProps }) => {
	const _t = useL10n().getText;

	// action should now be separate from heading so heading can be translated without affecting workflow

	const onButtonClick = e => {
		onHide({
			action: action,
			button: e?.target.id
		});
	}

	const renderBody = () => {
		if(typeof message === 'string') {
			return <div dangerouslySetInnerHTML={{ __html: message }}/>;
		} else if(message.length) {
			// output of L10n can be an array of React elements that we'll need to process
			let msg = '';
			message.forEach(part => msg += part.props.children);
			return <div dangerouslySetInnerHTML={{ __html: msg }}/>
		} else {
			return message;
		}
	}

	return (
		<Modal id="confirmDialog" onHide={ onButtonClick } { ...dialogProps }>
			<ModalHeader><h1>{ heading || action }</h1></ModalHeader>
			<ModalBody className={ bodyClass }>
				{ renderBody() }
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ onButtonClick } id="btnCancel">
					{ cancelText ?? _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary focus" onClick={ onButtonClick } id="btnOk">
					{ okText ?? _t('BTN_OK', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
}

ConfirmDialog.propTypes = {
	action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
	// and now Dialog types...
	show: PropTypes.bool,
	className: PropTypes.string,	// add a custom class to the component's own
	size: PropTypes.string	// small, large, max (omit for default medium)
};

export default ConfirmDialog;