import sound0 from 'assets/sounds/silence.mp3';
import sound1 from 'assets/sounds/bell-short.mp3';
import sound2 from 'assets/sounds/bell-long.mp3';

const files = [sound0, sound1, sound2];
let audioElement;

const play = (n, failHandler) => {
	// some browsers won't play a sound before human interaction - in this case, failHandler will be called
	// simply don't pass a handler if not needed (i.e. always played in response to user selection)
	audioElement = new Audio(files[n]);
	const promise = audioElement.play();
	promise.then(() => {}).catch(err => {
		// user needs to interact in order to allow playing of audio
		if(failHandler) failHandler();
	});
}

const pause = () => {
	audioElement.pause();
}

export default {
	play,
	pause
};