import React, { useEffect, useRef } from 'react';
import utilities from '../../services/utilities';
import useForm from '../../components/Form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../components/Modal';
import { FormCheckbox, FormDropdown, FormInput } from '../../components/formControls';
import ValidationMessage from '../../components/ValidationMessage';

const initFormData = {
	modifierGroupId: null,
	min: 0,
	max: null,
	required: false
};

const validationRules = {
	modifierGroupId: true,
	min: true
}

const ModifierGroupDialog = props => {

	const formSave = () => {
		const newGroup = { ...props.group, ...form.data };
		newGroup.min = parseInt(newGroup.min, 10);
		if(newGroup.max === null) {
			newGroup.max = 0;
		} else {
			newGroup.max = parseInt(newGroup.max, 10);
		}
		newGroup.modifierGroupId = parseInt(newGroup.modifierGroupId, 10);
		newGroup.name = props.groups.find(group => group.id === newGroup.modifierGroupId).name;
		if(newGroup.orderIndex === undefined) {
			newGroup.orderIndex = props.item.modifierGroups ? utilities.getNextOrderIndex(props.item.modifierGroups) : 1;
		}
		// console.log('formSave', newGroup);
		props.onHide(null, newGroup);
	}

	const form = useForm(formSave, validationRules, initFormData);
	const resetRef = useRef(form.reset);	// we don't want this function ref changing every render

	useEffect(() => {
		// business rules [see PB-85]
		let resetData = null;
		if(form.data.min === '' || form.data.min === '0') {
			if(!resetData) resetData = { ...form.data };
			resetData.min = 0;
			resetData.required = false;
		}
		if(form.data.max === '0') {
			if(!resetData) resetData = { ...form.data };
			resetData.max = null;
		}
		if(resetData) {
			resetRef.current(resetData);
		}
	}, [form.data]);

	useEffect(() => {
		if(props.show) {
			// on showing the Dialog, populate the form data either with the selected group (editing) or empty data (adding new)
			if(props.group) {
				const formData = { ...props.group };
				if(formData.max === 0) formData.max = null;
				resetRef.current(formData, false);
			} else {
				resetRef.current(initFormData, false);
			}
		}
	}, [props.show, props.group]);

	if(!props.groups) return null;

	return (
		<Modal id="modifierGroupDialog" show={ props.show } draggable={ true } onHide={ props.onHide }>
			<ModalHeader>Add Modifier Group</ModalHeader>

			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<div className="row mb15">
					<div className="col-sm">
						<FormDropdown
							placeholder="Modifier Group"
							form={ form }
							field="modifierGroupId"
							disabled={ !!form.data.modifierGroupId }
						>
							{ props.groups.map((group, ix) => {
								return <option key={ ix } value={ group.id + '' }>{ group.name }</option>
							}) }
						</FormDropdown>
					</div>
				</div>

				<div className="row mb10"><div className="col-sm">How many items should the customer be allowed to select from this group?</div></div>

				<div className="row">
					<label htmlFor="min" className="col-sm-auto">From:</label>
					<FormInput type="number" min="0" className="col-sm" form={ form } field="min" id="min"/>
					<label htmlFor="max" className="col-sm-auto">To:</label>
					<FormInput type="number" min="0" className="col-sm" placeholder="Any" form={ form } field="max" id="max"/>
					<FormCheckbox
						className="col-sm-auto"
						form={ form }
						field="required"
						disabled={ parseInt(form.data.min, 10) === 0 }
					>
						Selection is mandatory
					</FormCheckbox>
				</div>

			</ModalBody>

			<ModalFooter className="Modal-footer_buttons">
				<button
					className="btn btn-secondary"
					id="btnCancel"
					onClick={ props.onHide }
				>
					{ utilities.getText('LBL_CANCEL') }
				</button>
				<button
					className="btn btn-primary"
					id="btnOk"
					disabled={ !form.isDirty }
					onClick={ form.onSave }
				>
					{ utilities.getText('LBL_SAVE') }
				</button>
			</ModalFooter>

		</Modal>
	);
};

export default ModifierGroupDialog;