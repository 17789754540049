import React, { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import photo from '../services/photo';
import { errorsActionCreators } from '../store/errors';
import { actionTypes, venuesActionCreators } from '../store/venues';
import utilities from '../services/utilities';
import { Pane, PaneHeader, PaneBody } from '../components/Pane';
import PhotoCarousel from '../components/PhotoCarousel';
import ResizeDialog from '../components/ResizeDialog';
import UploaderPanel from '../components/UploaderPanel';

const Branding = props => {

	const [showResizeDialog, setShowResizeDialog] = useState(false);
	const [imageData, setImageData] = useState();
	const [source, setSource] = useState();
	const logoImgRef = useRef();
	const venueStore = useSelector(state => state.venues);
	const dispatch = useDispatch();

	const onSelectFiles = async (source, files) => {
		if(files[0].type !== 'image/jpeg') {
			dispatch(errorsActionCreators.setErrors([utilities.getText('ERR_WRONG_EXTENSION', files[0].name)]));
		} else {
			const imageDataUrl = await photo.readFile(files[0]);
			setImageData(imageDataUrl);
			setSource(source);
			setShowResizeDialog(true);
		}
	}
	
	const onResizeDialogHide = canvas => {
		if(canvas) {
			canvas.toBlob(blob => {
				// console.log(blob);
				switch(source) {
					case 'logoUploader':
						dispatch(venuesActionCreators.uploadVenueLogo(venueStore.venue.venueUUID, blob));
						break;
					case 'featuredPhotoUploader':
						dispatch(venuesActionCreators.uploadFeaturedPhoto(venueStore.venue.venueUUID, blob));
						break;
					default:
						dispatch(venuesActionCreators.uploadVenuePhoto(venueStore.venue.venueUUID, blob));
						break;
					}
			}, 'image/jpeg');
		}
		setShowResizeDialog(false);
	}

	const onDeletePhoto = (photo, type) => {
		dispatch(venuesActionCreators.deletePhoto(photo, type));
	}

	const setFavourite = photo => {
		dispatch(venuesActionCreators.setFeaturedPhoto(photo));
	}

	const getAction = type => {
		if(type === 'f') {
			switch(venueStore.loading) {
				case actionTypes.setFeaturedPhotoRequest: return 'featuring';
				case actionTypes.deleteFeaturedPhotoRequest: return 'deleting';
				default: return;
			}
		} else {
			if(venueStore.loading === actionTypes.deleteVenuePhotoRequest) return 'deleting';
		}
	}

	return (
		<Fragment>

			<div className="row animated fadeIn">
				<div className="col-lg-4">

					<Pane id="logoPane">
						<PaneHeader>Logo</PaneHeader>
						<PaneBody className="container">

							<UploaderPanel id="logoUploader" onSelectFiles={ onSelectFiles } loading={ venueStore.loading === actionTypes.uploadVenueLogoRequest }>
								{ utilities.getText('TXT_UPLOAD_LOGO') }
							</UploaderPanel>

							<div id="logoHolder">
								{ !!venueStore.venue?.logoUrl &&
									<img ref={ logoImgRef } alt="your logo" src={ venueStore.venue?.logoUrl }/>
								}
							</div>

						</PaneBody>
					</Pane>

				</div>
				<div className="col-lg-4">

					<Pane id="venuePhotosPane">
						<PaneHeader>Restaurant Photos</PaneHeader>
						<PaneBody className="container">

							<UploaderPanel id="venuePhotoUploader" onSelectFiles={ onSelectFiles } loading={ venueStore.loading === actionTypes.uploadVenuePhotoRequest }>
								{ utilities.getText('TXT_UPLOAD_VENUE_PHOTO') }
							</UploaderPanel>

							{ !!venueStore.venue?.photos?.length &&
								<PhotoCarousel
									photos={ venueStore.venue.photos }
									type="v"
									action={ getAction('v') }
									visibleSlides={ 2 }
									showDots={ true }
									showToolbar={ true }
									onDeletePhoto={ onDeletePhoto }
								/>
							}

						</PaneBody>
					</Pane>

				</div>
				<div className="col-lg-4">

					<Pane id="featuredPhotosPane">
						<PaneHeader>Featured Photos</PaneHeader>
						<PaneBody className="container">

							<UploaderPanel id="featuredPhotoUploader" onSelectFiles={ onSelectFiles } loading={ venueStore.loading === actionTypes.uploadVenuePhotoRequest }>
								{ utilities.getText('TXT_UPLOAD_FEATURED_PHOTO') }
							</UploaderPanel>

							{ !!venueStore.venue?.featuredPhotos?.length &&
								<PhotoCarousel
									photos={ venueStore.venue.featuredPhotos }
									selectedPhoto={ venueStore.venue.featuredPhoto }
									type="f"
									action={ getAction('f') }
									visibleSlides={ 2 }
									// showLast={ true }
									showDots={ true }
									showToolbar={ true }
									favourite={ venueStore.venue?.featuredPhotoId }
									setFavourite={ setFavourite }
									onDeletePhoto={ onDeletePhoto }
								/>
							}

						</PaneBody>
					</Pane>

				</div>
			</div>

			<ResizeDialog show={ showResizeDialog } imageData={ imageData } aspectRatio={ source === 'logoUploader' ? 1 : 4 / 3 } onHide={ onResizeDialogHide }/>

		</Fragment>
	);
};

export default Branding;