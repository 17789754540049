import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import utilities from 'services/utilities';
import { actionTypes, posActionCreators } from 'store/pos';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import { IconButton } from 'components/buttons';
import useForm from 'components/Form';
import { FormCheckbox, FormDropdown, FormInput, FormLabel } from 'components/formControls';
import { Pane, PaneBody, PaneFooter, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import Feedback from './Feedback';

const Pos = props => {

	const [confirmDialogShow, setConfirmDialogShow] = useState(false);
	const posStore = useSelector(state => state.pos);
	const venueId = useSelector(state => state.venues.venue?.venueUUID);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	const formSave = () => {
		form.data.receivesOrdersInOwnPOS = form.data.receivesOrdersInOwnPOS ? 1 : 0;
		dispatch(posActionCreators.updatePOSinterfaceConfig(form.data));
	}

	const form = useForm(formSave, validationRules, new PosConfig());
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(venueId) {
			dispatch(posActionCreators.fetchPOSinterfaceConfig());
		}
	}, [venueId, dispatch]);

	useEffect(() => {
		if(posStore.POSinterfaceConfig) {
			resetRef.current(posStore.POSinterfaceConfig);
		} else {
			resetRef.current(new PosConfig());
		}
	}, [posStore.POSinterfaceConfig]);

	const onDelete = e => {
		// console.log('delete', currentPhoto.photoId, props.type);
		setConfirmDialogShow(true);
	}

	const confirmDialogHide = response => {
		if(response.button === 'btnOk') {
			dispatch(posActionCreators.deletePOSinterfaceConfig());
		}
		setConfirmDialogShow(false);
	}

	return (
		<div className="animated fadeIn max-width-600">

			<Pane id="settingsPane">
				<PaneHeader className={ posStore.loading === actionTypes.fetchPOSinterfaceConfigRequest ? 'async' : null }>
					<h1>{ _t('HDG_POS_INTEGRATION', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_DELETE') }
							icon="delete"
							disabled={ form.data.posType === null }
							async={ posStore.loading === actionTypes.deletePOSinterfaceConfigRequest }
							onClick={ onDelete }
						/>
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							disabled={ form.data.posType === null }
							async={ posStore.loading === actionTypes.updatePOSinterfaceConfigRequest }
							onClick={ form.onSave }
						/>
					</div>
				</PaneHeader>
				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<div className="form-row">
						<FormLabel htmlFor="posType" className="col-sm-4" label="_POS_TYPE"/>
						<div className="col-sm-8">
							<FormDropdown form={ form } field="posType" options={ posOptions }/>
						</div>
					</div>
					<div className="form-row">
						<FormLabel htmlFor="carryoutOrderTypeId" className="col-sm-4" label="_CARRYOUT_ORDER_TYPE_ID"/>
						<div className="col-sm-8">
							<FormInput form={ form } field="carryoutOrderTypeId"/>
						</div>
					</div>
					<div className="form-row">
						<FormLabel htmlFor="deliveryOrderTypeId" className="col-sm-4" label="_DELIVERY_ORDER_TYPE_ID"/>
						<div className="col-sm-8">
							<FormInput form={ form } field="deliveryOrderTypeId"/>
						</div>
					</div>
					<div className="form-row">
						<FormLabel htmlFor="inhouseOrderTypeId" className="col-sm-4" label="_INHOUSE_ORDER_TYPE_ID"/>
						<div className="col-sm-8">
							<FormInput form={ form } field="inhouseOrderTypeId"/>
						</div>
					</div>
					<div className="form-row">
						<FormLabel htmlFor="employeeId" className="col-sm-4" label="_EMPLOYEE_ID"/>
						<div className="col-sm-8">
							<FormInput form={ form } field="employeeId"/>
						</div>
					</div>
					<div className="form-row">
						<FormLabel htmlFor="locationId" className="col-sm-4" label="_LOCATION_ID"/>
						<div className="col-sm-8">
							<FormInput form={ form } field="locationId"/>
						</div>
					</div>
					<div className="form-row">
						<FormLabel htmlFor="revenueCenterId" className="col-sm-4" label="_REVENUE_CENTER_ID"/>
						<div className="col-sm-8">
							<FormInput form={ form } field="revenueCenterId"/>
						</div>
					</div>

					<Feedback className="mt15" status={ posStore.status }/>

				</PaneBody>
				<PaneFooter className="align-items-center justify-content-center">
					<FormCheckbox form={ form } field="receivesOrdersInOwnPOS">{ utilities.getText('LBL_POS_INTEGRATION_ENABLED') }</FormCheckbox>
				</PaneFooter>
			</Pane>

			<ConfirmDialog
				action="Delete POS Interface Configuration"
				message="Are you sure you want to delete this POS Interface Configuration?"
				show={ confirmDialogShow }
				onHide={ confirmDialogHide }
			/>

			<RouterPrompt when={ form.isDirty }/>

		</div>
	);
};

export default Pos;

const validationRules = {
	posType: true,
}

class PosConfig {
	receivesOrdersInOwnPOS = false;
	posType = null;
	carryoutOrderTypeId = null;
	deliveryOrderTypeId = null;
	inhouseOrderTypeId = null;
	employeeId = null;
	locationId = null;
	revenueCenterId = null;
};

const posOptions = [
	{ value: "h", label: 'Azbar'},
	{ value: "c", label: 'Dinerware'},
	{ value: "d", label: 'Info Genesis'},
	{ value: "i", label: 'Maitre\'D'},
	{ value: "b", label: 'NCR Aloha'},
	{ value: "e", label: 'Oracle micros 3700'},
	{ value: "f", label: 'Oracle micros Simphony'},
	{ value: "a", label: 'Pictabite'},
	{ value: "g", label: 'POSitouch'},
	{ value: "j", label: 'Veloce'},
	{ value: "k", label: 'Xpient'}
];