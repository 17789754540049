import React from 'react';
import classNames from 'classnames';
import { IconButton } from './buttons';

const IconButtonGroup = props => {

	const leftIcon = props.leftIcon || 'keyboard_arrow_down';
	const rightIcon = props.rightIcon || 'keyboard_arrow_up';

	const incrementer = e => {
		const step = props.step || 1;
		if(e.currentTarget.textContent === leftIcon) {
			if(!props.incrementer || (props.min != null && props.value <= props.min)) return;
			props.incrementer(props.value - step);
		} else {
			if(!props.incrementer || (props.max != null && props.value >= props.max)) return;
			props.incrementer(props.value + step);
		}
	}
	
	const classes = classNames('IconButtonGroup', props.className);

	return (
		<div className={ classes }>

			<IconButton
				disabled={ (props.min != null && props.value <= props.min) }
				icon={ leftIcon }
				onClick={ incrementer }
			/>

			<div className="IconButtonGroup-value">{ props.value }</div>

			<IconButton
				disabled={ (props.max != null && props.value >= props.max) }
				icon={ rightIcon }
				onClick={ incrementer }
			/>

		</div>
	);
};

export default IconButtonGroup;