import { useState, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { errorCodes, errorsActionCreators } from 'store/errors';
import { userActionCreators } from 'store/user';
import { venuesActionCreators } from 'store/venues';
import useL10n from 'L10n';
import FlyoutPage from 'layout/FlyoutPage';
import VenueHeader from 'layout/VenueHeader';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import SignInDialog from 'dialogs/SignInDialog';
import ResetDialog from 'dialogs/ResetDialog';
import UserInfoDialog from 'dialogs/UserInfoDialog';
import VenuesDialog from 'dialogs/VenuesDialog';
import VenueEditorDialog from 'Locations/VenueEditorDialog';
import PosConsoleContainer from 'Products/PosConsoleContainer';
import Overview from './Overview';
import Locations from './Locations';
import Products from './Products/';
import Orders from './Orders';
import Customers from './Customers';
import Reports from './Reports';
import Admin from './Admin';
import Test from './Test';
import './VenuesContainer.scss';

const VenuesContainer = props => {
	const _t = useL10n().getText;
	const history = useHistory();
	const location = useLocation();
	const fromHome = location.state?.fromHome ?? false;	// we vary how signing in/out works depending on how we got here

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [showVenuesDialog, setShowVenuesDialog] = useState(false);
	const [showVenueEditor, setShowVenueEditor] = useState(false);
	const [showPosConsole, setShowPosConsole] = useState(false);
	const [showUserInfo, setShowUserInfo] = useState(false);
	const [showSignInDialog, setShowSignInDialogDialog] = useState(false);
	const [showResetDialog, setShowResetDialog] = useState(false);
	const [search, setSearch] = useState(process.env.REACT_APP_ENV === 'developer' ? 'Eveleigh' : '');	// seafood
	const [startPosY, setStartPosY] = useState();
	
	const venueStore = useSelector(state => state.venues);
	const userStore = useSelector(state => state.user);
	const errorStore = useSelector(state => state.errors);
	const settingStore = useSelector(state => state.settings);
	const dispatch = useDispatch();

	useEffect(() => {
		// handle authentication / authorisation errors
		if(errorStore.errors) {
			const err = errorStore.errors[0];
			if(err.code === errorCodes.INVALID_TOKEN ||
					err.code === errorCodes.UNAUTHORISED_ACCESS ||
					err.code === errorCodes.INCORRECT_PASSWORD ||
					err.code === errorCodes.LOGIN_FAILURE) {
				// user needs to sign in so clear error, then prompt to sign in again...
				if(err.code !== errorCodes.INCORRECT_PASSWORD && err.code !== errorCodes.LOGIN_FAILURE) {
					// hang onto authentication error so we can re-show signin with appropriate msg - otherwise: clear
					dispatch(errorsActionCreators.clearErrors());
				}
				setShowVenuesDialog(false);
				setShowSignInDialogDialog(true);
			}
		}
	}, [dispatch, errorStore.errors]);

	useEffect(() => {
		// initialise from localStorage only once
		if(!userStore.user) {
			const pbUser = localStorage.getItem('pb_user');
			if(pbUser) {
				const user = JSON.parse(pbUser);
				dispatch(userActionCreators.setUser(user));
			} else {
				// we decided to remove this 04/03/22 as seems unecessary as 1st access will return 403 which prompts for sign-in
				setShowSignInDialogDialog(true);
			}
		}
	}, [userStore.user, dispatch]);

	useEffect(() => {
		if(userStore.user && !venueStore.venue) {
			// if there's a user but no venue in state, get a venue
			if(venueStore.id) {
				// we have a venue id (from user selection), so fetch the identified venue
				dispatch(venuesActionCreators.fetchVenue());
			} else {
				// ok, so we've not got a venue id to work with yet, maybe there's a venue in local storage from last time?
				const pbVenue = localStorage.getItem('pb_venue');
				if(pbVenue) {
					const venue = JSON.parse(pbVenue);
					dispatch(venuesActionCreators.setVenue(venue));
				} else {
					// nope, no venue info anywhere, so prompt the user to select one
					if(!userStore.user?.hasPrivilegedAccess) {
							dispatch(venuesActionCreators.fetchVenuesByOwner());
					}
					setShowVenuesDialog(true);
				}
			}
		}
	}, [venueStore.venue, venueStore.id, userStore.user, dispatch]);

	const setVenue = id => {
		dispatch(venuesActionCreators.setSelectedVenue(id));
		setShowVenuesDialog(false);
	}

	const showVenueDialog = e => {
		if(!userStore.user?.hasPrivilegedAccess) {
			dispatch(venuesActionCreators.fetchVenuesByOwner());
		}
		setShowVenuesDialog(true);
	}

	const hideVenuesDialogHander = btn => {
		setShowVenuesDialog(false);
		if(btn === 'btnAddNew') {
			setShowVenueEditor(true);
		}
	}

	const hideSignInDialogHandler = (btn, data) => {
		// console.log(btn, data);
		switch (btn) {
			case 'btnOk': dispatch(userActionCreators.login(data.username, data.password)); break;
			case 'btnReset': setShowResetDialog(true); break;
			default: history.push('/');	break;	// user selects cancel, go back to home page (cancel only visible if came from homepage)
		}
		setShowSignInDialogDialog(false);
	}

	const onSearchChange = e => {
		setSearch(e.target.value);
	}

	const fetchVenues = e => {
		dispatch(venuesActionCreators.fetchVenues(search));
	}

	const togglePosConsole = (e, y) => {
		// having the PosConsole as a child of Products avoids any positioning issues caused with it being buried deeper in the DOM
		if(y) setStartPosY(y);	// y can vary due to responsive page, but x is measured from the right
		setShowPosConsole(!showPosConsole);
	}

	const signInOutHandler = e => {
		if(process.env.REACT_APP_ENV === 'developer') {
			setShowUserInfo(true);
		} else {
			if(userStore.user) {
				// sign out
				setShowConfirmDialog(true);
			} else {
				// sign in
				setShowSignInDialogDialog(true);
			}
		}
	}

	const hideResetDialogHandler = payload => {
		// console.log(payload);
		if(payload.email) {
			dispatch(userActionCreators.resetPassword(payload.email));
			setShowSignInDialogDialog(true);
		}
		setShowResetDialog(false);
	}

	const onHideConfirmDialog = response => {
		if(response.button === 'btnOk') {
			dispatch(userActionCreators.logout());
		}
		setShowConfirmDialog(false);
	}

	const onUserInfoHide = e => {
		setShowUserInfo(false);
	}

	const signIn = e => {
		setShowUserInfo(false);
		setShowSignInDialogDialog(true);
	}

	const signOut = e => {
		setShowUserInfo(false);
		dispatch(userActionCreators.logout());
	}

	const userPrivilegeChangeHandler = e => {
		const user = { ...userStore.user };
		user.hasPrivilegedAccess = !user.hasPrivilegedAccess;
		dispatch(userActionCreators.setUser(user));
	}

	const fetchCuisineTypes = () => {
		dispatch(venuesActionCreators.fetchCuisineTypes());
	}

	const hideVenueEditorHandler = e => {
		setShowVenueEditor(false);
	}

	const saveVenue = (venue, makeSelected) => {
		setShowVenueEditor(false);
		// console.log(venue, makeSelected);
		dispatch(venuesActionCreators.addVenue(venue, makeSelected));
	}

	return (
		<FlyoutPage signInOutHandler={ signInOutHandler } fullscreen={ settingStore.fullscreen }>

			<div className="Venues container-fluid">
				<VenueHeader className="row" venue={ venueStore.venue } venueInfoClickHandler={ showVenueDialog }/>

				<Switch>
					<Route path='/venues/home'><Overview/></Route>
					<Route path='/venues/locations/'><Locations/></Route>
					<Route path='/venues/products/'>
						<Products showPosConsoleButton={ !!venueStore.venue?.receivesOrdersInOwnPOS } togglePosConsole={ togglePosConsole }/>
					</Route>
					<Route path='/venues/orders/'><Orders/></Route>
					<Route path='/venues/customers/'><Customers/></Route>
					<Route path='/venues/reports/'><Reports/></Route>
					<Route path='/venues/admin/'><Admin/></Route>
					<Route path='/venues/test/'><Test/></Route>
				</Switch>
			</div>

			<VenueEditorDialog
				show={ showVenueEditor }
				venueStore={ venueStore }
				fetchCuisineTypes={ fetchCuisineTypes }
				saveVenue={ saveVenue }
				onHide={ hideVenueEditorHandler }
			/>

			<VenuesDialog
				show={ showVenuesDialog }
				search={ search }
				user={ userStore.user }
				venueStore={ venueStore }
				onChange={ onSearchChange }
				onHide={ hideVenuesDialogHander }
				fetchVenues={ fetchVenues }
				setVenue={ setVenue }
			/>

			<SignInDialog
				show={ showSignInDialog }
				err={ errorStore.errors ? errorStore.errors[0] : null }
				allowCancel={ fromHome }
				onHide={ hideSignInDialogHandler }
			/>

			<ResetDialog
				show={ showResetDialog }
				onHide={ hideResetDialogHandler }
			/>

			<PosConsoleContainer
				show={ showPosConsole }
				y={ startPosY }
				close={ togglePosConsole }
			/>

			<UserInfoDialog
				show={ showUserInfo }
				userStore={ userStore }
				privilegeChangeHandler={ userPrivilegeChangeHandler }
				onHide={ onUserInfoHide }
				signIn={ signIn }
				signOut={ signOut }
			/>

			<ConfirmDialog
				action={ _t('HDG_SIGNOUT', true) }
				message={ _t('TXT_CONFIRM_SIGNOUT') }
				show={ showConfirmDialog }
				onHide={ onHideConfirmDialog }
			/>

		</FlyoutPage>

	);

};

export default VenuesContainer;