import { useEffect, useRef, useState } from 'react';
import utilities from '../../services/utilities';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../components/Modal';
import useForm from '../../components/Form';
import { FormCheckbox, FormDropdown, FormInput, FormLabel } from '../../components/formControls';
import ValidationMessage from '../../components/ValidationMessage';

const AddPrinterDialog = props => {

	const [families, setFamilies] = useState();
	const [models, setModels] = useState();

	useEffect(() => {
		if(props.show) {
			formRef.current.reset({});
			const families = Object.keys(props.types);
			setFamilies(families);
		}
	}, [props.show, props.types]);

	const formSave = e => {
		// const payload = {
		// 	printerId: form.data.printerId,
		// 	printerType: form.data.printerType,
		// 	name: form.data.name,
		// 	enabled: !!form.data.enabled,
		// }
		const payload = { ...form.data };
		delete(payload.family);
		payload.enabled = !!payload.enabled;
		props.onHide(payload);
	}

	const form = useForm(formSave, validationRules);
	const formRef = useRef(form);

	const onFamilyChange = (e, field, value, label) => {
		setModels(props.types[label].models);
		form.onChange(null, 'printerType', null);
	}

	return (
		<Modal show={ props.show } draggable>
			<ModalHeader>Add Printer</ModalHeader>
			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<div className="form-row">
					<FormLabel htmlFor="family" className="col-sm-4" label="_PRINTER_FAMILY"/>
					<div className="col-sm-8">
						<FormDropdown options={ families } form={ form } field="family" onChange={ onFamilyChange }/>
					</div>
				</div>
				<div className="form-row">
					<FormLabel htmlFor="printerType" className="col-sm-4" label="_PRINTER_MODEL"/>
					<div className="col-sm-8">
						<FormDropdown disabled={ !form.data?.family } options={ models } form={ form } field="printerType"/>
					</div>
				</div>
				<div className="form-row">
					<FormLabel htmlFor="printerId" className="col-sm-4" label="_PRINTER_ID"/>
					<div className="col-sm-8">
						<FormInput form={ form } field="printerId"/>
					</div>
				</div>
				<div className="form-row">
					<FormLabel htmlFor="name" className="col-sm-4" label="_PRINTER_NAME"/>
					<div className="col-sm-8">
						<FormInput form={ form } field="name"/>
					</div>
				</div>
				<div className="form-row">
					<FormLabel htmlFor="enabled" className="col-sm-4" label="_PRINTER_ENABLED"/>
					<div className="col-sm-8">
						<FormCheckbox form={ form } field="enabled"/>
					</div>
				</div>

			</ModalBody>
			<ModalFooter className="Modal-footer_buttons">

				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">{ utilities.getText('LBL_CANCEL') }</button>
				<button className="btn btn-primary" disabled={ false } onClick={ form.onSave } id="btnOk">{ utilities.getText('TTP_PRINTER_ADD') }</button>

			</ModalFooter>
		</Modal>
	);
};

export default AddPrinterDialog;

const validationRules = {
	family: true,
	printerType: true,
	printerId: true,
	name: true
}