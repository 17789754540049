import React from 'react';
import classNames from 'classnames';
import './controls.scss';

export const Checkbox = ({ label, labelFirst, children, className, disabled, title, ...inputProps }) => {
	return (
		<div disabled={ disabled } className={ classNames("Checkbox", className, { labelFirst: labelFirst }) } title={ title }>
			<label className="Checkbox-label">
				<input type="checkbox" className="Checkbox-input sr-only" disabled={ disabled } { ...inputProps }/>
				<span className="Checkbox-text">{ label || children }</span>
			</label>
		</div>
	);
}

export const Radio = ({ label, labelFirst, children, className, disabled, title, ...inputProps }) => {
	return (
		<div disabled={ disabled } className={ classNames("Radio", className, { labelFirst: labelFirst }) } title={ title }>
			<label className="Radio-label">
				<input type="radio" className="Radio-input sr-only" disabled={ disabled } { ...inputProps }/>
				<span className="Radio-text">{ label || children }</span>
			</label>
		</div>
	);
}

export const InputWithButton = ({ className, isLoading, disabled, buttonDisabled, onButtonClick, onButtonKeyUp, icon, focus, ...inputProps }, ref) => {
	const cmpClassName = classNames('InputWithButton', 'input-group', className);
	const btnClassName = classNames('btn', { 'async': isLoading });
	const inpClassName = classNames('form-control', { 'focus': focus });	// this is useful if you want the control to get focus when a modal opens
	const id = inputProps.name || inputProps.id;
	const btnId = id ? 'btn' + id.substr(0, 1).toUpperCase() + id.substr(1) : null;
	return (
		<div className={ cmpClassName }>
			<input type="text" className={ inpClassName } disabled={ disabled } { ...inputProps }/>
			<div className="input-group-append">
				<button className={ btnClassName } type="button" disabled={ disabled | isLoading | buttonDisabled } onClick={ onButtonClick } onKeyUp={ onButtonKeyUp } id={ btnId }>
					<i className="material-icons">{ icon }</i>
				</button>
			</div>
		</div>
	);
}

export const Slider = ({ className, onChange, ...inputProps }) => {
	return (
		<div className="Slider">
			<input type="range" { ...inputProps } onChange={ onChange }/>
		</div>
	);
}