import { Fragment } from 'react';
import { IconButton } from './buttons';

const Paginator = props => {

	const pageHandler = e => {
		if(e.currentTarget.textContent === 'keyboard_arrow_left') {
			if(props.page > 1) {
				props.fetchPage(-1);
			}
		} else {
			if(props.hasNextPage) {
				props.fetchPage(1);
			}
		}
	}

	if(props.alwaysVisible || props.page > 1 || props.hasNextPage) {
		return (
			<Fragment>
				<IconButton title="previous page" disabled={ props.page === 1 } icon="keyboard_arrow_left" onClick={ pageHandler }/>
				<div className="page-info" title="current page number">{ props.page }</div>
				<IconButton title="next page" disabled={ !props.hasNextPage } icon="keyboard_arrow_right" onClick={ pageHandler }/>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default Paginator;