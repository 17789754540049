import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import './UploaderPanel.scss';

const UploaderPanel = props => {

	const uploaderPanelRef = useRef();
	const uploaderButton = useRef();

	const preventDefaults = e => {
		e.preventDefault();
		e.stopPropagation();
	}

	const addClass = e => {
		e.dataTransfer.dropEffect = 'copy';
		uploaderPanelRef.current.classList.add('UploaderPanel_dragover');
	}

	const removeClass = e => {
		uploaderPanelRef.current.classList.remove('UploaderPanel_dragover');
	}

	const onDrop = e => {
		const dt = e.dataTransfer;
		const { files } = dt;
		// console.log(files);
		props.onSelectFiles(props.id, fileListToArray(files));
	}

	const selectFile = e => {
		uploaderButton.current.click();
	}
	
	const onFileSelect = e => {
		const files = e.target.files;
		// console.log(files);
		props.onSelectFiles(props.id, fileListToArray(files));
	}

	useEffect(() => {
		// console.log('initialise drag and drop...');
		const dropZone = uploaderPanelRef.current;
		['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropZone.addEventListener(eventName, preventDefaults, false));
		['dragenter', 'dragover'].forEach(eventName => dropZone.addEventListener(eventName, addClass, false));
		['dragleave', 'drop'].forEach(eventName => dropZone.addEventListener(eventName, removeClass, false));
		dropZone.addEventListener('drop', onDrop, false);

		return () => {
			// console.log('deactivate drag and drop...');
			['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropZone.removeEventListener(eventName, preventDefaults, false));
			['dragenter', 'dragover'].forEach(eventName => dropZone.removeEventListener(eventName, addClass, false));
			['dragleave', 'drop'].forEach(eventName => dropZone.removeEventListener(eventName, removeClass, false));
			dropZone.removeEventListener('drop', onDrop, false);
		}
		// should only run once, not on changes so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fileText = props.allowMultiple ? 'files' : 'file';
	const dropMessage = `Drop ${ fileText } here to upload or`;
	const buttonMessage = `Select ${ fileText }`;
	return (
		<div ref={ uploaderPanelRef } className="UploaderPanel" id={ props.id }>
			<article dangerouslySetInnerHTML={{ __html: props.children }}/>
			<span className="material-icons">file_upload</span>
			<p>{ dropMessage }</p>
			<button type="button" className={ classNames('btn', { async: props.loading })} onClick={ selectFile }>
				{ buttonMessage }
			</button>
			<input ref={ uploaderButton } type="file" onChange={ onFileSelect } multiple={ props.allowMultiple }/>
		</div>
	);
}

export default UploaderPanel;

const fileListToArray = fileList => {
	const files = [];
	for(let i = 0, l = fileList.length; i < l; i++) {
		files.push(fileList.item(i));
	}
	return files;
}