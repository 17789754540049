import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import isMonday from 'date-fns/isMonday';
import previousMonday from 'date-fns/previousMonday';
import dates from 'services/dates';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import { actionTypes, ordersActionCreators } from 'store/orders';
import useForm from 'components/Form';
import { FormDatePicker, FormDropdown, FormLabel } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { InputWithButton } from 'components/controls';
import { Pane, PaneHeader, PaneBody, PaneFooter } from 'components/Pane';
import { Row, Cell, Col } from 'components/Table';
import ValidationMessage from 'components/ValidationMessage';
import MessageDialog from 'dialogs/MessageDialog';
import OrderDetailsDialog from 'dialogs/OrderDetailsDialog';
import Paginator from 'components/Paginator';

const Payouts = props => {
	const { getText, getDistributionType } = useL10n();

	const fromOptions = [
		{ value: 0, label: 'today' },		// _t('TXT_TODAY')
		{ value: 1, label: 'yesterday' },
		{ value: 2, label: 'this week' },
		{ value: 3, label: 'this month' },
		{ value: 4, label: 'from date:' }
	];

	const filterOptions = [
		{ value: 'paymentPendingOrders', label: 'payout pending' },
		{ value: 'paymentReceivedOrders', label: 'payout received' },
		{ value: 'canceledOrders', label: 'canceled orders' },
		{ value: 'errorOrders', label: 'payment errors' },
		{ value: 'submittedOrders', label: 'submitted orders' },
		{ value: 'unsubmittedOrders', label: 'unsubmitted orders' },
		{ value: 'allOrders', label: 'all orders' }
	];

	const [options, setOptions] = useState(fromOptions);
	const [showFromDate, setShowFromDate] = useState(false);
	const [showMessageDialog, setShowMessageDialog] = useState();
	const [showOrderDetailsDialog, setShowOrderDetailsDialog] = useState(false);
	const [page, setPage] = useState(1);
	const [currentOrder, setCurrentOrder] = useState();
	const orders = useSelector(state => state.orders);
	const orderBalances = orders.orderBalances;
	const currencySymbol = useSelector(state => state.venues.venue?.currencySymbol);
	// const settingStore = useSelector(state => state.settings);
	const user = useSelector(state => state.user.user);
	const venue = useSelector(state => state.venues.venue);
	const timeZone = venue?.openingHours?.timezone.timezone;
	const [today, setToday] = useState();

	const dispatch = useDispatch();

	const form = useForm(null, null, {
		reportType: 'paymentPendingOrders',
		fromOption: 0
		// I was storing these 2 in settings but hiding for now as not as useful as 1st thought
		// reportType: settingStore.payoutsReportType,
		// fromOption: settingStore.payoutsFromOption
	});
	const formRef = useRef(form);

	useEffect(() => {
		if(orders.orderBalances?.nextPageCancelled) {
			// due to the fact the server doesn't always know another page is actually available, sometimes we need to undo the next page
			setPage(p => p - 1);
		}
	}, [orders.orderBalances]);

	useEffect(() => {
		if(venue) {
			dispatch(ordersActionCreators.fetchOrderBalances());
		}
		return () => {
			dispatch(ordersActionCreators.clearOrderBalances());
		}
	}, [venue, dispatch]);

	useEffect(() => {
		// set today to be today in appropriate TZ
		const today = dates.getLocalToday(timeZone);
		setToday(today);
	}, [timeZone]);

	useEffect(() => {
		// a bit of data massaging...
		if(typeof form.data.fromOption === 'string') {
			formRef.current.onChange(null, 'fromOption', parseInt(form.data.fromOption, 10));
		}
	}, [form.data]);

	const fetchPageOffset = dir => {
		const newPage = page + dir;
		setPage(newPage);
		fetchPage(newPage);
	}

	const fetchPage = page => {
		let from;
		let to;
		if(typeof page !== 'number') setPage(1);
		const now = new Date();
		const todayInt = today.getTime();
		const pageSize = Math.round((window.innerHeight - 400) / 40);	// - a) / b) a = rough height of page minus rows, b = height of row
		// prep the payload based on chosen settings...
		switch(form.data.fromOption) {
			case 0:	// today
				from = todayInt;
				to = todayInt + dates.day - 1;
				break;
			case 1:	// yesterday
				from = todayInt - dates.day;
				to = todayInt - 1;
				break;
			case 2:	// this week
				let mondayInt;
				if(isMonday(now)) {
					mondayInt = todayInt;
				} else {
					mondayInt = previousMonday(now).getTime();
				}
				from = mondayInt;
				to = from + (7 * dates.day) - 1;
				break;
			case 3:	// this month
				// note: this month will be the month of the user running the report, which may differ from this month from the PoV of the venue
				let firstDay = dates.zonedDateToUtc(timeZone, now.getFullYear(), now.getMonth(), 1);
				const days = getDaysInMonth(firstDay);
				from = firstDay.getTime();	// midnight local to the venue
				to = from + (days * dates.day) - 1;
				break;
			case 4:
				if(!form.data.fromDate) return;
				from = dates.zonedDateToUtc(timeZone, form.data.fromDate).getTime();
				to = form.data.toDate ? dates.zonedDateToUtc(timeZone, form.data.toDate).getTime() + dates.day - 1 : null;
				break;
			default: return;
		}
		// re-fetch data on every form change...
		const payload = {
			reportType: form.data.reportType,
			from: from,
			pagingOffset: typeof page === 'number' ? (page - 1) * pageSize : 0,
			pagingSize: pageSize
		};
		if(to) payload.to = to;
		// console.log(payload);
		dispatch(ordersActionCreators.fetchOrderBalances(payload));
	}

	const changeHandler = (e, field, value, label) => {
		// console.log(e, field, value, label);
		switch(field) {
			case 'reportType':
				// dispatch(settingsActionCreators.updateSetting('payoutsReportType', value));
				break;
			case 'fromOption':
				if(value === '4') {				// 'from date:' selected so switch to date control
					setShowFromDate(true);
				} else {
					// dispatch(settingsActionCreators.updateSetting('payoutsFromOption', value));
					formRef.current.onChange(null, 'toDate', null);
				}
				break;
			case 'fromDate':
				fromOptions[4] = { value: 4, label: 'from date: ' + dates.format(value)};
				setOptions(fromOptions);
				setShowFromDate(false);
			break;
			default:
				// looks like from date chosen then no date selected so tidy up
				formRef.current.onChange(null, 'fromOption', null);
				setShowFromDate(false);
				break;
		}
	}

	const hideMessageDialog = () => {
		setShowMessageDialog();
	}

	const infoClickHandler = e => {
		if(e.currentTarget.id === 'btnStripePendingBalance') {
			setShowMessageDialog(getText('TXT_PENDING_BALANCE'));
		} else {
			setShowMessageDialog(getText('TXT_AVAILABLE_BALANCE'));
		}
	}

	const orderDetailsHandler = order => {
		setCurrentOrder(order);
		setShowOrderDetailsDialog(true);
	}

	const hideOrderDetailsDialog = btn => {
		console.log('dialog returned:', btn);
		setShowOrderDetailsDialog(false);
	}

	const renderOrders = () => {
		return orderBalances.orders.map((order, ix) => {
			return <Row key={ 'po-' + ix }>
				<Cell>{ order.externalOrderId }</Cell>
				<Cell>{ dates.format(order.timestamp, dates.styles.DATE, timeZone) }</Cell>
				<Cell>{ getDistributionType(order) }</Cell>
				<Cell>{ getText(utilities.getPaymentStatusKey(order.paymentStatus)) }</Cell>
				<Cell>{ utilities.formatPrice(order.netAmountForResto, currencySymbol) }</Cell>
				<Cell>{ dates.format(order.netAmountAvailableOn, dates.styles.DATE, timeZone) }</Cell>
				<IconButton title="details" icon="more_horiz" onClick={ () => orderDetailsHandler(order) }/>
			</Row>
		});
	}

	// console.log(orderBalances, page);

	return (
		<div className="animated fadeIn pb20">

			<div className="row mb20">

				<div className="col-auto row">
					<FormLabel className="col-auto" htmlFor="fromDate" label="_PENDING"/>
					<div className="col-auto">
						<InputWithButton
							id="stripePendingBalance"
							isLoading={ !orderBalances?.stripeBalances }
							icon="info_outline"
							readOnly
							value={ utilities.formatPrice(orderBalances?.stripeBalances.stripePendingBalance, currencySymbol) }
							onButtonClick={ infoClickHandler }
						/>
					</div>
				</div>

				<div className="col row">
					<FormLabel className="col-auto" htmlFor="fromDate" label="_AVAILABLE"/>
					<div className="col-auto">
						<InputWithButton
							id="stripeAvailableBalance"
							isLoading={ !orderBalances?.stripeBalances }
							icon="info_outline"
							readOnly
							value={ utilities.formatPrice(orderBalances?.stripeBalances.stripeAvailableBalance, currencySymbol) }
							onButtonClick={ infoClickHandler }
						/>
					</div>
				</div>

			</div>

			<hr className="mb10"/>

			<div className="row mb20">

				<div className="col">
					<FormLabel htmlFor="reportType" className="pt0" label="_FILTER"/>
					<FormDropdown className="payout-filter" form={ form } field="reportType" id="reportType" options={ filterOptions } onChange={ changeHandler }/>
				</div>

				<div className="col">
					<div className="row">
						<div className="col">
							<FormLabel htmlFor="fromOption" className="pt0" label="_DATES"/>
							{ showFromDate === true
									? <FormDatePicker form={ form } field="fromDate" open={ true } onChange={ changeHandler } onClose={ changeHandler }/>
									: <FormDropdown form={ form } field="fromOption" options={ options } onChange={ changeHandler }/>
							}
							</div>

						<div className="col">
							<FormLabel htmlFor="toDate" className="pt0" label="_DATE_TO" disabled={ form.data.fromOption !== 4 }/>
							<FormDatePicker form={ form } field="toDate" disabled={ form.data.fromOption !== 4 }/>
						</div>
					</div>
				</div>

				<div className="col-auto row no-gutters align-items-end">
					<IconButton
						className="btn-primary"
						async={ orders.loading === actionTypes.fetchOrderBalancesRequest }
						disabled= { form.data.fromOption == null || (form.data.fromOption === 4 && !form.data.toDate) }
						icon="refresh"
						onClick={ fetchPage }
					/>
				</div>

			</div>

			{ orderBalances?.orders != null &&
					((orderBalances.orders.length === 0 && page === 1)
						?
							<ValidationMessage open={ true } message={ getText('TXT_NO_DATA', false, getText('TXT_PAYOUT')) }/>
						:
							<Pane className="Pane-paginated" id="payoutsReport" role="table">
								<PaneHeader className={ 'rewardsStore.loading' === 'FETCH_CUSTOMERLOYALTYSTATS_REWARDS_REQUEST' ? 'async' : '' }>
									<Col>Order Id</Col>
									<Col>Order Date</Col>
									<Col>Distribution</Col>
									<Col>Payment Status</Col>
									<Col>Net Amount</Col>
									<Col>Available On</Col>
								</PaneHeader>
								<PaneBody>
									{ renderOrders() }
								</PaneBody>
								<PaneFooter className="toolbar toolbar_rightOnly">
									<Paginator
										page={ page }
										alwaysVisible={ true }
										hasNextPage={ orderBalances?.hasNextPage }
										fetchPage={ fetchPageOffset }
									/>
								</PaneFooter>
							</Pane>
					)
			}

			<MessageDialog
				title="Help"
				message={ showMessageDialog || '' }
				show={ !!showMessageDialog }
				onHide={ hideMessageDialog }
			/>

			<OrderDetailsDialog
				show={ showOrderDetailsDialog }
				privileged={ user?.hasPrivilegedAccess }
				order={ currentOrder }
				from="Payouts"
				onHide={ hideOrderDetailsDialog }
			/>

		</div>
	);
}

export default Payouts;