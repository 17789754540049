import React from 'react';
import './CustomerRating.scss';

const CustomerRating = props => {

	if(!props.item.menuItemId) return null;

	const createStars = rating => {
		const stars = [];
		for(let i = 0; i < 5; i++, rating--) {
			if(rating > 0.5) {
				stars.push(<i key={ i } className="material-icons">star</i>);
			} else if(rating === 0.5) {
				stars.push(<i key={ i } className="material-icons">star_half</i>);
			} else {
				stars.push(<i key={ i } className="material-icons">star_border</i>);
			}
		}
		return stars;
	}

	if(props.item.starsRating) {
		return (
			<div className="CustomerRating">
				<div className="CustomerRating-rating">{ props.item.starsRating }</div>
				<div>
					<div className="CustomerRating-stars">
						{ createStars(props.item.starsRating) }
					</div>
					<div className="CustomerRating-ratings">
						{ props.item.starsVoteCount } { props.item.starsVoteCount === 1 ? 'rating' : 'ratings' }
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="CustomerRating">
				<span>(unrated)</span>
			</div>
		);
	}

};

export default CustomerRating;